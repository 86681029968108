import { IconComponent } from "Core/types/Icon";

const CheckCircleIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.4922 32.5039L28.4922 41.5039L46.4922 23.5039M62.9922 32.5039C62.9922 49.0725 49.5607 62.5039 32.9922 62.5039C16.4236 62.5039 2.99219 49.0725 2.99219 32.5039C2.99219 15.9354 16.4236 2.50391 32.9922 2.50391C49.5607 2.50391 62.9922 15.9354 62.9922 32.5039Z"
      stroke="currentColor"
      strokeWidth="4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.12"
      d="M32.9922 62.5039C49.5607 62.5039 62.9922 49.0725 62.9922 32.5039C62.9922 15.9354 49.5607 2.50391 32.9922 2.50391C16.4236 2.50391 2.99219 15.9354 2.99219 32.5039C2.99219 49.0725 16.4236 62.5039 32.9922 62.5039Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckCircleIcon;
