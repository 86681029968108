import { EditorState, Modifier } from "draft-js";

const removeSelection = (state: EditorState, setState: (e: EditorState) => void) => {
  const selection = state.getSelection();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const endKey = selection.getEndKey();
  const endOffset = selection.getEndOffset();
  if (startKey !== endKey || startOffset !== endOffset) {
    const newContent = Modifier.removeRange(state.getCurrentContent(), selection, "forward");
    const tempEditorState = EditorState.push(state, newContent, "remove-range");
    setState(tempEditorState);
    return tempEditorState;
  }
  return state;
};

export default removeSelection;
