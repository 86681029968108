import getTopics from "./getTopics";
import { ForumTopic } from "Forum/types/Topic";

type Response = ForumTopic;

type Props = (id: string) => Promise<Response>;

const getTopic: Props = async (id) => {
  const topics = await getTopics({ limit: 100, offset: 0 });
  const topic = topics.items.find((x) => x._id === id) as ForumTopic;
  return topic;
};

export default getTopic;
