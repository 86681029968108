import HowToPage from "./pages/HowToPage";
import { Navigate, Route, Routes } from "react-router-dom";

const HowTo: React.FC = () => {
  return (
    <Routes>
      <Route index element={<HowToPage />} />
      <Route path="*" element={<Navigate to="/howto" />} />
    </Routes>
  );
};
export default HowTo;
