import styles from "./styles.module.scss";
import UserCancelIcon from "Core/components/Icon/userCancel";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import { MembershipUpdateNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<MembershipUpdateNotificationData>;
};

const NotificationButtonItemMembershipUpdate: React.FC<Props> = ({ username, data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <UserCancelIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <strong>{username} has waived</strong> the membership fee. You will not be billed in the upcoming months.
        </div>
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemMembershipUpdate;
