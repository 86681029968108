type PropEvent = {
  preventDefault: () => void;
};

type Props = <T extends PropEvent>(callback?: (e: T) => void) => (e: T) => void;

const preventDefault: Props = (callback) => (e) => {
  e?.preventDefault();
  callback?.(e);
};

export default preventDefault;
