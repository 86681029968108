type Props = (value: string, month?: "short" | "long") => string;

const getRelativeDate: Props = (isoDateString, month = "long") => {
  const date = new Date(isoDateString);
  const currentDate = new Date();

  const timeDifferenceInSeconds = Math.floor((currentDate.getTime() - date.getTime()) / 1000);

  if (timeDifferenceInSeconds < 60) {
    return `${timeDifferenceInSeconds} seconds ago`;
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (
    date.getDate() === currentDate.getDate() - 1 &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  ) {
    return "yesterday";
  } else {
    return date.toLocaleDateString("en-US", {
      month,
      day: "numeric",
      year: "numeric",
    });
  }
};

export default getRelativeDate;
