import { IconComponent } from "Core/types/Icon";

const BoldIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.528 9.37295C13.673 10.1229 15.2 12.054 15.2 14.318C15.2 17.234 12.667 19.5979 9.54299 19.5979H0.799988V0.397949H7.48599C10.61 0.397949 13.143 2.76195 13.143 5.67795C13.143 7.11695 12.527 8.41995 11.528 9.37295ZM7.48599 9.03795C9.47399 9.03795 11.086 7.53395 11.086 5.67795C11.086 3.82295 9.47399 2.31795 7.48599 2.31795H2.85699V9.03795H7.48599V9.03795ZM2.85699 17.6779V10.9579H9.54299C11.531 10.9579 13.143 12.463 13.143 14.318C13.143 16.174 11.531 17.6779 9.54299 17.6779H2.85699V17.6779Z"
      fill="currentColor"
    />
  </svg>
);

export default BoldIcon;
