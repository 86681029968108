import { IconComponent } from "Core/types/Icon";

const NotificationIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.65278 17.5001L5.90278 17.8334C7.02778 19.3334 9.27778 19.3334 10.4028 17.8334L10.6528 17.5001M1.63847 12.9581L1.7246 13.1356C2.27774 14.276 3.43376 15.0001 4.70118 15.0001H11.5519C12.6379 15.0001 13.6488 14.4452 14.2319 13.529C15.0551 12.2356 14.837 10.5388 13.7136 9.49552L13.6771 9.46155C12.9143 8.75322 12.5388 7.72128 12.6679 6.6884L12.7218 6.25763C13.0265 3.81991 11.1257 1.66675 8.66903 1.66675H7.24994C4.79624 1.66675 2.89779 3.81728 3.20213 6.25203L3.26826 6.78107C3.39005 7.75534 3.03417 8.7285 2.31259 9.39435C1.327 10.3039 1.05317 11.7514 1.63847 12.9581Z"
      stroke="currentColor"
      strokeWidth="1.66"
      strokeLinecap="round"
    />
  </svg>
);

export default NotificationIcon;
