import useAuth from "Auth/context";
import academyApi from "Academy/api";
import AcademyRelated from "./Related";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { permissions } from "Auth/constants";
import { PostContent } from "Content/components";
import { dateUtils, draftjsUtils } from "Core/utils";
import { AcademyEditButton } from "Academy/components";
import { useGet, useMounted, useUpdated } from "Core/hooks";
import { EmptyContainer, PageHeader } from "Core/components";

const AcademyPage: React.FC = () => {
  const auth = useAuth();
  const { academySlug } = useParams();
  const academy = useGet(() => academyApi.getAcademy(String(academySlug)));
  const tag = academy.data?.tags.map((x) => x.tag).join(", ");

  useMounted(() => {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0 });
    }, 50);
  });

  useUpdated(() => {
    academy.reset();
    academy.request().then(() => {
      window.scroll({ top: -1, left: 0 });
    });
  }, academySlug);

  return (
    <div className={styles.academyPage}>
      <EmptyContainer empty={!academy.data} loading={academy.loading} emptyText="Academy page could not be found.">
        <PageHeader
          back
          title={draftjsUtils.getPlainText(academy.data?.title.value)}
          subtitle={`${tag ? `${tag}  •  ` : ""}${dateUtils.getDateString(academy.data?.createdAt)}`}
          extra={
            auth.user?.role === permissions.admin && !!academy.data?._id ? (
              <AcademyEditButton blogId={academy.data._id} />
            ) : undefined
          }
        />
        <div className={styles.academyPageContent}>
          <div className={styles.academyPageCard}>
            <div className={styles.academyPageCardContent}>
              {/* <div className={styles.academyPageCardTitle}>{draftjsUtils.getPlainText(academy.data?.title.value)}</div> */}
              <div>
                <PostContent value={academy.data?.content || []} />
              </div>
              {/* <AcademyPageSubscribe slug={String(academySlug)} /> */}
            </div>
            {/* <LikeDislike /> */}
          </div>
          <AcademyRelated slug={String(academySlug)} />
        </div>
      </EmptyContainer>
    </div>
  );
};

export default AcademyPage;
