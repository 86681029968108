import getTopic from "./getTopic";
import getTopics from "./getTopics";
import getThread from "./getThread";
import likeThread from "./likeThread";
import replyThread from "./replyThread";
import createThread from "./createThread";
import updateThread from "./updateThread";
import deleteThread from "./deleteThread";
import searchThreads from "./searchThreads";
import getTopicThreads from "./getTopicThreads";
import deleteThreadReply from "./deleteThreadReply";
import updateThreadReply from "./updateThreadReply";

const forumApi = {
  getTopic,
  getThread,
  getTopics,
  likeThread,
  replyThread,
  createThread,
  updateThread,
  deleteThread,
  searchThreads,
  getTopicThreads,
  deleteThreadReply,
  updateThreadReply,
};

export default forumApi;
