import { AtomicBlockUtils, EditorState } from "draft-js";

type Props = (editorState: EditorState, src: string) => EditorState;

const createVideo: Props = (editorState, src) => {
  const contentState = editorState.getCurrentContent();
  const newContentState = contentState.createEntity("draft-js-video-plugin-video", "IMMUTABLE", { src });

  var entityKey = newContentState.getLastCreatedEntityKey();
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

  return newEditorState;
};

export default createVideo;
