import forumApi from "Forum/api";
import { useState } from "react";
import styles from "./styles.module.scss";
import CloseIcon from "Core/components/Icon/close";
import SearchIcon from "Core/components/Icon/search";
import { useDebounce, useGet, useUpdated } from "Core/hooks";
import { ThreadCard, ThreadSortSelect } from "Forum/components";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, EmptyContainer, Input, PageHeader, Pagination } from "Core/components";

const ForumSearchPage: React.FC = () => {
  const navigate = useNavigate();
  const { topicId } = useParams();
  const debounce = useDebounce(500);
  const [params, setParams] = useSearchParams();
  const [sort, setSort] = useState(params.get("s") || "newest");
  const [keyword, setKeyword] = useState(params.get("k") || "");
  const [page, setPage] = useState(Number(params.get("p") || "1"));
  const topics = useGet(() => forumApi.searchThreads({ limit: 10, offset: (page - 1) * 10 }, keyword, sort, topicId), {
    initialLoad: !!keyword,
  });

  const updateParams = (newPage: number, newSort: string, newKeyword: string) => {
    setParams(
      (p) => {
        p.set("s", newSort);
        p.set("k", newKeyword);
        p.set("p", String(newPage));
        return p;
      },
      { replace: true }
    );
  };

  const onClear = () => {
    navigate(-1);
  };

  const onBlur = () => {
    if (!keyword) {
      navigate(-1);
    }
  };

  useUpdated(() => {
    if (keyword) {
      topics.request();
      updateParams(page, sort, keyword);
    } else {
      onClear();
    }
  }, page);

  useUpdated(() => {
    debounce(() => {
      if (page === 1) {
        if (keyword) {
          updateParams(page, sort, keyword);
          if (keyword.length > 2) {
            topics.request();
          } else {
            topics.reset();
          }
        } else {
          onClear();
        }
      } else {
        setPage(1);
      }
    });
  }, [keyword, sort]);

  return (
    <div className={styles.forumSearchPage}>
      <PageHeader
        back
        title="Join the conversation"
        subtitle="Connect with creators to share ideas and engage in vibrant discussions."
      />
      <div className={styles.forumSearchPageContainer}>
        <div className={styles.forumSearchPageContent}>
          <div className={styles.forumSearchPageHeader}>
            <Input
              autoFocus
              onBlur={onBlur}
              value={keyword}
              onChange={setKeyword}
              before={<SearchIcon />}
              placeholder="Search the Forum"
              className={styles.forumSearchPageInput}
              after={
                keyword ? (
                  <Button className={styles.clear} onClick={onClear}>
                    <CloseIcon />
                  </Button>
                ) : undefined
              }
            />
            {topics.data && !!topics.data.count && (
              <div className={styles.forumSearchPageFilter}>
                <ThreadSortSelect value={sort} onChange={setSort} variant="outline" />
              </div>
            )}
          </div>
          <EmptyContainer
            loading={topics.loading}
            empty={!!keyword && topics.data && !topics.data?.items.length}
            emptyText={`Search results for "${keyword}" could not be found.`}
          >
            {topics.data && (
              <div className={styles.forumSearchPageContentList}>
                <div className={styles.forumSearchPageContentListTitle}>Found {topics.data?.count} results</div>
                {topics.data?.items.map((x) => (
                  <Link to={`/forum/${x.topicID}/thread/${x._id}`}>
                    <ThreadCard key={x._id} data={x} />
                  </Link>
                ))}
              </div>
            )}
            <Pagination
              pageSize={10}
              currentPage={page}
              onPageChange={setPage}
              showOnSinglePage={false}
              totalRecords={topics.data?.count}
            />
          </EmptyContainer>
        </div>
      </div>
    </div>
  );
};

export default ForumSearchPage;
