const getDifference = (startISOString: string, endISOString: string): string => {
  const startDate = new Date(startISOString);
  const endDate = new Date(endISOString);

  const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());

  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? "min" : "mins"}`;
  } else if (hours < 24) {
    return `${hours} ${hours === 1 ? "hour" : "hours"}`;
  } else {
    return `${days} ${days === 1 ? "day" : "days"}`;
  }
};

export default getDifference;
