import api from "Core/api";

type Response = number;

type ApiResponse = { count: number };

type Props = () => Promise<Response>;

const getNotificationCount: Props = async () => {
  const response = await api.get<ApiResponse>("notification/count");
  return response.data.count;
};

export default getNotificationCount;
