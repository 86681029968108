import { EditorState } from "draft-js";
import styles from "./styles.module.scss";
import Button from "Core/components/Button";
import EmojiPicker from "Core/components/EmojiPicker";
import createText from "Core/utils/draftjs/createText";
import DraftjsEmojiIcon from "Core/components/Icon/draftjsEmoji";

type Props = {
  value: EditorState;
  maxLength?: number;
  onChange: (e: EditorState) => void;
};

const Emoji: React.FC<Props> = ({ value, maxLength, onChange }) => {
  const handleOnChange = (e: string) => {
    const length = value.getCurrentContent().getPlainText("").length;
    if (!(maxLength && length > maxLength - 1)) {
      onChange(createText(value, e));
    }
  };

  return (
    <EmojiPicker onChange={handleOnChange}>
      <Button variant="light" className={styles.draftjsEmojiPicker}>
        <DraftjsEmojiIcon />
      </Button>
    </EmojiPicker>
  );
};

export type { Props as EmojiProps };

export default Emoji;
