import { IconComponent } from "Core/types/Icon";

const HeartOutlineThinIcon: IconComponent = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24.3333 8.7023C24.0576 7.80817 23.5637 6.96805 22.8063 6.21712C22.21 5.62588 21.5911 5.23598 20.9701 5M12.8658 2.5279C10.6342 1.11234 7.03031 -0.22624 3.91804 2.94762C-3.46996 10.4818 9.1998 25 15 25C20.8002 25 33.47 10.4818 26.082 2.94762C22.9697 -0.226205 19.3658 1.11236 17.1343 2.52791C15.8734 3.32776 14.1267 3.32775 12.8658 2.5279Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HeartOutlineThinIcon;
