import cx from "Core/utils/cx";
import EmojiPickerSearch from "./Search";
import styles from "./styles.module.scss";
import { categories, items } from "./items";
import Popover from "Core/components/Popover";
import { Placement } from "Core/types/Placement";
import RamblyIcon from "Core/components/Icon/rambly";
import { Option, IconOption } from "Core/types/Option";
import { Fragment, ReactElement, useState } from "react";
import containsText from "Core/utils/string/containsText";
import closePopover from "Core/utils/element/closePopover";

type Data = {
  category: string;
  items: Option[];
};

type Props = {
  children: ReactElement;
  onChange: (e: string) => void;
  placement?: Placement;
};

const EmojiPicker: React.FC<Props> = ({ children, onChange, placement }) => {
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");

  const calculatedData = categories.reduce((prev: Data[], x: IconOption) => {
    const isCategory = !(category && category !== x.label);
    if (isCategory) {
      const categoryContains = containsText(x.label, keyword);
      if (categoryContains) {
        return [...prev, { category: x.label, items: items[x.label] }];
      } else {
        const categoryItems = items[x.label].filter((y) => containsText(y.label, keyword));
        if (categoryItems.length > 0) {
          return [...prev, { category: x.label, items: categoryItems }];
        } else {
          return [...prev];
        }
      }
    } else {
      return [...prev];
    }
  }, []);

  const onEmoji = (e: string) => () => {
    onChange(e);
    setKeyword("");
    setCategory("");
    closePopover();
  };

  const onCategory = (e: string) => () => {
    setCategory(e);
  };

  return (
    <Popover
      popoverClassName={styles.popover}
      placement={placement ? placement : "bottom"}
      className={styles.picker}
      variant="light"
    >
      {children}
      <div className={styles.container}>
        <EmojiPickerSearch onChange={setKeyword} />
        <div className={styles.list}>
          {calculatedData.map((x) => (
            <Fragment key={x.category}>
              <h3>{x.category}</h3>
              {x.items.map((y) => (
                <span key={`${x.category}-${y.label}`} onClick={onEmoji(y.value)}>
                  {y.value}
                </span>
              ))}
            </Fragment>
          ))}
        </div>
        <div className={styles.category}>
          <span className={cx([styles.active, category === ""])} onClick={onCategory("")}>
            <RamblyIcon />
          </span>
          {categories.map((x) => (
            <span key={x.label} className={cx([styles.active, category === x.label])} onClick={onCategory(x.label)}>
              {x.icon}
            </span>
          ))}
        </div>
      </div>
    </Popover>
  );
};

export default EmojiPicker;
