import getAcademy from "./getAcademy";
import getAcademies from "./getAcademies";
import getCategories from "./getCategories";
import deleteAcademy from "./deleteAcademy";
import subscribeAcademy from "./subscribeAcademy";
import getRelatedAcademies from "./getRelatedAcademies";

const academyApi = {
  getAcademy,
  getAcademies,
  getCategories,
  deleteAcademy,
  subscribeAcademy,
  getRelatedAcademies,
};

export default academyApi;
