import api from "Core/api";
import { DraftjsState } from "Core/types/Draftjs";

type Response = void;

type ApiResponse = void;

type Props = (replyId: string, topicId: string, threadId: string, message: DraftjsState) => Promise<Response>;

const updateThreadReply: Props = async (id, topic, thread, message) => {
  await api.post<ApiResponse>(
    `${process.env.REACT_APP_HUB_API_URL}topics/${topic}/thread/${thread}/reply/${id}/update`,
    { message }
  );
  return;
};

export default updateThreadReply;
