import styles from "./styles.module.scss";

type SectionHeaderProps = {
  tag: string;
  title: string;
  subtitle: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ tag, title, subtitle }) => {
  return (
    <div className={styles.sectionHeader}>
      <div className={styles.sectionHeaderTag}>{tag}</div>
      <div className={styles.sectionHeaderTitle}>{title}</div>
      <div className={styles.sectionHeaderSubtitle}>{subtitle}</div>
    </div>
  );
};

export default SectionHeader;
