import FaqListPage from "./pages/FaqListPage";
import { Navigate, Route, Routes } from "react-router-dom";

const Faq: React.FC = () => {
  return (
    <Routes>
      <Route index element={<FaqListPage />} />
      <Route path="*" element={<Navigate to="/faq" />} />
    </Routes>
  );
};
export default Faq;
