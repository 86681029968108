import { IconComponent } from "Core/types/Icon";

const ArrowRightIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 15" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M8,1 L14.462,7.461 L8,13.923 M0.897,7.489 L14,7.489"
        id="Shape"
        stroke="currentColor"
        strokeWidth="2.154"
      ></path>
    </g>
  </svg>
);

export default ArrowRightIcon;
