import { RefObject, useCallback, useEffect } from "react";

type Callback = () => void;
type Props = (callback: Callback, ref: RefObject<HTMLElement>, ignore?: string[]) => void;

const useOuterClick: Props = (callback, ref, ignore = []) => {
  const cb = useCallback(() => callback(), []);

  const listener = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (ignore && !!ignore.length) {
        let currentElement = e.target;

        while (currentElement) {
          let classNames = currentElement.className;
          if (classNames && typeof classNames === "string" && ignore.some((x) => classNames?.includes(x))) {
            break;
          }
          currentElement = currentElement.parentNode;
        }
        if (!currentElement) {
          cb();
        }
      } else {
        cb();
      }
    }
  };

  const handler = useCallback(() => {
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, []);

  useEffect(handler, [ref, cb]);
};

export default useOuterClick;
