import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import ExternalLink from "Core/components/ExternalLink";
import socialPlatforms from "Content/constants/socialPlatforms";
import { SocialButton } from "Content/types/ContentEditor/SocialButton";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<SocialButton>;

const SocialButtonView: React.FC<Props> = ({ value }) => {
  const { style, text, platform, link, type } = value.data;
  const buttonStyle = { width: style.width };

  return (
    <div className={styles.container} style={{ textAlign: style.textAlign }}>
      <ExternalLink href={link} target="_blank" params>
        <button
          style={buttonStyle}
          aria-label={value.data.text}
          className={cx(styles.button, styles[platform], styles[type])}
        >
          <div className={styles.content}>
            <span className={styles.icon} style={{ color: style.color }}>
              {socialPlatforms[platform]}
            </span>
            <span className={styles.text} style={{ color: style.color }}>
              {text}
            </span>
          </div>
        </button>
      </ExternalLink>
    </div>
  );
};

export default SocialButtonView;
