import cx from "Core/utils/cx";
import MediaViewText from "./Text";
import MediaViewModal from "./Modal";
import { Img } from "Core/components";
import styles from "./styles.module.scss";
import Avatar from "Core/components/Avatar";
import { useState, MouseEvent } from "react";
import Button from "Content/components/Button";
import imageSrc from "Core/utils/url/imageSrc";
import ExternalLink from "Core/components/ExternalLink";
import { Media } from "Content/types/ContentEditor/Media";
import { GridType } from "Content/types/ContentEditor/Grid";
import ExternalLinkIcon from "Core/components/Icon/externalLink";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<Media> & {
  grid?: GridType;
};

const MediaView: React.FC<Props> = ({ value, type = "post", grid = "single" }) => {
  const [visible, setVisible] = useState(false);
  const { style, text, url, link, description, user } = value.data;

  const containerStyle = {
    maxWidth: "100%",
    boxShadow: style.boxShadow,
    borderRadius: style.borderRadius || 16,
    aspectRatio: `${style.width} / ${style.height}`,
    margin: style.textAlign === "right" ? "0 0 0 auto" : style.textAlign === "left" ? "0 auto 0 0" : undefined,
  };

  const onClose = () => {
    setVisible(false);
  };

  const onClick = () => {
    if (!link) {
      setVisible(true);
    }
  };

  const onExternalClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(user?.image_url, "_blank");
  };

  return (
    <>
      <div
        onClick={onClick}
        style={containerStyle}
        className={cx(styles.container, styles[grid], styles[value.data.type], styles[type])}
      >
        {value.data.type === "gif" && (
          <span className={styles.gifBg}>
            <Img w={style.width} h={style.height} src={value.data.url} />
          </span>
        )}
        <ExternalLink href={link} target="_blank" params>
          <span className={styles.imageContainer}>
            <Img
              w={style.width}
              h={style.height}
              src={imageSrc(url)}
              style={{
                objectFit: "cover",
                width: value.data.type === "gif" ? undefined : "100%",
                height: value.data.type === "gif" ? undefined : "100%",
              }}
            />
          </span>
          {text.type !== "none" && (
            <MediaViewText style={value.data.style} description={description} text={text} grid={grid} />
          )}
        </ExternalLink>
        {user && (
          <div className={styles.user}>
            <Avatar value={user.profile_image} className={styles.avatar} size={36} />
            <div className={styles.username}>{user.name || user.username}</div>
            <Button className={styles.external} variant="text" onClick={onExternalClick}>
              <ExternalLinkIcon />
            </Button>
          </div>
        )}
      </div>
      {!link && visible && (
        <MediaViewModal containerStyle={containerStyle} value={value} visible={visible} onClose={onClose} />
      )}
    </>
  );
};

export default MediaView;
