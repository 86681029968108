import { IconOption } from "Core/types/Option";
import AlignLeftIcon from "Core/components/Icon/alignLeft";
import AlignRightIcon from "Core/components/Icon/alignRight";
import AlignCenterIcon from "Core/components/Icon/alignCenter";

const alignments: IconOption[] = [
  {
    label: "Left",
    value: "align-left",
    icon: <AlignLeftIcon />,
  },
  {
    label: "Center",
    value: "align-center",
    icon: <AlignCenterIcon />,
  },
  {
    label: "Right",
    value: "align-right",
    icon: <AlignRightIcon />,
  },
];

export default alignments;
