const basicButtonTypes = {
  default: require("Content/components/PostContent/Item/BasicButton/Types/Default").default,
  outlined: require("Content/components/PostContent/Item/BasicButton/Types/Outlined").default,
  reflex: require("Content/components/PostContent/Item/BasicButton/Types/Reflex").default,
  raised: require("Content/components/PostContent/Item/BasicButton/Types/Raised").default,
  smooth: require("Content/components/PostContent/Item/BasicButton/Types/Smooth").default,
  gradient: require("Content/components/PostContent/Item/BasicButton/Types/Gradient").default,
  image: require("Content/components/PostContent/Item/BasicButton/Types/Image").default,
  pattern: require("Content/components/PostContent/Item/BasicButton/Types/Pattern").default,
  link: require("Content/components/PostContent/Item/BasicButton/Types/Link").default,
};

export default basicButtonTypes;
