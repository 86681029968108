import api from "Core/api";

type Response = { tag: string; subTags: string[] }[];

type ApiResponse = { tag: string; subTags: string[] }[];

type Props = () => Promise<Response>;

const getCategories: Props = async () => {
  const response = await api.get<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}public/blog/menu/tags`);
  return response.data;
};

export default getCategories;
