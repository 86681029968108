import get from "Core/utils/object/get";

type Props = (
  data: any,
  key: string,
  onChange: (key: string) => (e: any) => void
) => {
  value: any;
  onChange: (e: any) => void;
};

const prop: Props = (data, key, onChange) => {
  return {
    value: get(data, key),
    onChange: onChange(key),
  };
};

export default prop;
