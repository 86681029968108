const Type6: React.FC = () => {
  return (
    <svg
      height="1"
      fill="none"
      width="100%"
      viewBox="0 0 534 1"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        rx="0.25"
        width="100%"
        height="0.5"
        strokeWidth="0.5"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeDasharray="2 4 6 8"
      />
    </svg>
  );
};

export default Type6;
