import { IconComponent } from "Core/types/Icon";

const TwitterIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_167_925)">
      <path
        d="M10.7124 7.78944L17.4133 0H15.8254L10.0071 6.76346L5.35992 0H0L7.02738 10.2275L0 18.396H1.58799L7.73237 11.2535L12.6401 18.396H18L10.7121 7.78944H10.7124ZM8.53747 10.3177L7.82546 9.29922L2.16017 1.19544H4.59922L9.17118 7.73545L9.8832 8.75389L15.8262 17.2549H13.3871L8.53747 10.318V10.3177Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_167_925">
        <rect width="18" height="18.4054" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TwitterIcon;
