import { IconComponent } from "Core/types/Icon";

const AlertIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.12"
      d="M2.68431 22.1314L13.6508 3.18927C14.2569 2.14239 14.56 1.61894 14.9553 1.44314C15.3002 1.28979 15.6939 1.28979 16.0388 1.44314C16.4342 1.61894 16.7372 2.14238 17.3433 3.18927L28.3098 22.1314C28.9181 23.1821 29.2222 23.7074 29.1773 24.1386C29.1381 24.5146 28.9411 24.8564 28.6352 25.0787C28.2846 25.3336 27.6776 25.3336 26.4636 25.3336H4.53056C3.31652 25.3336 2.70951 25.3336 2.3589 25.0787C2.05309 24.8564 1.85606 24.5146 1.81685 24.1386C1.7719 23.7074 2.07604 23.1821 2.68431 22.1314Z"
      fill="currentColor"
    />
    <path
      d="M15.4971 10.0003V15.3336M15.4971 20.667H15.5104M13.6508 3.18927L2.68431 22.1314C2.07604 23.1821 1.7719 23.7074 1.81685 24.1386C1.85606 24.5146 2.05309 24.8564 2.3589 25.0787C2.70951 25.3336 3.31652 25.3336 4.53056 25.3336H26.4636C27.6776 25.3336 28.2846 25.3336 28.6352 25.0787C28.9411 24.8564 29.1381 24.5146 29.1773 24.1386C29.2222 23.7074 28.9181 23.1821 28.3098 22.1314L17.3433 3.18927C16.7372 2.14238 16.4342 1.61894 16.0388 1.44314C15.6939 1.28979 15.3002 1.28979 14.9553 1.44314C14.56 1.61894 14.2569 2.14239 13.6508 3.18927Z"
      stroke="currentColor"
      strokeWidth="2.66"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AlertIcon;
