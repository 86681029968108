export const ANY_SCRIPT = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;
export const EXTERNAL_SCRIPT = /<script[^>]+src=(['"])(.*?)\1/i;
export const INJECTED_SCRIPT = /<script[\s\S]*?>[\s\S]*?createElement[\s\S]*?src\s?=\s?(['"])(.*?)\1/i;

const extractExternalScriptURL = (e: string): string | null => {
  const match = e.match(EXTERNAL_SCRIPT);
  return match && match[2];
};

const extractInjectedScriptURL = (e: string): string | null => {
  const match = e.match(INJECTED_SCRIPT);
  return match && match[2];
};

const extractScriptURL = (e: string) => {
  return extractExternalScriptURL(e) || extractInjectedScriptURL(e) || "";
};

const uniqueURIs = (scripts: string[]) => {
  return Object.keys(scripts.reduce((a, b) => (b ? { ...a, [b]: true } : a), {}));
};

export const getScripts = (e: string): string[] => {
  const scripts = e.match(/<script[\s\S]*?<\/script>/gi);
  return scripts ? uniqueURIs(scripts.map(extractScriptURL)) : [];
};

export const injectScriptTag = (e: string): HTMLScriptElement => {
  const scriptTag = document.createElement("script");
  scriptTag.src = e.replace("&amp;", "&");
  document.head.appendChild(scriptTag);
  return scriptTag;
};
