import selectAllText from "./selectAllText";
import { EditorState, RichUtils } from "draft-js";

type Props = (state: EditorState, key: string, selected?: string, selectAll?: boolean) => EditorState;

const toggleInlineStyle: Props = (state, key, selected, selectAll) => {
  let newState = state;
  const selection = newState.getSelection();
  const [start, end] = [selection.getAnchorOffset(), selection.getFocusOffset()];
  if (start === end && selectAll) newState = selectAllText(newState);

  if (selected) newState = RichUtils.toggleInlineStyle(newState, selected);
  newState = RichUtils.toggleInlineStyle(newState, key);
  return newState;
};

export default toggleInlineStyle;
