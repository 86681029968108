type RootMenuItem = {
  label: string;
  value: string;
};

const rootMenuItems: RootMenuItem[] = [
  {
    label: "How-To",
    value: "/howto",
  },
  {
    label: "Academy",
    value: "/academy",
  },
  {
    label: "Forum",
    value: "/forum",
  },
  {
    label: "FAQ",
    value: "/faq",
  },
];

export default rootMenuItems;
