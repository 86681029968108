import styles from "./styles.module.scss";
import { Empty } from "Content/types/ContentEditor/Empty";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<Empty> & {
  active?: boolean;
  onChange?: (key: string) => (e: any) => void;
};

const EmptyView: React.FC<Props> = () => {
  return <div className={styles.container} />;
};

export default EmptyView;
