import flatten from "./flatten";
import equalsText from "./equalsText";
import containsText from "./containsText";
import isValidEmail from "./isValidEmail";
import capitalizeText from "./capitalizeText";
import getCountryCode from "./getCountryCode";

const stringUtils = {
  flatten,
  equalsText,
  containsText,
  isValidEmail,
  capitalizeText,
  getCountryCode,
};

export default stringUtils;
