import cx from "Core/utils/cx";
import useAuth from "Auth/context";
import { CSSProperties } from "react";
import styles from "./styles.module.scss";
import Avatar from "Core/components/Avatar";
import TextImageIcon from "Core/components/Icon/textImage";
import { MediaTextType } from "Content/types/ContentEditor/Media";

type Props = {
  style: CSSProperties;
  grid: string;
  text: {
    value: string;
    type: MediaTextType;
    style: CSSProperties;
    image: { value: string; style: CSSProperties; text: string };
    emoji: { emoji: string; option: string; optionID: string };
  };
  description: {
    value: string;
    type: MediaTextType;
    style: CSSProperties;
    image: { value: string; style: CSSProperties };
    emoji: { emoji: string; option: string; optionID: string };
  };
};

const MediaViewText: React.FC<Props> = ({ text, grid, description }) => {
  const auth = useAuth();
  return (
    <div className={cx("mediaViewText", styles.text, styles[text.type], styles[grid], styles.style)}>
      <p style={text.style}>
        {text.type === "type34" && (
          <span className={styles.emoji}>
            <label> {text.emoji?.emoji ? <>{text.emoji.emoji}</> : <>👠</>}</label>
          </span>
        )}
        {text.type === "type33" && (
          <Avatar className={styles.avatar} value={text.image?.value ? text.image.value : auth.user?.avatar} />
        )}
        {text.type === "type33" && (
          <span className={styles.userName}>{text.image?.text ? text.image?.text : auth.user?.username}</span>
        )}
        <span>{text.value}</span>
        {text.type === "type34" && (
          <span className={styles.description}>
            {description.value ? description.value : "Consectetur adipiscing elit, sed do eiusmod tempor."}
          </span>
        )}
      </p>
      {text.type === "type35" && (
        <span className={styles.icon}>
          <TextImageIcon />
        </span>
      )}
    </div>
  );
};

export default MediaViewText;
