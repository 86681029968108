import useMounted from "./useMounted";
import useUpdated from "./useUpdated";
import useUnmounted from "./useUnmounted";
import { useCallback, useRef, useState } from "react";

type Options = {
  render?: boolean;
  container?: Element | HTMLElement | null;
};

type Props = (callback: (top: number, prev: number) => void, options?: Options) => void;

const useScroll: Props = (callback, options) => {
  const ref = useRef<number>(0);
  const [value, setValue] = useState(0);
  const cb = useCallback(callback, []);

  const onScroll = () => {
    if (options?.render) {
      setValue(
        options.container ? options.container.scrollTop : window.pageYOffset || document.documentElement.scrollTop
      );
    } else {
      cb(
        options?.container ? options.container.scrollTop : window.pageYOffset || document.documentElement.scrollTop,
        ref.current
      );
      ref.current = options?.container
        ? options.container.scrollTop
        : window.pageYOffset || document.documentElement.scrollTop;
    }
  };

  useMounted(() => {
    onScroll();
    (options?.container ? options.container : document).addEventListener("scroll", onScroll);
  });

  useUnmounted(() => {
    (options?.container ? options.container : document).removeEventListener("scroll", onScroll);
  });

  useUpdated(() => callback(value, ref.current), value);
};

export default useScroll;
