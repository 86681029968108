import days from "./days";
import prevDays from "./prevDays";
import nextDays from "./nextDays";
import { CalendarDay } from "Core/types/DateTime";

type Props = (month: number, year: number) => CalendarDay[];

const month: Props = (month, year) => {
  let result = [];

  result.push(...prevDays(month, year));
  result.push(...days(month, year));
  result.push(...nextDays(month, year, 42 - result.length));

  return result;
};

export default month;
