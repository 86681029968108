import styles from "./styles.module.scss";
import { Post } from "Content/types/Post";
// import { draftjsUtils } from "Core/utils";
import { PostContent } from "Content/components";
import { HowToEditButton } from "HowTo/components";
// import { LikeDislike } from "Core/components";

type HowToContentProps = {
  selected: Post;
};

const HowToContent: React.FC<HowToContentProps> = ({ selected }) => {
  return (
    <div className={styles.howToContent}>
      <div className={styles.howToContentContainer}>
        <HowToEditButton blogId={String(selected._id)} />
        <div>
          <PostContent value={selected.content} />
        </div>
      </div>
      {/* <LikeDislike /> */}
    </div>
  );
};

export default HowToContent;
