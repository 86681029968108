import { Select } from "Core/components";
import styles from "./styles.module.scss";
import { ButtonVariant } from "Core/components/Button";

type ThreadSortSelectProps = {
  value: string;
  variant?: ButtonVariant;
  onChange: (e: string) => void;
};

const ThreadSortSelect: React.FC<ThreadSortSelectProps> = ({ value, variant, onChange }) => {
  return (
    <Select title="Filter" value={value} onChange={onChange} className={styles.threadSortSelect} variant={variant}>
      <Select.Option value="newest">Most recent</Select.Option>
      <Select.Option value="comments">Most comments</Select.Option>
      <Select.Option value="likes">Most likes</Select.Option>
      <Select.Option value="asc">Title (A to Z)</Select.Option>
      <Select.Option value="desc">Title (Z to A)</Select.Option>
    </Select>
  );
};

export default ThreadSortSelect;
