type GradientObject = {
  deg: number;
  color1: string;
  color2: string;
};

type Props = (gradient: string) => GradientObject | undefined;

const toGradientObject: Props = (gradient) => {
  if (!gradient) return undefined;
  const deg = gradient.match(/[0-9]{1,3}deg/)?.map((x) => x.replace("deg", ""));
  const colors = gradient.match(/rgba\([0-9, ]*\)/g)?.map((x) => x);

  return {
    deg: Number(deg?.[0] || "0"),
    color1: colors?.[0] || "",
    color2: colors?.[1] || "",
  };
};

export default toGradientObject;
