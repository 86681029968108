import useAuth from "Auth/context";
import { Avatar } from "Core/components";
import styles from "./styles.module.scss";
import getPlainText from "Core/utils/draftjs/getPlainText";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import { CommentNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<CommentNotificationData>;
};

const NotificationButtonItemComment: React.FC<Props> = ({ username, data }) => {
  const auth = useAuth();

  const onClick = () => {
    window.open(`/${auth.user?.username}/${data.data.slug}`, "_self");
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <Avatar value={data.data.avatar?.url} className={styles.avatar} />
      <div className={styles.content}>
        <div className={styles.title}>
          <span>{username}</span> commented 💬 on "{getPlainText(data.data.title?.value)}" content.
        </div>
        {getPlainText(data.data.comment?.value).trim() && (
          <div className={styles.innerContent}>{getPlainText(data.data.comment?.value)}</div>
        )}
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemComment;
