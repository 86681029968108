import useAuth from "Auth/context";
import { Button, Modal } from "Core/components";
import { ForumThread } from "Forum/types/Thread";
import CommentIcon from "Core/components/Icon/comment";

type ForumThreadCommentButtonProps = {
  thread: ForumThread;
};

const ForumThreadCommentButton: React.FC<ForumThreadCommentButtonProps> = ({ thread }) => {
  const auth = useAuth();

  const onClick = () => {
    if (auth.user) {
      const element = document.getElementById("AddComment");
      if (element) {
        window.scrollTo({
          behavior: "smooth",
          top: element?.offsetTop - 50,
        });
      }
    } else {
      Modal.info({
        ok: "Log In",
        cancel: "Close",
        title: "You need to login.",
        subtitle: "Only logged in users can Like or Comment.",
      }).then(() => {
        window.open(`${process.env.REACT_APP_URL}login`, "_blank");
      });
    }
  };

  return (
    <Button variant="lightGray" before={<CommentIcon />} onClick={onClick}>
      {thread.replies} {thread.replies > 1 ? "comments" : "comment"}
    </Button>
  );
};

export default ForumThreadCommentButton;
