import Lottie from "react-lottie";
import styles from "./styles.module.scss";
import * as animationData from "Landing/constants/introLottie.json";

type LandingIntroProps = {};

const LandingIntro: React.FC<LandingIntroProps> = () => {
  return (
    <div className={styles.landingIntro}>
      <div className={styles.landingIntroAnimation}>
        <Lottie options={{ loop: true, autoplay: true, animationData }} />
      </div>
      <div className={styles.landingIntroContent}>
        <h1>
          <div>Welcome to The</div>
          <span>Rambly Hub</span>
        </h1>
        <h2>
          Our <span>Ram Fam</span> is all about <span className={styles.bold}>supporting</span>,{" "}
          <span className={styles.bold}>guiding</span>, and <span className={styles.bold}>inspiring one another</span>.
          Whether you’re looking for answers to your questions, sharing your own expertise, or simply looking to connect
          with other <span>Ramblers</span>, we’ve got you covered.
        </h2>
      </div>
    </div>
  );
};

export default LandingIntro;
