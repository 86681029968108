import api from "Core/api";
import { Post } from "Content/types/Post";

type Response = Post[];

type ApiResponse = Post[];

type Props = () => Promise<Response>;

const getPlainPosts: Props = async () => {
  const response = await api.get<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}public/blog/list/howto`);
  return response.data.slice(0, 4);
};

export default getPlainPosts;
