const disableDateBefore = (currentDateString: string, dateString: string) => {
  const currentDate = new Date(currentDateString);
  const inputDate = new Date(dateString);

  // We need to set the time to 00:00:00 for accurate comparison
  currentDate.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);

  return inputDate.getTime() < currentDate.getTime();
};

export default disableDateBefore;
