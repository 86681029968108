import cx from "Core/utils/cx";
import { EditorState } from "draft-js";
import styles from "./styles.module.scss";
import useUpdated from "Core/hooks/useUpdated";
import asArray from "Core/utils/object/asArray";
import { ComponentType, useState } from "react";
import preventDefault from "Core/utils/element/preventDefault";
import { ToolbarProps } from "@draft-js-plugins/inline-toolbar";
import getSelectedTextLength from "Core/utils/draftjs/getSelectedTextLength";
import { ToolbarChildrenProps } from "@draft-js-plugins/inline-toolbar/lib/components/Toolbar";

const items = {
  size: () => null,
  list: () => null,
  bold: require("./Bold").default,
  italic: require("./Italic").default,
  align: require("./Align").default,
  strike: require("./Strike").default,
  underline: require("./Underline").default,
  link: require("./Link").default,
  color: require("./Color").default,
  backgroundColor: require("./BackgroundColor").default,
};

type DraftjsToolbarOption = keyof typeof items;

type Props = {
  value?: EditorState;
  Component: ComponentType<ToolbarProps>;
  disabledOptions?: DraftjsToolbarOption[];
};

const DraftjsToolbar: React.FC<Props> = ({ Component, disabledOptions = [], value }) => {
  const selectionLength = value ? getSelectedTextLength(value) : 0;
  const [active, setActive] = useState<string>();
  const filteredItems = asArray(items).filter((x) => {
    return !(disabledOptions.includes(x.key) || (active && active !== x.key));
  });

  const onToggle = (e?: string) => () => {
    setActive(active === e ? undefined : e);
  };

  useUpdated(() => {
    if (selectionLength === 0 && !!active) {
      onToggle(active)();
    }
  }, selectionLength);

  const children = (props: ToolbarChildrenProps) => {
    return (
      <div onMouseDown={preventDefault()} className={cx(styles.card, [styles.activeCard, !!active])}>
        {!active && <h4>Text Properties</h4>}
        <ul>
          {filteredItems.map((x) => (
            <x.value key={x.key} active={active === x.key} onToggle={onToggle(x.key)} {...props} />
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className={cx(styles.container, [styles.active, active === "link"])}>
      <Component children={children} />
    </div>
  );
};

export type { DraftjsToolbarOption };

export default DraftjsToolbar;
