import { IconComponent } from "Core/types/Icon";

const ChevronDownIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.986028 0.479956C1.26871 0.202386 1.64063 0.033999 2.03572 0.00470585C2.43082 -0.0245873 2.8235 0.0871098 3.14403 0.319956L3.33003 0.479956L11 8.04796L18.67 0.477956C18.953 0.200686 19.325 0.0326678 19.7201 0.00374121C20.1152 -0.0251853 20.5077 0.0868523 20.828 0.319956L21.014 0.477956C21.603 1.05896 21.657 1.96796 21.174 2.60796L21.014 2.79196L12.172 11.52C11.8891 11.7972 11.517 11.9652 11.122 11.9942C10.7269 12.0231 10.3343 11.9111 10.014 11.678L9.82803 11.52L0.986028 2.79196C0.832249 2.64106 0.710094 2.46101 0.626705 2.26235C0.543316 2.0637 0.500366 1.85041 0.500366 1.63496C0.500366 1.41951 0.543316 1.20622 0.626705 1.00756C0.710094 0.808899 0.832249 0.628857 0.986028 0.477956V0.479956Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronDownIcon;
