const faqQuestions = [
  {
    key: 1,
    type: "General",
    question: "What is Rambly?",
    answer:
      "Rambly enables content creators to deliver unique content and experiences to their audience while earning money. Meanwhile, audiences can support their favorite creators by subscribing to their content and accessing exclusive perks.",
  },
  {
    key: 2,
    type: "General",
    question: "Who uses Rambly?",
    answer:
      "Rambly was designed for ALL content creators. From travel bloggers, writers, artists, musicians, podcasters, educators, and more.",
  },
  {
    key: 3,
    type: "General",
    question: "How can I contact Rambly?",
    answer:
      "We’d love to hear from you. Get in touch by emailing info@rambly.com. We’ll be sure to get back to you as soon as we can.",
  },
  {
    key: 4,
    type: "Payment",
    question: "How much does Rambly cost to use?",
    answer:
      "Rambly is completely free to use for both content creators and their audiences. Access all our templates, tools, and features at no cost.",
  },
  {
    key: 5,
    type: "Payment",
    question: "How will I get paid?",
    answer:
      "Your donations, monthly subscriptions, and other forms of revenue will be deposited directly into your Stripe, PayPal, Google Pay, or Amazon Pay account. You get to keep 100% of what you make. However, minimal processing fees may arise from credit card transactions.",
  },
  {
    key: 6,
    type: "Payment",
    question: "How soon will I get my money?",
    answer: "You won’t have to wait to access your funds. Your money will be deposited into your account right away.",
  },
  {
    key: 7,
    type: "Payment",
    question: "How do I set up my payment method?",
    answer:
      "Each payment method will have different ways of setting it up. Go to settings, select “Integrations”, then “Payment”. Choose which account you want to connect and follow the instructions to set it up.",
  },
  {
    key: 8,
    type: "Payment",
    question: "How do I set up my payment method?",
    answer: `Click on "Settings”. Select “Integrations” and then “Donation.” Make sure you have Donations turned on. Simply adjust the donation amount to the value you want under “Price”. Click “Save” and you should be all set to receive your new donation amount.`,
  },
  {
    key: 9,
    type: "Account Settings",
    question: "Can I change my username?",
    answer: "Once your account is made, you won't be able to change your username.",
  },
  {
    key: 10,
    type: "Account Settings",
    question: "How can I update my email address?",
    answer: `To update your email address, select “Settings” from the dropdown menu. Under “Profile Settings”, click “Profile”. Enter your new email address in the designated field. Don’t forget to click “Save” to apply your changes.`,
  },
  {
    key: 11,
    type: "Account Settings",
    question: "How do I reset my password?",
    answer: `If you forgot your password, you can reset it on the login page. First, click “Forgot Password?” Enter the email address connected to your Rambly account. Then check your email for a PIN code. Enter the PIN code onto the reset password page and you will be prompted to create a new password.`,
  },
  {
    key: 12,
    type: "Account Settings",
    question: "How do I change my password?",
    answer: `To change your password, select “Settings” from the dropdown menu. Under “Profile Settings”, click “Profile”. Then select “Change Password” in the password field. Follow the prompt to update your password.`,
  },
  {
    key: 13,
    type: "Account Settings",
    question: "How do I delete my account?",
    answer: `To delete your account, select “Settings” from the dropdown menu. Under “Profile Settings”, click “Profile”.  On the bottom, click “Delete Account.”`,
  },
];

export default faqQuestions;
