import cx from "Core/utils/cx";
import { ReactNode } from "react";
import styles from "./styles.module.scss";
import LoadingIcon from "Core/components/Icon/loading";

type Props = {
  empty?: boolean;
  loading?: boolean;
  emptyText?: string;
  className?: string;
  children?: ReactNode;
};

const EmptyContainer: React.FC<Props> = ({ loading, className, children, empty, emptyText }) => {
  return (
    <div className={cx(styles.emptyContainer, className)}>
      {empty ? (
        <div className={cx(styles.emptyContainerContent, [styles.loading, loading])}>{emptyText}</div>
      ) : (
        children
      )}
      {loading && <div className={styles.emptyContainerLoading}>{<LoadingIcon />}</div>}
    </div>
  );
};

export default EmptyContainer;
