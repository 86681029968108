import api from "Core/api";
import { groupByTag } from "HowTo/utils";
import { Post } from "Content/types/Post";
import { MenuItem } from "HowTo/types/MenuItem";

type Response = MenuItem[];

type ApiResponse = Post[];

type Props = () => Promise<Response>;

const getPosts: Props = async () => {
  const response = await api.get<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}public/blog/list/howto`);
  return groupByTag(response.data);
};

export default getPosts;
