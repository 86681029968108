import forumApi from "Forum/api";
import { useState } from "react";
import useAuth from "Auth/context";
import { useRequest } from "Core/hooks";
import { Button, Modal } from "Core/components";
import LikeIcon from "Core/components/Icon/like";
import { ForumThread } from "Forum/types/Thread";
import LikeFilledIcon from "Core/components/Icon/likeFilled";

type ForumThreadLikeButtonProps = {
  thread: ForumThread;
};

const ForumThreadLikeButton: React.FC<ForumThreadLikeButtonProps> = ({ thread }) => {
  const auth = useAuth();
  const like = useRequest(forumApi.likeThread);
  const [value, setValue] = useState(thread.likes);
  const [active, setActive] = useState(thread.isLiked);

  const onClick = () => {
    if (auth.user) {
      like.request(thread.topicID, thread._id, !active).then(() => {
        setActive(!active);
        setValue(active ? value - 1 : value + 1);
      });
    } else {
      Modal.info({
        ok: "Log In",
        cancel: "Close",
        title: "You need to login.",
        subtitle: "Only logged in users can Like or Comment.",
      }).then(() => {
        window.open(`${process.env.REACT_APP_URL}login`, "_blank");
      });
    }
  };

  return (
    <Button
      onClick={onClick}
      loading={like.loading}
      variant={active ? "dark" : "lightGray"}
      before={active ? <LikeFilledIcon /> : <LikeIcon />}
    >
      {active ? "Liked" : `${value} ${value > 1 ? "likes" : "like"}`}
    </Button>
  );
};

export default ForumThreadLikeButton;
