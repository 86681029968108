import { useState } from "react";
import useMounted from "./useMounted";
import { Modal } from "Core/components";

type Action<U> = () => Promise<U>;

type Options = {
  showError?: boolean;
  initialLoad?: boolean;
};

const useGet = <U,>(action: Action<U>, options?: Options) => {
  const [data, setData] = useState<U>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const updateState = (x: boolean, y: string) => {
    setLoading(x);
    setError(y);
  };

  const request: Action<U> = async (callback?: (response: U) => void) => {
    try {
      updateState(true, "");
      const response = await action();
      updateState(false, "");
      setData(response);
      if (callback) {
        callback(response);
      }
      return response;
    } catch (error: any) {
      updateState(false, String(error.message));
      if (options?.showError !== false) {
        Modal.error({ title: error.title, subtitle: error.message });
      }
      throw error;
    }
  };

  const reset = () => {
    setError("");
    setData(undefined);
  };

  useMounted(() => {
    if (options?.initialLoad !== false) {
      request();
    }
  });

  return {
    data,
    error,
    reset,
    loading,
    request,
    update: setData,
  };
};

export default useGet;
