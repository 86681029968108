import forumApi from "Forum/api";
import { useState } from "react";
import ForumListItem from "./Item";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { SearchButton } from "Forum/components";
import { useGet, useUpdated } from "Core/hooks";
import { EmptyContainer, PageHeader, Pagination } from "Core/components";

const ForumListPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const topics = useGet(() => forumApi.getTopics({ limit: 9, offset: (page - 1) * 9 }));

  useUpdated(() => {
    topics.request();
  }, page);

  return (
    <div className={styles.forumListPage}>
      <PageHeader
        back
        title="Join the conversation"
        subtitle="Connect with creators to share ideas and engage in vibrant discussions."
      />
      <div className={styles.forumListPageContainer}>
        <EmptyContainer loading={topics.loading} empty={!topics.data?.items.length} emptyText="Topics not found.">
          <div className={styles.forumListPageContent}>
            <div>
              <SearchButton />
            </div>
            <div className={styles.forumListPageContentList}>
              {topics.data?.items.map((x) => (
                <Link key={x._id} to={`/forum/${x._id}`}>
                  <ForumListItem data={x} />
                </Link>
              ))}
            </div>
            <Pagination
              pageSize={9}
              currentPage={page}
              onPageChange={setPage}
              showOnSinglePage={false}
              totalRecords={topics.data?.count}
            />
          </div>
        </EmptyContainer>
      </div>
    </div>
  );
};

export default ForumListPage;
