import { IconComponent } from "Core/types/Icon";

const RefundIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.00008 5L6.66675 3.33334M6.66675 3.33334L5.00008 1.66667M6.66675 3.33334H5.00008C3.15913 3.33334 1.66675 4.82572 1.66675 6.66667M15.0001 15L13.3334 16.6667M13.3334 16.6667L15.0001 18.3333M13.3334 16.6667H15.0001C16.841 16.6667 18.3334 15.1743 18.3334 13.3333M8.49094 5.41667C9.04599 3.26014 11.0036 1.66667 13.3334 1.66667C16.0948 1.66667 18.3334 3.90525 18.3334 6.66667C18.3334 8.99645 16.74 10.9541 14.5835 11.5091M11.6667 13.3333C11.6667 16.0948 9.42817 18.3333 6.66675 18.3333C3.90532 18.3333 1.66675 16.0948 1.66675 13.3333C1.66675 10.5719 3.90532 8.33334 6.66675 8.33334C9.42817 8.33334 11.6667 10.5719 11.6667 13.3333Z"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RefundIcon;
