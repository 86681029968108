import { Img } from "Core/components";
import { ContentItem } from "Content/types/ContentEditor/Item";
import { BasicButton } from "Content/types/ContentEditor/BasicButton";

type Props = {
  edit: boolean;
  value: ContentItem<BasicButton>;
};

const Image: React.FC<Props> = ({ value }) => {
  const style = {
    width: value.data.style.width,
    color: value.data.style.color,
    borderRadius: value.data.style.borderRadius,
  };

  return (
    <button style={style} aria-label={value.data.text}>
      <Img
        w={600}
        h={150}
        alt={value.data.text}
        src={value.data.style.backgroundImage || `${process.env.REACT_APP_URL}assets/img/post/button/pattern/0.png`}
      />
      <span>{value.data.text}</span>
    </button>
  );
};

export default Image;
