type Props = (hex: string, alpha?: string) => string;

const hex2rgba: Props = (hex, alpha) => {
  const color = hex.replace("#", "");
  const isValid = /^([A-Fa-f0-9]{3,4}){1,2}$/.test(color);
  if (!isValid) return `rgba(0, 0, 0, ${alpha ? Number(alpha) / 100 : "1"})`;
  const chunkSize = Math.floor(color.length / 3);
  const hexArr = color.match(new RegExp(`.{${chunkSize}}`, "g")) || [];

  const [r, g, b, a] = hexArr?.map((x) => parseInt(x.repeat(2 / x.length), 16));

  return `rgba(${r}, ${g}, ${b}, ${alpha ? Number(alpha) / 100 : a ? a / 255 : 1})`;
};

export default hex2rgba;
