import { IconComponent } from "Core/types/Icon";

const PinIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.92341 1.60676C9.75294 1.43625 9.59455 1.27784 9.45307 1.1599C9.30504 1.0365 9.10639 0.896426 8.84641 0.843724C8.50205 0.773915 8.14405 0.842859 7.85024 1.03557C7.62843 1.18106 7.49601 1.38489 7.4044 1.55444C7.31684 1.71648 7.22861 1.92239 7.13366 2.14401L6.41058 3.8312C6.39434 3.86908 6.38639 3.88754 6.38031 3.90087L6.3799 3.90178L6.37921 3.9025C6.36909 3.91309 6.3549 3.92733 6.32576 3.95647L5.28517 4.99707C5.23964 5.04259 5.21715 5.06496 5.20027 5.08071L5.19906 5.08183L5.19747 5.08221C5.17499 5.08752 5.14391 5.09383 5.08077 5.10645L2.60953 5.6007C2.31641 5.65931 2.05281 5.71201 1.84591 5.77347C1.63885 5.83498 1.37136 5.93615 1.16907 6.16413C0.910316 6.45574 0.792156 6.846 0.845693 7.23217C0.887548 7.53407 1.05399 7.76662 1.19216 7.93265C1.33022 8.09857 1.5203 8.28863 1.73169 8.49999L3.64256 10.4109L0.342723 13.7107C0.0823734 13.971 0.0823736 14.3931 0.342723 14.6535C0.603073 14.9138 1.02518 14.9138 1.28553 14.6535L4.58537 11.3537L6.49629 13.2646C6.70765 13.476 6.89771 13.6661 7.06363 13.8041C7.22966 13.9423 7.46221 14.1087 7.76411 14.1506C8.15028 14.2041 8.54054 14.086 8.83215 13.8272C9.06013 13.6249 9.1613 13.3574 9.22281 13.1504C9.28428 12.9435 9.33697 12.6799 9.39558 12.3867L9.88983 9.91551C9.90245 9.85237 9.90876 9.82129 9.91407 9.79881L9.91445 9.79722L9.91557 9.79601C9.93132 9.77913 9.95369 9.75664 9.99922 9.71111L11.0398 8.67052C11.0689 8.64138 11.0832 8.62719 11.0938 8.61707L11.0945 8.61638L11.0954 8.61597C11.1087 8.60989 11.1272 8.60194 11.1651 8.5857L12.8523 7.86263C13.0739 7.76767 13.2798 7.67944 13.4418 7.59188C13.6114 7.50027 13.8152 7.36785 13.9607 7.14604C14.1534 6.85223 14.2224 6.49423 14.1526 6.14987C14.0999 5.88989 13.9598 5.69125 13.8364 5.54321C13.7184 5.40173 13.56 5.24334 13.3895 5.07287L9.92341 1.60676Z"
      fill="currentColor"
    />
  </svg>
);

export default PinIcon;
