import { useState } from "react";
import useAuth from "Auth/context";
import academyApi from "Academy/api";
import AcademyListFilter from "./Filter";
import styles from "./styles.module.scss";
import { permissions } from "Auth/constants";
import { useGet, useUpdated } from "Core/hooks";
import { Link, useSearchParams } from "react-router-dom";
import { AcademyCard, AcademyCreateButton } from "Academy/components";
import { EmptyContainer, PageHeader, Pagination } from "Core/components";

const AcademyListPage: React.FC = () => {
  const auth = useAuth();
  const [params, setParams] = useSearchParams();
  const [sort, setSort] = useState(params.get("s") || "newest");
  const [page, setPage] = useState(Number(params.get("p") || "1"));
  const [category, setCategory] = useState(params.get("c") || "All");
  const academies = useGet(() =>
    academyApi.getAcademies({ limit: 9, offset: (page - 1) * 9 }, sort, category !== "All" ? category : undefined)
  );

  const updateParams = (newPage: number, newSort: string, newCategory: string) => {
    setParams(
      (p) => {
        p.set("s", newSort);
        p.set("c", newCategory);
        p.set("p", String(newPage));
        return p;
      },
      { replace: true }
    );
  };

  const refresh = () => {
    page === 1 ? academies.request() : setPage(1);
  };

  useUpdated(() => {
    updateParams(page, sort, category);
    academies.request();
  }, page);

  useUpdated(() => {
    updateParams(page, sort, category);
    page === 1 ? academies.request() : setPage(1);
  }, [category, sort]);

  return (
    <div className={styles.academyListPage}>
      <PageHeader
        back
        title="Read the latest posts"
        subtitle="Level up your content for success on Rambly and beyond."
        extra={auth.user?.role === permissions.admin ? <AcademyCreateButton /> : undefined}
      />
      <div className={styles.academyListPageContainer}>
        <AcademyListFilter value={category} sort={sort} setSort={setSort} onChange={setCategory} />
        <EmptyContainer
          loading={academies.loading}
          empty={!academies.data?.items.length}
          emptyText="There are no events at the moment."
        >
          <div className={styles.academyListPageContent}>
            <div className={styles.academyListPageContentList}>
              {academies.data?.items.map((x) => (
                <Link key={x._id} to={`/academy/${x.slug}`}>
                  <AcademyCard data={x} refresh={refresh} />
                </Link>
              ))}
            </div>
            <Pagination
              pageSize={9}
              currentPage={page}
              onPageChange={setPage}
              showOnSinglePage={false}
              totalRecords={academies.data?.count}
            />
          </div>
        </EmptyContainer>
      </div>
    </div>
  );
};

export default AcademyListPage;
