import { useRef } from "react";
import useMounted from "Core/hooks/useMounted";
import useUpdated from "Core/hooks/useUpdated";
import getUniqueId from "Core/utils/getUniqueId";
import useUnmounted from "Core/hooks/useUnmounted";

type Props = {
  src: string;
};

const Script: React.FC<Props> = ({ src }) => {
  const ref = useRef<HTMLScriptElement>();

  const removeScript = () => {
    ref.current?.remove();
    ref.current = undefined;
  };

  const renderScript = () => {
    removeScript();

    const head = document.querySelector("head");
    let script = document.createElement("script");

    script.src = src;
    script.id = getUniqueId();
    ref.current = script;

    head?.appendChild(script);
  };

  useMounted(() => {
    if (src) {
      renderScript();
    }
  });

  useUpdated(() => {
    if (src) {
      renderScript();
    }
  }, src);

  useUnmounted(() => {
    removeScript();
  });

  return <></>;
};

export default Script;
