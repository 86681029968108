import { ContentItemType } from "Content/types/ContentEditor/Item";

type Props = Record<ContentItemType, any>;

const itemViewContainerItems: Props = {
  text: require("Content/components/PostContent/Item/Text").default,
  grid: require("Content/components/PostContent/Item/Grid").default,
  title: require("Content/components/PostContent/Item/Title").default,
  audio: require("Content/components/PostContent/Item/Audio").default,
  empty: require("Content/components/PostContent/Item/Empty").default,
  embed: require("Content/components/PostContent/Item/Embed").default,
  media: require("Content/components/PostContent/Item/Media").default,
  divider: require("Content/components/PostContent/Item/Divider").default,
  basicButton: require("Content/components/PostContent/Item/BasicButton").default,
  socialButton: require("Content/components/PostContent/Item/SocialButton").default,
};

export default itemViewContainerItems;
