import { cx } from "Core/utils";
import { useState } from "react";
import { Menu } from "Core/components";
import styles from "./styles.module.scss";
import { faqQuestions } from "Faq/constants";

type FaqListFilterProps = {
  value: string;
  className?: string;
  onChange: (e: string) => void;
};

const FaqListFilter: React.FC<FaqListFilterProps> = ({ className, value, onChange }) => {
  const [types] = useState(["all", ...Array.from(new Set(faqQuestions.map((item) => item.type)))]);

  return (
    <div className={cx(styles.faqListFilter, className)} style={{ backgroundImage: "url(/assets/img/dotted.svg)" }}>
      <div className={styles.faqListFilterContent}>
        <Menu value={value} onChange={onChange}>
          {types.map((x) => (
            <Menu.Item key={x} value={x}>
              {x}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default FaqListFilter;
