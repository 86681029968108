import useMounted from "Core/hooks/useMounted";
import { getEmbedConfiguration } from "./embeds";
import { getScripts, injectScriptTag } from "./helpers";
import { CSSProperties, ReactNode, useRef } from "react";

type Props = {
  markup: string;
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
};

const EmbedContainer: React.FC<Props> = ({ children, className, style, markup }) => {
  const ref = useRef<HTMLDivElement>(null);

  useMounted(() => {
    getScripts(markup).map((src) => injectScript(src));
  });

  const injectScript = (e: string) => {
    const embed = getEmbedConfiguration(e);
    if (embed && embed.isLoaded()) {
      embed.reload(ref.current!);
    } else {
      return injectScriptTag(e);
    }
    return null;
  };

  return (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  );
};

export default EmbedContainer;
