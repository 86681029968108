import useAuth from "Auth/context";
import styles from "./styles.module.scss";
import Avatar from "Core/components/Avatar";
import getPlainText from "Core/utils/draftjs/getPlainText";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import { LikeNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<LikeNotificationData>;
};

const NotificationButtonItemLike: React.FC<Props> = ({ username, data }) => {
  const auth = useAuth();

  const onClick = () => {
    window.open(`/${auth.user?.username}/${data.data.slug}`, "_self");
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <Avatar value={data.data.avatar?.url} className={styles.avatar} />
      <div className={styles.content}>
        <div className={styles.title}>
          <span>{username}</span> liked 💛 on <span>"{getPlainText(data.data.title?.value)}"</span> content.
        </div>
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemLike;
