import get from "./get";
import set from "./set";
import clone from "./clone";
import merge from "./merge";
import asArray from "./asArray";
import arrayMove from "./arrayMove";

const objectUtils = {
  set,
  get,
  merge,
  clone,
  asArray,
  arrayMove,
};

export default objectUtils;
