import { IconComponent } from "Core/types/Icon";

const TwitchIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 112 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.551 67.5894L83.3484 85.7907H54.755L39.1564 101.387V85.7907H15.759V10.3987H101.551V67.5894ZM5.35636 5.84555e-05L0.1577 20.7987V114.385H23.5577V127.387H36.5524L49.555 114.385H70.3497L111.944 72.7961V5.84555e-05H5.35636V5.84555e-05Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.3551 62.3943H54.7537V31.193H44.3551V62.3943ZM72.9497 62.3943H83.3484V31.193H72.9497V62.3943Z"
      fill="currentColor"
    />
  </svg>
);

export default TwitchIcon;
