import { useEffect, useRef } from "react";

type Props = (callback: () => void) => void;

const useMounted: Props = (callback) => {
  const mounted = useRef<boolean>(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      callback();
    }
  }, [callback]);
};

export default useMounted;
