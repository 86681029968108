import forumApi from "Forum/api";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import { DraftjsState } from "Core/types/Draftjs";
import { Button, DraftjsEditor } from "Core/components";
import getPlainText from "Core/utils/draftjs/getPlainText";

type ForumThreadCommentsItemEditProps = {
  id: string;
  topic: string;
  thread: string;
  value: DraftjsState;
  onCancel: () => void;
  onFinish: () => void;
  onChange: (e: DraftjsState) => void;
};

const ForumThreadCommentsItemEdit: React.FC<ForumThreadCommentsItemEditProps> = ({
  id,
  topic,
  thread,
  value,
  onCancel,
  onChange,
  onFinish,
}) => {
  const isEmpty = getPlainText(value.value).trim() === "";
  const updateReply = useRequest(forumApi.updateThreadReply);

  const onSubmit = () => {
    updateReply.request(id, topic, thread, value).then(() => {
      onFinish();
    });
  };

  const buttons = (
    <>
      <Button
        variant="light"
        onClick={onCancel}
        disabled={isEmpty}
        className={styles.forumThreadCommentsItemEditorCancel}
      >
        Cancel
      </Button>
      <Button
        onClick={onSubmit}
        disabled={isEmpty}
        loading={updateReply.loading}
        className={styles.forumThreadCommentsItemEditorSave}
      >
        Save
      </Button>
    </>
  );

  return (
    <div className={styles.forumThreadCommentsItemEdit}>
      <DraftjsEditor
        image
        video
        emoji
        value={value}
        buttons={buttons}
        onChange={onChange}
        placeholder="Enter your comment"
        className={styles.forumThreadCommentsItemEditor}
      />
    </div>
  );
};

export default ForumThreadCommentsItemEdit;
