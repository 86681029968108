import logout from "./logout";
import getAccount from "./getAccount";
import refreshToken from "./refreshToken";
import getSystemSettings from "./getSystemSettings";

const authApi = {
  logout,
  getAccount,
  refreshToken,
  getSystemSettings,
};

export default authApi;
