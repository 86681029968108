import { useState } from "react";
import forumApi from "Forum/api";
import useAuth from "Auth/context";
import styles from "./styles.module.scss";
import ForumThreadCreateTags from "./Tags";
import { permissions } from "Auth/constants";
import { createThreadForm } from "Forum/utils";
import { useForm, useRequest } from "Core/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { PinSelect, TopicSelect, CommentSelect } from "Forum/components";
import { Button, DraftjsEditor, Form, Input, Modal, PageHeader } from "Core/components";

type ForumThreadCreatePageProps = {};

const ForumThreadCreatePage: React.FC<ForumThreadCreatePageProps> = () => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const form = useForm(createThreadForm());
  const [topicId, setTopicId] = useState(params.topicId);
  const createThread = useRequest(forumApi.createThread);

  const onSubmit = () => {
    createThread.request(String(topicId), form.state).then((response) => {
      Modal.success({
        ok: "See Post",
        cancel: "Close",
        title: "Your forum post is published!",
        subtitle: "You can make updates to this post whenever you would like.",
      })
        .then(() => {
          navigate(`/forum/${topicId}/thread/${response}`);
        })
        .catch(() => {
          navigate(`/forum/${topicId}`);
        });
    });
  };

  return (
    <div className={styles.forumThreadCreatePage}>
      <PageHeader back title="Forum Post" subtitle="" />
      <div className={styles.forumThreadCreatePageContainer}>
        <div className={styles.forumThreadCreatePageContent}>
          <Form form={form} onSubmit={onSubmit}>
            <TopicSelect value={topicId} onChange={setTopicId} variant="outline" />
            <Input {...form.field("title")} label="Title*" maxLength={135} />
            <ForumThreadCreateTags {...form.field("tags")} />
            <div className={styles.forumThreadCreatePageMessage}>
              <DraftjsEditor
                {...form.field("message")}
                image
                video
                emoji
                maxLength={4000}
                label="Content*"
                className={styles.draftjs}
              />
            </div>
            {auth.user?.role === permissions.admin && (
              <>
                <CommentSelect {...form.field("noComment")} variant="outline" />
                <PinSelect {...form.field("pinned")} variant="outline" />
              </>
            )}
            <div className={styles.forumThreadCreatePageButton}>
              <Button type="submit" disabled={!form.valid} loading={createThread.loading}>
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForumThreadCreatePage;
