import cx from "Core/utils/cx";
import { useState } from "react";
import "draft-js/dist/Draft.css";
import useEmoji from "./useEmoji";
import useVideo from "./useVideo";
import useImage from "./useImage";
import decorators from "./Decorators";
import useToolbar from "./useToolbar";
import styles from "./styles.module.scss";
import toRaw from "Core/utils/draftjs/toRaw";
import getHTML from "Core/utils/draftjs/getHTML";
import fromRaw from "Core/utils/draftjs/fromRaw";
import useDebounce from "Core/hooks/useDebounce";
import { DraftjsProps } from "Core/types/Draftjs";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import blockStyleFn from "Core/utils/draftjs/blockStyleFn";
import removeSelection from "Core/utils/draftjs/removeSelection";
import Draftjs, { EditorPlugin } from "@draft-js-plugins/editor";
import customStyleMap from "Core/constants/draftjs/customStyleMap";
import { DraftHandleValue, EditorState, Modifier } from "draft-js";
import getSelectedTextLength from "Core/utils/draftjs/getSelectedTextLength";

const DraftjsEdit: React.FC<DraftjsProps> = ({
  label,
  value,
  image,
  video,
  emoji,
  buttons,
  onChange,
  maxLength,
  className,
  placeholder,
  toolbar = true,
  disabledOptions,
}) => {
  const debounce = useDebounce(300);
  const emojiState = useEmoji(emoji);
  const videoState = useVideo(video);
  const imageState = useImage(image);
  const toolbarState = useToolbar(toolbar);
  // const [focused, setFocused] = useState(false);
  const [state, setState] = useState(value?.value ? fromRaw(value.value) : EditorState.createEmpty());
  const [plugins] = useState<EditorPlugin[]>(
    [toolbarState?.plugin, emojiState?.plugin, videoState?.plugin, imageState?.plugin].filter(
      (x) => x
    ) as EditorPlugin[]
  );

  const handleOnChange = (e: EditorState) => {
    if (!(maxLength && e.getCurrentContent().getPlainText().length > maxLength)) {
      setState(e);
      debounce(() => onChange!({ value: toRaw(e), html: getHTML(e) }));
    }
  };

  const handleBeforeInput = (): DraftHandleValue => {
    const length = state.getCurrentContent().getPlainText("").length;
    if (maxLength && length > maxLength - 1) {
      return "handled";
    }
    return "not-handled";
  };

  const handlePastedText = (pastedText: string) => {
    const length = state.getCurrentContent().getPlainText("").length;
    const selectedTextLength = getSelectedTextLength(state);

    if (maxLength && length + pastedText.length - selectedTextLength > maxLength) {
      const selection = state.getSelection();
      const isCollapsed = selection.isCollapsed();
      const tempState = !isCollapsed ? removeSelection(state, setState) : state;

      const inputLength = tempState.getCurrentContent().getPlainText().length;
      let remainingLength = maxLength - inputLength;

      const newContent = Modifier.insertText(
        tempState.getCurrentContent(),
        tempState.getSelection(),
        pastedText.slice(0, remainingLength)
      );
      const newState = EditorState.push(tempState, newContent, "insert-characters");
      setState(newState);
      onChange?.({ value: toRaw(newState), html: getHTML(newState) });

      return "handled";
    }
    return "not-handled";
  };

  // const onFocus = () => {
  //   setFocused(true);
  // };

  // const onBlur = () => {
  //   setFocused(false);
  // };

  return (
    <div className={cx(styles.container, className, [styles.withMax, !!maxLength])}>
      {label && <label>{label}</label>}
      <section className={cx(styles.input, styles.open)}>
        <div className={styles.draftjs}>
          <Draftjs
            // onBlur={onBlur}
            stripPastedStyles
            plugins={plugins}
            // onFocus={onFocus}
            autoCorrect="off"
            autoComplete="off"
            editorState={state}
            autoCapitalize="off"
            preserveSelectionOnBlur
            decorators={decorators}
            placeholder={placeholder}
            onChange={handleOnChange}
            blockStyleFn={blockStyleFn}
            customStyleMap={customStyleMap}
            handlePastedText={handlePastedText}
            handleBeforeInput={handleBeforeInput}
          />
        </div>
        {toolbarState && <toolbarState.Component value={state} disabledOptions={disabledOptions} />}
        {/* {(focused || !!draftjsUtils.getPlainText(value?.value)) && ( */}
        <>
          <div className={styles.buttons}>
            {emojiState && <emojiState.Component value={state} onChange={handleOnChange} maxLength={maxLength} />}
            {videoState && <videoState.Component value={state} onChange={handleOnChange} />}
            {imageState && <imageState.Component value={state} onChange={handleOnChange} />}
            {buttons}
            {maxLength && (
              <div className={styles.length}>{maxLength - state.getCurrentContent().getPlainText("").length}</div>
            )}
          </div>
        </>
        {/* )} */}
      </section>
    </div>
  );
};

export default DraftjsEdit;
