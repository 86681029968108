import forumApi from "Forum/api";
import useGet from "Core/hooks/useGet";
import styles from "./styles.module.scss";
import Select from "Core/components/Select";
import { ButtonVariant } from "Core/components/Button";

type Props = {
  value?: string;
  disabled?: boolean;
  variant?: ButtonVariant;
  onChange?: (e: string) => void;
};

const TopicSelect: React.FC<Props> = ({ value, disabled, variant, onChange }) => {
  const topics = useGet(() => forumApi.getTopics({ limit: 100, offset: 0 }));

  return (
    <div className={styles.topicSelect}>
      <Select
        value={value}
        label="Topic*"
        variant={variant}
        disabled={disabled}
        onChange={onChange}
        placeholder="Select Topic"
        className={styles.topicSelectSelection}
      >
        {topics.data?.items.map((x) => (
          <Select.Option key={x._id} value={x._id}>
            {x.title}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default TopicSelect;
