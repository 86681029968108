import days from "./days";
import start from "./start";
import { CalendarDay } from "Core/types/DateTime";

const prevMonthDays = (month: number, year: number) => {
  let result: CalendarDay[] = [];
  const length = start(month, year);

  if (length > 0) {
    const prevMonth = month > 1 ? month - 1 : 12;
    const prevYear = month > 1 ? year : year - 1;
    result = days(prevMonth, prevYear, true);
  }

  return result.slice(-length);
};

export default prevMonthDays;
