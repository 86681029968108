import Faq from "Faq";
import Forum from "Forum";
import HowTo from "HowTo";
import Academy from "Academy";
import Landing from "Landing";
import RootLayout from "./layout";
import { Navigate, Route, Routes } from "react-router-dom";

const Root: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route element={<RootLayout />}>
        <Route path="faq/*" element={<Faq />} />
        <Route path="howto/*" element={<HowTo />} />
        <Route path="academy/*" element={<Academy />} />
        <Route path="forum/*" element={<Forum />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Root;
