import { useState } from "react";
import { useUpdated } from "Core/hooks";
import styles from "./styles.module.scss";
import { Post } from "Content/types/Post";
import { cx, draftjsUtils } from "Core/utils";
import { MenuItem } from "HowTo/types/MenuItem";
import ArrowUpIcon from "Core/components/Icon/arrowUp";
import ArrowDownIcon from "Core/components/Icon/arrowDown";

type HowToSidebarItemProps = {
  data: MenuItem;
  selected?: Post;
  onClick: (e: Post) => void;
};

const HowToSidebarItem: React.FC<HowToSidebarItemProps> = ({ selected, data, onClick }) => {
  const [collapsed, setCollapsed] = useState(data.key === "subMenu" && selected?.menuTag === data.label);

  const onLabelClick = () => {
    if (data.key === "menu") {
      onClick(data.item);
    } else {
      setCollapsed(!collapsed);
    }
  };

  useUpdated(() => {
    if (data.key === "subMenu" && !!selected && selected.menuTag === data.label) {
      setCollapsed(true);
    }
  }, selected);

  return (
    <div
      className={cx(
        styles.howToSidebarItem,
        [styles.active, data.key === "menu" && data.item === selected],
        [styles.collapsed, data.key === "subMenu" && !!data.items.length && collapsed]
      )}
    >
      <div className={styles.howToSidebarItemLabel} onClick={onLabelClick}>
        {data.key === "menu" ? draftjsUtils.getPlainText(data.item.title.value) : data.label}
        {data.key === "subMenu" && !!data.items.length && (
          <div className={styles.howToSidebarItemArrow}>{collapsed ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
        )}
      </div>
      {data.key === "subMenu" && !!data.items.length && collapsed && (
        <div className={styles.howToSidebarItemList}>
          {data.items.map((x) => (
            <HowToSidebarItem key={String(x._id)} data={x} selected={selected} onClick={onClick} />
          ))}
        </div>
      )}
    </div>
  );
};

export default HowToSidebarItem;
