import getSafeUrl from "Core/utils/url/getSafeUrl";

type Props = (url: string, userId?: string, postId?: string) => string;

const getUrlWithParams: Props = (url, userId, postId) => {
  const safeUrl = getSafeUrl(url);
  const hasParams = safeUrl.indexOf("?") > -1;
  const user = userId;
  const post = postId;

  const params = `s1=alf&s2=${user}${post ? `&s3=${post}` : ""}`;

  return hasParams ? `${safeUrl}&${params}` : `${safeUrl}?${params}`;
};

export default getUrlWithParams;
