import { useMounted } from "Core/hooks";
import styles from "./styles.module.scss";
import Button from "Core/components/Button";
import { cx, getUniqueId } from "Core/utils";
import Popover from "Core/components/Popover";
import Dropdown from "Core/components/Dropdown";
import DropdownItem from "Core/components/Dropdown/Item";
import ChevronDownIcon from "Core/components/Icon/chevronDown";
import { Children, ReactElement, ReactNode, MouseEvent, useRef, useState } from "react";

type MenuItemProps = {
  value: any;
  icon?: ReactNode;
  after?: ReactNode;
  children?: ReactNode;
  onClick?: (value: any) => void;
  submenu?: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
};

type Props = {
  value?: any;
  disabled?: boolean;
  className?: string;
  mobileDropdown?: boolean;
  onChange?: (e: any) => void;
  children?: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
};

const Menu = ({ value, children, onChange, disabled, className, mobileDropdown }: Props) => {
  const [id] = useState(getUniqueId());
  const ref = useRef<HTMLUListElement>(null);

  const selected = children
    ? [...Children.map(children, (x) => [x, ...(x.props.submenu || ([] as any))]).flat()].find(
        (x) => x.props.value === value
      )
    : null;

  const onScroll = (itemId: string) => {
    const el = document.getElementById(`${id}-${itemId}`);

    if (ref.current && el) {
      const ulLeft = ref.current.getBoundingClientRect().left;
      const elLeft = el.getBoundingClientRect().left;
      const scrollOffset = elLeft - ulLeft + ref.current.scrollLeft - ref.current.clientWidth / 2 + el.clientWidth / 2;

      ref.current.scrollTo({ left: scrollOffset, behavior: "smooth" });
    }
  };

  const handleOnChange = (e: any) => () => {
    onChange?.(e.value);
    e.onClick && e.onClick(e.value);
    onScroll(e.value);
  };

  const onSubmenuClick = (p: any) => (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleOnChange(p)();
  };

  useMounted(() => {
    if (value) {
      onScroll(value);
    }
  });

  return children ? (
    <>
      <ul
        ref={ref}
        className={cx(styles.menu, [styles.disabled, disabled], [styles.desktopHidden, mobileDropdown], className)}
      >
        {Children.map(children, (x) =>
          x.props.submenu ? (
            <Popover
              closeOnClick
              closeOnScroll
              variant="light"
              popoverClassName={styles.popover}
              className={styles.popoverContent}
            >
              <li
                key={x.props.value}
                role={value === x.props.value ? "active" : undefined}
                className={cx(styles.menuItem, [
                  styles.active,
                  value === x.props.value ||
                    Children.map(x.props.submenu, (y) => y).some((y) => y.props.value === value),
                ])}
              >
                <span onClick={onSubmenuClick(x.props)}>{x.props.children}</span>
                <div className={styles.menuItemArrow}>
                  <ChevronDownIcon />
                </div>
              </li>
              {Children.map(x.props.submenu, (y) => (
                <li
                  key={y.props.value}
                  onClick={handleOnChange(y.props)}
                  role={value === y.props.value ? "active" : undefined}
                  className={cx(styles.menuItem, styles.subMenuItem, [styles.active, value === y.props.value])}
                >
                  {y.props.children}
                </li>
              ))}
            </Popover>
          ) : (
            <li
              key={x.props.value}
              id={`${id}-${x.props.value}`}
              onClick={handleOnChange(x.props)}
              role={value === x.props.value ? "active" : undefined}
              className={cx(styles.menuItem, [styles.active, value === x.props.value])}
            >
              {x.props.children}
            </li>
          )
        )}
      </ul>
      {mobileDropdown && (
        <Dropdown title="Select" className={styles.menuDropdown}>
          <Button variant="text" after={<ChevronDownIcon />} className={styles.menuDropdownButton}>
            {selected?.props.children || "Select"}
          </Button>
          {Children.map(children, (x) =>
            x.props.submenu ? (
              Children.map(x.props.submenu, (y) => (
                <DropdownItem
                  key={y.props.value}
                  active={value === y.props.value}
                  onClick={handleOnChange(y.props)}
                  className={cx(styles.menuItem, [styles.active, value === y.props.value])}
                >
                  {y.props.children}
                </DropdownItem>
              ))
            ) : (
              <DropdownItem
                key={x.props.value}
                active={value === x.props.value}
                onClick={handleOnChange(x.props)}
                className={cx(styles.menuItem, [styles.active, value === x.props.value])}
              >
                {x.props.children}
              </DropdownItem>
            )
          )}
        </Dropdown>
      )}
    </>
  ) : (
    <></>
  );
};

Menu.Item = (_: MenuItemProps) => null;

export default Menu;
