import now from "./now";
import day from "./day";
import past from "./past";
import until from "./until";
import future from "./future";
import calendar from "./calendar";

const time = { now, day, calendar, until, past, future };

export default time;
