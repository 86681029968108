import api from "Core/api";
import { Post } from "Content/types/Post";
import { Pagination } from "Core/types/Pagination";

type Response = { items: Post[]; count: number };

type ApiResponse = { items: Post[]; count: number };

type Props = (pagination: Pagination, sort?: string, tag?: string) => Promise<Response>;

const getAcademies: Props = async (pagination, sort = "newest", tag) => {
  const response = await api.post<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}public/blog/list`, {
    sort,
    tag,
    ...pagination,
  });
  return response.data;
};

export default getAcademies;
