import useAuth from "Auth/context";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import PlusIcon from "Core/components/Icon/plus";

type HowToCreateButtonProps = {};

const HowToCreateButton: React.FC<HowToCreateButtonProps> = () => {
  const auth = useAuth();

  return auth.user ? (
    <a href={`${process.env.REACT_APP_URL}blog/admin/create?blogType=howto`} className={styles.academyCreateLink}>
      <Button before={<PlusIcon />} className={styles.academyCreateButton}>
        Create a How-To Content
      </Button>
    </a>
  ) : (
    <></>
  );
};

export default HowToCreateButton;
