import { EditorState } from "draft-js";

type Props = (state: EditorState) => EditorState;

const selectAllText: Props = (state) => {
  let content = state.getCurrentContent();

  let selection = state.getSelection().merge({
    anchorOffset: 0,
    focusKey: content.getLastBlock().getKey(),
    anchorKey: content.getFirstBlock().getKey(),
    focusOffset: content.getLastBlock().getText().length,
  });

  return EditorState.acceptSelection(state, selection);
};

export default selectAllText;
