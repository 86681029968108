import styles from "./styles.module.scss";
import Avatar from "Core/components/Avatar";
import Button from "Core/components/Button";
import getPlainText from "Core/utils/draftjs/getPlainText";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import ChevronRightIcon from "Core/components/Icon/chevronRight";
import { CommentResponseNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<CommentResponseNotificationData>;
};

const NotificationButtonItemCommentResponse: React.FC<Props> = ({ username, data }) => {
  const onClick = () => {
    window.open(`/${data.data.username}/${data.data.slug}`, "_self");
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <Avatar value={data.data.avatar?.url} className={styles.avatar} />
      <div className={styles.content}>
        <div className={styles.title}>
          You have received a response to your <strong>comment</strong> on <span>{username}'s</span> content.
        </div>
        {getPlainText(data.data.comment?.value).trim() && (
          <div className={styles.innerContent}>
            <div className={styles.comment}>{getPlainText(data.data.comment?.value)}</div>
            <Button after={<ChevronRightIcon />} variant="text">
              See now
            </Button>
          </div>
        )}
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemCommentResponse;
