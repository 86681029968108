import { KeyboardEvent } from "react";

type Event = KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>;
type Key = "Enter" | "Backspace";
type Callback = (e: Event) => void;
type Props = (key: Key, callback: Callback) => (event: Event) => void;

const onKey: Props = (key, callback) => (e) => {
  if (e.key === key) {
    callback(e);
  }
};

export default onKey;
