import useAuth from "Auth/context";
import cookie from "Core/utils/cookie";
import NotificationContext from "./context";
import { ReactNode, useRef, useState } from "react";
import { useUpdated, useEventBus, useUnmounted } from "Core/hooks";

type Props = {
  children?: ReactNode;
};

const NotificationProvider: React.FC<Props> = ({ children }) => {
  const auth = useAuth();
  const timerRef = useRef(2000);
  const eventBus = useEventBus();
  const ref = useRef<WebSocket>();
  const reconnectRef = useRef(false);
  const [connected, setConnected] = useState(false);

  const onOpen = () => {
    setConnected(true);
    timerRef.current = 2000;
    ref.current?.send(JSON.stringify({ type: "handshake", data: { userID: auth.user?._id } }));
  };

  const onReconnect = () => {
    if (!reconnectRef.current) {
      reconnectRef.current = true;
      setConnected(false);
      ref.current = undefined;
      setTimeout(() => {
        timerRef.current *= 2;
        connect();
      }, timerRef.current);
    }
  };

  const onMessage = (e: any) => {
    const message = JSON.parse(e.data);
    if (message.type === "heartbeat") {
      ref.current?.send(e.data);
    } else {
      eventBus.emit(message.type, message.data);
    }
  };

  const connect = () => {
    reconnectRef.current = false;
    ref.current = new WebSocket(`${process.env.REACT_APP_WS_URL}/wss?token=${cookie.getToken()}`);
    ref.current.onopen = onOpen;
    ref.current.onclose = onReconnect;
    ref.current.onmessage = onMessage;
    ref.current.onerror = onReconnect;
  };

  useUpdated(() => {
    if (auth.user) {
      connect();
    }
  }, auth.user);

  useUnmounted(() => {
    if (connected) {
      ref.current?.close();
    }
  });

  return <NotificationContext.Provider children={children} value={{ connected }} />;
};

export default NotificationProvider;
