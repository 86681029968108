type Props = (
  container: HTMLElement,
  popover: HTMLElement,
  placement: "left" | "right" | "top" | "bottom" | "topLeft" | "bottomLeft" | "bottomRight"
) => {
  top: number;
  left: number;
};

const getPopoverPosition: Props = (container, popover, placement) => {
  const { body } = document;
  const { documentElement } = document;

  const containerRect = container?.children[0]?.getBoundingClientRect();
  const popoverRect = popover?.getBoundingClientRect();

  const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;

  const clientTop = documentElement.clientTop || body.clientTop || 0;
  const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

  let top = containerRect?.top + scrollTop - clientTop;
  let left = containerRect?.left + scrollLeft - clientLeft;

  const calcPlace = {
    top: () => {
      top -= popoverRect?.height + 10;
      left += containerRect?.width / 2 - popoverRect?.width / 2;
    },
    left: () => {
      left -= popoverRect?.width + 10;
    },
    right: () => {
      left += containerRect?.width + 10;
    },
    topLeft: () => {
      top -= popoverRect?.height + 10;
      left -= popoverRect?.width + 10;
    },
    bottom: () => {
      top += containerRect?.height + 10;
      left += containerRect?.width / 2 - popoverRect?.width / 2;
      if (popoverRect.width + left > window.innerWidth) {
        left = window.innerWidth - popoverRect.width;
      }
    },
    bottomLeft: () => {
      top -= popoverRect?.height - containerRect?.height;
      left -= popoverRect?.width + 10;
    },
    bottomRight: () => {
      top -= popoverRect?.height - containerRect?.height;
      left += containerRect?.width + 10;
    },
  };

  calcPlace[placement]();

  return {
    top: top < 0 ? 0 : top,
    left: left < 0 ? 0 : left,
  };
};

export default getPopoverPosition;
