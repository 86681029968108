import { IconComponent } from "Core/types/Icon";

const MoreIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 0C11.1046 -4.82823e-08 12 0.89543 12 2C12 3.10457 11.1046 4 10 4C8.89543 4 8 3.10457 8 2C8 0.895431 8.89543 4.82823e-08 10 0Z"
      fill="currentColor"
    />
    <path
      d="M2 0C3.10457 -4.82823e-08 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 4.82824e-08 3.10457 0 2C-4.82821e-08 0.895431 0.89543 4.82823e-08 2 0Z"
      fill="currentColor"
    />
    <path
      d="M18 0C19.1046 -4.82823e-08 20 0.89543 20 2C20 3.10457 19.1046 4 18 4C16.8954 4 16 3.10457 16 2C16 0.895431 16.8954 4.82823e-08 18 0Z"
      fill="currentColor"
    />
  </svg>
);

export default MoreIcon;
