const Type1: React.FC = () => {
  return (
    <svg
      height="1"
      fill="none"
      width="100%"
      viewBox="0 0 534 1"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100%" height="1" rx="0.5" fill="transparent" />
    </svg>
  );
};

export default Type1;
