import { IconComponent } from "Core/types/Icon";

const LinkIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.89744 1C3.64037 1 1 3.68629 1 7C1 10.3137 3.64037 13 6.89744 13C7.32227 13 7.66667 12.6496 7.66667 12.2174C7.66667 11.7852 7.32227 11.4348 6.89744 11.4348C4.49004 11.4348 2.53846 9.44926 2.53846 7C2.53846 4.55074 4.49004 2.56522 6.89744 2.56522C7.32227 2.56522 7.66667 2.21483 7.66667 1.78261C7.66667 1.35039 7.32227 1 6.89744 1Z"
      fill="currentColor"
    />
    <path
      d="M15.1026 1C14.6777 1 14.3333 1.35039 14.3333 1.78261C14.3333 2.21483 14.6777 2.56522 15.1026 2.56522C17.51 2.56522 19.4615 4.55074 19.4615 7C19.4615 9.44926 17.51 11.4348 15.1026 11.4348C14.6777 11.4348 14.3333 11.7852 14.3333 12.2174C14.3333 12.6496 14.6777 13 15.1026 13C18.3596 13 21 10.3137 21 7C21 3.68629 18.3596 1 15.1026 1Z"
      fill="currentColor"
    />
    <path
      d="M6.89744 6.21739C6.4726 6.21739 6.12821 6.56778 6.12821 7C6.12821 7.43222 6.4726 7.78261 6.89744 7.78261H15.1026C15.5274 7.78261 15.8718 7.43222 15.8718 7C15.8718 6.56778 15.5274 6.21739 15.1026 6.21739H6.89744Z"
      fill="currentColor"
    />
    <path
      d="M6.89744 1C3.64037 1 1 3.68629 1 7C1 10.3137 3.64037 13 6.89744 13C7.32227 13 7.66667 12.6496 7.66667 12.2174C7.66667 11.7852 7.32227 11.4348 6.89744 11.4348C4.49004 11.4348 2.53846 9.44926 2.53846 7C2.53846 4.55074 4.49004 2.56522 6.89744 2.56522C7.32227 2.56522 7.66667 2.21483 7.66667 1.78261C7.66667 1.35039 7.32227 1 6.89744 1Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M15.1026 1C14.6777 1 14.3333 1.35039 14.3333 1.78261C14.3333 2.21483 14.6777 2.56522 15.1026 2.56522C17.51 2.56522 19.4615 4.55074 19.4615 7C19.4615 9.44926 17.51 11.4348 15.1026 11.4348C14.6777 11.4348 14.3333 11.7852 14.3333 12.2174C14.3333 12.6496 14.6777 13 15.1026 13C18.3596 13 21 10.3137 21 7C21 3.68629 18.3596 1 15.1026 1Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M6.89744 6.21739C6.4726 6.21739 6.12821 6.56778 6.12821 7C6.12821 7.43222 6.4726 7.78261 6.89744 7.78261H15.1026C15.5274 7.78261 15.8718 7.43222 15.8718 7C15.8718 6.56778 15.5274 6.21739 15.1026 6.21739H6.89744Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export default LinkIcon;
