import LandingFaq from "./Faq";
import useAuth from "Auth/context";
import LandingHowTo from "./HowTo";
import LandingForum from "./Forum";
import LandingIntro from "./Intro";
import LandingHeader from "./Header";
import LandingFooter from "./Footer";
import LandingAcademy from "./Academy";
import { useMounted } from "Core/hooks";
import styles from "./styles.module.scss";

const Landing: React.FC = () => {
  const auth = useAuth();

  useMounted(() => {
    auth.get();
    setTimeout(() => {
      window.scroll({ top: -1, left: 0 });
    }, 50);
  });

  return (
    <div className={styles.landing}>
      <LandingHeader />
      <LandingIntro />
      <LandingHowTo />
      <LandingAcademy />
      <LandingForum />
      <LandingFaq />
      <LandingFooter />
    </div>
  );
};

export default Landing;
