import { EditorState } from "draft-js";
import blockStyleFn from "./blockStyleFn";
import getUrlWithParams from "./getUrlWithParams";
import getSafeUrl from "Core/utils/url/getSafeUrl";
import { stateToHTML } from "draft-js-export-html";
import customStyleMap from "Core/constants/draftjs/customStyleMap";

type Props = (state: EditorState) => string;

const getHTML: Props = (state) => {
  const content = state.getCurrentContent();

  const html = stateToHTML(content, {
    defaultBlockTag: "div",
    inlineStyles: Object.keys(customStyleMap).reduce((a, b) => ({ ...a, [b]: { style: customStyleMap[b] } }), {}),
    blockStyleFn: (e) => {
      const blockStyle = blockStyleFn(e);
      return { attributes: { class: `${e.getType()} ${blockStyle}` } };
    },
    entityStyleFn: (entity) => {
      const entityType = entity.getType();
      if (entityType === "LINK") {
        const data = entity.getData();
        return {
          element: "a",
          attributes: {
            href: getUrlWithParams(getSafeUrl(data.url)),
            target: "_blank",
          },
          style: {},
        };
      } else if (entityType === "draft-js-video-plugin-video") {
        const data = entity.getData();
        return {
          element: "iframe",
          attributes: {
            src: data.src,
            style: undefined,
            frameborder: "0",
            allowfullscreen: "",
          },
        };
      } else {
        return;
      }
    },
  });

  return html;
};

export default getHTML;
