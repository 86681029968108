import useAuth from "Auth/context";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import EditIcon from "Core/components/Icon/edit";

type AcademyEditButtonProps = {
  blogId: string;
};

const AcademyEditButton: React.FC<AcademyEditButtonProps> = ({ blogId }) => {
  const auth = useAuth();

  return auth.user ? (
    <a href={`${process.env.REACT_APP_URL}blog/admin/${blogId}`} className={styles.academyEditLink}>
      <Button before={<EditIcon />} className={styles.academyEditButton}>
        Edit Blog
      </Button>
    </a>
  ) : (
    <></>
  );
};

export default AcademyEditButton;
