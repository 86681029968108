import toRaw from "./toRaw";
import fromRaw from "./fromRaw";
import getHTML from "./getHTML";
import createText from "./createText";
import createImage from "./createImage";
import createVideo from "./createVideo";
import blockStyleFn from "./blockStyleFn";
import getBlockType from "./getBlockType";
import getPlainText from "./getPlainText";
import toggleEntity from "./toggleEntity";
import selectAllText from "./selectAllText";
import getInlineStyle from "./getInlineStyle";
import removeSelection from "./removeSelection";
import toggleBlockType from "./toggleBlockType";
import getUrlWithParams from "./getUrlWithParams";
import toggleInlineStyle from "./toggleInlineStyle";
import createDraftjsState from "./createDraftjsState";
import getSelectedTextLength from "./getSelectedTextLength";

const draftjsUtils = {
  toRaw,
  fromRaw,
  getHTML,
  createText,
  createImage,
  createVideo,
  blockStyleFn,
  getBlockType,
  getPlainText,
  toggleEntity,
  selectAllText,
  getInlineStyle,
  removeSelection,
  toggleBlockType,
  getUrlWithParams,
  toggleInlineStyle,
  createDraftjsState,
  getSelectedTextLength,
};

export default draftjsUtils;
