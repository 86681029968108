import { IconComponent } from "Core/types/Icon";

const CategoriesIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.14537 0.270771C4.84076 0.249987 4.46972 0.249993 4.02527 0.25H3.97475C3.53029 0.249993 3.15925 0.249987 2.85464 0.270771C2.53754 0.292406 2.23801 0.339049 1.94762 0.459332C1.27379 0.73844 0.73844 1.27379 0.459332 1.94762C0.339049 2.23801 0.292406 2.53754 0.270771 2.85464C0.249987 3.15925 0.249993 3.53028 0.25 3.97474V4.02525C0.249993 4.46971 0.249987 4.84076 0.270771 5.14537C0.292406 5.46247 0.339049 5.76199 0.459332 6.05238C0.73844 6.72621 1.27379 7.26156 1.94762 7.54067C2.23801 7.66095 2.53754 7.7076 2.85464 7.72923C3.15925 7.75001 3.53028 7.75001 3.97474 7.75H4.02526C4.46972 7.75001 4.84075 7.75001 5.14537 7.72923C5.46247 7.7076 5.76199 7.66095 6.05238 7.54067C6.72621 7.26156 7.26156 6.72621 7.54067 6.05238C7.66095 5.76199 7.7076 5.46247 7.72923 5.14537C7.75001 4.84075 7.75001 4.46972 7.75 4.02526V3.97474C7.75001 3.53028 7.75001 3.15925 7.72923 2.85464C7.7076 2.53754 7.66095 2.23801 7.54067 1.94762C7.26156 1.27379 6.72621 0.73844 6.05238 0.459332C5.76199 0.339049 5.46247 0.292406 5.14537 0.270771ZM2.52165 1.84515C2.5988 1.81319 2.71602 1.78372 2.95674 1.76729C3.20421 1.75041 3.5238 1.75 4 1.75C4.4762 1.75 4.7958 1.75041 5.04326 1.76729C5.28399 1.78372 5.4012 1.81319 5.47836 1.84515C5.78464 1.97202 6.02798 2.21536 6.15485 2.52165C6.18681 2.5988 6.21629 2.71602 6.23271 2.95674C6.24959 3.20421 6.25 3.5238 6.25 4C6.25 4.4762 6.24959 4.7958 6.23271 5.04326C6.21629 5.28399 6.18681 5.4012 6.15485 5.47836C6.02798 5.78464 5.78464 6.02798 5.47836 6.15485C5.4012 6.18681 5.28399 6.21629 5.04326 6.23271C4.7958 6.24959 4.4762 6.25 4 6.25C3.5238 6.25 3.20421 6.24959 2.95674 6.23271C2.71602 6.21629 2.5988 6.18681 2.52165 6.15485C2.21536 6.02798 1.97202 5.78464 1.84515 5.47836C1.81319 5.4012 1.78372 5.28399 1.76729 5.04326C1.75041 4.7958 1.75 4.4762 1.75 4C1.75 3.5238 1.75041 3.20421 1.76729 2.95674C1.78372 2.71602 1.81319 2.5988 1.84515 2.52165C1.97202 2.21536 2.21536 1.97202 2.52165 1.84515Z"
      fill="currentColor"
    />
    <path
      d="M14.75 1C14.75 0.585787 14.4142 0.25 14 0.25C13.5858 0.25 13.25 0.585787 13.25 1V3.25H11C10.5858 3.25 10.25 3.58579 10.25 4C10.25 4.41421 10.5858 4.75 11 4.75H13.25V7C13.25 7.41421 13.5858 7.75 14 7.75C14.4142 7.75 14.75 7.41421 14.75 7V4.75H17C17.4142 4.75 17.75 4.41421 17.75 4C17.75 3.58579 17.4142 3.25 17 3.25H14.75V1Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.14537 10.2708C4.84075 10.25 4.46972 10.25 4.02526 10.25H3.97474C3.53028 10.25 3.15925 10.25 2.85464 10.2708C2.53754 10.2924 2.23801 10.339 1.94762 10.4593C1.27379 10.7384 0.73844 11.2738 0.459332 11.9476C0.339049 12.238 0.292406 12.5375 0.270771 12.8546C0.249987 13.1592 0.249993 13.5303 0.25 13.9747V14.0253C0.249993 14.4697 0.249987 14.8408 0.270771 15.1454C0.292406 15.4625 0.339049 15.762 0.459332 16.0524C0.73844 16.7262 1.27379 17.2616 1.94762 17.5407C2.23801 17.661 2.53754 17.7076 2.85464 17.7292C3.15925 17.75 3.53028 17.75 3.97474 17.75H4.02526C4.46972 17.75 4.84075 17.75 5.14537 17.7292C5.46247 17.7076 5.76199 17.661 6.05238 17.5407C6.72621 17.2616 7.26156 16.7262 7.54067 16.0524C7.66095 15.762 7.7076 15.4625 7.72923 15.1454C7.75001 14.8408 7.75001 14.4697 7.75 14.0253V13.9747C7.75001 13.5303 7.75001 13.1592 7.72923 12.8546C7.7076 12.5375 7.66095 12.238 7.54067 11.9476C7.26156 11.2738 6.72621 10.7384 6.05238 10.4593C5.76199 10.339 5.46247 10.2924 5.14537 10.2708ZM2.52165 11.8452C2.5988 11.8132 2.71602 11.7837 2.95674 11.7673C3.20421 11.7504 3.5238 11.75 4 11.75C4.4762 11.75 4.7958 11.7504 5.04326 11.7673C5.28399 11.7837 5.4012 11.8132 5.47836 11.8452C5.78464 11.972 6.02798 12.2154 6.15485 12.5216C6.18681 12.5988 6.21629 12.716 6.23271 12.9567C6.24959 13.2042 6.25 13.5238 6.25 14C6.25 14.4762 6.24959 14.7958 6.23271 15.0433C6.21629 15.284 6.18681 15.4012 6.15485 15.4784C6.02798 15.7846 5.78464 16.028 5.47836 16.1549C5.4012 16.1868 5.28399 16.2163 5.04326 16.2327C4.7958 16.2496 4.4762 16.25 4 16.25C3.5238 16.25 3.20421 16.2496 2.95674 16.2327C2.71602 16.2163 2.5988 16.1868 2.52165 16.1549C2.21536 16.028 1.97202 15.7846 1.84515 15.4784C1.81319 15.4012 1.78372 15.284 1.76729 15.0433C1.75041 14.7958 1.75 14.4762 1.75 14C1.75 13.5238 1.75041 13.2042 1.76729 12.9567C1.78372 12.716 1.81319 12.5988 1.84515 12.5216C1.97202 12.2154 2.21536 11.972 2.52165 11.8452Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1454 10.2708C14.8408 10.25 14.4697 10.25 14.0253 10.25H13.9747C13.5303 10.25 13.1592 10.25 12.8546 10.2708C12.5375 10.2924 12.238 10.339 11.9476 10.4593C11.2738 10.7384 10.7384 11.2738 10.4593 11.9476C10.339 12.238 10.2924 12.5375 10.2708 12.8546C10.25 13.1592 10.25 13.5303 10.25 13.9747V14.0253C10.25 14.4697 10.25 14.8408 10.2708 15.1454C10.2924 15.4625 10.339 15.762 10.4593 16.0524C10.7384 16.7262 11.2738 17.2616 11.9476 17.5407C12.238 17.661 12.5375 17.7076 12.8546 17.7292C13.1592 17.75 13.5303 17.75 13.9747 17.75H14.0253C14.4697 17.75 14.8408 17.75 15.1454 17.7292C15.4625 17.7076 15.762 17.661 16.0524 17.5407C16.7262 17.2616 17.2616 16.7262 17.5407 16.0524C17.661 15.762 17.7076 15.4625 17.7292 15.1454C17.75 14.8408 17.75 14.4697 17.75 14.0253V13.9747C17.75 13.5303 17.75 13.1592 17.7292 12.8546C17.7076 12.5375 17.661 12.238 17.5407 11.9476C17.2616 11.2738 16.7262 10.7384 16.0524 10.4593C15.762 10.339 15.4625 10.2924 15.1454 10.2708ZM12.5216 11.8452C12.5988 11.8132 12.716 11.7837 12.9567 11.7673C13.2042 11.7504 13.5238 11.75 14 11.75C14.4762 11.75 14.7958 11.7504 15.0433 11.7673C15.284 11.7837 15.4012 11.8132 15.4784 11.8452C15.7846 11.972 16.028 12.2154 16.1549 12.5216C16.1868 12.5988 16.2163 12.716 16.2327 12.9567C16.2496 13.2042 16.25 13.5238 16.25 14C16.25 14.4762 16.2496 14.7958 16.2327 15.0433C16.2163 15.284 16.1868 15.4012 16.1549 15.4784C16.028 15.7846 15.7846 16.028 15.4784 16.1549C15.4012 16.1868 15.284 16.2163 15.0433 16.2327C14.7958 16.2496 14.4762 16.25 14 16.25C13.5238 16.25 13.2042 16.2496 12.9567 16.2327C12.716 16.2163 12.5988 16.1868 12.5216 16.1549C12.2154 16.028 11.972 15.7846 11.8452 15.4784C11.8132 15.4012 11.7837 15.284 11.7673 15.0433C11.7504 14.7958 11.75 14.4762 11.75 14C11.75 13.5238 11.7504 13.2042 11.7673 12.9567C11.7837 12.716 11.8132 12.5988 11.8452 12.5216C11.972 12.2154 12.2154 11.972 12.5216 11.8452Z"
      fill="currentColor"
    />
  </svg>
);

export default CategoriesIcon;
