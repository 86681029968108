import { useState } from "react";
import Input from "Core/components/Input";
import styles from "./styles.module.scss";
import useDebounce from "Core/hooks/useDebounce";
import SearchIcon from "Core/components/Icon/search";

type Props = {
  onChange: (e: string) => void;
};

const EmojiPickerSearch: React.FC<Props> = ({ onChange }) => {
  const [value, setValue] = useState("");
  const debounce = useDebounce(300);

  const handleOnChange = (e: string) => {
    setValue(e);
    debounce(() => onChange(e));
  };

  return (
    <div className={styles.container}>
      <Input
        value={value}
        placeholder="Search"
        before={<SearchIcon />}
        className={styles.input}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default EmojiPickerSearch;
