import { v4 as uuidv4 } from "uuid";

let uuids: string[] = [];

type Props = (addToList?: boolean) => string;

const getUniqueId: Props = (addToList) => {
  let id = "";

  while (!id) {
    const uuid = uuidv4();
    if (!uuids.includes(uuid)) {
      id = uuid;
      if (addToList) uuids.push(uuid);
    }
  }

  return id;
};

export const updateUniqueIds = (e: string[]) => {
  uuids = e;
};

export default getUniqueId;
