import { useGet } from "Core/hooks";
import academyApi from "Academy/api";
import { Menu } from "Core/components";
import styles from "./styles.module.scss";
import { AcademySortSelect } from "Academy/components";

type AcademyListFilterProps = {
  sort: string;
  value: string;
  setSort: (e: string) => void;
  onChange: (e: string) => void;
};

const AcademyListFilter: React.FC<AcademyListFilterProps> = ({ value, sort, setSort, onChange }) => {
  const categories = useGet(academyApi.getCategories);
  const combinedCategories = categories.data
    ? [{ tag: "All", subTags: [] }, ...categories.data]
    : [{ tag: "All", subTags: [] }];

  return (
    <div className={styles.academyListFilter} style={{ backgroundImage: "url(/assets/img/dotted.svg)" }}>
      <div className={styles.academyListFilterContent}>
        <Menu value={value} onChange={onChange} mobileDropdown>
          {combinedCategories.map((x) =>
            x.subTags.length ? (
              <Menu.Item
                key={x.tag}
                value={x.tag}
                submenu={x.subTags.map((y) => (
                  <Menu.Item key={y} value={y}>
                    {y}
                  </Menu.Item>
                ))}
              >
                {x.tag}
              </Menu.Item>
            ) : (
              <Menu.Item key={x.tag} value={x.tag}>
                {x.tag}
              </Menu.Item>
            )
          )}
        </Menu>
        <div className={styles.academyListFilterSelect}>
          <AcademySortSelect value={sort} onChange={setSort} />
        </div>
      </div>
    </div>
  );
};

export default AcademyListFilter;
