import { Button } from "Core/components";
import styles from "./styles.module.scss";
import { Post } from "Content/types/Post";
import { draftjsUtils } from "Core/utils";
import ArrowUpRightIcon from "Core/components/Icon/arrowUpRight";

type LandingHowToCardProps = {
  data: Post;
};

const LandingHowToCard: React.FC<LandingHowToCardProps> = ({ data }) => {
  return (
    <div className={styles.landingHowToCard}>
      <div className={styles.landingHowToCardTitle}>
        {draftjsUtils.getPlainText(data.title.value)}
        <Button variant="primary">
          <ArrowUpRightIcon />
        </Button>
      </div>
      <div className={styles.landingHowToCardSubtitle}>{data.summary}</div>
    </div>
  );
};

export default LandingHowToCard;
