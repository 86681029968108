import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import itemViewContainerItems from "./items";
import ErrorBoundary from "Core/components/ErrorBoundary";
import { GridType } from "Content/types/ContentEditor/Grid";
import { ContentItem } from "Content/types/ContentEditor/Item";
import { ContentEditorType } from "Content/types/ContentEditor/Type";

type Props = {
  grid?: GridType;
  value: ContentItem;
  p?: "default" | "small";
  type?: ContentEditorType;
};

const ItemViewContainer: React.FC<Props> = ({ value, grid, type = "post", p = "default" }) => {
  const Component = itemViewContainerItems[value.data.key];

  return Component ? (
    <div className={cx(styles.container, styles[p], styles[type], styles[value.data.key])}>
      <ErrorBoundary>
        <Component type={type} value={value} grid={grid} />
      </ErrorBoundary>
    </div>
  ) : (
    <></>
  );
};

export default ItemViewContainer;
