const closePopover = () => {
  const body = document.querySelector("body");
  const element = document.createElement("div");
  const event = new Event("mousedown", { bubbles: true, cancelable: true });

  body?.appendChild(element);
  element.dispatchEvent(event);

  element.remove();
};

export default closePopover;
