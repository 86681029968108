import { cx } from "Core/utils";
import { useState } from "react";
import useAuth from "Auth/context";
import { useScroll } from "Core/hooks";
import styles from "./styles.module.scss";
import { Logo, Img, Button } from "Core/components";
import MessageIcon from "Core/components/Icon/message";
import { NotificationButton } from "Notification/components";
import { NavigationMenu, AccountMenu, GuestMenu } from "Root/components";

const LandingHeader: React.FC = () => {
  const auth = useAuth();
  const [scrolled, setScrolled] = useState(false);

  const onClick = () => {
    window.scroll({ top: -1, left: 0 });
  };

  useScroll((top) => {
    setScrolled(top > 100);
  });

  return (
    <header className={cx(styles.header, [styles.scrolled, scrolled])}>
      <div className={styles.headerContent}>
        <Logo className={styles.logo} onClick={onClick} light />
        <NavigationMenu className={styles.headerNavigationMenu} />
        <div className={styles.headerRight}>
          {auth.user ? (
            <>
              <a href={`${process.env.REACT_APP_URL}chat`}>
                <Button variant="outline" className={styles.circle}>
                  <MessageIcon />
                </Button>
              </a>
              <NotificationButton className={styles.circle} />
              <AccountMenu className={styles.accountMenu} mobileClassName={styles.mobileAccountMenu} />
            </>
          ) : (
            <GuestMenu theme="dark" />
          )}
        </div>
      </div>
      <Img src="/assets/img/dotted.svg" className={styles.headerBg} />
    </header>
  );
};

export default LandingHeader;
