import { TimeDay } from "Core/types/DateTime";
import getDate from "Core/utils/date/getDate";

type Props = (payload?: string) => TimeDay;

const dateTime: Props = (payload) => {
  const [year, month, day] = `${getDate(payload || new Date().toISOString())}`.split("-");

  return {
    day: Number(day),
    year: Number(year),
    month: Number(month),
    date: `${year}-${month}-${day}`,
  };
};

export default dateTime;
