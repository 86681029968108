import { SelectionState } from "draft-js";
import Utils from "@draft-js-plugins/utils";
import Button from "Core/components/Button";
import { ChangeEvent, useState } from "react";
import LinkIcon from "Core/components/Icon/link";
import getSafeUrl from "Core/utils/url/getSafeUrl";
import isValidUrl from "Core/utils/url/isValidUrl";
import toggleEntity from "Core/utils/draftjs/toggleEntity";
import preventDefault from "Core/utils/element/preventDefault";
import { DraftjsToolbarButtonProps } from "Core/types/Draftjs";
import stopPropagation from "Core/utils/element/stopPropagation";
import ArrowLeftIcon from "Core/components/Icon/arrowLeft";

const Link: React.FC<DraftjsToolbarButtonProps> = ({ getEditorState, setEditorState, active, onToggle }) => {
  const [url, setUrl] = useState("");
  const editorState = getEditorState();
  const entity = Utils.getCurrentEntity(editorState);
  const [selection, setSelection] = useState<SelectionState>();
  const valid = isValidUrl(getSafeUrl(url), true);

  const onOpen = () => {
    setSelection(editorState.getSelection());
    setUrl(entity?.getData().url || "");
    onToggle();
  };

  const onSelect = (e: object | null) => {
    setEditorState(toggleEntity(editorState, "LINK", e, selection));
    onToggle();
  };

  const onSubmit = () => {
    onSelect({ url: getSafeUrl(url) });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const onClear = () => {
    setUrl("");
    onSelect(null);
  };

  return active ? (
    <>
      <h4>
        <Button variant="text" before={<ArrowLeftIcon />} onClick={onToggle}>
          Link
        </Button>
      </h4>
      <section>
        <input autoFocus value={url} onChange={preventDefault(onChange)} placeholder="Enter Link" />
        <Button variant="primary" onClick={onSubmit} disabled={!valid}>
          Save
        </Button>
      </section>
    </>
  ) : (
    <li onClick={onOpen} role={entity ? "active" : ""}>
      <span>
        <LinkIcon />
      </span>
      <h5>Link</h5>
      {entity && (
        <Button variant="dark" onClick={stopPropagation(onClear)}>
          Clear
        </Button>
      )}
    </li>
  );
};

export default Link;
