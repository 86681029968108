import { IconComponent } from "Core/types/Icon";

const SearchIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5011 16.5019L13.5843 13.585M15.6677 8.58461C15.6677 12.4969 12.4962 15.6685 8.58387 15.6685C4.67156 15.6685 1.5 12.4969 1.5 8.58461C1.5 4.67229 4.67156 1.50073 8.58387 1.50073C12.4962 1.50073 15.6677 4.67229 15.6677 8.58461Z"
      stroke="currentColor"
      strokeWidth="1.66679"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;
