import colors from "./colors";
import fontSizes from "./fontSizes";
import { CSSProperties } from "react";
import lineHeights from "./lineHeights";
import verticalAlignments from "./verticalAlignments";

type Props = Record<string, CSSProperties>;
type CreateMap = (key: keyof CSSProperties, value: (string | number)[], append?: CSSProperties) => Props;

const createFromArray: CreateMap = (key, value, append = {}) => {
  return value.reduce((x, y) => ({ ...x, [`${key}-${y}`]: { [key]: y, ...append } }), {});
};

const customStyleMap: Props = {
  ...createFromArray("color", colors),
  ...createFromArray("fontSize", fontSizes),
  ...createFromArray("lineHeight", lineHeights),
  ...createFromArray(
    "verticalAlign",
    verticalAlignments.map((x) => x.value)
  ),
  ...createFromArray("backgroundColor", colors, { padding: "0 2px", borderRadius: 4 }),
  BLOCKQUOTE: {
    padding: 15,
    color: "white",
    margin: "5px 0",
    display: "block",
    borderRadius: 20,
    backgroundColor: "#543bf7",
  },
  BOLD: {
    fontWeight: 900,
  },
};

export default customStyleMap;
