import cx from "Core/utils/cx";
import modalOpen from "./Open";
import { ReactNode } from "react";
import styles from "./styles.module.scss";
import modalOpenError from "./Open/error";
import Portal from "Core/components/Portal";
import modalOpenSuccess from "./Open/success";
import modalOpenConfirm from "./Open/confirm";
import modalOpenInfo from "./Open/info";

type Props = {
  width?: number;
  visible: boolean;
  icon?: ReactNode;
  title?: ReactNode;
  className?: string;
  onClose: () => void;
  subtitle?: ReactNode;
  children?: ReactNode;
  contentClassName?: string;
};

const Modal = ({ icon, title, width, visible, onClose, subtitle, children, className, contentClassName }: Props) => {
  return visible ? (
    <Portal container={document.getElementById("modalContainer")}>
      <div className={cx(styles.modal, className)} role="dialog">
        <div className={styles.modalBackdrop} onClick={onClose} />
        <main className={cx(styles.modalCard, contentClassName)} style={{ width }}>
          {icon && <div className={styles.modalCardIcon}>{icon}</div>}
          {title && <div className={styles.modalCardTitle}>{title}</div>}
          {subtitle && <div className={styles.modalCardSubtitle}>{subtitle}</div>}
          {children && <div>{children}</div>}
        </main>
      </div>
    </Portal>
  ) : (
    <></>
  );
};

Modal.open = modalOpen;
Modal.info = modalOpenInfo;
Modal.error = modalOpenError;
Modal.confirm = modalOpenConfirm;
Modal.success = modalOpenSuccess;

export default Modal;
