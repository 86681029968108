import { useRef } from "react";
import { cx } from "Core/utils";
import styles from "./styles.module.scss";
import NotificationButtonItem from "./Item";
import NotificationButtonEmpty from "./Empty";
import notificationApi from "Notification/api";
import { Button, Popover } from "Core/components";
import { PopoverRef } from "Core/components/Popover";
import ArrowLeftIcon from "Core/components/Icon/arrowLeft";
import NotificationIcon from "Core/components/Icon/notification";
import { useMounted, useGet, useRequest, useEventBus } from "Core/hooks";
import NotificationFilledIcon from "Core/components/Icon/notificationFilled";

type Props = {
  className?: string;
};

const NotificationButton: React.FC<Props> = ({ className }) => {
  const eventBus = useEventBus();
  const popover = useRef<PopoverRef>(null);
  const notifications = useGet(notificationApi.getNotifications);
  const hasUnread = !!notifications.data?.find((x) => !x.read);
  const readAll = useRequest(notificationApi.readAllNotifications, { showError: true });
  const unreadCount = notifications.data?.filter((x) => !x.read).length;

  const onReadAll = () => {
    readAll.request(notifications.data?.map((x) => x._id) || []).then(() => {
      notifications.request();
    });
  };

  const onClose = () => {
    popover.current?.close();
  };

  useMounted(() => {
    eventBus.on("notification", notifications.request);
  });

  return (
    <Popover
      ref={popover}
      variant="light"
      placement="bottomLeft"
      popoverClassName={styles.popover}
      className={styles.popoverContainer}
    >
      <Button
        auto
        variant="whiteOutline"
        className={cx(styles.button, className, [styles.unread, hasUnread, styles.read])}
      >
        {hasUnread ? <NotificationFilledIcon /> : <NotificationIcon />}
        {!!unreadCount && <div className={styles.unreadDot}>{unreadCount > 99 ? "99+" : unreadCount}</div>}
      </Button>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>
              <ArrowLeftIcon onClick={onClose} />
              Notification
            </div>
            <Button variant="text" onClick={onReadAll} loading={readAll.loading} disabled={!notifications.data?.length}>
              Delete all
            </Button>
          </div>
          {notifications.data?.length ? (
            <div className={styles.innerContent}>
              {notifications.data?.length ? (
                notifications.data.map((x) => (
                  <NotificationButtonItem key={x._id} data={x} refresh={notifications.request} />
                ))
              ) : (
                <NotificationButtonEmpty />
              )}
            </div>
          ) : (
            <NotificationButtonEmpty />
          )}
        </div>
      </div>
    </Popover>
  );
};

export default NotificationButton;
