import { colorUtils } from "Core/utils";
import { ContentItem } from "Content/types/ContentEditor/Item";
import { BasicButton } from "Content/types/ContentEditor/BasicButton";

type Props = {
  edit: boolean;
  value: ContentItem<BasicButton>;
};

const Reflex: React.FC<Props> = ({ value }) => {
  const style = {
    color: value.data.style.color,
    width: value.data.style.width,
    borderRadius: value.data.style.borderRadius,
    backgroundColor: value.data.style.backgroundColor,
    boxShadow: `0px 7px 14px ${colorUtils.modifyAlpha(value.data.style.backgroundColor, 0.24)}`,
  };

  return (
    <button style={style} aria-label={value.data.text}>
      {value.data.text}
    </button>
  );
};

export default Reflex;
