import { IconComponent } from "Core/types/Icon";

const ArrowUpIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      strokeWidth="2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7 17V1M7 1L1 7M7 1L13 7"
    />
  </svg>
);

export default ArrowUpIcon;
