import { useRef } from "react";
import { getUniqueId } from "Core/utils";
import { useMounted, useUnmounted, useUpdated } from "Core/hooks";

type Props = {
  href: string;
};

const Css: React.FC<Props> = ({ href }) => {
  const preconnectRef = useRef<HTMLLinkElement>();
  const preloadRef = useRef<HTMLLinkElement>();
  const stylesheetRef = useRef<HTMLLinkElement>();

  const removeLinks = () => {
    preconnectRef.current?.remove();
    preconnectRef.current = undefined;

    preloadRef.current?.remove();
    preloadRef.current = undefined;

    stylesheetRef.current?.remove();
    stylesheetRef.current = undefined;
  };

  const renderLinks = () => {
    removeLinks();

    const head = document.querySelector("head");
    let preconnect = document.createElement("link");
    let preload = document.createElement("link");
    let stylesheet = document.createElement("link");

    preconnect.id = getUniqueId();
    preconnect.rel = "preconnect";
    preconnect.href = href;

    preload.id = getUniqueId();
    preload.rel = "preload";
    preload.as = "style";
    preload.href = href;

    stylesheet.id = getUniqueId();
    stylesheet.rel = "stylesheet";
    stylesheet.href = href;

    preconnectRef.current = preconnect;
    preloadRef.current = preload;
    stylesheetRef.current = stylesheet;

    head?.appendChild(preconnect);
    head?.appendChild(preload);
    head?.appendChild(stylesheet);
  };

  useMounted(() => {
    if (href) {
      renderLinks();
    }
  });

  useUpdated(() => {
    if (href) {
      renderLinks();
    }
  }, href);

  useUnmounted(() => {
    removeLinks();
  });

  return <></>;
};

export default Css;
