import styles from "./styles.module.scss";
import { Pagination } from "Core/components";
import ForumThreadCommentsItem from "./Item";
import { ForumTopicReply } from "Forum/types/Reply";

type ForumThreadCommentsProps = {
  page: number;
  count: number;
  refresh: () => void;
  data?: ForumTopicReply[];
  setPage: (e: number) => void;
};

const ForumThreadComments: React.FC<ForumThreadCommentsProps> = ({ count, page, setPage, data, refresh }) => {
  return (
    <div className={styles.forumThreadComments}>
      <div className={styles.forumThreadCommentsList}>
        {data?.map((x) => (
          <ForumThreadCommentsItem key={x._id} data={x} refresh={refresh} />
        ))}
      </div>
      <Pagination
        pageSize={10}
        currentPage={page}
        totalRecords={count}
        onPageChange={setPage}
        showOnSinglePage={false}
      />
    </div>
  );
};

export default ForumThreadComments;
