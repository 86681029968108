import { cx } from "Core/utils";
import useAuth from "Auth/context";
import AccountMenuMobile from "./Mobile";
import styles from "./styles.module.scss";
import { permissions } from "Auth/constants";
import { useNavigate } from "react-router-dom";
import ChevronDownIcon from "Core/components/Icon/chevronDown";
import { Avatar, Button, Dropdown, DropdownItem } from "Core/components";

type AccountMenuProps = {
  className?: string;
  mobileClassName?: string;
};

const AccountMenu: React.FC<AccountMenuProps> = ({ className, mobileClassName }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const onBack = () => {
    window.open(`${process.env.REACT_APP_URL}creator`, "_self");
  };

  const onCockpit = () => {
    window.open(`${process.env.REACT_APP_COCKPIT_URL}`, "_self");
  };

  const onClick = (e: string) => () => {
    navigate(e);
  };

  return (
    <>
      <Dropdown className={styles.accountMenuDropdown}>
        <Button
          variant="whiteOutline"
          after={<ChevronDownIcon />}
          className={cx(styles.accountMenuButton, className)}
          before={<Avatar value={auth.user?.avatar} size={26} />}
        >
          <span className={styles.accountMenuButtonText}>{auth.user?.name || auth.user?.username}</span>
        </Button>
        <DropdownItem onClick={onClick("/howto")}>How-To</DropdownItem>
        <DropdownItem onClick={onClick("/academy")}>Academy</DropdownItem>
        <DropdownItem onClick={onClick("/forum")}>Forum</DropdownItem>
        <DropdownItem onClick={onClick("/announcement")}>Announcement</DropdownItem>
        <DropdownItem onClick={onBack}>Back to Rambly</DropdownItem>
        {auth.user?.role === permissions.admin && <DropdownItem onClick={onCockpit}>Rambly Cockpit</DropdownItem>}
        <DropdownItem onClick={auth.logout}>Logout</DropdownItem>
      </Dropdown>
      <AccountMenuMobile className={mobileClassName} />
    </>
  );
};

export default AccountMenu;
