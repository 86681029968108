import { cx } from "Core/utils";
import styles from "./styles.module.scss";
import { rootMenuItems } from "Root/constants";
import { Link, useMatch } from "react-router-dom";

type NavigationMenuProps = {
  className?: string;
  type?: "footer" | "header";
};

const NavigationMenu: React.FC<NavigationMenuProps> = ({ className, type = "header" }) => {
  const match = useMatch("/:selectedKey/*");
  const basePath = `/${match?.params.selectedKey}`;

  return (
    <div className={cx(styles.navigationMenu, styles[type], className)}>
      {rootMenuItems.map((x) => (
        <Link
          to={x.value}
          key={x.value}
          className={cx(styles.navigationMenuItem, [styles.active, x.value === basePath])}
        >
          {x.label}
        </Link>
      ))}
    </div>
  );
};

export default NavigationMenu;
