import { ReactNode } from "react";
import getUniqueId from "Core/utils/getUniqueId";
import { createRoot, Root } from "react-dom/client";

type Props = (
  id: string,
  type: keyof HTMLElementTagNameMap
) => {
  remove: () => void;
  render: (children: ReactNode) => void;
};

const createComponent: Props = (id) => {
  let root: Root;
  const itemId = getUniqueId();
  let item = document.createElement("div");
  const container = document.getElementById(id)!;

  const remove = () => {
    root.unmount();
    item.remove();
  };

  const render = (children: ReactNode) => {
    item.id = itemId;
    container.appendChild(item);
    root = createRoot(item);

    root.render(children);
  };

  return {
    remove,
    render,
  };
};

export default createComponent;
