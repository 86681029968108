import { IconComponent } from "Core/types/Icon";

const ArrowDownIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 0.999999L7 17M7 17L13 11M7 17L1 11"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowDownIcon;
