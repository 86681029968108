const getProviderFromEmbedCode = (embedCode: string): string => {
  // Regular expressions to match various embed code patterns
  const youtubeRegex = /youtube\.com\/embed\/([a-zA-Z0-9_-]+)/i;
  const vimeoRegex = /player\.vimeo\.com\/video\/([0-9]+)/i;
  const twitterRegex = /twitter\.com\/([a-zA-Z0-9_]+)\/status\/([0-9]+)/i;
  const instagramRegex = /instagram\.com\/p\/([a-zA-Z0-9_-]+)/i;
  const tiktokRegex = /tiktok\.com\/@([a-zA-Z0-9_.-]+)\/video\/([0-9]+)/i;
  const twitchRegex = /twitch\.tv\/([a-zA-Z0-9_]+)\/v\/([0-9]+)/i;

  // Try to match YouTube embed code
  const youtubeMatch = embedCode.match(youtubeRegex);
  if (youtubeMatch && youtubeMatch.length >= 2) {
    return "YouTube";
  }

  // Try to match Vimeo embed code
  const vimeoMatch = embedCode.match(vimeoRegex);
  if (vimeoMatch && vimeoMatch.length >= 2) {
    return "Vimeo";
  }

  // Try to match Twitter embed code
  const twitterMatch = embedCode.match(twitterRegex);
  if (twitterMatch && twitterMatch.length >= 3) {
    return "Twitter";
  }

  // Try to match Instagram embed code
  const instagramMatch = embedCode.match(instagramRegex);
  if (instagramMatch && instagramMatch.length >= 2) {
    return "Instagram";
  }

  // Try to match TikTok embed code
  const tiktokMatch = embedCode.match(tiktokRegex);
  if (tiktokMatch && tiktokMatch.length >= 3) {
    return "TikTok";
  }

  // Try to match Twitch embed code
  const twitchMatch = embedCode.match(twitchRegex);
  if (twitchMatch && twitchMatch.length >= 3) {
    return "Twitch";
  }

  // If no provider is matched, return null
  return "Embed";
};

export default getProviderFromEmbedCode;
