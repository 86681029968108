import styles from "./styles.module.scss";
import UserDeleteIcon from "Core/components/Icon/userDelete";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import { MembershipCancelNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<MembershipCancelNotificationData>;
};

const NotificationButtonItemMembershipCancel: React.FC<Props> = ({ username, data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <UserDeleteIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <strong>{username} has discontinued offering memberships.</strong> You will no longer be charged a monthly
          membership fee.
        </div>
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemMembershipCancel;
