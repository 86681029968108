import { ReactNode } from "react";
import styles from "./styles.module.scss";
import Modal from "Core/components/Modal";
import Button from "Core/components/Button";
import createComponent from "Core/utils/element/createComponent";

type Payload = {
  ok?: string;
  width?: number;
  title?: string;
  icon?: ReactNode;
  onOk?: () => void;
  subtitle?: string;
  closeOnOk?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  closeOnCancel?: boolean;
  cancel?: string | boolean;
};

type Props = (payload: Payload) => Promise<void>;

const modalOpen: Props = ({
  onOk,
  icon,
  title,
  width,
  cancel,
  onClose,
  onCancel,
  subtitle,
  ok = "Close",
  closeOnOk = true,
  closeOnCancel = true,
}) => {
  return new Promise((resolve, reject) => {
    const component = createComponent("modalContainer", "div");

    const onOkClick = () => {
      onOk?.();
      if (closeOnOk) {
        component.remove();
        resolve();
      }
    };

    const onCancelClick = () => {
      onCancel?.();
      if (closeOnCancel) {
        component.remove();
        reject();
      }
    };

    const onModalClose = () => {
      component.remove();
      onClose?.();
    };

    component.render(
      <Modal width={width} onClose={onModalClose} icon={icon} title={title} subtitle={subtitle} visible>
        <div className={styles.modalButtons}>
          {cancel && (
            <Button variant="outline" onClick={onCancelClick}>
              {cancel}
            </Button>
          )}
          {ok && (
            <Button variant="primary" onClick={onOkClick}>
              {ok}
            </Button>
          )}
        </div>
      </Modal>
    );
  });
};

export default modalOpen;
