import monthLength from "./length";
import { CalendarDay } from "Core/types/DateTime";

type Props = (month: number, year: number, outside?: boolean) => CalendarDay[];

const days: Props = (month, year, outside) => {
  let result = [];
  const length = monthLength(month, year);

  for (let i = 0; i < length; i++) {
    result.push({
      day: i + 1,
      month,
      year,
      outside,
      date: `${year}-${String(month).padStart(2, "0")}-${String(i + 1).padStart(2, "0")}`,
    });
  }
  return result;
};

export default days;
