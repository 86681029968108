import api from "Core/api";
import { User } from "Auth/types/User";
import cookie from "Core/utils/cookie";

type Response = User;

type ApiResponse = User;

type Props = () => Promise<Response>;

const getAccount: Props = async () => {
  if (cookie.getToken()) {
    const response = await api.get<ApiResponse>("editor/me");
    if (response.data.settings && (response.data.settings.categories as any) === "") {
      response.data.settings.categories = [];
    }

    return response.data;
  } else {
    throw Error("");
  }
};

export default getAccount;
