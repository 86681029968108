import styles from "./styles.module.scss";
import RefundIcon from "Core/components/Icon/refund";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import { RefundNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<RefundNotificationData>;
};

const NotificationButtonItemRefund: React.FC<Props> = ({ username, data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <RefundIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <strong>The refund fee associated</strong> with <strong>{username}'s</strong> content will be credited to your
          account.
        </div>
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemRefund;
