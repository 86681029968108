import howToApi from "HowTo/api";
import { useState } from "react";
import useAuth from "Auth/context";
import HowToSidebar from "./Sidebar";
import HowToContent from "./Content";
import styles from "./styles.module.scss";
import { Post } from "Content/types/Post";
import { permissions } from "Auth/constants";
import { HowToCreateButton } from "HowTo/components";
import getPlainText from "Core/utils/draftjs/getPlainText";
import { EmptyContainer, PageHeader } from "Core/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDevice, useGet, useMounted, useUpdated } from "Core/hooks";

const HowToPage: React.FC = () => {
  const auth = useAuth();
  const device = useDevice();
  const navigate = useNavigate();
  const posts = useGet(howToApi.getPosts);
  const [params, setParams] = useSearchParams();
  const [selected, setSelected] = useState<Post>();

  const updateParams = (id?: string) => {
    setParams(
      (p) => {
        id ? p.set("s", id) : p.delete("s");
        return p;
      },
      { replace: true }
    );
  };

  const onBack = () => {
    selected ? setSelected(undefined) : navigate(-1);
  };

  useMounted(() => {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0 });
    }, 50);
  });

  useUpdated(() => {
    if (!!posts.data?.length) {
      const paramsItem = posts.data
        .reduce((prev, x) => {
          return x.key === "menu" ? [...prev, x.item] : [...prev, ...x.items.map((y) => y.item)];
        }, [] as Post[])
        .find((x) => x._id === params.get("s"));
      if (paramsItem) {
        setSelected(paramsItem);
      } else {
        if (device.isDesktop) {
          const firstItem = posts.data[0];
          firstItem.key === "menu" ? setSelected(firstItem.item) : setSelected(firstItem.items[0].item);
        }
      }
    }
  }, posts.data);

  useUpdated(() => {
    updateParams(selected?._id);
  }, selected);

  return (
    <div className={styles.howToPage}>
      <PageHeader
        back={onBack}
        title={getPlainText(selected?.title.value) || "Learn the Rambly basics"}
        subtitle="Navigate Rambly like a pro with our step-by-step 'How To' guides."
        extra={auth.user?.role === permissions.admin ? <HowToCreateButton /> : undefined}
      />
      <div className={styles.howToPageContainer}>
        <EmptyContainer loading={posts.loading} empty={!posts.data?.length}>
          <div className={styles.howToPageContent}>
            <HowToSidebar selected={selected} setSelected={setSelected} data={posts.data} />
            {selected && <HowToContent selected={selected} />}
          </div>
        </EmptyContainer>
      </div>
    </div>
  );
};

export default HowToPage;
