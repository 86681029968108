type Props = () => string;

const getWeekBefore: Props = () => {
  let date = new Date();
  date.setDate(date.getDate() - 7);
  date.setMinutes(date.getMinutes() - new Date().getTimezoneOffset());
  return date.toISOString().split("T")[0];
};

export default getWeekBefore;
