import { IconComponent } from "Core/types/Icon";

const LikeFilledIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.61551 0.834229C9.01549 0.834229 8.47176 1.18759 8.22808 1.73588L5.42745 8.0373C5.3595 8.19017 5.32402 8.26902 5.29494 8.32452L5.29235 8.32942L5.28683 8.32979C5.2243 8.33383 5.13784 8.33423 4.97054 8.33423L4.30492 8.33423C3.86563 8.33421 3.48666 8.3342 3.17451 8.3597C2.84506 8.38662 2.51738 8.44605 2.20206 8.60671C1.73166 8.8464 1.34921 9.22885 1.10952 9.69925C0.94886 10.0146 0.88943 10.3423 0.862513 10.6717C0.83701 10.9838 0.837023 11.3628 0.837037 11.8021V15.6997C0.837023 16.139 0.83701 16.518 0.862513 16.8301C0.88943 17.1595 0.94886 17.4872 1.10952 17.8025C1.34921 18.2729 1.73166 18.6554 2.20206 18.8951C2.51738 19.0557 2.84506 19.1152 3.17451 19.1421C3.48665 19.1676 3.86562 19.1676 4.3049 19.1676C4.69084 19.1676 5.0037 18.8547 5.0037 18.4688L5.0037 11.6676C5.0037 11.2073 5.3768 10.8342 5.83704 10.8342C6.29727 10.8342 6.67037 11.2073 6.67037 11.6676L6.67037 17.8342C6.67037 18.3009 6.67037 18.5343 6.7612 18.7126C6.84109 18.8694 6.96858 18.9968 7.12538 19.0767C7.30364 19.1676 7.53699 19.1676 8.0037 19.1676H13.27C13.8523 19.1676 14.3358 19.1676 14.7336 19.1373C15.1489 19.1057 15.5317 19.0379 15.9013 18.8704C16.4793 18.6085 16.9705 18.1871 17.3173 17.6557C17.539 17.3158 17.6642 16.9477 17.7586 16.5421C17.849 16.1535 17.9225 15.6757 18.0111 15.1002L18.4574 12.1989C18.5747 11.4365 18.6709 10.8114 18.7049 10.3012C18.74 9.77489 18.717 9.28375 18.5375 8.81298C18.2618 8.09013 17.7432 7.48568 17.0707 7.10326C16.6327 6.85421 16.1508 6.75684 15.6252 6.71149C15.1158 6.66754 14.4834 6.66755 13.7119 6.66756H13.0037C12.7566 6.66756 12.6214 6.66691 12.5242 6.65898L12.5132 6.65802L12.5123 6.64703C12.5044 6.54988 12.5037 6.41467 12.5037 6.16756V3.72243C12.5037 2.12732 11.2106 0.834229 9.61551 0.834229Z"
      fill="currentColor"
    />
  </svg>
);

export default LikeFilledIcon;
