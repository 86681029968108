import hex2rgba from "./hex2rgba";

type Props = (color?: string, alpha?: number) => string;

const modifyAlpha: Props = (color, alpha) => {
  if (!color) return "";

  const value = color.indexOf("rgba") > -1 ? color : hex2rgba(color);
  const [r, g, b] = value.replace("rgba(", "").replace(/ /g, "").replace(")", "").split(",");

  return `rgba(${r}, ${g}, ${b}, ${String(alpha)})`;
};

export default modifyAlpha;
