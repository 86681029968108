import imageSrc from "./imageSrc";
import isValidUrl from "./isValidUrl";
import getSafeUrl from "./getSafeUrl";
import getShortUrl from "./getShortUrl";

const urlUtils = {
  imageSrc,
  getSafeUrl,
  isValidUrl,
  getShortUrl,
};

export default urlUtils;
