import { useState } from "react";
import contentApi from "Content/api";
import { EditorState } from "draft-js";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import Input from "Core/components/Input";
import Button from "Core/components/Button";
import Popover from "Core/components/Popover";
import isValidUrl from "Core/utils/url/isValidUrl";
import createVideo from "Core/utils/draftjs/createVideo";
import closePopover from "Core/utils/element/closePopover";
import DraftjsVideoIcon from "Core/components/Icon/draftjsVideo";

type Props = {
  value: EditorState;
  onChange: (e: EditorState) => void;
};
const AddVideo: React.FC<Props> = ({ value, onChange }) => {
  const [url, setUrl] = useState("");
  const parseEmbedUrl = useRequest(contentApi.parseEmbedUrl, { showError: true });

  const onAdd = () => {
    parseEmbedUrl.request(url).then((response) => {
      onChange(createVideo(value, response));
      setUrl("");
      closePopover();
    });
  };

  return (
    <Popover variant="light" popoverClassName={styles.popover}>
      <Button variant="light">
        <DraftjsVideoIcon />
      </Button>
      <div className={styles.row}>
        <div>
          <Input
            value={url}
            onChange={setUrl}
            id="DraftjsVideoInput"
            className={styles.input}
            placeholder="Enter Video URL"
          />
        </div>
        <div>
          <Button disabled={!isValidUrl(url)} onClick={onAdd} block>
            Add
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export type { Props as VideoProps };

export default AddVideo;
