type Props = (url: string) => string;

const getSafeUrl: Props = (url) => {
  const regEx = /^https?:\/\//i;
  const isEmail = url.startsWith("mailto:");

  return !regEx.test(url) && !isEmail ? `http://${url}` : url;
};

export default getSafeUrl;
