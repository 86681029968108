import Button from "Core/components/Button";
import UnderlineIcon from "Core/components/Icon/underline";
import { DraftjsToolbarButtonProps } from "Core/types/Draftjs";
import toggleInlineStyle from "Core/utils/draftjs/toggleInlineStyle";

const Underline: React.FC<DraftjsToolbarButtonProps> = ({ getEditorState, setEditorState }) => {
  const editorState = getEditorState();
  const selected = editorState.getCurrentInlineStyle().has("UNDERLINE");

  const onSelect = () => {
    setEditorState(toggleInlineStyle(editorState, "UNDERLINE"));
  };

  return (
    <li onClick={onSelect} role={selected ? "active" : ""}>
      <span>
        <UnderlineIcon />
      </span>
      <h5>Underline</h5>
      {selected && <Button variant="dark">Clear</Button>}
    </li>
  );
};

export default Underline;
