import { cookie } from "Core/utils";
import axios, { AxiosError, AxiosResponse } from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    apikey: process.env.REACT_APP_API_KEY || "",
  },
});

const interceptors = {
  onSuccess: (response: AxiosResponse) => {
    return response;
  },
  onError: (e: AxiosError<any>) => {
    const title = e.response?.data?.header || "An error occured";
    const message = e.response?.data?.message || e?.message || "Unknown error occured.";
    throw { title, message };
  },
};

api.interceptors.response.use(interceptors.onSuccess, interceptors.onError);

const token = cookie.getToken();

if (token) {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export default api;
