import useAuth from "Auth/context";
import { Link } from "react-router-dom";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import PlusIcon from "Core/components/Icon/plus";

type ThreadCreateButtonProps = {
  topicId: string;
};

const ThreadCreateButton: React.FC<ThreadCreateButtonProps> = ({ topicId }) => {
  const auth = useAuth();

  return auth.user ? (
    <Link to={`/forum/${topicId}/create`}>
      <Button before={<PlusIcon />} className={styles.threadCreateButton}>
        Start a discussion
      </Button>
    </Link>
  ) : (
    <></>
  );
};

export default ThreadCreateButton;
