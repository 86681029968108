import authApi from "Auth/api";
import AuthContext from "./context";
import { cookie } from "Core/utils";
import { User } from "Auth/types/User";
import { useRequest } from "Core/hooks";
import { ReactNode, useState } from "react";
import { permissions } from "Auth/constants";

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>();
  const getAccount = useRequest(authApi.getAccount, { showError: false });
  const isGuest = user?.username.startsWith("guest_") || user?.role === permissions.endUser;

  const get = async () => {
    try {
      const response = await getAccount.request();
      setUser(response);
      return;
    } catch (error) {
      setUser(null);
      cookie.deleteToken();
      throw error;
    }
  };

  const logout = () => {
    authApi.logout().then(() => {
      setUser(null);
    });
  };

  return (
    <AuthContext.Provider
      value={{
        get,
        user,
        logout,
        isGuest,
        loading: !user && getAccount.loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
