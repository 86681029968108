import { NotificationType } from "Notification/types/NotificationType";

type Props = Record<NotificationType, any>;

const notificationItems: Props = {
  like: require("./Like").default,
  refund: require("./Refund").default,
  donate: require("./Donate").default,
  donated: require("./Donated").default,
  comment: require("./Comment").default,
  newContent: require("./NewContent").default,
  membership: require("./Membership").default,
  updateContent: require("./UpdateContent").default,
  refundRequested: require("./RefundRequested").default,
  commentResponse: require("./CommentResponse").default,
  updatePaidContent: require("./UpdateContent").default,
  newSubscription: require("./NewSubscription").default,
  membershipCancel: require("./MembershipCancel").default,
  membershipUpdate: require("./MembershipUpdate").default,
  membershipDisable: require("./MembershipDisable").default,
  newCategoryContent: require("./NewCategoryContent").default,
  newSubscriptionThanks: require("./SubscriptionThanks").default,
};

export default notificationItems;
