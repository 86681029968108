import { Img } from "Core/components";
import styles from "./styles.module.scss";
import { Title } from "Content/types/ContentEditor/Title";
import DraftjsEditor from "Content/components/DraftjsEditor";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<Title> & {
  active?: boolean;
  onChange?: (key: string) => (e: any) => void;
};

const TextView: React.FC<Props> = ({ value }) => {
  return (
    <div className={styles.container}>
      {value.data.image.value ? (
        <div className={styles.image} style={value.data.image.style}>
          <Img w={56} h={56} src={value.data.image.value} />
        </div>
      ) : (
        <div className={styles.count} style={value.data.count.style}>
          {value.data.count.value}
        </div>
      )}
      <div className={styles.textContainer} style={value.data.style}>
        <DraftjsEditor value={value.data.text} />
      </div>
    </div>
  );
};

export default TextView;
