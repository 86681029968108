import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import { faqQuestions } from "Faq/constants";
import FaqListCard from "Faq/pages/FaqListPage/Card";
import FaqListFilter from "Faq/pages/FaqListPage/Filter";
import SectionHeader from "Landing/components/SectionHeader";
import ArrowUpRightIcon from "Core/components/Icon/arrowUpRight";

const LandingFaq: React.FC = () => {
  const [filter, setFilter] = useState("all");
  const [active, setActive] = useState<number>(0);
  const filteredQuestions = faqQuestions.filter((x) => filter === "all" || x.type === filter).slice(0, 5);

  const onFilter = (e: string) => {
    setActive(0);
    setFilter(e);
  };

  const onClick = (e: number) => () => {
    setActive(e);
  };

  return (
    <section className={styles.landingFaq}>
      <div className={styles.landingFaqContent}>
        <SectionHeader
          tag="FAQ"
          title="Any questions? We got you."
          subtitle="Find quick and easy answers on commonly asked questions."
        />
        <FaqListFilter value={filter} onChange={onFilter} className={styles.landingFaqFilter} />
        {filteredQuestions.map((x, i) => (
          <FaqListCard
            key={x.key}
            type={x.type}
            answer={x.answer}
            onClick={onClick(i)}
            question={x.question}
            active={i === active}
            className={styles.landingFaqCard}
          />
        ))}
        <div className={styles.landingFaqButton}>
          <Link to="/faq">
            <Button after={<ArrowUpRightIcon />}>See All FAQs</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LandingFaq;
