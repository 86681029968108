import { IconComponent } from "Core/types/Icon";

const EditIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.12"
      d="M2.5 15.2708C2.5 14.8631 2.5 14.6593 2.54605 14.4675C2.58688 14.2974 2.65422 14.1348 2.7456 13.9857C2.84867 13.8175 2.9928 13.6734 3.28105 13.3852L13.75 2.9162C14.4404 2.22585 15.5597 2.22585 16.25 2.9162C16.9404 3.60656 16.9404 4.72585 16.25 5.4162L5.78107 15.8852C5.49282 16.1734 5.34869 16.3175 5.1805 16.4206C5.03138 16.512 4.8688 16.5793 4.69874 16.6202C4.50693 16.6662 4.3031 16.6662 3.89545 16.6662H2.5V15.2708Z"
      fill="currentColor"
    />
    <path
      d="M9.99998 16.6662H17.5M2.5 16.6662H3.89545C4.3031 16.6662 4.50693 16.6662 4.69874 16.6202C4.8688 16.5793 5.03138 16.512 5.1805 16.4206C5.34869 16.3175 5.49282 16.1734 5.78107 15.8852L16.25 5.4162C16.9404 4.72585 16.9404 3.60656 16.25 2.9162C15.5597 2.22585 14.4404 2.22585 13.75 2.9162L3.28105 13.3852C2.9928 13.6734 2.84867 13.8175 2.7456 13.9857C2.65422 14.1349 2.58688 14.2974 2.54605 14.4675C2.5 14.6593 2.5 14.8631 2.5 15.2708V16.6662Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
