import cx from "Core/utils/cx";
import { useRef } from "react";
import styles from "./styles.module.scss";
import Script from "Content/components/Script";
import { Embed } from "Content/types/ContentEditor/Embed";
import EmbedContainer from "Content/components/EmbedContainer";
import getProviderFromEmbedCode from "Content/utils/getEmbedProvider";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<Embed>;

const EmbedView: React.FC<Props> = ({ grid, value, edit }) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const provider =
    value.data.provider === "Embed Content" ? getProviderFromEmbedCode(value.data.html) : value.data.provider;

  return (
    <div
      className={cx(styles.container, styles[provider], styles[grid || "single"], [styles.disabled, edit])}
      style={{
        aspectRatio: grid && grid !== "single" ? `${value.data.style.width} / ${value.data.style.height}` : undefined,
      }}
    >
      {value.data.html ? (
        <EmbedContainer className={styles.content} markup={value.data.html}>
          <div dangerouslySetInnerHTML={{ __html: value.data.html }} className={styles.innerContent} />
        </EmbedContainer>
      ) : (
        <div className={styles.content}>
          {provider === "Vimeo" && !edit && <Script src="https://player.vimeo.com/api/player.js" />}
          <iframe title={provider} ref={ref} width="100%" height="100%" allowFullScreen src={value.data.url} />
        </div>
      )}
    </div>
  );
};

export default EmbedView;
