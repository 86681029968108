import styles from "./styles.module.scss";
import Avatar from "Core/components/Avatar";
import getPlainText from "Core/utils/draftjs/getPlainText";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import { NewContentNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<NewContentNotificationData>;
};

const NotificationButtonItemNewContent: React.FC<Props> = ({ username, data }) => {
  const onClick = () => {
    window.open(`/${data.data.username}/${data.data.slug}`, "_self");
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <Avatar value={data.data.avatar?.url} className={styles.avatar} />
      <div className={styles.content}>
        <div className={styles.title}>
          <strong>{username}</strong> has published new content! 🥳
        </div>
        <div className={styles.innerContent}>
          <div className={styles.image}>
            <img src={data.data.listingImageUrl} alt="" />
          </div>
          <div className={styles.text}>
            <div className={styles.textTitle}>{getPlainText(data.data.title.value)}</div>
            <div className={styles.textSummary}>{data.data.summary}</div>
          </div>
        </div>
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemNewContent;
