import { ReactNode } from "react";

type Props = {
  href?: string;
  active?: boolean;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  target?: "_blank" | "_self";
};

const DropdownItem: React.FC<Props> = ({ className, children, href, target, active, onClick }) => {
  return (
    <div
      className={className}
      aria-selected={active}
      role={href ? "link" : "button"}
      onClick={!href ? onClick : undefined}
    >
      {href ? (
        <a href={href} target={target} aria-label={href}>
          {children}
        </a>
      ) : (
        children
      )}
    </div>
  );
};

export default DropdownItem;
