import cx from "Core/utils/cx";
import { Img } from "Core/components";
import styles from "./styles.module.scss";
import UserIcon from "Core/components/Icon/user";

type Props = {
  size?: number;
  value?: string;
  className?: string;
};

const Avatar: React.FC<Props> = ({ className, value, size = 28 }) => {
  return (
    <div className={cx(styles.avatar, className)} style={!value ? { width: size, height: size } : undefined}>
      {value ? <Img src={value} w={size} h={size} /> : <UserIcon style={{ fontSize: size / 2 }} />}
    </div>
  );
};

export default Avatar;
