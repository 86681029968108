import { IconComponent } from "Core/types/Icon";

const TikTokIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 417 481" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M365.19 102.66C361.95 100.986 358.796 99.1499 355.74 97.16C346.853 91.2846 338.704 84.3615 331.47 76.54C313.37 55.83 306.61 34.82 304.12 20.11H304.22C302.14 7.9 303 0 303.13 0H220.69V318.78C220.69 323.06 220.69 327.29 220.51 331.47C220.51 331.99 220.46 332.47 220.43 333.03C220.43 333.26 220.43 333.5 220.38 333.74C220.38 333.8 220.38 333.86 220.38 333.92C219.511 345.358 215.845 356.407 209.703 366.095C203.562 375.783 195.133 383.814 185.16 389.48C174.765 395.394 163.009 398.496 151.05 398.48C112.64 398.48 81.51 367.16 81.51 328.48C81.51 289.8 112.64 258.48 151.05 258.48C158.321 258.473 165.547 259.617 172.46 261.87L172.56 177.93C151.574 175.219 130.253 176.887 109.944 182.828C89.6342 188.77 70.7764 198.856 54.56 212.45C40.3506 224.796 28.4048 239.527 19.26 255.98C15.78 261.98 2.65 286.09 1.06 325.22C0.0599962 347.43 6.73 370.44 9.91 379.95V380.15C11.91 385.75 19.66 404.86 32.29 420.97C42.4744 433.893 54.5069 445.245 68 454.66V454.46L68.2 454.66C108.11 481.78 152.36 480 152.36 480C160.02 479.69 185.68 480 214.82 466.19C247.14 450.88 265.54 428.07 265.54 428.07C277.295 414.441 286.642 398.909 293.18 382.14C300.64 362.53 303.13 339.01 303.13 329.61V160.49C304.13 161.09 317.45 169.9 317.45 169.9C317.45 169.9 336.64 182.2 366.58 190.21C388.06 195.91 417 197.11 417 197.11V115.27C406.86 116.37 386.27 113.17 365.19 102.66Z"
      fill="currentColor"
    />
  </svg>
);

export default TikTokIcon;
