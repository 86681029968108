import contentApi from "Content/api";
import WaveSurfer from "wavesurfer.js";
import styles from "./styles.module.scss";
import Loading from "Core/components/Loading";
import Button from "Content/components/Button";
import { dateUtils, getUniqueId } from "Core/utils";
import { CSSProperties, useRef, useState } from "react";
import AudioPlayIcon from "Core/components/Icon/audioPlay";
import AudioPauseIcon from "Core/components/Icon/audioPause";
import AudioForwardIcon from "Core/components/Icon/audioForward";
import AudioBackwardIcon from "Core/components/Icon/audioBackward";
import { useRequest, useUpdated, useMounted, useUnmounted } from "Core/hooks";

type Props = {
  value?: string;
  style?: CSSProperties;
};

const AudioPlayer: React.FC<Props> = ({ value, style }) => {
  const [uid] = useState(getUniqueId());
  const ref = useRef<HTMLDivElement>(null);
  const [length, setLength] = useState("");
  const waveSurferRef = useRef<WaveSurfer>();
  const [loaded, setLoaded] = useState(false);
  const [current, setCurrent] = useState("");
  const [playing, setPlaying] = useState(false);
  const parseAudio = useRequest(contentApi.parseAudio, { showError: false });

  const clear = () => {
    setPlaying(false);
    setLoaded(false);
    waveSurferRef.current?.empty();
  };

  const loadAudio = () => {
    clear();
    if (value) {
      parseAudio.request(value).then((response) => {
        if (waveSurferRef.current) {
          waveSurferRef.current?.loadBlob(response);
        }
      });
    }
  };

  const onPlay = () => {
    playing ? waveSurferRef.current?.pause() : waveSurferRef.current?.play();
  };

  const onForward = () => {
    waveSurferRef.current?.skip(10);
  };

  const onBackward = () => {
    waveSurferRef.current?.skip(-10);
  };

  const ready = (e: number) => {
    setLength(dateUtils.formatSeconds(e));
  };

  const load = () => {
    setLoaded(true);
  };

  const play = () => {
    setPlaying(true);
  };

  const pause = () => {
    setPlaying(false);
  };

  const timeupdate = (e: number) => {
    setCurrent(dateUtils.formatSeconds(e));
  };

  useMounted(() => {
    if (ref.current) {
      const waveSurfer = WaveSurfer.create({
        barGap: 2,
        height: 45,
        barWidth: 2,
        barHeight: 1,
        barRadius: 10,
        cursorWidth: 0,
        autoplay: false,
        waveColor: "#DDD8FD",
        progressColor: "#543BF7",
        container: ref.current || document.getElementById(`Audio-${uid}`),
      });
      waveSurfer.on("ready", ready);
      waveSurfer.on("load", load);
      waveSurfer.on("play", play);
      waveSurfer.on("pause", pause);
      waveSurfer.on("timeupdate", timeupdate);
      waveSurferRef.current = waveSurfer;
    }
    loadAudio();
  });

  useUpdated(() => {
    loadAudio();
  }, value);

  useUnmounted(() => {
    waveSurferRef.current?.destroy();
    waveSurferRef.current = undefined;
  });

  return (
    <div className={styles.audioPlayer} style={style}>
      <Loading loading={parseAudio.loading}>
        <div className={styles.preview}>
          <div ref={ref} id={`Audio-${uid}`} />
          <div className={styles.current}>{current}</div>
          <div className={styles.length}>{length}</div>
        </div>
      </Loading>
      <div className={styles.controls}>
        <Button variant="text" disabled={!loaded || parseAudio.loading} onClick={onBackward}>
          <AudioBackwardIcon />
        </Button>
        <Button variant="danger" onClick={onPlay} disabled={!loaded || parseAudio.loading} circle>
          {playing ? <AudioPauseIcon /> : <AudioPlayIcon />}
        </Button>
        <Button variant="text" disabled={!loaded || parseAudio.loading} onClick={onForward}>
          <AudioForwardIcon />
        </Button>
      </div>
    </div>
  );
};

export default AudioPlayer;
