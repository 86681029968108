import { objectUtils } from "Core/utils";
import { useRef, useEffect } from "react";

type Callback<T> = (previous?: T) => void;

const useUpdated = <T>(callback: Callback<T>, props: T) => {
  const mounted = useRef<boolean>(false);
  const ref = useRef<T>();

  useEffect(() => {
    if (!mounted.current) {
      ref.current = objectUtils.clone(props);
      mounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (mounted) {
      if (JSON.stringify(props) !== JSON.stringify(ref.current)) {
        const prev = objectUtils.clone(ref.current);
        callback && callback(prev);
        ref.current = objectUtils.clone(props);
      }
    }
  }, [props]);
};

export default useUpdated;
