import styles from "./styles.module.scss";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import HeartOutlineThinIcon from "Core/components/Icon/heartOutlineThin";
import { DonatedNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<DonatedNotificationData>;
};

const NotificationButtonItemDonate: React.FC<Props> = ({ username, data }) => {
  const onClick = () => {
    if (data.data.type === "private") {
      window.open(`/${data.data.username}`, "_self");
    }
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <HeartOutlineThinIcon className={styles.avatar} />
      <div className={styles.content}>
        <div className={styles.title}>
          Thanks for donating ${data.data.amount} to {username}! 😎
        </div>
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemDonate;
