import api from "Core/api";
import { ForumTopic } from "Forum/types/Topic";
import { Pagination } from "Core/types/Pagination";

type Response = { items: ForumTopic[]; count: number };

type ApiResponse = { items: ForumTopic[]; count: number };

type Props = (p: Pagination, sort?: string) => Promise<Response>;

const getTopics: Props = async (p, sort) => {
  const response = await api.post<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}public/topics/list`, {
    ...p,
    sort,
  });
  return response.data;
};

export default getTopics;
