import { Post } from "Content/types/Post";
import { getUniqueId } from "Core/utils";
import { MenuItem, MenuItemSubMenu } from "HowTo/types/MenuItem";

type Props = (posts: Post[]) => MenuItem[];

const groupByTag: Props = (posts) => {
  const groupedPosts: MenuItem[] = [];

  posts.forEach((x) => {
    if (x.menuTag) {
      const groupIndex = groupedPosts.findIndex((y) => y.key === "subMenu" && y.label === x.menuTag);
      if (groupIndex > -1) {
        (groupedPosts[groupIndex] as MenuItemSubMenu).items.push({ key: "menu", item: x, _id: String(x._id) });
      } else {
        groupedPosts.push({
          key: "subMenu",
          label: x.menuTag,
          _id: getUniqueId(),
          items: [{ key: "menu", item: x, _id: String(x._id) }],
        });
      }
    } else {
      groupedPosts.push({ key: "menu", item: x, _id: String(x._id) });
    }
  });

  return groupedPosts;
};

export default groupByTag;
