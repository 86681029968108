import { IconComponent } from "Core/types/Icon";

const CheckIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 13" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M17.387,0.512 C17.153,0.276 16.833,0.142 16.5,0.142 C16.167,0.142 15.847,0.276 15.613,0.512 L6.3,9.838 L2.388,5.913 C2.059,5.595 1.587,5.478 1.148,5.603 C0.709,5.729 0.371,6.079 0.261,6.522 C0.151,6.965 0.284,7.432 0.613,7.75 L5.413,12.55 C5.647,12.787 5.967,12.92 6.3,12.92 C6.633,12.92 6.953,12.787 7.188,12.55 L17.388,2.35 C17.644,2.113 17.79,1.78 17.79,1.431 C17.79,1.082 17.644,0.749 17.388,0.512 L17.387,0.512 Z"
        id="Path"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default CheckIcon;
