import { IconComponent } from "Core/types/Icon";

const ItalicIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.268 2.31795L5.108 17.6779H8.444V19.5979H0V17.6779H3.287L6.447 2.31795H3.556V0.397949H12V2.31795H8.268V2.31795Z"
      fill="currentColor"
    />
  </svg>
);

export default ItalicIcon;
