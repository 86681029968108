import api from "Core/api";
import { Post } from "Content/types/Post";

type Response = Post;

type ApiResponse = Post;

type Props = (slug: string) => Promise<Response>;

const getAcademy: Props = async (slug) => {
  const response = await api.get<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}public/blog/${slug}`);
  return response.data;
};

export default getAcademy;
