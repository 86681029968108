import cx from "Core/utils/cx";
import { ReactNode } from "react";
import styles from "./styles.module.scss";
import LoadingIcon from "Core/components/Icon/loading";

type Props = {
  loading?: boolean;
  className?: string;
  content?: ReactNode;
  children?: ReactNode;
};

const Loading: React.FC<Props> = ({ loading = true, className, content, children }) => {
  return (
    <div className={cx(styles.container, className)}>
      {children}
      {loading && (
        <div className={cx(styles.content, [styles.hasContent, !!content])}>{content || <LoadingIcon />}</div>
      )}
    </div>
  );
};

export default Loading;
