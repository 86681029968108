import { ReactNode } from "react";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import ArrowLeftIcon from "Core/components/Icon/arrowLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { cx } from "Core/utils";

type PageHeaderProps = {
  title: string;
  subtitle: string;
  extra?: ReactNode;
  back?: (() => void) | boolean | string;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, extra, back }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const showBack =
    !!back && (typeof back === "function" || typeof back === "string" || (typeof back === "boolean" && !!location.key));

  const onBack = () => {
    if (typeof back === "function") {
      back();
    } else {
      if (typeof back === "string" && !!location.key) {
        navigate(back);
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <div className={styles.pageHeader}>
      <div className={styles.pageHeaderContent}>
        <div className={styles.pageHeaderText}>
          <h1>
            {showBack && (
              <Button variant="whiteText" onClick={onBack}>
                <ArrowLeftIcon />
              </Button>
            )}
            {title}
          </h1>
          <h2 className={cx([styles.pageSubtitleSpaced, !!showBack])}>{subtitle}</h2>
        </div>
        {extra && <div className={styles.pageHeaderExtra}>{extra}</div>}
      </div>
    </div>
  );
};

export default PageHeader;
