import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import CheckIcon from "Core/components/Icon/check";
import DropdownItem from "Core/components/Dropdown/Item";
import ChevronDownIcon from "Core/components/Icon/chevronDown";
import Button, { ButtonVariant } from "Core/components/Button";
import Dropdown, { DropdownRef } from "Core/components/Dropdown";
import { Children, ReactElement, ReactNode, useRef } from "react";

type SelectOptionProps = {
  value: any;
  children?: ReactNode;
};

type Props<T> = {
  value?: T;
  label?: string;
  title?: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  variant?: ButtonVariant;
  onChange?: (e: T) => void;
  children?: ReactElement<SelectOptionProps> | ReactElement<SelectOptionProps>[];
};

const Select = <T,>({
  label,
  title,
  value,
  onChange,
  disabled,
  children,
  className,
  placeholder,
  variant = "whiteOutline",
}: Props<T>) => {
  const dropdown = useRef<DropdownRef>(null);
  const selected = children ? Children.map(children, (x) => x).find((x) => x.props.value === value) : null;

  const onClick = (e: any) => () => {
    onChange?.(e);
    dropdown.current?.close();
  };

  return (
    <div className={cx(styles.select, className)}>
      {label && <div className={styles.selectLabel}>{label}</div>}
      <Dropdown title={title || label} className={styles.selectDropdown} ref={dropdown} disabled={disabled}>
        <Button className={styles.selectDropdownButton} after={<ChevronDownIcon />} variant={variant} block>
          <div>{selected?.props.children || placeholder || "Select"}</div>
        </Button>
        {children &&
          Children.map(children, (x) => (
            <DropdownItem
              key={x.props.value}
              onClick={onClick(x.props.value)}
              active={x.props.value === selected?.props.value}
              className={cx([styles.selectItemActive, x.props.value === selected?.props.value])}
            >
              {x.props.children}
              {x.props.value === selected?.props.value && <CheckIcon className={styles.selectDropdownCheck} />}
            </DropdownItem>
          ))}
      </Dropdown>
    </div>
  );
};

Select.Option = (_: SelectOptionProps) => null;

export default Select;
