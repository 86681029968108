import api from "Core/api";
import Cookie from "Core/utils/cookie";

type Response = void;

type ApiResponse = {
  _id: string;
  username: string;
  accessToken: string;
  refreshToken: string;
};

type Props = (email: string, refreshToken: string) => Promise<Response>;

const refreshToken: Props = async (email, refreshToken) => {
  const response = await api.post<ApiResponse>("auth/refresh", { email, refreshToken });
  Cookie.setToken(response.data.accessToken, response.data.refreshToken);
  return;
};

export default refreshToken;
