import AcademyPage from "./pages/AcademyPage";
import AcademyListPage from "./pages/AcademyListPage";
import { Navigate, Route, Routes } from "react-router-dom";

const Academy: React.FC = () => {
  return (
    <Routes>
      <Route index element={<AcademyListPage />} />
      <Route path=":academySlug" element={<AcademyPage />} />
      <Route path="*" element={<Navigate to="/academy" />} />
    </Routes>
  );
};
export default Academy;
