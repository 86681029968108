import { EditorState, Modifier } from "draft-js";

type Props = (editorState: EditorState, value: string) => EditorState;

const createText: Props = (editorState, value) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  return EditorState.set(editorState, {
    currentContent: Modifier.insertText(contentState, selection, value),
  });
};

export default createText;
