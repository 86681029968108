import ExternalLink from "./External";
import { ContentState } from "draft-js";
import { ReactNode, useState } from "react";
import useMounted from "Core/hooks/useMounted";
import getSafeUrl from "Core/utils/url/getSafeUrl";

type Props = {
  entityKey: string;
  children?: ReactNode;
  contentState: ContentState;
};

const Link: React.FC<Props> = ({ children, contentState, entityKey }) => {
  const [url, setUrl] = useState("");

  useMounted(async () => {
    setUrl(getSafeUrl(contentState.getEntity(entityKey).getData().url));
  });

  return (
    <ExternalLink href={url} elk={{ target: "button" }} params>
      {children}
    </ExternalLink>
  );
};

export default Link;
