import { TimeDay } from "Core/types/DateTime";

type Props = (payload: TimeDay) => TimeDay;

const safe: Props = (payload) => {
  payload.year = payload.month > 12 ? payload.year + 1 : payload.month < 1 ? payload.year - 1 : payload.year;
  payload.month = payload.month > 12 ? 1 : payload.month < 1 ? 12 : payload.month;
  return payload;
};

export default safe;
