import getAlpha from "./getAlpha";
import hex2rgba from "./hex2rgba";
import rgba2hex from "./rgba2hex";
import modifyAlpha from "./modifyAlpha";
import toColorObject from "./toColorObject";
import toGradientObject from "./toGradientObject";

const colorUtils = {
  getAlpha,
  hex2rgba,
  rgba2hex,
  modifyAlpha,
  toColorObject,
  toGradientObject,
};

export default colorUtils;
