import { IconComponent } from "Core/types/Icon";

const UnderlineIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.35 0.203003H14.25V9.923C14.25 13.502 12.48 15.323 9.00001 15.323C5.52001 15.323 3.75001 13.502 3.75001 9.923V0.203003H1.65001V9.923C1.65001 14.695 4.36001 17.483 9.00001 17.483C13.64 17.483 16.35 14.695 16.35 9.923V0.203003V0.203003ZM0.600006 19.643V21.803H17.4V19.643H0.600006Z"
      fill="currentColor"
    />
  </svg>
);

export default UnderlineIcon;
