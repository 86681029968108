import { cx } from "Core/utils";
import howToApi from "HowTo/api";
import { useGet } from "Core/hooks";
import LandingHowToCard from "./Card";
import { Link } from "react-router-dom";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import SectionHeader from "Landing/components/SectionHeader";
import ArrowUpRightIcon from "Core/components/Icon/arrowUpRight";

const LandingHowTo: React.FC = () => {
  const posts = useGet(howToApi.getPlainPosts);

  return (
    <section className={styles.landingHowTo}>
      <div className={styles.landingHowToContent}>
        <SectionHeader
          tag="How To"
          title="Learn the Rambly basics"
          subtitle="Navigate Rambly like a pro with our step-by-step 'How To' guides."
        />
        <div className={styles.landingHowToContentList}>
          {posts.data?.map((x, i) => (
            <Link
              key={x._id}
              state={{ selected: x }}
              to={`/howto?s=${x._id}`}
              className={cx([
                styles.landingHowToContentListLastItem,
                posts.data!.length > 3 && i === posts.data!.length - 1,
              ])}
            >
              <LandingHowToCard data={x} />
            </Link>
          ))}
        </div>
        <div className={styles.landingHowToButton}>
          <Link to="/howto">
            <Button after={<ArrowUpRightIcon />}>See All How Tos</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LandingHowTo;
