import { IconOption } from "Core/types/Option";
import ArrowLeftIcon from "Core/components/Icon/arrowLeft";
import ArrowRightIcon from "Core/components/Icon/arrowRight";

const verticalAlignments: IconOption[] = [
  {
    label: "Align Top",
    value: "top",
    icon: <ArrowLeftIcon />,
  },
  {
    label: "Align Bottom",
    value: "bottom",
    icon: <ArrowRightIcon />,
  },
];

export default verticalAlignments;
