import { IconComponent } from "Core/types/Icon";

const PinterestIcon: IconComponent = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M0 8.99989C0 12.6852 2.21611 15.8511 5.38727 17.243C5.36196 16.6146 5.38277 15.8602 5.54393 15.1764C5.71697 14.4458 6.70198 10.2724 6.70198 10.2724C6.70198 10.2724 6.41447 9.69773 6.41447 8.84845C6.41447 7.51472 7.18749 6.51863 8.1502 6.51863C8.96885 6.51863 9.36436 7.1335 9.36436 7.86979C9.36436 8.69271 8.83948 9.92363 8.56955 11.0637C8.34406 12.0184 9.04823 12.7971 9.99007 12.7971C11.6953 12.7971 12.8438 10.607 12.8438 8.0121C12.8438 6.03961 11.5152 4.56323 9.09879 4.56323C6.3687 4.56323 4.66791 6.59914 4.66791 8.87327C4.66791 9.65737 4.89909 10.2103 5.2612 10.6385C5.4277 10.8351 5.45084 10.9142 5.39058 11.1401C5.34741 11.3057 5.24827 11.7043 5.2072 11.8623C5.1473 12.0903 4.96259 12.1717 4.75657 12.0876C3.4991 11.5742 2.91347 10.1972 2.91347 8.64926C2.91347 6.09269 5.06967 3.02715 9.3458 3.02715C12.782 3.02715 15.0436 5.51361 15.0436 8.18267C15.0436 11.7132 13.0807 14.3507 10.1874 14.3507C9.21572 14.3507 8.30173 13.8255 7.98863 13.2289C7.98863 13.2289 7.46613 15.3025 7.35546 15.703C7.16463 16.3968 6.79113 17.0904 6.44963 17.6309C7.25906 17.8699 8.11413 18 9.00035 18C13.9703 18 18.0001 13.9706 18.0001 8.99989C18.0001 4.02943 13.9703 0 9.00035 0C4.02989 0 0 4.02943 0 8.99989Z"
    />
  </svg>
);

export default PinterestIcon;
