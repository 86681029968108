import api from "Core/api";
import { ForumThread } from "Forum/types/Thread";
import { Pagination } from "Core/types/Pagination";

type Response = { items: ForumThread[]; count: number };

type ApiResponse = { items: ForumThread[]; count: number };

type Props = (pagination: Pagination, keyword: string, sort?: string, topicId?: string) => Promise<Response>;

const searchThreads: Props = async (pagination, keyword, sort = "newest", topicId) => {
  const response = await api.post<ApiResponse>(
    `${process.env.REACT_APP_HUB_API_URL}public/topics/${topicId ? `${topicId}/` : ""}search`,
    { ...pagination, keyword, sort }
  );
  return response.data;
};

export default searchThreads;
