import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import { Grid } from "Content/types/ContentEditor/Grid";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";
import ItemViewContainer from "Content/components/PostContent/Item/Container";

type Props = ContentItemViewProps<Grid>;

const GridView: React.FC<Props> = ({ value, type }) => {
  return (
    <div className={cx(styles.container, styles[value.data.mobile], styles[value.data.type])} style={value.data.style}>
      {value.data.items.map((x) => (
        <div key={x.contentItemID} className={styles.item} style={value.data.itemStyle}>
          <ItemViewContainer p="small" value={x} grid={value.data.type} type={type} />
        </div>
      ))}
    </div>
  );
};

export default GridView;
