import { IconComponent } from "Core/types/Icon";

const CommentIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.12"
      d="M2.49939 6.5C2.49939 5.09987 2.49939 4.3998 2.77187 3.86502C3.01156 3.39462 3.39401 3.01217 3.86441 2.77248C4.39919 2.5 5.09926 2.5 6.49939 2.5H13.4994C14.8995 2.5 15.5996 2.5 16.1344 2.77248C16.6048 3.01217 16.9872 3.39462 17.2269 3.86502C17.4994 4.3998 17.4994 5.09987 17.4994 6.5V11C17.4994 12.4001 17.4994 13.1002 17.2269 13.635C16.9872 14.1054 16.6048 14.4878 16.1344 14.7275C15.5996 15 14.8995 15 13.4994 15H8.06918C7.54914 15 7.28911 15 7.0404 15.051C6.81975 15.0963 6.60622 15.1712 6.40563 15.2737C6.17953 15.3892 5.97649 15.5517 5.5704 15.8765L3.58252 17.4668C3.23578 17.7442 3.06241 17.8829 2.91651 17.8831C2.78961 17.8832 2.66958 17.8255 2.59041 17.7263C2.49939 17.6123 2.49939 17.3903 2.49939 16.9463V6.5Z"
      fill="currentColor"
    />
    <path
      d="M5.83272 7.08333H9.99939M5.83272 10H12.4994M8.06918 15H13.4994C14.8995 15 15.5996 15 16.1344 14.7275C16.6048 14.4878 16.9872 14.1054 17.2269 13.635C17.4994 13.1002 17.4994 12.4001 17.4994 11V6.5C17.4994 5.09987 17.4994 4.3998 17.2269 3.86502C16.9872 3.39462 16.6048 3.01217 16.1344 2.77248C15.5996 2.5 14.8995 2.5 13.4994 2.5H6.49939C5.09926 2.5 4.39919 2.5 3.86441 2.77248C3.39401 3.01217 3.01156 3.39462 2.77187 3.86502C2.49939 4.3998 2.49939 5.09987 2.49939 6.5V16.9463C2.49939 17.3903 2.49939 17.6123 2.59041 17.7263C2.66958 17.8255 2.78961 17.8832 2.91651 17.8831C3.06241 17.8829 3.23578 17.7442 3.58252 17.4668L5.5704 15.8765C5.97649 15.5517 6.17953 15.3892 6.40563 15.2737C6.60622 15.1712 6.81975 15.0963 7.0404 15.051C7.28911 15 7.54914 15 8.06918 15Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommentIcon;
