import hex2rgba from "./hex2rgba";
import { RgbaColor } from "react-colorful";

type Props = (color: string) => RgbaColor | undefined;

const toColorObject: Props = (color) => {
  if (!color) return undefined;
  const value = color.indexOf("rgba") > -1 ? color : hex2rgba(color);
  const [r, g, b, a] = value.replace("rgba(", "").replace(/ /g, "").replace(")", "").split(",");

  return {
    r: Number(r),
    g: Number(g),
    b: Number(b),
    a: Number(a),
  };
};

export default toColorObject;
