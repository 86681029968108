import Emoji from "./Emoji";
import { useState } from "react";
import { DraftjsEmojiState } from "Core/types/Draftjs";
import createEmojiPlugin from "@draft-js-plugins/emoji";

type Props = (enabled?: boolean) => DraftjsEmojiState | undefined;

const useEmoji: Props = (enabled?: boolean) => {
  const [emojiState] = useState<DraftjsEmojiState | undefined>(
    enabled
      ? () => {
          const emojiPlugin = createEmojiPlugin({ emojiImage: undefined, disableInlineEmojis: true });

          return {
            plugin: emojiPlugin,
            Component: (props) => <Emoji {...props} />,
          };
        }
      : undefined
  );

  return emojiState;
};

export default useEmoji;
