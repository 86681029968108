import { ContentItem } from "Content/types/ContentEditor/Item";
import { BasicButton } from "Content/types/ContentEditor/BasicButton";

type Props = {
  edit: boolean;
  value: ContentItem<BasicButton>;
};

const Gradient: React.FC<Props> = ({ value }) => {
  const style = {
    color: value.data.style.color,
    width: value.data.style.width,
    background: value.data.style.background,
    borderRadius: value.data.style.borderRadius,
  };

  return (
    <button style={style} aria-label={value.data.text}>
      {value.data.text}
    </button>
  );
};

export default Gradient;
