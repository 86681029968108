import forumApi from "Forum/api";
import { useState } from "react";
import useAuth from "Auth/context";
import styles from "./styles.module.scss";
import ForumThreadCreateTags from "./Tags";
import { permissions } from "Auth/constants";
import { createThreadForm } from "Forum/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useMounted, useRequest } from "Core/hooks";
import { CommentSelect, PinSelect, TopicSelect } from "Forum/components";
import { Button, DraftjsEditor, Form, Input, Loading, PageHeader } from "Core/components";

type ForumThreadCreatePageProps = {};

const ForumThreadCreatePage: React.FC<ForumThreadCreatePageProps> = () => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const form = useForm(createThreadForm());
  const [loaded, setLoaded] = useState(false);
  const getThread = useRequest(forumApi.getThread);
  const updateThread = useRequest(forumApi.updateThread);

  const onSubmit = () => {
    updateThread.request(String(params.topicId), String(params.threadId), form.state).then(() => {
      navigate(`/forum/${String(params.topicId)}/thread/${String(params.threadId)}`);
    });
  };

  useMounted(() => {
    getThread.request(String(params.topicId), String(params.threadId), { limit: 0, offset: 0 }).then((response) => {
      form.set({
        tags: response.thread.tags,
        title: response.thread.title,
        pinned: response.thread.pinned,
        message: response.thread.message,
        noComment: response.thread.noComment,
      });
      setLoaded(true);
    });
  });

  return (
    <div className={styles.forumThreadCreatePage}>
      <PageHeader back title="Update Forum Post" subtitle="" />
      <div className={styles.forumThreadCreatePageContainer}>
        <div className={styles.forumThreadCreatePageContent}>
          {loaded ? (
            <Form form={form} onSubmit={onSubmit}>
              <TopicSelect value={String(params.topicId)} variant="outline" disabled />
              <Input {...form.field("title")} label="Title*" maxLength={135} />
              <ForumThreadCreateTags {...form.field("tags")} />
              <div className={styles.forumThreadCreatePageMessage}>
                <DraftjsEditor
                  {...form.field("message")}
                  image
                  video
                  emoji
                  maxLength={4000}
                  label="Content*"
                  className={styles.draftjs}
                />
              </div>
              {auth.user?.role === permissions.admin && (
                <>
                  <CommentSelect {...form.field("noComment")} variant="outline" />
                  <PinSelect {...form.field("pinned")} variant="outline" />
                </>
              )}
              <div className={styles.forumThreadCreatePageButton}>
                <Button type="submit" disabled={!form.valid} loading={updateThread.loading}>
                  Submit
                </Button>
              </div>
            </Form>
          ) : (
            <Loading loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default ForumThreadCreatePage;
