import { Img } from "Core/components";
import styles from "./styles.module.scss";

const NotificationButtonEmpty: React.FC = () => {
  return (
    <div className={styles.container}>
      <Img src="/assets/img/notification/empty.svg" w={90} h={90} alt="" />
      <div>You have no notifications.</div>
    </div>
  );
};

export default NotificationButtonEmpty;
