import Link from "./Link";
import { CompositeDecorator, DraftDecorator } from "draft-js";

const decorators: Array<CompositeDecorator | DraftDecorator> = [
  {
    strategy: (block, callback, contentState) => {
      if (!contentState) return;
      block.findEntityRanges((x) => {
        const entityKey = x.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
      }, callback);
    },
    component: Link,
  },
];

export default decorators;
