import { IconComponent } from "Core/types/Icon";

const UserDeleteIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.3334 15H18.3334M10.0001 12.9167H6.25008C5.08711 12.9167 4.50563 12.9167 4.03246 13.0602C2.96713 13.3834 2.13345 14.217 1.81028 15.2824C1.66675 15.7555 1.66675 16.337 1.66675 17.5M12.0834 6.25C12.0834 8.32107 10.4045 10 8.33341 10C6.26235 10 4.58341 8.32107 4.58341 6.25C4.58341 4.17893 6.26235 2.5 8.33341 2.5C10.4045 2.5 12.0834 4.17893 12.0834 6.25Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserDeleteIcon;
