import forumApi from "Forum/api";
import useAuth from "Auth/context";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import MoreIcon from "Core/components/Icon/more";
import EditIcon from "Core/components/Icon/edit";
import { ForumTopicReply } from "Forum/types/Reply";
import DeleteIcon from "Core/components/Icon/delete";
import EyeOutlineIcon from "Core/components/Icon/eyeOutline";
import { Button, Dropdown, DropdownItem, Modal } from "Core/components";

type ForumThreadCommentsItemMenuProps = {
  edit: boolean;
  onEdit: () => void;
  refresh: () => void;
  reply: ForumTopicReply;
};

const ForumThreadCommentsItemMenu: React.FC<ForumThreadCommentsItemMenuProps> = ({ edit, reply, refresh, onEdit }) => {
  const auth = useAuth();
  const deleteReply = useRequest(forumApi.deleteThreadReply);

  const onProfile = () => {
    window.open(`${process.env.REACT_APP_URL}${reply.username}`, "_blank");
  };

  const onDelete = () => {
    Modal.confirm({
      title: "Delete Comment",
      subtitle: "Are you sure you want to permanently remove your comment? You cannot undo this action.",
    }).then(() => {
      deleteReply.request(reply._id, reply.topicID, reply.threadID).then(() => {
        refresh();
      });
    });
  };

  return !edit ? (
    <Dropdown className={styles.forumThreadCommentsItemMenu}>
      <Button className={styles.forumThreadCommentsItemMenuButton} variant="light">
        <MoreIcon />
      </Button>
      {auth.user?.username === reply.username ? (
        <>
          <DropdownItem onClick={onEdit}>
            <EditIcon />
            Edit
          </DropdownItem>
          <DropdownItem onClick={onDelete} className={styles.forumThreadCommentsItemMenuDanger}>
            <DeleteIcon />
            Delete
          </DropdownItem>
        </>
      ) : (
        <DropdownItem onClick={onProfile}>
          <EyeOutlineIcon />
          Show Profile
        </DropdownItem>
      )}
    </Dropdown>
  ) : (
    <></>
  );
};

export default ForumThreadCommentsItemMenu;
