import api from "Core/api";
import { ForumThread } from "Forum/types/Thread";
import { Pagination } from "Core/types/Pagination";
import { ForumTopicReply } from "Forum/types/Reply";

type Response = {
  count: number;
  thread: ForumThread;
  items: ForumTopicReply[];
};

type ApiResponse = {
  count: number;
  thread: ForumThread;
  items: ForumTopicReply[];
};

type Props = (topicId: string, threadId: string, pagination: Pagination) => Promise<Response>;

const getThread: Props = async (topicId, threadId, pagination) => {
  const response = await api.post<ApiResponse>(
    api.defaults.headers.common["Authorization"]
      ? `${process.env.REACT_APP_HUB_API_URL}/topics/${topicId}/thread/${threadId}`
      : `${process.env.REACT_APP_HUB_API_URL}public/topics/${topicId}/thread/${threadId}`,
    pagination
  );
  return response.data;
};

export default getThread;
