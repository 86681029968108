import { IconComponent } from "Core/types/Icon";

const SnapchatIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.9844 19C10.1393 19 9.30704 18.6944 8.5388 18.132C7.20717 17.1663 5.79871 16.7506 4.01895 17.313C4.03815 15.3386 1.9895 16.0966 0.984375 15.1492C2.98181 14.5685 4.35826 13.4438 5.25455 11.7934C5.90755 10.577 5.66428 10.033 4.32625 9.51347C3.87171 9.33621 3.44277 9.11616 3.00103 8.91445C3.03944 8.77997 3.07785 8.64551 3.12266 8.51714C3.5708 8.57826 4.03175 8.62717 4.47989 8.70052C5.48502 8.87778 5.81792 8.60883 5.7539 7.66751C5.71549 7.13572 5.67708 6.60395 5.65146 6.07216C5.55544 4.06727 6.31728 2.44747 8.21869 1.5306C9.38302 0.890313 12.472 0.758992 13.7501 1.5306C15.6515 2.44748 16.4133 4.06727 16.3173 6.07216C16.2917 6.60395 16.2533 7.13572 16.2148 7.66751C16.1508 8.60883 16.4837 8.87778 17.4889 8.70052C17.937 8.62717 18.3979 8.57826 18.8461 8.51714C18.8909 8.64551 18.9293 8.77997 18.9677 8.91445C18.526 9.11616 18.097 9.33621 17.6425 9.51347C16.3045 10.033 16.0612 10.577 16.7142 11.7934C17.6105 13.4438 18.9869 14.5685 20.9844 15.1492C19.9792 16.0966 17.9306 15.3386 17.9498 17.313C16.17 16.7506 14.7616 17.1663 13.43 18.132C12.6617 18.6944 11.8294 19 10.9844 19Z"
      stroke="#303030"
      fill="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SnapchatIcon;
