import Img from "Core/components/Img";
import LogoUrl from "Core/assets/logo.svg";
import IconUrl from "Core/assets/icon.svg";
import LogoLightUrl from "Core/assets/logoLight.svg";

type LogoProps = {
  icon?: boolean;
  light?: boolean;
  className?: string;
  onClick?: () => void;
};

const Logo: React.FC<LogoProps> = ({ onClick, icon, light, className }) => {
  return (
    <Img
      h={32}
      w={175}
      alt="RamblyHUB"
      onClick={onClick}
      className={className}
      src={icon ? IconUrl : light ? LogoLightUrl : LogoUrl}
    />
  );
};

export default Logo;
