import { IconComponent } from "Core/types/Icon";

const PlusIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M16.5,10.5 L10.5,10.5 L10.5,16.5 C10.5,17.3284271 9.82842712,18 9,18 C8.17157288,18 7.5,17.3284271 7.5,16.5 L7.5,10.5 L1.5,10.5 C0.671572875,10.5 1.77635684e-15,9.82842712 1.77635684e-15,9 C1.77635684e-15,8.17157288 0.671572875,7.5 1.5,7.5 L7.5,7.5 L7.5,1.5 C7.5,0.671572875 8.17157288,1.77635684e-15 9,1.77635684e-15 C9.82842712,1.77635684e-15 10.5,0.671572875 10.5,1.5 L10.5,7.5 L16.5,7.5 C17.3284271,7.5 18,8.17157288 18,9 C18,9.82842712 17.3284271,10.5 16.5,10.5 Z"
        id="ic_plus_dark"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default PlusIcon;
