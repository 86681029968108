import ItemViewContainer from "./Item/Container";
import { ContentItem } from "Content/types/ContentEditor/Item";

type PostContentProps = {
  value: ContentItem[];
};

const PostContent: React.FC<PostContentProps> = ({ value }) => {
  return (
    <div>
      {value.map((x) => (
        <ItemViewContainer key={x.contentItemID} value={x} />
      ))}
    </div>
  );
};

export default PostContent;
