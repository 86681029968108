import FaqListCard from "./Card";
import { useState } from "react";
import FaqListFilter from "./Filter";
import styles from "./styles.module.scss";
import { PageHeader } from "Core/components";
import { faqQuestions } from "Faq/constants";
import { useSearchParams } from "react-router-dom";

const FaqListPage: React.FC = () => {
  const [params] = useSearchParams();
  const [filter, setFilter] = useState(params.get("t") || "all");
  const [active, setActive] = useState<number>(Number(params.get("a") || "0"));
  const filteredQuestions = faqQuestions.filter((x) => filter === "all" || x.type === filter);

  const updateParams = (tab: string, index: number) => {
    const url = `${window.location.pathname}?t=${tab}&a=${index}`;
    window.history.replaceState({ path: url }, "", url);
  };

  const onClick = (e: number) => () => {
    setActive(e);
    updateParams(filter, e);
  };

  const onFilter = (e: string) => {
    setActive(0);
    setFilter(e);
    updateParams(e, 0);
  };

  return (
    <div className={styles.faqListPage}>
      <PageHeader
        back
        title="Any Questions? We got you."
        subtitle="Find quick and easy answers on commonly asked questions."
      />
      <div className={styles.faqListPageContainer}>
        <FaqListFilter value={filter} onChange={onFilter} />
        <div className={styles.faqListPageContent}>
          {filteredQuestions.map((x, i) => (
            <FaqListCard
              key={x.key}
              type={x.type}
              answer={x.answer}
              onClick={onClick(i)}
              question={x.question}
              active={i === active}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqListPage;
