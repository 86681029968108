import { cx } from "Core/utils";
import styles from "./styles.module.scss";
import { CSSProperties } from "react";

type ImageProps = {
  src?: string;
  alt?: string;
  className?: string;
  w?: number | string;
  h?: number | string;
  onClick?: () => void;
  style?: CSSProperties;
  fit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  pos?: "bottom" | "center" | "inherit" | "initial" | "left" | "right" | "top";
};

const Image: React.FC<ImageProps> = ({ src, className, w, h, fit, pos, style, onClick, alt = "" }) => {
  return (
    <img
      src={src}
      alt={alt}
      width={w}
      height={h}
      onClick={onClick}
      className={cx(className, styles.img)}
      style={{ aspectRatio: `${w} / ${h}`, objectFit: fit, objectPosition: pos, ...style }}
    />
  );
};

export default Image;
