import Video from "./Video";
import { useState } from "react";
import { DraftjsVideoState } from "Core/types/Draftjs";
import createVideoPlugin from "@draft-js-plugins/video";

type Props = (enabled?: boolean) => DraftjsVideoState | undefined;

const useVideo: Props = (enabled) => {
  const [videoState] = useState<DraftjsVideoState | undefined>(
    enabled
      ? () => {
          const videoPlugin = createVideoPlugin();

          return {
            plugin: videoPlugin,
            Component: (props) => <Video {...props} />,
          };
        }
      : undefined
  );

  return videoState;
};

export default useVideo;
