import styles from "./styles.module.scss";
import Avatar from "Core/components/Avatar";
import getPlainText from "Core/utils/draftjs/getPlainText";
import ArrowRightIcon from "Core/components/Icon/arrowRight";
import CategoriesIcon from "Core/components/Icon/categories";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import { Notification } from "Notification/types/Notification";
import { NewCategoryContentNotificationData } from "Notification/types/NotificationData";

type Props = {
  data: Notification<NewCategoryContentNotificationData>;
};

const NotificationButtonItemNewCategoryContent: React.FC<Props> = ({ data }) => {
  const onClick = () => {
    window.open(`/${data.data.username}/${data.data.slug}`, "_self");
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.icon}>
        <CategoriesIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          Have a look at some engaging content posted in the <strong>{data.data.category}</strong> category.
        </div>
        <div className={styles.innerContent}>
          <div className={styles.image}>
            <img src={data.data.listingImageUrl} alt="" />
          </div>
          <div className={styles.text}>
            <div className={styles.user}>
              <Avatar value={data.data.avatar?.url} className={styles.avatar} />
              {data.data.username} <ArrowRightIcon />
            </div>
            <div className={styles.textTitle}>{getPlainText(data.data.title.value)}</div>
          </div>
        </div>
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemNewCategoryContent;
