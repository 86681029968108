import { IconComponent } from "Core/types/Icon";

const FacebookIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.625 0H7.25C4.5 0 2.75 1.647 2.75 4.353V6.353H0.375C0.125 6.353 0 6.588 0 6.706V9.647C0 9.882 0.125 10 0.375 10H2.75V19.647C2.75 19.882 2.875 20 3.125 20H6.25C6.5 20 6.625 19.882 6.625 19.647V10H9.5C9.75 10 9.875 9.882 9.875 9.647V6.706C9.875 6.588 9.875 6.471 9.75 6.471C9.625 6.471 9.625 6.353 9.5 6.353H6.75V4.706C6.75 3.882 7 3.529 8.125 3.529H9.75C9.875 3.529 10 3.294 10 3.176V0.353C10 0.118 9.875 0 9.625 0Z"
      fill="currentColor"
    />
  </svg>
);

export default FacebookIcon;
