import { IconOption, Option } from "Core/types/Option";
import EmojiFlagsIcon from "Core/components/Icon/emojiFlags";
import EmojiObjectsIcon from "Core/components/Icon/emojiObjects";
import EmojiSymbolsIcon from "Core/components/Icon/emojiSymbols";
import EmojiActivityIcon from "Core/components/Icon/emojiActivity";
import EmojiFoodAndDrinkIcon from "Core/components/Icon/emojiFoodAndDrink";
import EmojiSmileysAndEmotion from "Core/components/Icon/emojiSmileysAndEmotion";
import EmojiTravelAndPlacesIcon from "Core/components/Icon/emojiTravelAndPlaces";
import EmojiAnimalsAndNatureIcon from "Core/components/Icon/emojiAnimalsAndNature";

export const categories: IconOption[] = [
  { label: "Smileys & Emotion", value: "smileysAndEmotion", icon: <EmojiSmileysAndEmotion /> },
  { label: "Animals & Nature", value: "animalsAndNature", icon: <EmojiAnimalsAndNatureIcon /> },
  { label: "Food & Drink", value: "foodAndDrink", icon: <EmojiFoodAndDrinkIcon /> },
  { label: "Activity", value: "activity", icon: <EmojiActivityIcon /> },
  { label: "Travel & Places", value: "travelAndPlaces", icon: <EmojiTravelAndPlacesIcon /> },
  { label: "Objects", value: "objects", icon: <EmojiObjectsIcon /> },
  { label: "Symbols", value: "symbols", icon: <EmojiSymbolsIcon /> },
  { label: "Flags", value: "flags", icon: <EmojiFlagsIcon /> },
];

export const items: Record<string, Option[]> = {
  "Smileys & Emotion": [
    { label: "Grinning Face Flag", value: "😀" },
    { label: "Grinning Face With Big Eyes Flag", value: "😃" },
    { label: "Grinning Face With Smiling Eyes Flag", value: "😄" },
    { label: "Beaming Face With Smiling Eyes Flag", value: "😁" },
    { label: "Grinning Squinting Face Flag", value: "😆" },
    { label: "Grinning Face With Sweat Flag", value: "😅" },
    { label: "Rolling On The Floor Laughing Flag", value: "🤣" },
    { label: "Face With Tears Of Joy Flag", value: "😂" },
    { label: "Slightly Smiling Face Flag", value: "🙂" },
    { label: "Upside-down Face Flag", value: "🙃" },
    { label: "Melting Face Flag", value: "🫠" },
    { label: "Winking Face Flag", value: "😉" },
    { label: "Smiling Face With Smiling Eyes Flag", value: "😊" },
    { label: "Smiling Face With Halo Flag", value: "😇" },
    { label: "Smiling Face With Hearts Flag", value: "🥰" },
    { label: "Smiling Face With Heart-eyes Flag", value: "😍" },
    { label: "Star-struck Flag", value: "🤩" },
    { label: "Face Blowing A Kiss Flag", value: "😘" },
    { label: "Kissing Face Flag", value: "😗" },
    { label: "Kissing Face With Closed Eyes Flag", value: "😚" },
    { label: "Kissing Face With Smiling Eyes Flag", value: "😙" },
    { label: "Smiling Face With Tear Flag", value: "🥲" },
    { label: "Face Savoring Food Flag", value: "😋" },
    { label: "Face With Tongue Flag", value: "😛" },
    { label: "Winking Face With Tongue Flag", value: "😜" },
    { label: "Zany Face Flag", value: "🤪" },
    { label: "Squinting Face With Tongue Flag", value: "😝" },
    { label: "Money-mouth Face Flag", value: "🤑" },
    { label: "Smiling Face With Open Hands Flag", value: "🤗" },
    { label: "Face With Hand Over Mouth Flag", value: "🤭" },
    { label: "Face With Open Eyes And Hand Over Mouth Flag", value: "🫢" },
    { label: "Face With Peeking Eye Flag", value: "🫣" },
    { label: "Shushing Face Flag", value: "🤫" },
    { label: "Thinking Face Flag", value: "🤔" },
    { label: "Saluting Face Flag", value: "🫡" },
    { label: "Zipper-mouth Face Flag", value: "🤐" },
    { label: "Face With Raised Eyebrow Flag", value: "🤨" },
    { label: "Neutral Face Flag", value: "😐" },
    { label: "Expressionless Face Flag", value: "😑" },
    { label: "Face Without Mouth Flag", value: "😶" },
    { label: "Dotted Line Face Flag", value: "🫥" },
    { label: "Face In Clouds Flag", value: "😶‍🌫️" },
    { label: "Smirking Face Flag", value: "😏" },
    { label: "Unamused Face Flag", value: "😒" },
    { label: "Face With Rolling Eyes Flag", value: "🙄" },
    { label: "Grimacing Face Flag", value: "😬" },
    { label: "Face Exhaling Flag", value: "😮‍💨" },
    { label: "Lying Face Flag", value: "🤥" },
    { label: "Relieved Face Flag", value: "😌" },
    { label: "Pensive Face Flag", value: "😔" },
    { label: "Sleepy Face Flag", value: "😪" },
    { label: "Drooling Face Flag", value: "🤤" },
    { label: "Sleeping Face Flag", value: "😴" },
    { label: "Face With Medical Mask Flag", value: "😷" },
    { label: "Face With Thermometer Flag", value: "🤒" },
    { label: "Face With Head-bandage Flag", value: "🤕" },
    { label: "Nauseated Face Flag", value: "🤢" },
    { label: "Face Vomiting Flag", value: "🤮" },
    { label: "Sneezing Face Flag", value: "🤧" },
    { label: "Hot Face Flag", value: "🥵" },
    { label: "Cold Face Flag", value: "🥶" },
    { label: "Woozy Face Flag", value: "🥴" },
    { label: "Face With Crossed-out Eyes Flag", value: "😵" },
    { label: "Face With Spiral Eyes Flag", value: "😵‍💫" },
    { label: "Exploding Head Flag", value: "🤯" },
    { label: "Cowboy Hat Face Flag", value: "🤠" },
    { label: "Partying Face Flag", value: "🥳" },
    { label: "Disguised Face Flag", value: "🥸" },
    { label: "Smiling Face With Sunglasses Flag", value: "😎" },
    { label: "Nerd Face Flag", value: "🤓" },
    { label: "Face With Monocle Flag", value: "🧐" },
    { label: "Confused Face Flag", value: "😕" },
    { label: "Face With Diagonal Mouth Flag", value: "🫤" },
    { label: "Worried Face Flag", value: "😟" },
    { label: "Slightly Frowning Face Flag", value: "🙁" },
    { label: "Frowning Face Flag", value: "☹️" },
    { label: "Face With Open Mouth Flag", value: "😮" },
    { label: "Hushed Face Flag", value: "😯" },
    { label: "Astonished Face Flag", value: "😲" },
    { label: "Flushed Face Flag", value: "😳" },
    { label: "Pleading Face Flag", value: "🥺" },
    { label: "Face Holding Back Tears Flag", value: "🥹" },
    { label: "Frowning Face With Open Mouth Flag", value: "😦" },
    { label: "Anguished Face Flag", value: "😧" },
    { label: "Fearful Face Flag", value: "😨" },
    { label: "Anxious Face With Sweat Flag", value: "😰" },
    { label: "Sad But Relieved Face Flag", value: "😥" },
    { label: "Crying Face Flag", value: "😢" },
    { label: "Loudly Crying Face Flag", value: "😭" },
    { label: "Face Screaming In Fear Flag", value: "😱" },
    { label: "Confounded Face Flag", value: "😖" },
    { label: "Persevering Face Flag", value: "😣" },
    { label: "Disappointed Face Flag", value: "😞" },
    { label: "Downcast Face With Sweat Flag", value: "😓" },
    { label: "Weary Face Flag", value: "😩" },
    { label: "Tired Face Flag", value: "😫" },
    { label: "Yawning Face Flag", value: "🥱" },
    { label: "Face With Steam From Nose Flag", value: "😤" },
    { label: "Pouting Face Flag", value: "😡" },
    { label: "Angry Face Flag", value: "😠" },
    { label: "Face With Symbols On Mouth Flag", value: "🤬" },
    { label: "Smiling Face With Horns Flag", value: "😈" },
    { label: "Angry Face With Horns Flag", value: "👿" },
    { label: "Waving Hand Flag", value: "👋" },
    { label: "Raised Back Of Hand Flag", value: "🤚" },
    { label: "Hand With Fingers Splayed Flag", value: "🖐️" },
    { label: "Raised Hand Flag", value: "✋" },
    { label: "Vulcan Salute Flag", value: "🖖" },
    { label: "Rightwards Hand Flag", value: "🫱" },
    { label: "Leftwards Hand Flag", value: "🫲" },
    { label: "Palm Down Hand Flag", value: "🫳" },
    { label: "Palm Up Hand Flag", value: "🫴" },
    { label: "OK Hand Flag", value: "👌" },
    { label: "Pinched Fingers Flag", value: "🤌" },
    { label: "Pinching Hand Flag", value: "🤏" },
    { label: "Victory Hand Flag", value: "✌️" },
    { label: "Crossed Fingers Flag", value: "🤞" },
    { label: "Hand With Index Finger And Thumb Crossed Flag", value: "🫰" },
    { label: "Love-you Gesture Flag", value: "🤟" },
    { label: "Sign Of The Horns Flag", value: "🤘" },
    { label: "Call Me Hand Flag", value: "🤙" },
    { label: "Backhand Index Pointing Left Flag", value: "👈" },
    { label: "Backhand Index Pointing Right Flag", value: "👉" },
    { label: "Backhand Index Pointing Up Flag", value: "👆" },
    { label: "Middle Finger Flag", value: "🖕" },
    { label: "Backhand Index Pointing Down Flag", value: "👇" },
    { label: "Index Pointing Up Flag", value: "☝️" },
    { label: "Index Pointing At The Viewer Flag", value: "🫵" },
    { label: "Thumbs Up Flag", value: "👍" },
    { label: "Thumbs Down Flag", value: "👎" },
    { label: "Raised Fist Flag", value: "✊" },
    { label: "Oncoming Fist Flag", value: "👊" },
    { label: "Left-facing Fist Flag", value: "🤛" },
    { label: "Right-facing Fist Flag", value: "🤜" },
    { label: "Clapping Hands Flag", value: "👏" },
    { label: "Raising Hands Flag", value: "🙌" },
    { label: "Heart Hands Flag", value: "🫶" },
    { label: "Open Hands Flag", value: "👐" },
    { label: "Palms Up Together Flag", value: "🤲" },
    { label: "Handshake Flag", value: "🤝" },
    { label: "Folded Hands Flag", value: "🙏" },
    { label: "Skull Flag", value: "💀" },
    { label: "Skull And Crossbones Flag", value: "☠️" },
    { label: "Pile Of Poo Flag", value: "💩" },
    { label: "Clown Face Flag", value: "🤡" },
    { label: "Ogre Flag", value: "👹" },
    { label: "Goblin Flag", value: "👺" },
    { label: "Ghost Flag", value: "👻" },
    { label: "Alien Flag", value: "👽" },
    { label: "Alien Monster Flag", value: "👾" },
    { label: "Robot Flag", value: "🤖" },
    { label: "Grinning Cat Flag", value: "😺" },
    { label: "Grinning Cat With Smiling Eyes Flag", value: "😸" },
    { label: "Cat With Tears Of Joy Flag", value: "😹" },
    { label: "Smiling Cat With Heart-eyes Flag", value: "😻" },
    { label: "Cat With Wry Smile Flag", value: "😼" },
    { label: "Kissing Cat Flag", value: "😽" },
    { label: "Weary Cat Flag", value: "🙀" },
    { label: "Crying Cat Flag", value: "😿" },
    { label: "Pouting Cat Flag", value: "😾" },
    { label: "See-no-evil Monkey Flag", value: "🙈" },
    { label: "Hear-no-evil Monkey Flag", value: "🙉" },
    { label: "Speak-no-evil Monkey Flag", value: "🙊" },
    { label: "Kiss Mark Flag", value: "💋" },
    { label: "Love Letter Flag", value: "💌" },
    { label: "Heart With Arrow Flag", value: "💘" },
    { label: "Heart With Ribbon Flag", value: "💝" },
    { label: "Sparkling Heart Flag", value: "💖" },
    { label: "Growing Heart Flag", value: "💗" },
    { label: "Beating Heart Flag", value: "💓" },
    { label: "Revolving Hearts Flag", value: "💞" },
    { label: "Two Hearts Flag", value: "💕" },
    { label: "Heart Decoration Flag", value: "💟" },
    { label: "Heart Exclamation Flag", value: "❣️" },
    { label: "Broken Heart Flag", value: "💔" },
    { label: "Heart On Fire Flag", value: "❤️‍🔥" },
    { label: "Mending Heart Flag", value: "❤️‍🩹" },
    { label: "Red Heart Flag", value: "❤️" },
    { label: "Orange Heart Flag", value: "🧡" },
    { label: "Yellow Heart Flag", value: "💛" },
    { label: "Green Heart Flag", value: "💚" },
    { label: "Blue Heart Flag", value: "💙" },
    { label: "Purple Heart Flag", value: "💜" },
    { label: "Brown Heart Flag", value: "🤎" },
    { label: "Black Heart Flag", value: "🖤" },
    { label: "White Heart Flag", value: "🤍" },
    { label: "Hundred Points Flag", value: "💯" },
    { label: "Anger Symbol Flag", value: "💢" },
    { label: "Collision Flag", value: "💥" },
    { label: "Dizzy Flag", value: "💫" },
    { label: "Sweat Droplets Flag", value: "💦" },
    { label: "Dashing Away Flag", value: "💨" },
    { label: "Hole Flag", value: "🕳️" },
    { label: "Bomb Flag", value: "💣" },
    { label: "Speech Balloon Flag", value: "💬" },
    { label: "Eye In Speech Bubble Flag", value: "👁️‍🗨️" },
    { label: "Left Speech Bubble Flag", value: "🗨️" },
    { label: "Right Anger Bubble Flag", value: "🗯️" },
    { label: "Thought Balloon Flag", value: "💭" },
    { label: "Zzz Flag", value: "💤" },
    { label: "Writing Hand Flag", value: "✍️" },
    { label: "Nail Polish Flag", value: "💅" },
    { label: "Selfie Flag", value: "🤳" },
    { label: "Flexed Biceps Flag", value: "💪" },
    { label: "Mechanical Arm Flag", value: "🦾" },
    { label: "Mechanical Leg Flag", value: "🦿" },
    { label: "Leg Flag", value: "🦵" },
    { label: "Foot Flag", value: "🦶" },
    { label: "Ear Flag", value: "👂" },
    { label: "Ear With Hearing Aid Flag", value: "🦻" },
    { label: "Nose Flag", value: "👃" },
    { label: "Brain Flag", value: "🧠" },
    { label: "Anatomical Heart Flag", value: "🫀" },
    { label: "Lungs Flag", value: "🫁" },
    { label: "Tooth Flag", value: "🦷" },
    { label: "Bone Flag", value: "🦴" },
    { label: "Eyes Flag", value: "👀" },
    { label: "Eye Flag", value: "👁️" },
    { label: "Tongue Flag", value: "👅" },
    { label: "Mouth Flag", value: "👄" },
    { label: "Biting Lip Flag", value: "🫦" },
    { label: "Baby Flag", value: "👶" },
    { label: "Child Flag", value: "🧒" },
    { label: "Boy Flag", value: "👦" },
    { label: "Girl Flag", value: "👧" },
    { label: "Person Flag", value: "🧑" },
    { label: "Person: Blond Hair Flag", value: "👱" },
    { label: "Man Flag", value: "👨" },
    { label: "Person: Beard Flag", value: "🧔" },
    { label: "Man: Beard Flag", value: "🧔‍♂️" },
    { label: "Woman: Beard Flag", value: "🧔‍♀️" },
    { label: "Man: Red Hair Flag", value: "👨‍🦰" },
    { label: "Man: Curly Hair Flag", value: "👨‍🦱" },
    { label: "Man: White Hair Flag", value: "👨‍🦳" },
    { label: "Man: Bald Flag", value: "👨‍🦲" },
    { label: "Woman Flag", value: "👩" },
    { label: "Woman: Red Hair Flag", value: "👩‍🦰" },
    { label: "Person: Red Hair Flag", value: "🧑‍🦰" },
    { label: "Woman: Curly Hair Flag", value: "👩‍🦱" },
    { label: "Person: Curly Hair Flag", value: "🧑‍🦱" },
    { label: "Woman: White Hair Flag", value: "👩‍🦳" },
    { label: "Person: White Hair Flag", value: "🧑‍🦳" },
    { label: "Woman: Bald Flag", value: "👩‍🦲" },
    { label: "Person: Bald Flag", value: "🧑‍🦲" },
    { label: "Woman: Blond Hair Flag", value: "👱‍♀️" },
    { label: "Man: Blond Hair Flag", value: "👱‍♂️" },
    { label: "Older Person Flag", value: "🧓" },
    { label: "Old Man Flag", value: "👴" },
    { label: "Old Woman Flag", value: "👵" },
    { label: "Person Frowning Flag", value: "🙍" },
    { label: "Man Frowning Flag", value: "🙍‍♂️" },
    { label: "Woman Frowning Flag", value: "🙍‍♀️" },
    { label: "Person Pouting Flag", value: "🙎" },
    { label: "Man Pouting Flag", value: "🙎‍♂️" },
    { label: "Woman Pouting Flag", value: "🙎‍♀️" },
    { label: "Person Gesturing NO Flag", value: "🙅" },
    { label: "Man Gesturing NO Flag", value: "🙅‍♂️" },
    { label: "Woman Gesturing NO Flag", value: "🙅‍♀️" },
    { label: "Person Gesturing OK Flag", value: "🙆" },
    { label: "Man Gesturing OK Flag", value: "🙆‍♂️" },
    { label: "Woman Gesturing OK Flag", value: "🙆‍♀️" },
    { label: "Person Tipping Hand Flag", value: "💁" },
    { label: "Man Tipping Hand Flag", value: "💁‍♂️" },
    { label: "Woman Tipping Hand Flag", value: "💁‍♀️" },
    { label: "Person Raising Hand Flag", value: "🙋" },
    { label: "Man Raising Hand Flag", value: "🙋‍♂️" },
    { label: "Woman Raising Hand Flag", value: "🙋‍♀️" },
    { label: "Deaf Person Flag", value: "🧏" },
    { label: "Deaf Man Flag", value: "🧏‍♂️" },
    { label: "Deaf Woman Flag", value: "🧏‍♀️" },
    { label: "Person Bowing Flag", value: "🙇" },
    { label: "Man Bowing Flag", value: "🙇‍♂️" },
    { label: "Woman Bowing Flag", value: "🙇‍♀️" },
    { label: "Person Facepalming Flag", value: "🤦" },
    { label: "Man Facepalming Flag", value: "🤦‍♂️" },
    { label: "Woman Facepalming Flag", value: "🤦‍♀️" },
    { label: "Person Shrugging Flag", value: "🤷" },
    { label: "Man Shrugging Flag", value: "🤷‍♂️" },
    { label: "Woman Shrugging Flag", value: "🤷‍♀️" },
    { label: "Health Worker Flag", value: "🧑‍⚕️" },
    { label: "Man Health Worker Flag", value: "👨‍⚕️" },
    { label: "Woman Health Worker Flag", value: "👩‍⚕️" },
    { label: "Student Flag", value: "🧑‍🎓" },
    { label: "Man Student Flag", value: "👨‍🎓" },
    { label: "Woman Student Flag", value: "👩‍🎓" },
    { label: "Teacher Flag", value: "🧑‍🏫" },
    { label: "Man Teacher Flag", value: "👨‍🏫" },
    { label: "Woman Teacher Flag", value: "👩‍🏫" },
    { label: "Judge Flag", value: "🧑‍⚖️" },
    { label: "Man Judge Flag", value: "👨‍⚖️" },
    { label: "Woman Judge Flag", value: "👩‍⚖️" },
    { label: "Farmer Flag", value: "🧑‍🌾" },
    { label: "Man Farmer Flag", value: "👨‍🌾" },
    { label: "Woman Farmer Flag", value: "👩‍🌾" },
    { label: "Cook Flag", value: "🧑‍🍳" },
    { label: "Man Cook Flag", value: "👨‍🍳" },
    { label: "Woman Cook Flag", value: "👩‍🍳" },
    { label: "Mechanic Flag", value: "🧑‍🔧" },
    { label: "Man Mechanic Flag", value: "👨‍🔧" },
    { label: "Woman Mechanic Flag", value: "👩‍🔧" },
    { label: "Factory Worker Flag", value: "🧑‍🏭" },
    { label: "Man Factory Worker Flag", value: "👨‍🏭" },
    { label: "Woman Factory Worker Flag", value: "👩‍🏭" },
    { label: "Office Worker Flag", value: "🧑‍💼" },
    { label: "Man Office Worker Flag", value: "👨‍💼" },
    { label: "Woman Office Worker Flag", value: "👩‍💼" },
    { label: "Scientist Flag", value: "🧑‍🔬" },
    { label: "Man Scientist Flag", value: "👨‍🔬" },
    { label: "Woman Scientist Flag", value: "👩‍🔬" },
    { label: "Technologist Flag", value: "🧑‍💻" },
    { label: "Man Technologist Flag", value: "👨‍💻" },
    { label: "Woman Technologist Flag", value: "👩‍💻" },
    { label: "Singer Flag", value: "🧑‍🎤" },
    { label: "Man Singer Flag", value: "👨‍🎤" },
    { label: "Woman Singer Flag", value: "👩‍🎤" },
    { label: "Artist Flag", value: "🧑‍🎨" },
    { label: "Man Artist Flag", value: "👨‍🎨" },
    { label: "Woman Artist Flag", value: "👩‍🎨" },
    { label: "Pilot Flag", value: "🧑‍✈️" },
    { label: "Man Pilot Flag", value: "👨‍✈️" },
    { label: "Woman Pilot Flag", value: "👩‍✈️" },
    { label: "Astronaut Flag", value: "🧑‍🚀" },
    { label: "Man Astronaut Flag", value: "👨‍🚀" },
    { label: "Woman Astronaut Flag", value: "👩‍🚀" },
    { label: "Firefighter Flag", value: "🧑‍🚒" },
    { label: "Man Firefighter Flag", value: "👨‍🚒" },
    { label: "Woman Firefighter Flag", value: "👩‍🚒" },
    { label: "Police Officer Flag", value: "👮" },
    { label: "Man Police Officer Flag", value: "👮‍♂️" },
    { label: "Woman Police Officer Flag", value: "👮‍♀️" },
    { label: "Detective Flag", value: "🕵️" },
    { label: "Man Detective Flag", value: "🕵️‍♂️" },
    { label: "Woman Detective Flag", value: "🕵️‍♀️" },
    { label: "Guard Flag", value: "💂" },
    { label: "Man Guard Flag", value: "💂‍♂️" },
    { label: "Woman Guard Flag", value: "💂‍♀️" },
    { label: "Ninja Flag", value: "🥷" },
    { label: "Construction Worker Flag", value: "👷" },
    { label: "Man Construction Worker Flag", value: "👷‍♂️" },
    { label: "Woman Construction Worker Flag", value: "👷‍♀️" },
    { label: "Person With Crown Flag", value: "🫅" },
    { label: "Prince Flag", value: "🤴" },
    { label: "Princess Flag", value: "👸" },
    { label: "Person Wearing Turban Flag", value: "👳" },
    { label: "Man Wearing Turban Flag", value: "👳‍♂️" },
    { label: "Woman Wearing Turban Flag", value: "👳‍♀️" },
    { label: "Person With Skullcap Flag", value: "👲" },
    { label: "Woman With Headscarf Flag", value: "🧕" },
    { label: "Person In Tuxedo Flag", value: "🤵" },
    { label: "Man In Tuxedo Flag", value: "🤵‍♂️" },
    { label: "Woman In Tuxedo Flag", value: "🤵‍♀️" },
    { label: "Person With Veil Flag", value: "👰" },
    { label: "Man With Veil Flag", value: "👰‍♂️" },
    { label: "Woman With Veil Flag", value: "👰‍♀️" },
    { label: "Pregnant Woman Flag", value: "🤰" },
    { label: "Pregnant Man Flag", value: "🫃" },
    { label: "Pregnant Person Flag", value: "🫄" },
    { label: "Breast-feeding Flag", value: "🤱" },
    { label: "Woman Feeding Baby Flag", value: "👩‍🍼" },
    { label: "Man Feeding Baby Flag", value: "👨‍🍼" },
    { label: "Person Feeding Baby Flag", value: "🧑‍🍼" },
    { label: "Baby Angel Flag", value: "👼" },
    { label: "Santa Claus Flag", value: "🎅" },
    { label: "Mrs. Claus Flag", value: "🤶" },
    { label: "Mx Claus Flag", value: "🧑‍🎄" },
    { label: "Superhero Flag", value: "🦸" },
    { label: "Man Superhero Flag", value: "🦸‍♂️" },
    { label: "Woman Superhero Flag", value: "🦸‍♀️" },
    { label: "Supervillain Flag", value: "🦹" },
    { label: "Man Supervillain Flag", value: "🦹‍♂️" },
    { label: "Woman Supervillain Flag", value: "🦹‍♀️" },
    { label: "Mage Flag", value: "🧙" },
    { label: "Man Mage Flag", value: "🧙‍♂️" },
    { label: "Woman Mage Flag", value: "🧙‍♀️" },
    { label: "Fairy Flag", value: "🧚" },
    { label: "Man Fairy Flag", value: "🧚‍♂️" },
    { label: "Woman Fairy Flag", value: "🧚‍♀️" },
    { label: "Vampire Flag", value: "🧛" },
    { label: "Man Vampire Flag", value: "🧛‍♂️" },
    { label: "Woman Vampire Flag", value: "🧛‍♀️" },
    { label: "Merperson Flag", value: "🧜" },
    { label: "Merman Flag", value: "🧜‍♂️" },
    { label: "Mermaid Flag", value: "🧜‍♀️" },
    { label: "Elf Flag", value: "🧝" },
    { label: "Man Elf Flag", value: "🧝‍♂️" },
    { label: "Woman Elf Flag", value: "🧝‍♀️" },
    { label: "Genie Flag", value: "🧞" },
    { label: "Man Genie Flag", value: "🧞‍♂️" },
    { label: "Woman Genie Flag", value: "🧞‍♀️" },
    { label: "Zombie Flag", value: "🧟" },
    { label: "Man Zombie Flag", value: "🧟‍♂️" },
    { label: "Woman Zombie Flag", value: "🧟‍♀️" },
    { label: "Troll Flag", value: "🧌" },
    { label: "Person Getting Massage Flag", value: "💆" },
    { label: "Man Getting Massage Flag", value: "💆‍♂️" },
    { label: "Woman Getting Massage Flag", value: "💆‍♀️" },
    { label: "Person Getting Haircut Flag", value: "💇" },
    { label: "Man Getting Haircut Flag", value: "💇‍♂️" },
    { label: "Woman Getting Haircut Flag", value: "💇‍♀️" },
    { label: "Person Walking Flag", value: "🚶" },
    { label: "Man Walking Flag", value: "🚶‍♂️" },
    { label: "Woman Walking Flag", value: "🚶‍♀️" },
    { label: "Person Standing Flag", value: "🧍" },
    { label: "Man Standing Flag", value: "🧍‍♂️" },
    { label: "Woman Standing Flag", value: "🧍‍♀️" },
    { label: "Person Kneeling Flag", value: "🧎" },
    { label: "Man Kneeling Flag", value: "🧎‍♂️" },
    { label: "Woman Kneeling Flag", value: "🧎‍♀️" },
    { label: "Person With White Cane Flag", value: "🧑‍🦯" },
    { label: "Man With White Cane Flag", value: "👨‍🦯" },
    { label: "Woman With White Cane Flag", value: "👩‍🦯" },
    { label: "Person In Motorized Wheelchair Flag", value: "🧑‍🦼" },
    { label: "Man In Motorized Wheelchair Flag", value: "👨‍🦼" },
    { label: "Woman In Motorized Wheelchair Flag", value: "👩‍🦼" },
    { label: "Person In Manual Wheelchair Flag", value: "🧑‍🦽" },
    { label: "Man In Manual Wheelchair Flag", value: "👨‍🦽" },
    { label: "Woman In Manual Wheelchair Flag", value: "👩‍🦽" },
    { label: "Person Running Flag", value: "🏃" },
    { label: "Man Running Flag", value: "🏃‍♂️" },
    { label: "Woman Running Flag", value: "🏃‍♀️" },
    { label: "Woman Dancing Flag", value: "💃" },
    { label: "Man Dancing Flag", value: "🕺" },
    { label: "Person In Suit Levitating Flag", value: "🕴️" },
    { label: "People With Bunny Ears Flag", value: "👯" },
    { label: "Men With Bunny Ears Flag", value: "👯‍♂️" },
    { label: "Women With Bunny Ears Flag", value: "👯‍♀️" },
    { label: "Person In Steamy Room Flag", value: "🧖" },
    { label: "Man In Steamy Room Flag", value: "🧖‍♂️" },
    { label: "Woman In Steamy Room Flag", value: "🧖‍♀️" },
    { label: "Person Climbing Flag", value: "🧗" },
    { label: "Man Climbing Flag", value: "🧗‍♂️" },
    { label: "Woman Climbing Flag", value: "🧗‍♀️" },
    { label: "Person Fencing Flag", value: "🤺" },
    { label: "Horse Racing Flag", value: "🏇" },
    { label: "Skier Flag", value: "⛷️" },
    { label: "Snowboarder Flag", value: "🏂" },
    { label: "Person Golfing Flag", value: "🏌️" },
    { label: "Man Golfing Flag", value: "🏌️‍♂️" },
    { label: "Woman Golfing Flag", value: "🏌️‍♀️" },
    { label: "Person Surfing Flag", value: "🏄" },
    { label: "Man Surfing Flag", value: "🏄‍♂️" },
    { label: "Woman Surfing Flag", value: "🏄‍♀️" },
    { label: "Person Rowing Boat Flag", value: "🚣" },
    { label: "Man Rowing Boat Flag", value: "🚣‍♂️" },
    { label: "Woman Rowing Boat Flag", value: "🚣‍♀️" },
    { label: "Person Swimming Flag", value: "🏊" },
    { label: "Man Swimming Flag", value: "🏊‍♂️" },
    { label: "Woman Swimming Flag", value: "🏊‍♀️" },
    { label: "Person Bouncing Ball Flag", value: "⛹️" },
    { label: "Man Bouncing Ball Flag", value: "⛹️‍♂️" },
    { label: "Woman Bouncing Ball Flag", value: "⛹️‍♀️" },
    { label: "Person Lifting Weights Flag", value: "🏋️" },
    { label: "Man Lifting Weights Flag", value: "🏋️‍♂️" },
    { label: "Woman Lifting Weights Flag", value: "🏋️‍♀️" },
    { label: "Person Biking Flag", value: "🚴" },
    { label: "Man Biking Flag", value: "🚴‍♂️" },
    { label: "Woman Biking Flag", value: "🚴‍♀️" },
    { label: "Person Mountain Biking Flag", value: "🚵" },
    { label: "Man Mountain Biking Flag", value: "🚵‍♂️" },
    { label: "Woman Mountain Biking Flag", value: "🚵‍♀️" },
    { label: "Person Cartwheeling Flag", value: "🤸" },
    { label: "Man Cartwheeling Flag", value: "🤸‍♂️" },
    { label: "Woman Cartwheeling Flag", value: "🤸‍♀️" },
    { label: "People Wrestling Flag", value: "🤼" },
    { label: "Men Wrestling Flag", value: "🤼‍♂️" },
    { label: "Women Wrestling Flag", value: "🤼‍♀️" },
    { label: "Person Playing Water Polo Flag", value: "🤽" },
    { label: "Man Playing Water Polo Flag", value: "🤽‍♂️" },
    { label: "Woman Playing Water Polo Flag", value: "🤽‍♀️" },
    { label: "Person Playing Handball Flag", value: "🤾" },
    { label: "Man Playing Handball Flag", value: "🤾‍♂️" },
    { label: "Woman Playing Handball Flag", value: "🤾‍♀️" },
    { label: "Person Juggling Flag", value: "🤹" },
    { label: "Man Juggling Flag", value: "🤹‍♂️" },
    { label: "Woman Juggling Flag", value: "🤹‍♀️" },
    { label: "Person In Lotus Position Flag", value: "🧘" },
    { label: "Man In Lotus Position Flag", value: "🧘‍♂️" },
    { label: "Woman In Lotus Position Flag", value: "🧘‍♀️" },
    { label: "Person Taking Bath Flag", value: "🛀" },
    { label: "Person In Bed Flag", value: "🛌" },
    { label: "People Holding Hands Flag", value: "🧑‍🤝‍🧑" },
    { label: "Women Holding Hands Flag", value: "👭" },
    { label: "Woman And Man Holding Hands Flag", value: "👫" },
    { label: "Men Holding Hands Flag", value: "👬" },
    { label: "Kiss Flag", value: "💏" },
    { label: "Kiss: Woman, Man Flag", value: "👩‍❤️‍💋‍👨" },
    { label: "Kiss: Man, Man Flag", value: "👨‍❤️‍💋‍👨" },
    { label: "Kiss: Woman, Woman Flag", value: "👩‍❤️‍💋‍👩" },
    { label: "Couple With Heart Flag", value: "💑" },
    { label: "Couple With Heart: Woman, Man Flag", value: "👩‍❤️‍👨" },
    { label: "Couple With Heart: Man, Man Flag", value: "👨‍❤️‍👨" },
    { label: "Couple With Heart: Woman, Woman Flag", value: "👩‍❤️‍👩" },
    { label: "Family Flag", value: "👪" },
    { label: "Family: Man, Woman, Boy Flag", value: "👨‍👩‍👦" },
    { label: "Family: Man, Woman, Girl Flag", value: "👨‍👩‍👧" },
    { label: "Family: Man, Woman, Girl, Boy Flag", value: "👨‍👩‍👧‍👦" },
    { label: "Family: Man, Woman, Boy, Boy Flag", value: "👨‍👩‍👦‍👦" },
    { label: "Family: Man, Woman, Girl, Girl Flag", value: "👨‍👩‍👧‍👧" },
    { label: "Family: Man, Man, Boy Flag", value: "👨‍👨‍👦" },
    { label: "Family: Man, Man, Girl Flag", value: "👨‍👨‍👧" },
    { label: "Family: Man, Man, Girl, Boy Flag", value: "👨‍👨‍👧‍👦" },
    { label: "Family: Man, Man, Boy, Boy Flag", value: "👨‍👨‍👦‍👦" },
    { label: "Family: Man, Man, Girl, Girl Flag", value: "👨‍👨‍👧‍👧" },
    { label: "Family: Woman, Woman, Boy Flag", value: "👩‍👩‍👦" },
    { label: "Family: Woman, Woman, Girl Flag", value: "👩‍👩‍👧" },
    { label: "Family: Woman, Woman, Girl, Boy Flag", value: "👩‍👩‍👧‍👦" },
    { label: "Family: Woman, Woman, Boy, Boy Flag", value: "👩‍👩‍👦‍👦" },
    { label: "Family: Woman, Woman, Girl, Girl Flag", value: "👩‍👩‍👧‍👧" },
    { label: "Family: Man, Boy Flag", value: "👨‍👦" },
    { label: "Family: Man, Boy, Boy Flag", value: "👨‍👦‍👦" },
    { label: "Family: Man, Girl Flag", value: "👨‍👧" },
    { label: "Family: Man, Girl, Boy Flag", value: "👨‍👧‍👦" },
    { label: "Family: Man, Girl, Girl Flag", value: "👨‍👧‍👧" },
    { label: "Family: Woman, Boy Flag", value: "👩‍👦" },
    { label: "Family: Woman, Boy, Boy Flag", value: "👩‍👦‍👦" },
    { label: "Family: Woman, Girl Flag", value: "👩‍👧" },
    { label: "Family: Woman, Girl, Boy Flag", value: "👩‍👧‍👦" },
    { label: "Family: Woman, Girl, Girl Flag", value: "👩‍👧‍👧" },
    { label: "Speaking Head Flag", value: "🗣️" },
    { label: "Bust In Silhouette Flag", value: "👤" },
    { label: "Busts In Silhouette Flag", value: "👥" },
    { label: "People Hugging Flag", value: "🫂" },
    { label: "Footprints Flag", value: "👣" },
  ],
  "Animals & Nature": [
    { label: "Monkey Face Flag", value: "🐵" },
    { label: "Monkey Flag", value: "🐒" },
    { label: "Gorilla Flag", value: "🦍" },
    { label: "Orangutan Flag", value: "🦧" },
    { label: "Dog Face Flag", value: "🐶" },
    { label: "Dog Flag", value: "🐕" },
    { label: "Guide Dog Flag", value: "🦮" },
    { label: "Service Dog Flag", value: "🐕‍🦺" },
    { label: "Poodle Flag", value: "🐩" },
    { label: "Wolf Flag", value: "🐺" },
    { label: "Fox Flag", value: "🦊" },
    { label: "Raccoon Flag", value: "🦝" },
    { label: "Cat Face Flag", value: "🐱" },
    { label: "Cat Flag", value: "🐈" },
    { label: "Black Cat Flag", value: "🐈‍⬛" },
    { label: "Lion Flag", value: "🦁" },
    { label: "Tiger Face Flag", value: "🐯" },
    { label: "Tiger Flag", value: "🐅" },
    { label: "Leopard Flag", value: "🐆" },
    { label: "Horse Face Flag", value: "🐴" },
    { label: "Horse Flag", value: "🐎" },
    { label: "Unicorn Flag", value: "🦄" },
    { label: "Zebra Flag", value: "🦓" },
    { label: "Deer Flag", value: "🦌" },
    { label: "Bison Flag", value: "🦬" },
    { label: "Cow Face Flag", value: "🐮" },
    { label: "Ox Flag", value: "🐂" },
    { label: "Water Buffalo Flag", value: "🐃" },
    { label: "Cow Flag", value: "🐄" },
    { label: "Pig Face Flag", value: "🐷" },
    { label: "Pig Flag", value: "🐖" },
    { label: "Boar Flag", value: "🐗" },
    { label: "Pig Nose Flag", value: "🐽" },
    { label: "Ram Flag", value: "🐏" },
    { label: "Ewe Flag", value: "🐑" },
    { label: "Goat Flag", value: "🐐" },
    { label: "Camel Flag", value: "🐪" },
    { label: "Two-hump Camel Flag", value: "🐫" },
    { label: "Llama Flag", value: "🦙" },
    { label: "Giraffe Flag", value: "🦒" },
    { label: "Elephant Flag", value: "🐘" },
    { label: "Mammoth Flag", value: "🦣" },
    { label: "Rhinoceros Flag", value: "🦏" },
    { label: "Hippopotamus Flag", value: "🦛" },
    { label: "Mouse Face Flag", value: "🐭" },
    { label: "Mouse Flag", value: "🐁" },
    { label: "Rat Flag", value: "🐀" },
    { label: "Hamster Flag", value: "🐹" },
    { label: "Rabbit Face Flag", value: "🐰" },
    { label: "Rabbit Flag", value: "🐇" },
    { label: "Chipmunk Flag", value: "🐿️" },
    { label: "Beaver Flag", value: "🦫" },
    { label: "Hedgehog Flag", value: "🦔" },
    { label: "Bat Flag", value: "🦇" },
    { label: "Bear Flag", value: "🐻" },
    { label: "Polar Bear Flag", value: "🐻‍❄️" },
    { label: "Koala Flag", value: "🐨" },
    { label: "Panda Flag", value: "🐼" },
    { label: "Sloth Flag", value: "🦥" },
    { label: "Otter Flag", value: "🦦" },
    { label: "Skunk Flag", value: "🦨" },
    { label: "Kangaroo Flag", value: "🦘" },
    { label: "Badger Flag", value: "🦡" },
    { label: "Paw Prints Flag", value: "🐾" },
    { label: "Turkey Flag", value: "🦃" },
    { label: "Chicken Flag", value: "🐔" },
    { label: "Rooster Flag", value: "🐓" },
    { label: "Hatching Chick Flag", value: "🐣" },
    { label: "Baby Chick Flag", value: "🐤" },
    { label: "Front-facing Baby Chick Flag", value: "🐥" },
    { label: "Bird Flag", value: "🐦" },
    { label: "Penguin Flag", value: "🐧" },
    { label: "Dove Flag", value: "🕊️" },
    { label: "Eagle Flag", value: "🦅" },
    { label: "Duck Flag", value: "🦆" },
    { label: "Swan Flag", value: "🦢" },
    { label: "Owl Flag", value: "🦉" },
    { label: "Dodo Flag", value: "🦤" },
    { label: "Feather Flag", value: "🪶" },
    { label: "Flamingo Flag", value: "🦩" },
    { label: "Peacock Flag", value: "🦚" },
    { label: "Parrot Flag", value: "🦜" },
    { label: "Frog Flag", value: "🐸" },
    { label: "Crocodile Flag", value: "🐊" },
    { label: "Turtle Flag", value: "🐢" },
    { label: "Lizard Flag", value: "🦎" },
    { label: "Snake Flag", value: "🐍" },
    { label: "Dragon Face Flag", value: "🐲" },
    { label: "Dragon Flag", value: "🐉" },
    { label: "Sauropod Flag", value: "🦕" },
    { label: "T-Rex Flag", value: "🦖" },
    { label: "Spouting Whale Flag", value: "🐳" },
    { label: "Whale Flag", value: "🐋" },
    { label: "Dolphin Flag", value: "🐬" },
    { label: "Seal Flag", value: "🦭" },
    { label: "Fish Flag", value: "🐟" },
    { label: "Tropical Fish Flag", value: "🐠" },
    { label: "Blowfish Flag", value: "🐡" },
    { label: "Shark Flag", value: "🦈" },
    { label: "Octopus Flag", value: "🐙" },
    { label: "Spiral Shell Flag", value: "🐚" },
    { label: "Coral Flag", value: "🪸" },
    { label: "Snail Flag", value: "🐌" },
    { label: "Butterfly Flag", value: "🦋" },
    { label: "Bug Flag", value: "🐛" },
    { label: "Ant Flag", value: "🐜" },
    { label: "Honeybee Flag", value: "🐝" },
    { label: "Beetle Flag", value: "🪲" },
    { label: "Lady Beetle Flag", value: "🐞" },
    { label: "Cricket Flag", value: "🦗" },
    { label: "Cockroach Flag", value: "🪳" },
    { label: "Spider Flag", value: "🕷️" },
    { label: "Spider Web Flag", value: "🕸️" },
    { label: "Scorpion Flag", value: "🦂" },
    { label: "Mosquito Flag", value: "🦟" },
    { label: "Fly Flag", value: "🪰" },
    { label: "Worm Flag", value: "🪱" },
    { label: "Microbe Flag", value: "🦠" },
    { label: "Bouquet Flag", value: "💐" },
    { label: "Cherry Blossom Flag", value: "🌸" },
    { label: "White Flower Flag", value: "💮" },
    { label: "Lotus Flag", value: "🪷" },
    { label: "Rosette Flag", value: "🏵️" },
    { label: "Rose Flag", value: "🌹" },
    { label: "Wilted Flower Flag", value: "🥀" },
    { label: "Hibiscus Flag", value: "🌺" },
    { label: "Sunflower Flag", value: "🌻" },
    { label: "Blossom Flag", value: "🌼" },
    { label: "Tulip Flag", value: "🌷" },
    { label: "Seedling Flag", value: "🌱" },
    { label: "Potted Plant Flag", value: "🪴" },
    { label: "Evergreen Tree Flag", value: "🌲" },
    { label: "Deciduous Tree Flag", value: "🌳" },
    { label: "Palm Tree Flag", value: "🌴" },
    { label: "Cactus Flag", value: "🌵" },
    { label: "Sheaf Of Rice Flag", value: "🌾" },
    { label: "Herb Flag", value: "🌿" },
    { label: "Shamrock Flag", value: "☘️" },
    { label: "Four Leaf Clover Flag", value: "🍀" },
    { label: "Maple Leaf Flag", value: "🍁" },
    { label: "Fallen Leaf Flag", value: "🍂" },
    { label: "Leaf Fluttering In Wind Flag", value: "🍃" },
    { label: "Empty Nest Flag", value: "🪹" },
    { label: "Nest With Eggs Flag", value: "🪺" },
  ],
  "Food & Drink": [
    { label: "Grapes Flag", value: "🍇" },
    { label: "Melon Flag", value: "🍈" },
    { label: "Watermelon Flag", value: "🍉" },
    { label: "Tangerine Flag", value: "🍊" },
    { label: "Lemon Flag", value: "🍋" },
    { label: "Banana Flag", value: "🍌" },
    { label: "Pineapple Flag", value: "🍍" },
    { label: "Mango Flag", value: "🥭" },
    { label: "Red Apple Flag", value: "🍎" },
    { label: "Green Apple Flag", value: "🍏" },
    { label: "Pear Flag", value: "🍐" },
    { label: "Peach Flag", value: "🍑" },
    { label: "Cherries Flag", value: "🍒" },
    { label: "Strawberry Flag", value: "🍓" },
    { label: "Blueberries Flag", value: "🫐" },
    { label: "Kiwi Fruit Flag", value: "🥝" },
    { label: "Tomato Flag", value: "🍅" },
    { label: "Olive Flag", value: "🫒" },
    { label: "Coconut Flag", value: "🥥" },
    { label: "Avocado Flag", value: "🥑" },
    { label: "Eggplant Flag", value: "🍆" },
    { label: "Potato Flag", value: "🥔" },
    { label: "Carrot Flag", value: "🥕" },
    { label: "Ear Of Corn Flag", value: "🌽" },
    { label: "Hot Pepper Flag", value: "🌶️" },
    { label: "Bell Pepper Flag", value: "🫑" },
    { label: "Cucumber Flag", value: "🥒" },
    { label: "Leafy Green Flag", value: "🥬" },
    { label: "Broccoli Flag", value: "🥦" },
    { label: "Garlic Flag", value: "🧄" },
    { label: "Onion Flag", value: "🧅" },
    { label: "Mushroom Flag", value: "🍄" },
    { label: "Peanuts Flag", value: "🥜" },
    { label: "Beans Flag", value: "🫘" },
    { label: "Chestnut Flag", value: "🌰" },
    { label: "Bread Flag", value: "🍞" },
    { label: "Croissant Flag", value: "🥐" },
    { label: "Baguette Bread Flag", value: "🥖" },
    { label: "Flatbread Flag", value: "🫓" },
    { label: "Pretzel Flag", value: "🥨" },
    { label: "Bagel Flag", value: "🥯" },
    { label: "Pancakes Flag", value: "🥞" },
    { label: "Waffle Flag", value: "🧇" },
    { label: "Cheese Wedge Flag", value: "🧀" },
    { label: "Meat On Bone Flag", value: "🍖" },
    { label: "Poultry Leg Flag", value: "🍗" },
    { label: "Cut Of Meat Flag", value: "🥩" },
    { label: "Bacon Flag", value: "🥓" },
    { label: "Hamburger Flag", value: "🍔" },
    { label: "French Fries Flag", value: "🍟" },
    { label: "Pizza Flag", value: "🍕" },
    { label: "Hot Dog Flag", value: "🌭" },
    { label: "Sandwich Flag", value: "🥪" },
    { label: "Taco Flag", value: "🌮" },
    { label: "Burrito Flag", value: "🌯" },
    { label: "Tamale Flag", value: "🫔" },
    { label: "Stuffed Flatbread Flag", value: "🥙" },
    { label: "Falafel Flag", value: "🧆" },
    { label: "Egg Flag", value: "🥚" },
    { label: "Cooking Flag", value: "🍳" },
    { label: "Shallow Pan Of Food Flag", value: "🥘" },
    { label: "Pot Of Food Flag", value: "🍲" },
    { label: "Fondue Flag", value: "🫕" },
    { label: "Bowl With Spoon Flag", value: "🥣" },
    { label: "Green Salad Flag", value: "🥗" },
    { label: "Popcorn Flag", value: "🍿" },
    { label: "Butter Flag", value: "🧈" },
    { label: "Salt Flag", value: "🧂" },
    { label: "Canned Food Flag", value: "🥫" },
    { label: "Bento Box Flag", value: "🍱" },
    { label: "Rice Cracker Flag", value: "🍘" },
    { label: "Rice Ball Flag", value: "🍙" },
    { label: "Cooked Rice Flag", value: "🍚" },
    { label: "Curry Rice Flag", value: "🍛" },
    { label: "Steaming Bowl Flag", value: "🍜" },
    { label: "Spaghetti Flag", value: "🍝" },
    { label: "Roasted Sweet Potato Flag", value: "🍠" },
    { label: "Oden Flag", value: "🍢" },
    { label: "Sushi Flag", value: "🍣" },
    { label: "Fried Shrimp Flag", value: "🍤" },
    { label: "Fish Cake With Swirl Flag", value: "🍥" },
    { label: "Moon Cake Flag", value: "🥮" },
    { label: "Dango Flag", value: "🍡" },
    { label: "Dumpling Flag", value: "🥟" },
    { label: "Fortune Cookie Flag", value: "🥠" },
    { label: "Takeout Box Flag", value: "🥡" },
    { label: "Crab Flag", value: "🦀" },
    { label: "Lobster Flag", value: "🦞" },
    { label: "Shrimp Flag", value: "🦐" },
    { label: "Squid Flag", value: "🦑" },
    { label: "Oyster Flag", value: "🦪" },
    { label: "Soft Ice Cream Flag", value: "🍦" },
    { label: "Shaved Ice Flag", value: "🍧" },
    { label: "Ice Cream Flag", value: "🍨" },
    { label: "Doughnut Flag", value: "🍩" },
    { label: "Cookie Flag", value: "🍪" },
    { label: "Birthday Cake Flag", value: "🎂" },
    { label: "Shortcake Flag", value: "🍰" },
    { label: "Cupcake Flag", value: "🧁" },
    { label: "Pie Flag", value: "🥧" },
    { label: "Chocolate Bar Flag", value: "🍫" },
    { label: "Candy Flag", value: "🍬" },
    { label: "Lollipop Flag", value: "🍭" },
    { label: "Custard Flag", value: "🍮" },
    { label: "Honey Pot Flag", value: "🍯" },
    { label: "Baby Bottle Flag", value: "🍼" },
    { label: "Glass Of Milk Flag", value: "🥛" },
    { label: "Hot Beverage Flag", value: "☕" },
    { label: "Teapot Flag", value: "🫖" },
    { label: "Teacup Without Handle Flag", value: "🍵" },
    { label: "Sake Flag", value: "🍶" },
    { label: "Bottle With Popping Cork Flag", value: "🍾" },
    { label: "Wine Glass Flag", value: "🍷" },
    { label: "Cocktail Glass Flag", value: "🍸" },
    { label: "Tropical Drink Flag", value: "🍹" },
    { label: "Beer Mug Flag", value: "🍺" },
    { label: "Clinking Beer Mugs Flag", value: "🍻" },
    { label: "Clinking Glasses Flag", value: "🥂" },
    { label: "Tumbler Glass Flag", value: "🥃" },
    { label: "Pouring Liquid Flag", value: "🫗" },
    { label: "Cup With Straw Flag", value: "🥤" },
    { label: "Bubble Tea Flag", value: "🧋" },
    { label: "Beverage Box Flag", value: "🧃" },
    { label: "Mate Flag", value: "🧉" },
    { label: "Ice Flag", value: "🧊" },
    { label: "Chopsticks Flag", value: "🥢" },
    { label: "Fork And Knife With Plate Flag", value: "🍽️" },
    { label: "Fork And Knife Flag", value: "🍴" },
    { label: "Spoon Flag", value: "🥄" },
    { label: "Kitchen Knife Flag", value: "🔪" },
    { label: "Jar Flag", value: "🫙" },
    { label: "Amphora Flag", value: "🏺" },
  ],
  Activity: [
    { label: "Jack-o-lantern Flag", value: "🎃" },
    { label: "Christmas Tree Flag", value: "🎄" },
    { label: "Fireworks Flag", value: "🎆" },
    { label: "Sparkler Flag", value: "🎇" },
    { label: "Firecracker Flag", value: "🧨" },
    { label: "Sparkles Flag", value: "✨" },
    { label: "Balloon Flag", value: "🎈" },
    { label: "Party Popper Flag", value: "🎉" },
    { label: "Confetti Ball Flag", value: "🎊" },
    { label: "Tanabata Tree Flag", value: "🎋" },
    { label: "Pine Decoration Flag", value: "🎍" },
    { label: "Japanese Dolls Flag", value: "🎎" },
    { label: "Carp Streamer Flag", value: "🎏" },
    { label: "Wind Chime Flag", value: "🎐" },
    { label: "Moon Viewing Ceremony Flag", value: "🎑" },
    { label: "Red Envelope Flag", value: "🧧" },
    { label: "Ribbon Flag", value: "🎀" },
    { label: "Wrapped Gift Flag", value: "🎁" },
    { label: "Reminder Ribbon Flag", value: "🎗️" },
    { label: "Admission Tickets Flag", value: "🎟️" },
    { label: "Ticket Flag", value: "🎫" },
    { label: "Military Medal Flag", value: "🎖️" },
    { label: "Trophy Flag", value: "🏆" },
    { label: "Sports Medal Flag", value: "🏅" },
    { label: "1st Place Medal Flag", value: "🥇" },
    { label: "2nd Place Medal Flag", value: "🥈" },
    { label: "3rd Place Medal Flag", value: "🥉" },
    { label: "Soccer Ball Flag", value: "⚽" },
    { label: "Baseball Flag", value: "⚾" },
    { label: "Softball Flag", value: "🥎" },
    { label: "Basketball Flag", value: "🏀" },
    { label: "Volleyball Flag", value: "🏐" },
    { label: "American Football Flag", value: "🏈" },
    { label: "Rugby Football Flag", value: "🏉" },
    { label: "Tennis Flag", value: "🎾" },
    { label: "Flying Disc Flag", value: "🥏" },
    { label: "Bowling Flag", value: "🎳" },
    { label: "Cricket Game Flag", value: "🏏" },
    { label: "Field Hockey Flag", value: "🏑" },
    { label: "Ice Hockey Flag", value: "🏒" },
    { label: "Lacrosse Flag", value: "🥍" },
    { label: "Ping Pong Flag", value: "🏓" },
    { label: "Badminton Flag", value: "🏸" },
    { label: "Boxing Glove Flag", value: "🥊" },
    { label: "Martial Arts Uniform Flag", value: "🥋" },
    { label: "Goal Net Flag", value: "🥅" },
    { label: "Flag In Hole", value: "⛳" },
    { label: "Ice Skate Flag", value: "⛸️" },
    { label: "Fishing Pole Flag", value: "🎣" },
    { label: "Diving Mask Flag", value: "🤿" },
    { label: "Running Shirt Flag", value: "🎽" },
    { label: "Skis Flag", value: "🎿" },
    { label: "Sled Flag", value: "🛷" },
    { label: "Curling Stone Flag", value: "🥌" },
    { label: "Bullseye Flag", value: "🎯" },
    { label: "Yo-yo Flag", value: "🪀" },
    { label: "Kite Flag", value: "🪁" },
    { label: "Pool 8 Ball Flag", value: "🎱" },
    { label: "Crystal Ball Flag", value: "🔮" },
    { label: "Magic Wand Flag", value: "🪄" },
    { label: "Nazar Amulet Flag", value: "🧿" },
    { label: "Hamsa Flag", value: "🪬" },
    { label: "Video Game Flag", value: "🎮" },
    { label: "Joystick Flag", value: "🕹️" },
    { label: "Slot Machine Flag", value: "🎰" },
    { label: "Game Die Flag", value: "🎲" },
    { label: "Puzzle Piece Flag", value: "🧩" },
    { label: "Teddy Bear Flag", value: "🧸" },
    { label: "Piñata Flag", value: "🪅" },
    { label: "Mirror Ball Flag", value: "🪩" },
    { label: "Nesting Dolls Flag", value: "🪆" },
    { label: "Spade Suit Flag", value: "♠️" },
    { label: "Heart Suit Flag", value: "♥️" },
    { label: "Diamond Suit Flag", value: "♦️" },
    { label: "Club Suit Flag", value: "♣️" },
    { label: "Chess Pawn Flag", value: "♟️" },
    { label: "Joker Flag", value: "🃏" },
    { label: "Mahjong Red Dragon Flag", value: "🀄" },
    { label: "Flower Playing Cards Flag", value: "🎴" },
    { label: "Performing Arts Flag", value: "🎭" },
    { label: "Framed Picture Flag", value: "🖼️" },
    { label: "Artist Palette Flag", value: "🎨" },
    { label: "Thread Flag", value: "🧵" },
    { label: "Sewing Needle Flag", value: "🪡" },
    { label: "Yarn Flag", value: "🧶" },
    { label: "Knot Flag", value: "🪢" },
  ],
  "Travel & Places": [
    { label: "Globe Showing Europe-Africa Flag", value: "🌍" },
    { label: "Globe Showing Americas Flag", value: "🌎" },
    { label: "Globe Showing Asia-Australia Flag", value: "🌏" },
    { label: "Globe With Meridians Flag", value: "🌐" },
    { label: "World Map Flag", value: "🗺️" },
    { label: "Map Of Japan Flag", value: "🗾" },
    { label: "Compass Flag", value: "🧭" },
    { label: "Snow-capped Mountain Flag", value: "🏔️" },
    { label: "Mountain Flag", value: "⛰️" },
    { label: "Volcano Flag", value: "🌋" },
    { label: "Mount Fuji Flag", value: "🗻" },
    { label: "Camping Flag", value: "🏕️" },
    { label: "Beach With Umbrella Flag", value: "🏖️" },
    { label: "Desert Flag", value: "🏜️" },
    { label: "Desert Island Flag", value: "🏝️" },
    { label: "National Park Flag", value: "🏞️" },
    { label: "Stadium Flag", value: "🏟️" },
    { label: "Classical Building Flag", value: "🏛️" },
    { label: "Building Construction Flag", value: "🏗️" },
    { label: "Brick Flag", value: "🧱" },
    { label: "Rock Flag", value: "🪨" },
    { label: "Wood Flag", value: "🪵" },
    { label: "Hut Flag", value: "🛖" },
    { label: "Houses Flag", value: "🏘️" },
    { label: "Derelict House Flag", value: "🏚️" },
    { label: "House Flag", value: "🏠" },
    { label: "House With Garden Flag", value: "🏡" },
    { label: "Office Building Flag", value: "🏢" },
    { label: "Japanese Post Office Flag", value: "🏣" },
    { label: "Post Office Flag", value: "🏤" },
    { label: "Hospital Flag", value: "🏥" },
    { label: "Bank Flag", value: "🏦" },
    { label: "Hotel Flag", value: "🏨" },
    { label: "Love Hotel Flag", value: "🏩" },
    { label: "Convenience Store Flag", value: "🏪" },
    { label: "School Flag", value: "🏫" },
    { label: "Department Store Flag", value: "🏬" },
    { label: "Factory Flag", value: "🏭" },
    { label: "Japanese Castle Flag", value: "🏯" },
    { label: "Castle Flag", value: "🏰" },
    { label: "Wedding Flag", value: "💒" },
    { label: "Tokyo Tower Flag", value: "🗼" },
    { label: "Statue Of Liberty Flag", value: "🗽" },
    { label: "Church Flag", value: "⛪" },
    { label: "Mosque Flag", value: "🕌" },
    { label: "Hindu Temple Flag", value: "🛕" },
    { label: "Synagogue Flag", value: "🕍" },
    { label: "Shinto Shrine Flag", value: "⛩️" },
    { label: "Kaaba Flag", value: "🕋" },
    { label: "Fountain Flag", value: "⛲" },
    { label: "Tent Flag", value: "⛺" },
    { label: "Foggy Flag", value: "🌁" },
    { label: "Night With Stars Flag", value: "🌃" },
    { label: "Cityscape Flag", value: "🏙️" },
    { label: "Sunrise Over Mountains Flag", value: "🌄" },
    { label: "Sunrise Flag", value: "🌅" },
    { label: "Cityscape At Dusk Flag", value: "🌆" },
    { label: "Sunset Flag", value: "🌇" },
    { label: "Bridge At Night Flag", value: "🌉" },
    { label: "Hot Springs Flag", value: "♨️" },
    { label: "Carousel Horse Flag", value: "🎠" },
    { label: "Playground Slide Flag", value: "🛝" },
    { label: "Ferris Wheel Flag", value: "🎡" },
    { label: "Roller Coaster Flag", value: "🎢" },
    { label: "Barber Pole Flag", value: "💈" },
    { label: "Circus Tent Flag", value: "🎪" },
    { label: "Locomotive Flag", value: "🚂" },
    { label: "Railway Car Flag", value: "🚃" },
    { label: "High-speed Train Flag", value: "🚄" },
    { label: "Bullet Train Flag", value: "🚅" },
    { label: "Train Flag", value: "🚆" },
    { label: "Metro Flag", value: "🚇" },
    { label: "Light Rail Flag", value: "🚈" },
    { label: "Station Flag", value: "🚉" },
    { label: "Tram Flag", value: "🚊" },
    { label: "Monorail Flag", value: "🚝" },
    { label: "Mountain Railway Flag", value: "🚞" },
    { label: "Tram Car Flag", value: "🚋" },
    { label: "Bus Flag", value: "🚌" },
    { label: "Oncoming Bus Flag", value: "🚍" },
    { label: "Trolleybus Flag", value: "🚎" },
    { label: "Minibus Flag", value: "🚐" },
    { label: "Ambulance Flag", value: "🚑" },
    { label: "Fire Engine Flag", value: "🚒" },
    { label: "Police Car Flag", value: "🚓" },
    { label: "Oncoming Police Car Flag", value: "🚔" },
    { label: "Taxi Flag", value: "🚕" },
    { label: "Oncoming Taxi Flag", value: "🚖" },
    { label: "Automobile Flag", value: "🚗" },
    { label: "Oncoming Automobile Flag", value: "🚘" },
    { label: "Sport Utility Vehicle Flag", value: "🚙" },
    { label: "Pickup Truck Flag", value: "🛻" },
    { label: "Delivery Truck Flag", value: "🚚" },
    { label: "Articulated Lorry Flag", value: "🚛" },
    { label: "Tractor Flag", value: "🚜" },
    { label: "Racing Car Flag", value: "🏎️" },
    { label: "Motorcycle Flag", value: "🏍️" },
    { label: "Motor Scooter Flag", value: "🛵" },
    { label: "Manual Wheelchair Flag", value: "🦽" },
    { label: "Motorized Wheelchair Flag", value: "🦼" },
    { label: "Auto Rickshaw Flag", value: "🛺" },
    { label: "Bicycle Flag", value: "🚲" },
    { label: "Kick Scooter Flag", value: "🛴" },
    { label: "Skateboard Flag", value: "🛹" },
    { label: "Roller Skate Flag", value: "🛼" },
    { label: "Bus Stop Flag", value: "🚏" },
    { label: "Motorway Flag", value: "🛣️" },
    { label: "Railway Track Flag", value: "🛤️" },
    { label: "Oil Drum Flag", value: "🛢️" },
    { label: "Fuel Pump Flag", value: "⛽" },
    { label: "Wheel Flag", value: "🛞" },
    { label: "Police Car Light Flag", value: "🚨" },
    { label: "Horizontal Traffic Light Flag", value: "🚥" },
    { label: "Vertical Traffic Light Flag", value: "🚦" },
    { label: "Stop Sign Flag", value: "🛑" },
    { label: "Construction Flag", value: "🚧" },
    { label: "Anchor Flag", value: "⚓" },
    { label: "Ring Buoy Flag", value: "🛟" },
    { label: "Sailboat Flag", value: "⛵" },
    { label: "Canoe Flag", value: "🛶" },
    { label: "Speedboat Flag", value: "🚤" },
    { label: "Passenger Ship Flag", value: "🛳️" },
    { label: "Ferry Flag", value: "⛴️" },
    { label: "Motor Boat Flag", value: "🛥️" },
    { label: "Ship Flag", value: "🚢" },
    { label: "Airplane Flag", value: "✈️" },
    { label: "Small Airplane Flag", value: "🛩️" },
    { label: "Airplane Departure Flag", value: "🛫" },
    { label: "Airplane Arrival Flag", value: "🛬" },
    { label: "Parachute Flag", value: "🪂" },
    { label: "Seat Flag", value: "💺" },
    { label: "Helicopter Flag", value: "🚁" },
    { label: "Suspension Railway Flag", value: "🚟" },
    { label: "Mountain Cableway Flag", value: "🚠" },
    { label: "Aerial Tramway Flag", value: "🚡" },
    { label: "Satellite Flag", value: "🛰️" },
    { label: "Rocket Flag", value: "🚀" },
    { label: "Flying Saucer Flag", value: "🛸" },
    { label: "Bellhop Bell Flag", value: "🛎️" },
    { label: "Luggage Flag", value: "🧳" },
    { label: "Hourglass Done Flag", value: "⌛" },
    { label: "Hourglass Not Done Flag", value: "⏳" },
    { label: "Watch Flag", value: "⌚" },
    { label: "Alarm Clock Flag", value: "⏰" },
    { label: "Stopwatch Flag", value: "⏱️" },
    { label: "Timer Clock Flag", value: "⏲️" },
    { label: "Mantelpiece Clock Flag", value: "🕰️" },
    { label: "Twelve O’clock Flag", value: "🕛" },
    { label: "Twelve-thirty Flag", value: "🕧" },
    { label: "One O’clock Flag", value: "🕐" },
    { label: "One-thirty Flag", value: "🕜" },
    { label: "Two O’clock Flag", value: "🕑" },
    { label: "Two-thirty Flag", value: "🕝" },
    { label: "Three O’clock Flag", value: "🕒" },
    { label: "Three-thirty Flag", value: "🕞" },
    { label: "Four O’clock Flag", value: "🕓" },
    { label: "Four-thirty Flag", value: "🕟" },
    { label: "Five O’clock Flag", value: "🕔" },
    { label: "Five-thirty Flag", value: "🕠" },
    { label: "Six O’clock Flag", value: "🕕" },
    { label: "Six-thirty Flag", value: "🕡" },
    { label: "Seven O’clock Flag", value: "🕖" },
    { label: "Seven-thirty Flag", value: "🕢" },
    { label: "Eight O’clock Flag", value: "🕗" },
    { label: "Eight-thirty Flag", value: "🕣" },
    { label: "Nine O’clock Flag", value: "🕘" },
    { label: "Nine-thirty Flag", value: "🕤" },
    { label: "Ten O’clock Flag", value: "🕙" },
    { label: "Ten-thirty Flag", value: "🕥" },
    { label: "Eleven O’clock Flag", value: "🕚" },
    { label: "Eleven-thirty Flag", value: "🕦" },
    { label: "New Moon Flag", value: "🌑" },
    { label: "Waxing Crescent Moon Flag", value: "🌒" },
    { label: "First Quarter Moon Flag", value: "🌓" },
    { label: "Waxing Gibbous Moon Flag", value: "🌔" },
    { label: "Full Moon Flag", value: "🌕" },
    { label: "Waning Gibbous Moon Flag", value: "🌖" },
    { label: "Last Quarter Moon Flag", value: "🌗" },
    { label: "Waning Crescent Moon Flag", value: "🌘" },
    { label: "Crescent Moon Flag", value: "🌙" },
    { label: "New Moon Face Flag", value: "🌚" },
    { label: "First Quarter Moon Face Flag", value: "🌛" },
    { label: "Last Quarter Moon Face Flag", value: "🌜" },
    { label: "Thermometer Flag", value: "🌡️" },
    { label: "Sun Flag", value: "☀️" },
    { label: "Full Moon Face Flag", value: "🌝" },
    { label: "Sun With Face Flag", value: "🌞" },
    { label: "Ringed Planet Flag", value: "🪐" },
    { label: "Star Flag", value: "⭐" },
    { label: "Glowing Star Flag", value: "🌟" },
    { label: "Shooting Star Flag", value: "🌠" },
    { label: "Milky Way Flag", value: "🌌" },
    { label: "Cloud Flag", value: "☁️" },
    { label: "Sun Behind Cloud Flag", value: "⛅" },
    { label: "Cloud With Lightning And Rain Flag", value: "⛈️" },
    { label: "Sun Behind Small Cloud Flag", value: "🌤️" },
    { label: "Sun Behind Large Cloud Flag", value: "🌥️" },
    { label: "Sun Behind Rain Cloud Flag", value: "🌦️" },
    { label: "Cloud With Rain Flag", value: "🌧️" },
    { label: "Cloud With Snow Flag", value: "🌨️" },
    { label: "Cloud With Lightning Flag", value: "🌩️" },
    { label: "Tornado Flag", value: "🌪️" },
    { label: "Fog Flag", value: "🌫️" },
    { label: "Wind Face Flag", value: "🌬️" },
    { label: "Cyclone Flag", value: "🌀" },
    { label: "Rainbow Flag", value: "🌈" },
    { label: "Closed Umbrella Flag", value: "🌂" },
    { label: "Umbrella Flag", value: "☂️" },
    { label: "Umbrella With Rain Drops Flag", value: "☔" },
    { label: "Umbrella On Ground Flag", value: "⛱️" },
    { label: "High Voltage Flag", value: "⚡" },
    { label: "Snowflake Flag", value: "❄️" },
    { label: "Snowman Flag", value: "☃️" },
    { label: "Snowman Without Snow Flag", value: "⛄" },
    { label: "Comet Flag", value: "☄️" },
    { label: "Fire Flag", value: "🔥" },
    { label: "Droplet Flag", value: "💧" },
    { label: "Water Wave Flag", value: "🌊" },
  ],
  Objects: [
    { label: "Glasses Flag", value: "👓" },
    { label: "Sunglasses Flag", value: "🕶️" },
    { label: "Goggles Flag", value: "🥽" },
    { label: "Lab Coat Flag", value: "🥼" },
    { label: "Safety Vest Flag", value: "🦺" },
    { label: "Necktie Flag", value: "👔" },
    { label: "T-shirt Flag", value: "👕" },
    { label: "Jeans Flag", value: "👖" },
    { label: "Scarf Flag", value: "🧣" },
    { label: "Gloves Flag", value: "🧤" },
    { label: "Coat Flag", value: "🧥" },
    { label: "Socks Flag", value: "🧦" },
    { label: "Dress Flag", value: "👗" },
    { label: "Kimono Flag", value: "👘" },
    { label: "Sari Flag", value: "🥻" },
    { label: "One-piece Swimsuit Flag", value: "🩱" },
    { label: "Briefs Flag", value: "🩲" },
    { label: "Shorts Flag", value: "🩳" },
    { label: "Bikini Flag", value: "👙" },
    { label: "Woman’s Clothes Flag", value: "👚" },
    { label: "Purse Flag", value: "👛" },
    { label: "Handbag Flag", value: "👜" },
    { label: "Clutch Bag Flag", value: "👝" },
    { label: "Shopping Bags Flag", value: "🛍️" },
    { label: "Backpack Flag", value: "🎒" },
    { label: "Thong Sandal Flag", value: "🩴" },
    { label: "Man’s Shoe Flag", value: "👞" },
    { label: "Running Shoe Flag", value: "👟" },
    { label: "Hiking Boot Flag", value: "🥾" },
    { label: "Flat Shoe Flag", value: "🥿" },
    { label: "High-heeled Shoe Flag", value: "👠" },
    { label: "Woman’s Sandal Flag", value: "👡" },
    { label: "Ballet Shoes Flag", value: "🩰" },
    { label: "Woman’s Boot Flag", value: "👢" },
    { label: "Crown Flag", value: "👑" },
    { label: "Woman’s Hat Flag", value: "👒" },
    { label: "Top Hat Flag", value: "🎩" },
    { label: "Graduation Cap Flag", value: "🎓" },
    { label: "Billed Cap Flag", value: "🧢" },
    { label: "Military Helmet Flag", value: "🪖" },
    { label: "Rescue Worker’s Helmet Flag", value: "⛑️" },
    { label: "Prayer Beads Flag", value: "📿" },
    { label: "Lipstick Flag", value: "💄" },
    { label: "Ring Flag", value: "💍" },
    { label: "Gem Stone Flag", value: "💎" },
    { label: "Muted Speaker Flag", value: "🔇" },
    { label: "Speaker Low Volume Flag", value: "🔈" },
    { label: "Speaker Medium Volume Flag", value: "🔉" },
    { label: "Speaker High Volume Flag", value: "🔊" },
    { label: "Loudspeaker Flag", value: "📢" },
    { label: "Megaphone Flag", value: "📣" },
    { label: "Postal Horn Flag", value: "📯" },
    { label: "Bell Flag", value: "🔔" },
    { label: "Bell With Slash Flag", value: "🔕" },
    { label: "Musical Score Flag", value: "🎼" },
    { label: "Musical Note Flag", value: "🎵" },
    { label: "Musical Notes Flag", value: "🎶" },
    { label: "Studio Microphone Flag", value: "🎙️" },
    { label: "Level Slider Flag", value: "🎚️" },
    { label: "Control Knobs Flag", value: "🎛️" },
    { label: "Microphone Flag", value: "🎤" },
    { label: "Headphone Flag", value: "🎧" },
    { label: "Radio Flag", value: "📻" },
    { label: "Saxophone Flag", value: "🎷" },
    { label: "Accordion Flag", value: "🪗" },
    { label: "Guitar Flag", value: "🎸" },
    { label: "Musical Keyboard Flag", value: "🎹" },
    { label: "Trumpet Flag", value: "🎺" },
    { label: "Violin Flag", value: "🎻" },
    { label: "Banjo Flag", value: "🪕" },
    { label: "Drum Flag", value: "🥁" },
    { label: "Long Drum Flag", value: "🪘" },
    { label: "Mobile Phone Flag", value: "📱" },
    { label: "Mobile Phone With Arrow Flag", value: "📲" },
    { label: "Telephone Flag", value: "☎️" },
    { label: "Telephone Receiver Flag", value: "📞" },
    { label: "Pager Flag", value: "📟" },
    { label: "Fax Machine Flag", value: "📠" },
    { label: "Battery Flag", value: "🔋" },
    { label: "Low Battery Flag", value: "🪫" },
    { label: "Electric Plug Flag", value: "🔌" },
    { label: "Laptop Flag", value: "💻" },
    { label: "Desktop Computer Flag", value: "🖥️" },
    { label: "Printer Flag", value: "🖨️" },
    { label: "Keyboard Flag", value: "⌨️" },
    { label: "Computer Mouse Flag", value: "🖱️" },
    { label: "Trackball Flag", value: "🖲️" },
    { label: "Computer Disk Flag", value: "💽" },
    { label: "Floppy Disk Flag", value: "💾" },
    { label: "Optical Disk Flag", value: "💿" },
    { label: "Dvd Flag", value: "📀" },
    { label: "Abacus Flag", value: "🧮" },
    { label: "Movie Camera Flag", value: "🎥" },
    { label: "Film Frames Flag", value: "🎞️" },
    { label: "Film Projector Flag", value: "📽️" },
    { label: "Clapper Board Flag", value: "🎬" },
    { label: "Television Flag", value: "📺" },
    { label: "Camera Flag", value: "📷" },
    { label: "Camera With Flash Flag", value: "📸" },
    { label: "Video Camera Flag", value: "📹" },
    { label: "Videocassette Flag", value: "📼" },
    { label: "Magnifying Glass Tilted Left Flag", value: "🔍" },
    { label: "Magnifying Glass Tilted Right Flag", value: "🔎" },
    { label: "Candle Flag", value: "🕯️" },
    { label: "Light Bulb Flag", value: "💡" },
    { label: "Flashlight Flag", value: "🔦" },
    { label: "Red Paper Lantern Flag", value: "🏮" },
    { label: "Diya Lamp Flag", value: "🪔" },
    { label: "Notebook With Decorative Cover Flag", value: "📔" },
    { label: "Closed Book Flag", value: "📕" },
    { label: "Open Book Flag", value: "📖" },
    { label: "Green Book Flag", value: "📗" },
    { label: "Blue Book Flag", value: "📘" },
    { label: "Orange Book Flag", value: "📙" },
    { label: "Books Flag", value: "📚" },
    { label: "Notebook Flag", value: "📓" },
    { label: "Ledger Flag", value: "📒" },
    { label: "Page With Curl Flag", value: "📃" },
    { label: "Scroll Flag", value: "📜" },
    { label: "Page Facing Up Flag", value: "📄" },
    { label: "Newspaper Flag", value: "📰" },
    { label: "Rolled-up Newspaper Flag", value: "🗞️" },
    { label: "Bookmark Tabs Flag", value: "📑" },
    { label: "Bookmark Flag", value: "🔖" },
    { label: "Label Flag", value: "🏷️" },
    { label: "Money Bag Flag", value: "💰" },
    { label: "Coin Flag", value: "🪙" },
    { label: "Yen Banknote Flag", value: "💴" },
    { label: "Dollar Banknote Flag", value: "💵" },
    { label: "Euro Banknote Flag", value: "💶" },
    { label: "Pound Banknote Flag", value: "💷" },
    { label: "Money With Wings Flag", value: "💸" },
    { label: "Credit Card Flag", value: "💳" },
    { label: "Receipt Flag", value: "🧾" },
    { label: "Chart Increasing With Yen Flag", value: "💹" },
    { label: "Envelope Flag", value: "✉️" },
    { label: "E-mail Flag", value: "📧" },
    { label: "Incoming Envelope Flag", value: "📨" },
    { label: "Envelope With Arrow Flag", value: "📩" },
    { label: "Outbox Tray Flag", value: "📤" },
    { label: "Inbox Tray Flag", value: "📥" },
    { label: "Package Flag", value: "📦" },
    { label: "Closed Mailbox With Raised Flag", value: "📫" },
    { label: "Closed Mailbox With Lowered Flag", value: "📪" },
    { label: "Open Mailbox With Raised Flag", value: "📬" },
    { label: "Open Mailbox With Lowered Flag", value: "📭" },
    { label: "Postbox Flag", value: "📮" },
    { label: "Ballot Box With Ballot Flag", value: "🗳️" },
    { label: "Pencil Flag", value: "✏️" },
    { label: "Black Nib Flag", value: "✒️" },
    { label: "Fountain Pen Flag", value: "🖋️" },
    { label: "Pen Flag", value: "🖊️" },
    { label: "Paintbrush Flag", value: "🖌️" },
    { label: "Crayon Flag", value: "🖍️" },
    { label: "Memo Flag", value: "📝" },
    { label: "Briefcase Flag", value: "💼" },
    { label: "File Folder Flag", value: "📁" },
    { label: "Open File Folder Flag", value: "📂" },
    { label: "Card Index Dividers Flag", value: "🗂️" },
    { label: "Calendar Flag", value: "📅" },
    { label: "Tear-off Calendar Flag", value: "📆" },
    { label: "Spiral Notepad Flag", value: "🗒️" },
    { label: "Spiral Calendar Flag", value: "🗓️" },
    { label: "Card Index Flag", value: "📇" },
    { label: "Chart Increasing Flag", value: "📈" },
    { label: "Chart Decreasing Flag", value: "📉" },
    { label: "Bar Chart Flag", value: "📊" },
    { label: "Clipboard Flag", value: "📋" },
    { label: "Pushpin Flag", value: "📌" },
    { label: "Round Pushpin Flag", value: "📍" },
    { label: "Paperclip Flag", value: "📎" },
    { label: "Linked Paperclips Flag", value: "🖇️" },
    { label: "Straight Ruler Flag", value: "📏" },
    { label: "Triangular Ruler Flag", value: "📐" },
    { label: "Scissors Flag", value: "✂️" },
    { label: "Card File Box Flag", value: "🗃️" },
    { label: "File Cabinet Flag", value: "🗄️" },
    { label: "Wastebasket Flag", value: "🗑️" },
    { label: "Locked Flag", value: "🔒" },
    { label: "Unlocked Flag", value: "🔓" },
    { label: "Locked With Pen Flag", value: "🔏" },
    { label: "Locked With Key Flag", value: "🔐" },
    { label: "Key Flag", value: "🔑" },
    { label: "Old Key Flag", value: "🗝️" },
    { label: "Hammer Flag", value: "🔨" },
    { label: "Axe Flag", value: "🪓" },
    { label: "Pick Flag", value: "⛏️" },
    { label: "Hammer And Pick Flag", value: "⚒️" },
    { label: "Hammer And Wrench Flag", value: "🛠️" },
    { label: "Dagger Flag", value: "🗡️" },
    { label: "Crossed Swords Flag", value: "⚔️" },
    { label: "Water Pistol Flag", value: "🔫" },
    { label: "Boomerang Flag", value: "🪃" },
    { label: "Bow And Arrow Flag", value: "🏹" },
    { label: "Shield Flag", value: "🛡️" },
    { label: "Carpentry Saw Flag", value: "🪚" },
    { label: "Wrench Flag", value: "🔧" },
    { label: "Screwdriver Flag", value: "🪛" },
    { label: "Nut And Bolt Flag", value: "🔩" },
    { label: "Gear Flag", value: "⚙️" },
    { label: "Clamp Flag", value: "🗜️" },
    { label: "Balance Scale Flag", value: "⚖️" },
    { label: "White Cane Flag", value: "🦯" },
    { label: "Link Flag", value: "🔗" },
    { label: "Chains Flag", value: "⛓️" },
    { label: "Hook Flag", value: "🪝" },
    { label: "Toolbox Flag", value: "🧰" },
    { label: "Magnet Flag", value: "🧲" },
    { label: "Ladder Flag", value: "🪜" },
    { label: "Alembic Flag", value: "⚗️" },
    { label: "Test Tube Flag", value: "🧪" },
    { label: "Petri Dish Flag", value: "🧫" },
    { label: "Dna Flag", value: "🧬" },
    { label: "Microscope Flag", value: "🔬" },
    { label: "Telescope Flag", value: "🔭" },
    { label: "Satellite Antenna Flag", value: "📡" },
    { label: "Syringe Flag", value: "💉" },
    { label: "Drop Of Blood Flag", value: "🩸" },
    { label: "Pill Flag", value: "💊" },
    { label: "Adhesive Bandage Flag", value: "🩹" },
    { label: "Crutch Flag", value: "🩼" },
    { label: "Stethoscope Flag", value: "🩺" },
    { label: "X-ray Flag", value: "🩻" },
    { label: "Door Flag", value: "🚪" },
    { label: "Elevator Flag", value: "🛗" },
    { label: "Mirror Flag", value: "🪞" },
    { label: "Window Flag", value: "🪟" },
    { label: "Bed Flag", value: "🛏️" },
    { label: "Couch And Lamp Flag", value: "🛋️" },
    { label: "Chair Flag", value: "🪑" },
    { label: "Toilet Flag", value: "🚽" },
    { label: "Plunger Flag", value: "🪠" },
    { label: "Shower Flag", value: "🚿" },
    { label: "Bathtub Flag", value: "🛁" },
    { label: "Mouse Trap Flag", value: "🪤" },
    { label: "Razor Flag", value: "🪒" },
    { label: "Lotion Bottle Flag", value: "🧴" },
    { label: "Safety Pin Flag", value: "🧷" },
    { label: "Broom Flag", value: "🧹" },
    { label: "Basket Flag", value: "🧺" },
    { label: "Roll Of Paper Flag", value: "🧻" },
    { label: "Bucket Flag", value: "🪣" },
    { label: "Soap Flag", value: "🧼" },
    { label: "Bubbles Flag", value: "🫧" },
    { label: "Toothbrush Flag", value: "🪥" },
    { label: "Sponge Flag", value: "🧽" },
    { label: "Fire Extinguisher Flag", value: "🧯" },
    { label: "Shopping Cart Flag", value: "🛒" },
    { label: "Cigarette Flag", value: "🚬" },
    { label: "Coffin Flag", value: "⚰️" },
    { label: "Headstone Flag", value: "🪦" },
    { label: "Funeral Urn Flag", value: "⚱️" },
    { label: "Moai Flag", value: "🗿" },
    { label: "Placard Flag", value: "🪧" },
    { label: "Identification Card Flag", value: "🪪" },
  ],
  Symbols: [
    { label: "ATM Sign Flag", value: "🏧" },
    { label: "Litter In Bin Sign Flag", value: "🚮" },
    { label: "Potable Water Flag", value: "🚰" },
    { label: "Wheelchair Symbol Flag", value: "♿" },
    { label: "Men’s Room Flag", value: "🚹" },
    { label: "Women’s Room Flag", value: "🚺" },
    { label: "Restroom Flag", value: "🚻" },
    { label: "Baby Symbol Flag", value: "🚼" },
    { label: "Water Closet Flag", value: "🚾" },
    { label: "Passport Control Flag", value: "🛂" },
    { label: "Customs Flag", value: "🛃" },
    { label: "Baggage Claim Flag", value: "🛄" },
    { label: "Left Luggage Flag", value: "🛅" },
    { label: "Warning Flag", value: "⚠️" },
    { label: "Children Crossing Flag", value: "🚸" },
    { label: "No Entry Flag", value: "⛔" },
    { label: "Prohibited Flag", value: "🚫" },
    { label: "No Bicycles Flag", value: "🚳" },
    { label: "No Smoking Flag", value: "🚭" },
    { label: "No Littering Flag", value: "🚯" },
    { label: "Non-potable Water Flag", value: "🚱" },
    { label: "No Pedestrians Flag", value: "🚷" },
    { label: "No Mobile Phones Flag", value: "📵" },
    { label: "No One Under Eighteen Flag", value: "🔞" },
    { label: "Radioactive Flag", value: "☢️" },
    { label: "Biohazard Flag", value: "☣️" },
    { label: "Up Arrow Flag", value: "⬆️" },
    { label: "Up-right Arrow Flag", value: "↗️" },
    { label: "Right Arrow Flag", value: "➡️" },
    { label: "Down-right Arrow Flag", value: "↘️" },
    { label: "Down Arrow Flag", value: "⬇️" },
    { label: "Down-left Arrow Flag", value: "↙️" },
    { label: "Left Arrow Flag", value: "⬅️" },
    { label: "Up-left Arrow Flag", value: "↖️" },
    { label: "Up-down Arrow Flag", value: "↕️" },
    { label: "Left-right Arrow Flag", value: "↔️" },
    { label: "Right Arrow Curving Left Flag", value: "↩️" },
    { label: "Left Arrow Curving Right Flag", value: "↪️" },
    { label: "Right Arrow Curving Up Flag", value: "⤴️" },
    { label: "Right Arrow Curving Down Flag", value: "⤵️" },
    { label: "Clockwise Vertical Arrows Flag", value: "🔃" },
    { label: "Counterclockwise Arrows Button Flag", value: "🔄" },
    { label: "BACK Arrow Flag", value: "🔙" },
    { label: "END Arrow Flag", value: "🔚" },
    { label: "ON! Arrow Flag", value: "🔛" },
    { label: "SOON Arrow Flag", value: "🔜" },
    { label: "TOP Arrow Flag", value: "🔝" },
    { label: "Place Of Worship Flag", value: "🛐" },
    { label: "Atom Symbol Flag", value: "⚛️" },
    { label: "Om Flag", value: "🕉️" },
    { label: "Star Of David Flag", value: "✡️" },
    { label: "Wheel Of Dharma Flag", value: "☸️" },
    { label: "Yin Yang Flag", value: "☯️" },
    { label: "Latin Cross Flag", value: "✝️" },
    { label: "Orthodox Cross Flag", value: "☦️" },
    { label: "Star And Crescent Flag", value: "☪️" },
    { label: "Peace Symbol Flag", value: "☮️" },
    { label: "Menorah Flag", value: "🕎" },
    { label: "Dotted Six-pointed Star Flag", value: "🔯" },
    { label: "Aries Flag", value: "♈" },
    { label: "Taurus Flag", value: "♉" },
    { label: "Gemini Flag", value: "♊" },
    { label: "Cancer Flag", value: "♋" },
    { label: "Leo Flag", value: "♌" },
    { label: "Virgo Flag", value: "♍" },
    { label: "Libra Flag", value: "♎" },
    { label: "Scorpio Flag", value: "♏" },
    { label: "Sagittarius Flag", value: "♐" },
    { label: "Capricorn Flag", value: "♑" },
    { label: "Aquarius Flag", value: "♒" },
    { label: "Pisces Flag", value: "♓" },
    { label: "Ophiuchus Flag", value: "⛎" },
    { label: "Shuffle Tracks Button Flag", value: "🔀" },
    { label: "Repeat Button Flag", value: "🔁" },
    { label: "Repeat Single Button Flag", value: "🔂" },
    { label: "Play Button Flag", value: "▶️" },
    { label: "Fast-forward Button Flag", value: "⏩" },
    { label: "Next Track Button Flag", value: "⏭️" },
    { label: "Play Or Pause Button Flag", value: "⏯️" },
    { label: "Reverse Button Flag", value: "◀️" },
    { label: "Fast Reverse Button Flag", value: "⏪" },
    { label: "Last Track Button Flag", value: "⏮️" },
    { label: "Upwards Button Flag", value: "🔼" },
    { label: "Fast Up Button Flag", value: "⏫" },
    { label: "Downwards Button Flag", value: "🔽" },
    { label: "Fast Down Button Flag", value: "⏬" },
    { label: "Pause Button Flag", value: "⏸️" },
    { label: "Stop Button Flag", value: "⏹️" },
    { label: "Record Button Flag", value: "⏺️" },
    { label: "Eject Button Flag", value: "⏏️" },
    { label: "Cinema Flag", value: "🎦" },
    { label: "Dim Button Flag", value: "🔅" },
    { label: "Bright Button Flag", value: "🔆" },
    { label: "Antenna Bars Flag", value: "📶" },
    { label: "Vibration Mode Flag", value: "📳" },
    { label: "Mobile Phone Off Flag", value: "📴" },
    { label: "Female Sign Flag", value: "♀️" },
    { label: "Male Sign Flag", value: "♂️" },
    { label: "Transgender Symbol Flag", value: "⚧️" },
    { label: "Multiply Flag", value: "✖️" },
    { label: "Plus Flag", value: "➕" },
    { label: "Minus Flag", value: "➖" },
    { label: "Divide Flag", value: "➗" },
    { label: "Heavy Equals Sign Flag", value: "🟰" },
    { label: "Infinity Flag", value: "♾️" },
    { label: "Double Exclamation Mark Flag", value: "‼️" },
    { label: "Exclamation Question Mark Flag", value: "⁉️" },
    { label: "Red Question Mark Flag", value: "❓" },
    { label: "White Question Mark Flag", value: "❔" },
    { label: "White Exclamation Mark Flag", value: "❕" },
    { label: "Red Exclamation Mark Flag", value: "❗" },
    { label: "Wavy Dash Flag", value: "〰️" },
    { label: "Currency Exchange Flag", value: "💱" },
    { label: "Heavy Dollar Sign Flag", value: "💲" },
    { label: "Medical Symbol Flag", value: "⚕️" },
    { label: "Recycling Symbol Flag", value: "♻️" },
    { label: "Fleur-de-lis Flag", value: "⚜️" },
    { label: "Trident Emblem Flag", value: "🔱" },
    { label: "Name Badge Flag", value: "📛" },
    { label: "Japanese Symbol For Beginner Flag", value: "🔰" },
    { label: "Hollow Red Circle Flag", value: "⭕" },
    { label: "Check Mark Button Flag", value: "✅" },
    { label: "Check Box With Check Flag", value: "☑️" },
    { label: "Check Mark Flag", value: "✔️" },
    { label: "Cross Mark Flag", value: "❌" },
    { label: "Cross Mark Button Flag", value: "❎" },
    { label: "Curly Loop Flag", value: "➰" },
    { label: "Double Curly Loop Flag", value: "➿" },
    { label: "Part Alternation Mark Flag", value: "〽️" },
    { label: "Eight-spoked Asterisk Flag", value: "✳️" },
    { label: "Eight-pointed Star Flag", value: "✴️" },
    { label: "Sparkle Flag", value: "❇️" },
    { label: "Keycap: # Flag", value: "#️⃣" },
    { label: "Keycap: * Flag", value: "*️⃣" },
    { label: "Keycap: 0 Flag", value: "0️⃣" },
    { label: "Keycap: 1 Flag", value: "1️⃣" },
    { label: "Keycap: 2 Flag", value: "2️⃣" },
    { label: "Keycap: 3 Flag", value: "3️⃣" },
    { label: "Keycap: 4 Flag", value: "4️⃣" },
    { label: "Keycap: 5 Flag", value: "5️⃣" },
    { label: "Keycap: 6 Flag", value: "6️⃣" },
    { label: "Keycap: 7 Flag", value: "7️⃣" },
    { label: "Keycap: 8 Flag", value: "8️⃣" },
    { label: "Keycap: 9 Flag", value: "9️⃣" },
    { label: "Keycap: 10 Flag", value: "🔟" },
    { label: "Input Latin Uppercase Flag", value: "🔠" },
    { label: "Input Latin Lowercase Flag", value: "🔡" },
    { label: "Input Numbers Flag", value: "🔢" },
    { label: "Input Symbols Flag", value: "🔣" },
    { label: "Input Latin Letters Flag", value: "🔤" },
    { label: "A Button (blood Type) Flag", value: "🅰️" },
    { label: "AB Button (blood Type) Flag", value: "🆎" },
    { label: "B Button (blood Type) Flag", value: "🅱️" },
    { label: "CL Button Flag", value: "🆑" },
    { label: "COOL Button Flag", value: "🆒" },
    { label: "FREE Button Flag", value: "🆓" },
    { label: "Information Flag", value: "ℹ️" },
    { label: "ID Button Flag", value: "🆔" },
    { label: "Circled M Flag", value: "Ⓜ️" },
    { label: "NEW Button Flag", value: "🆕" },
    { label: "NG Button Flag", value: "🆖" },
    { label: "O Button (blood Type) Flag", value: "🅾️" },
    { label: "OK Button Flag", value: "🆗" },
    { label: "P Button Flag", value: "🅿️" },
    { label: "SOS Button Flag", value: "🆘" },
    { label: "UP! Button Flag", value: "🆙" },
    { label: "VS Button Flag", value: "🆚" },
    { label: "Japanese “here” Button Flag", value: "🈁" },
    { label: "Japanese “service Charge” Button Flag", value: "🈂️" },
    { label: "Japanese “monthly Amount” Button Flag", value: "🈷️" },
    { label: "Japanese “not Free Of Charge” Button Flag", value: "🈶" },
    { label: "Japanese “reserved” Button Flag", value: "🈯" },
    { label: "Japanese “bargain” Button Flag", value: "🉐" },
    { label: "Japanese “discount” Button Flag", value: "🈹" },
    { label: "Japanese “free Of Charge” Button Flag", value: "🈚" },
    { label: "Japanese “prohibited” Button Flag", value: "🈲" },
    { label: "Japanese “acceptable” Button Flag", value: "🉑" },
    { label: "Japanese “application” Button Flag", value: "🈸" },
    { label: "Japanese “passing Grade” Button Flag", value: "🈴" },
    { label: "Japanese “vacancy” Button Flag", value: "🈳" },
    { label: "Japanese “congratulations” Button Flag", value: "㊗️" },
    { label: "Japanese “secret” Button Flag", value: "㊙️" },
    { label: "Japanese “open For Business” Button Flag", value: "🈺" },
    { label: "Japanese “no Vacancy” Button Flag", value: "🈵" },
    { label: "Red Circle Flag", value: "🔴" },
    { label: "Orange Circle Flag", value: "🟠" },
    { label: "Yellow Circle Flag", value: "🟡" },
    { label: "Green Circle Flag", value: "🟢" },
    { label: "Blue Circle Flag", value: "🔵" },
    { label: "Purple Circle Flag", value: "🟣" },
    { label: "Brown Circle Flag", value: "🟤" },
    { label: "Black Circle Flag", value: "⚫" },
    { label: "White Circle Flag", value: "⚪" },
    { label: "Red Square Flag", value: "🟥" },
    { label: "Orange Square Flag", value: "🟧" },
    { label: "Yellow Square Flag", value: "🟨" },
    { label: "Green Square Flag", value: "🟩" },
    { label: "Blue Square Flag", value: "🟦" },
    { label: "Purple Square Flag", value: "🟪" },
    { label: "Brown Square Flag", value: "🟫" },
    { label: "Black Large Square Flag", value: "⬛" },
    { label: "White Large Square Flag", value: "⬜" },
    { label: "Black Medium Square Flag", value: "◼️" },
    { label: "White Medium Square Flag", value: "◻️" },
    { label: "Black Medium-small Square Flag", value: "◾" },
    { label: "White Medium-small Square Flag", value: "◽" },
    { label: "Large Orange Diamond Flag", value: "🔶" },
    { label: "Large Blue Diamond Flag", value: "🔷" },
    { label: "Small Orange Diamond Flag", value: "🔸" },
    { label: "Small Blue Diamond Flag", value: "🔹" },
    { label: "Red Triangle Pointed Up Flag", value: "🔺" },
    { label: "Red Triangle Pointed Down Flag", value: "🔻" },
    { label: "Diamond With A Dot Flag", value: "💠" },
    { label: "Radio Button Flag", value: "🔘" },
    { label: "White Square Button Flag", value: "🔳" },
    { label: "Black Square Button Flag", value: "🔲" },
  ],
  Flags: [
    { label: "Chequered Flag", value: "🏁" },
    { label: "Triangular Flag", value: "🚩" },
    { label: "Crossed Flags", value: "🎌" },
    { label: "Black Flag", value: "🏴" },
    { label: "White Flag", value: "🏳️" },
    { label: "Rainbow Flag", value: "🏳️‍🌈" },
    { label: "Transgender Flag", value: "🏳️‍⚧️" },
    { label: "Pirate Flag", value: "🏴‍☠️" },
    { label: "Ascension Island Flag", value: "🇦🇨" },
    { label: "Andorra Flag", value: "🇦🇩" },
    { label: "United Arab Emirates Flag", value: "🇦🇪" },
    { label: "Afghanistan Flag", value: "🇦🇫" },
    { label: "Antigua & Barbuda Flag", value: "🇦🇬" },
    { label: "Anguilla Flag", value: "🇦🇮" },
    { label: "Albania Flag", value: "🇦🇱" },
    { label: "Armenia Flag", value: "🇦🇲" },
    { label: "Angola Flag", value: "🇦🇴" },
    { label: "Antarctica Flag", value: "🇦🇶" },
    { label: "Argentina Flag", value: "🇦🇷" },
    { label: "American Samoa Flag", value: "🇦🇸" },
    { label: "Austria Flag", value: "🇦🇹" },
    { label: "Australia Flag", value: "🇦🇺" },
    { label: "Aruba Flag", value: "🇦🇼" },
    { label: "Åland Islands Flag", value: "🇦🇽" },
    { label: "Azerbaijan Flag", value: "🇦🇿" },
    { label: "Bosnia & Herzegovina Flag", value: "🇧🇦" },
    { label: "Barbados Flag", value: "🇧🇧" },
    { label: "Bangladesh Flag", value: "🇧🇩" },
    { label: "Belgium Flag", value: "🇧🇪" },
    { label: "Burkina Faso Flag", value: "🇧🇫" },
    { label: "Bulgaria Flag", value: "🇧🇬" },
    { label: "Bahrain Flag", value: "🇧🇭" },
    { label: "Burundi Flag", value: "🇧🇮" },
    { label: "Benin Flag", value: "🇧🇯" },
    { label: "St. Barthélemy Flag", value: "🇧🇱" },
    { label: "Bermuda Flag", value: "🇧🇲" },
    { label: "Brunei Flag", value: "🇧🇳" },
    { label: "Bolivia Flag", value: "🇧🇴" },
    { label: "Caribbean Netherlands Flag", value: "🇧🇶" },
    { label: "Brazil Flag", value: "🇧🇷" },
    { label: "Bahamas Flag", value: "🇧🇸" },
    { label: "Bhutan Flag", value: "🇧🇹" },
    { label: "Bouvet Island Flag", value: "🇧🇻" },
    { label: "Botswana Flag", value: "🇧🇼" },
    { label: "Belarus Flag", value: "🇧🇾" },
    { label: "Belize Flag", value: "🇧🇿" },
    { label: "Canada Flag", value: "🇨🇦" },
    { label: "Cocos (Keeling) Islands Flag", value: "🇨🇨" },
    { label: "Congo - Kinshasa Flag", value: "🇨🇩" },
    { label: "Central African Republic Flag", value: "🇨🇫" },
    { label: "Congo - Brazzaville Flag", value: "🇨🇬" },
    { label: "Switzerland Flag", value: "🇨🇭" },
    { label: "Côte D’Ivoire Flag", value: "🇨🇮" },
    { label: "Cook Islands Flag", value: "🇨🇰" },
    { label: "Chile Flag", value: "🇨🇱" },
    { label: "Cameroon Flag", value: "🇨🇲" },
    { label: "China Flag", value: "🇨🇳" },
    { label: "Colombia Flag", value: "🇨🇴" },
    { label: "Clipperton Island Flag", value: "🇨🇵" },
    { label: "Costa Rica Flag", value: "🇨🇷" },
    { label: "Cuba Flag", value: "🇨🇺" },
    { label: "Cape Verde Flag", value: "🇨🇻" },
    { label: "Curaçao Flag", value: "🇨🇼" },
    { label: "Christmas Island Flag", value: "🇨🇽" },
    { label: "Cyprus Flag", value: "🇨🇾" },
    { label: "Czechia Flag", value: "🇨🇿" },
    { label: "Germany Flag", value: "🇩🇪" },
    { label: "Diego Garcia Flag", value: "🇩🇬" },
    { label: "Djibouti Flag", value: "🇩🇯" },
    { label: "Denmark Flag", value: "🇩🇰" },
    { label: "Dominica Flag", value: "🇩🇲" },
    { label: "Dominican Republic Flag", value: "🇩🇴" },
    { label: "Algeria Flag", value: "🇩🇿" },
    { label: "Ceuta & Melilla Flag", value: "🇪🇦" },
    { label: "Ecuador Flag", value: "🇪🇨" },
    { label: "England Flag", value: "🏴󠁧󠁢󠁥󠁮󠁧󠁿" },
    { label: "Estonia Flag", value: "🇪🇪" },
    { label: "Egypt Flag", value: "🇪🇬" },
    { label: "Western Sahara Flag", value: "🇪🇭" },
    { label: "Eritrea Flag", value: "🇪🇷" },
    { label: "Spain Flag", value: "🇪🇸" },
    { label: "Ethiopia Flag", value: "🇪🇹" },
    { label: "European Union Flag", value: "🇪🇺" },
    { label: "Finland Flag", value: "🇫🇮" },
    { label: "Fiji Flag", value: "🇫🇯" },
    { label: "Falkland Islands Flag", value: "🇫🇰" },
    { label: "Micronesia Flag", value: "🇫🇲" },
    { label: "Faroe Islands Flag", value: "🇫🇴" },
    { label: "France Flag", value: "🇫🇷" },
    { label: "Gabon Flag", value: "🇬🇦" },
    { label: "United Kingdom Flag", value: "🇬🇧" },
    { label: "Grenada Flag", value: "🇬🇩" },
    { label: "Georgia Flag", value: "🇬🇪" },
    { label: "French Guiana Flag", value: "🇬🇫" },
    { label: "Guernsey Flag", value: "🇬🇬" },
    { label: "Ghana Flag", value: "🇬🇭" },
    { label: "Gibraltar Flag", value: "🇬🇮" },
    { label: "Greenland Flag", value: "🇬🇱" },
    { label: "Gambia Flag", value: "🇬🇲" },
    { label: "Guinea Flag", value: "🇬🇳" },
    { label: "Guadeloupe Flag", value: "🇬🇵" },
    { label: "Equatorial Guinea Flag", value: "🇬🇶" },
    { label: "Greece Flag", value: "🇬🇷" },
    { label: "South Georgia & South Sandwich Islands Flag", value: "🇬🇸" },
    { label: "Guatemala Flag", value: "🇬🇹" },
    { label: "Guam Flag", value: "🇬🇺" },
    { label: "Guinea-Bissau Flag", value: "🇬🇼" },
    { label: "Guyana Flag", value: "🇬🇾" },
    { label: "Hong Kong SAR China Flag", value: "🇭🇰" },
    { label: "Heard & McDonald Islands Flag", value: "🇭🇲" },
    { label: "Honduras Flag", value: "🇭🇳" },
    { label: "Croatia Flag", value: "🇭🇷" },
    { label: "Haiti Flag", value: "🇭🇹" },
    { label: "Hungary Flag", value: "🇭🇺" },
    { label: "Canary Islands Flag", value: "🇮🇨" },
    { label: "Indonesia Flag", value: "🇮🇩" },
    { label: "Ireland Flag", value: "🇮🇪" },
    { label: "Israel Flag", value: "🇮🇱" },
    { label: "Isle Of Man Flag", value: "🇮🇲" },
    { label: "India Flag", value: "🇮🇳" },
    { label: "British Indian Ocean Territory Flag", value: "🇮🇴" },
    { label: "Iraq Flag", value: "🇮🇶" },
    { label: "Iran Flag", value: "🇮🇷" },
    { label: "Iceland Flag", value: "🇮🇸" },
    { label: "Italy Flag", value: "🇮🇹" },
    { label: "Jersey Flag", value: "🇯🇪" },
    { label: "Jamaica Flag", value: "🇯🇲" },
    { label: "Jordan Flag", value: "🇯🇴" },
    { label: "Japan Flag", value: "🇯🇵" },
    { label: "Kenya Flag", value: "🇰🇪" },
    { label: "Kyrgyzstan Flag", value: "🇰🇬" },
    { label: "Cambodia Flag", value: "🇰🇭" },
    { label: "Kiribati Flag", value: "🇰🇮" },
    { label: "Comoros Flag", value: "🇰🇲" },
    { label: "St. Kitts & Nevis Flag", value: "🇰🇳" },
    { label: "North Korea Flag", value: "🇰🇵" },
    { label: "South Korea Flag", value: "🇰🇷" },
    { label: "Kuwait Flag", value: "🇰🇼" },
    { label: "Cayman Islands Flag", value: "🇰🇾" },
    { label: "Kazakhstan Flag", value: "🇰🇿" },
    { label: "Laos Flag", value: "🇱🇦" },
    { label: "Lebanon Flag", value: "🇱🇧" },
    { label: "St. Lucia Flag", value: "🇱🇨" },
    { label: "Liechtenstein Flag", value: "🇱🇮" },
    { label: "Sri Lanka Flag", value: "🇱🇰" },
    { label: "Liberia Flag", value: "🇱🇷" },
    { label: "Lesotho Flag", value: "🇱🇸" },
    { label: "Lithuania Flag", value: "🇱🇹" },
    { label: "Luxembourg Flag", value: "🇱🇺" },
    { label: "Latvia Flag", value: "🇱🇻" },
    { label: "Libya Flag", value: "🇱🇾" },
    { label: "Morocco Flag", value: "🇲🇦" },
    { label: "Monaco Flag", value: "🇲🇨" },
    { label: "Moldova Flag", value: "🇲🇩" },
    { label: "Montenegro Flag", value: "🇲🇪" },
    { label: "St. Martin Flag", value: "🇲🇫" },
    { label: "Madagascar Flag", value: "🇲🇬" },
    { label: "Marshall Islands Flag", value: "🇲🇭" },
    { label: "North Macedonia Flag", value: "🇲🇰" },
    { label: "Mali Flag", value: "🇲🇱" },
    { label: "Myanmar (Burma) Flag", value: "🇲🇲" },
    { label: "Mongolia Flag", value: "🇲🇳" },
    { label: "Macao SAR China Flag", value: "🇲🇴" },
    { label: "Northern Mariana Islands Flag", value: "🇲🇵" },
    { label: "Martinique Flag", value: "🇲🇶" },
    { label: "Mauritania Flag", value: "🇲🇷" },
    { label: "Montserrat Flag", value: "🇲🇸" },
    { label: "Malta Flag", value: "🇲🇹" },
    { label: "Mauritius Flag", value: "🇲🇺" },
    { label: "Maldives Flag", value: "🇲🇻" },
    { label: "Malawi Flag", value: "🇲🇼" },
    { label: "Mexico Flag", value: "🇲🇽" },
    { label: "Malaysia Flag", value: "🇲🇾" },
    { label: "Mozambique Flag", value: "🇲🇿" },
    { label: "Namibia Flag", value: "🇳🇦" },
    { label: "New Caledonia Flag", value: "🇳🇨" },
    { label: "Niger Flag", value: "🇳🇪" },
    { label: "Norfolk Island Flag", value: "🇳🇫" },
    { label: "Nigeria Flag", value: "🇳🇬" },
    { label: "Nicaragua Flag", value: "🇳🇮" },
    { label: "Netherlands Flag", value: "🇳🇱" },
    { label: "Norway Flag", value: "🇳🇴" },
    { label: "Nepal Flag", value: "🇳🇵" },
    { label: "Nauru Flag", value: "🇳🇷" },
    { label: "Niue Flag", value: "🇳🇺" },
    { label: "New Zealand Flag", value: "🇳🇿" },
    { label: "Oman Flag", value: "🇴🇲" },
    { label: "Panama Flag", value: "🇵🇦" },
    { label: "Peru Flag", value: "🇵🇪" },
    { label: "French Polynesia Flag", value: "🇵🇫" },
    { label: "Papua New Guinea Flag", value: "🇵🇬" },
    { label: "Philippines Flag", value: "🇵🇭" },
    { label: "Pakistan Flag", value: "🇵🇰" },
    { label: "Poland Flag", value: "🇵🇱" },
    { label: "St. Pierre & Miquelon Flag", value: "🇵🇲" },
    { label: "Pitcairn Islands Flag", value: "🇵🇳" },
    { label: "Puerto Rico Flag", value: "🇵🇷" },
    { label: "Palestinian Territories Flag", value: "🇵🇸" },
    { label: "Portugal Flag", value: "🇵🇹" },
    { label: "Palau Flag", value: "🇵🇼" },
    { label: "Paraguay Flag", value: "🇵🇾" },
    { label: "Qatar Flag", value: "🇶🇦" },
    { label: "Réunion Flag", value: "🇷🇪" },
    { label: "Romania Flag", value: "🇷🇴" },
    { label: "Serbia Flag", value: "🇷🇸" },
    { label: "Russia Flag", value: "🇷🇺" },
    { label: "Rwanda Flag", value: "🇷🇼" },
    { label: "Saudi Arabia Flag", value: "🇸🇦" },
    { label: "Scotland Flag", value: "🏴󠁧󠁢󠁳󠁣󠁴󠁿" },
    { label: "Solomon Islands Flag", value: "🇸🇧" },
    { label: "Seychelles Flag", value: "🇸🇨" },
    { label: "Sudan Flag", value: "🇸🇩" },
    { label: "Sweden Flag", value: "🇸🇪" },
    { label: "Singapore Flag", value: "🇸🇬" },
    { label: "St. Helena Flag", value: "🇸🇭" },
    { label: "Slovenia Flag", value: "🇸🇮" },
    { label: "Svalbard & Jan Mayen Flag", value: "🇸🇯" },
    { label: "Slovakia Flag", value: "🇸🇰" },
    { label: "Sierra Leone Flag", value: "🇸🇱" },
    { label: "San Marino Flag", value: "🇸🇲" },
    { label: "Senegal Flag", value: "🇸🇳" },
    { label: "Somalia Flag", value: "🇸🇴" },
    { label: "Suriname Flag", value: "🇸🇷" },
    { label: "South Sudan Flag", value: "🇸🇸" },
    { label: "São Tomé & Príncipe Flag", value: "🇸🇹" },
    { label: "El Salvador Flag", value: "🇸🇻" },
    { label: "Sint Maarten Flag", value: "🇸🇽" },
    { label: "Syria Flag", value: "🇸🇾" },
    { label: "Eswatini Flag", value: "🇸🇿" },
    { label: "Tristan Da Cunha Flag", value: "🇹🇦" },
    { label: "Turks & Caicos Islands Flag", value: "🇹🇨" },
    { label: "Chad Flag", value: "🇹🇩" },
    { label: "French Southern Territories Flag", value: "🇹🇫" },
    { label: "Togo Flag", value: "🇹🇬" },
    { label: "Thailand Flag", value: "🇹🇭" },
    { label: "Tajikistan Flag", value: "🇹🇯" },
    { label: "Tokelau Flag", value: "🇹🇰" },
    { label: "Timor-Leste Flag", value: "🇹🇱" },
    { label: "Turkmenistan Flag", value: "🇹🇲" },
    { label: "Tunisia Flag", value: "🇹🇳" },
    { label: "Tonga Flag", value: "🇹🇴" },
    { label: "Turkey Flag", value: "🇹🇷" },
    { label: "Trinidad & Tobago Flag", value: "🇹🇹" },
    { label: "Tuvalu Flag", value: "🇹🇻" },
    { label: "Taiwan Flag", value: "🇹🇼" },
    { label: "Tanzania Flag", value: "🇹🇿" },
    { label: "Ukraine Flag", value: "🇺🇦" },
    { label: "Uganda Flag", value: "🇺🇬" },
    { label: "U.S. Outlying Islands Flag", value: "🇺🇲" },
    { label: "United Nations Flag", value: "🇺🇳" },
    { label: "United States Flag", value: "🇺🇸" },
    { label: "Uruguay Flag", value: "🇺🇾" },
    { label: "Uzbekistan Flag", value: "🇺🇿" },
    { label: "Vatican City Flag", value: "🇻🇦" },
    { label: "St. Vincent & Grenadines Flag", value: "🇻🇨" },
    { label: "Venezuela Flag", value: "🇻🇪" },
    { label: "British Virgin Islands Flag", value: "🇻🇬" },
    { label: "U.S. Virgin Islands Flag", value: "🇻🇮" },
    { label: "Vietnam Flag", value: "🇻🇳" },
    { label: "Vanuatu Flag", value: "🇻🇺" },
    { label: "Wallis & Futuna Flag", value: "🇼🇫" },
    { label: "Samoa Flag", value: "🇼🇸" },
    { label: "Kosovo Flag", value: "🇽🇰" },
    { label: "Wales Flag", value: "🏴󠁧󠁢󠁷󠁬󠁳󠁿" },
    { label: "Yemen Flag", value: "🇾🇪" },
    { label: "Mayotte Flag", value: "🇾🇹" },
    { label: "South Africa Flag", value: "🇿🇦" },
    { label: "Zambia Flag", value: "🇿🇲" },
    { label: "Zimbabwe Flag", value: "🇿🇼" },
  ],
};
