/* eslint-disable react-hooks/exhaustive-deps */
import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import Button from "Core/components/Button";
import React, { useState, useEffect } from "react";
import ArrowLeftIcon from "Core/components/Icon/arrowLeft";
import ArrowRightIcon from "Core/components/Icon/arrowRight";

type Props = {
  pageSize: number;
  currentPage: number;
  totalRecords?: number;
  showOnSinglePage?: boolean;
  onPageChange: (page: number) => void;
};

const TablePagination: React.FC<Props> = ({
  pageSize,
  currentPage,
  onPageChange,
  totalRecords = 0,
  showOnSinglePage = true,
}) => {
  const totalPages = Math.ceil(totalRecords / pageSize) || 1;
  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const generatePages = () => {
    if (totalPages < 8) {
      setVisiblePages(Array.from({ length: totalPages }, (_, index) => index + 1));
    } else {
      if (currentPage < 5) {
        setVisiblePages([1, 2, 3, 4, 5, 0, totalPages - 1, totalPages]);
      } else if (currentPage > totalPages - 5) {
        setVisiblePages([1, 2, 0, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
      } else {
        setVisiblePages([1, 2, 0, currentPage - 1, currentPage, currentPage + 1, 0, totalPages - 1, totalPages]);
      }
    }
  };

  const onPageClick = (e: number) => () => {
    if (e !== 0) onPageChange(e);
  };

  const onArrowClick = (e: number) => () => {
    onPageChange(currentPage + e);
  };

  useEffect(() => {
    generatePages();
  }, [currentPage, totalRecords, pageSize]);

  return showOnSinglePage || (!showOnSinglePage && totalPages > 1) ? (
    <div className={styles.pagination}>
      <Button before={<ArrowLeftIcon />} disabled={currentPage === 1} onClick={onArrowClick(-1)}>
        <span className={styles.text}>Previous</span>
      </Button>
      <div className={styles.paginationPages}>
        {visiblePages.map((x, i) => (
          <div
            key={`${x}-${i}`}
            onClick={onPageClick(x)}
            className={cx(styles.paginationPage, [styles.dot, x === 0], [styles.selected, currentPage === x])}
          >
            {x || "..."}
          </div>
        ))}
      </div>
      <div className={styles.mobile}>
        Page <span>{currentPage}</span> of <span>{totalPages}</span>
      </div>
      <Button after={<ArrowRightIcon />} disabled={currentPage === totalPages} onClick={onArrowClick(1)}>
        <span className={styles.text}>Next</span>
      </Button>
    </div>
  ) : (
    <></>
  );
};

export default TablePagination;
