import { useEffect } from "react";
import useAuth from "Auth/context";
import { useMounted } from "Core/hooks";
import RootLayoutHeader from "./Header";
import RootLayoutFooter from "./Footer";
import styles from "./styles.module.scss";
import { FullscreenLoading } from "Core/components";
import { Outlet, useLocation } from "react-router-dom";

const RootLayout: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    window.scroll({ top: -1, left: 0 });
  }, [location]);

  useMounted(() => {
    auth.get().catch(() => {
      auth.logout();
    });
  });

  return auth.loading || auth.user === undefined ? (
    <FullscreenLoading loading />
  ) : (
    <div className={styles.rootLayout}>
      <RootLayoutHeader />
      <Outlet />
      <RootLayoutFooter />
    </div>
  );
};

export default RootLayout;
