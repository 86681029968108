import { useEffect } from "react";

type Props = (callback: () => void) => void;

const useUnmounted: Props = (callback) => {
  useEffect(() => {
    return () => {
      callback();
    };
  }, []);
};

export default useUnmounted;
