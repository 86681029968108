import cx from "Core/utils/cx";
import basicButtonTypes from "./Types";
import styles from "./styles.module.scss";
import ExternalLink from "Core/components/ExternalLink";
import { BasicButton } from "Content/types/ContentEditor/BasicButton";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<BasicButton>;

const BasicButtonView: React.FC<Props> = ({ value }) => {
  const Component = basicButtonTypes[value.data.type];

  return (
    <div style={{ textAlign: value.data.style.textAlign }} className={cx(styles.container, styles[value.data.type])}>
      <ExternalLink href={value.data.link} target="_blank" params>
        <Component value={value} />
      </ExternalLink>
    </div>
  );
};

export default BasicButtonView;
