import { IconComponent } from "Core/types/Icon";

const DraftjsVideoIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.875 9C0.875 4.5142 4.5142 0.875 9 0.875C13.4858 0.875 17.125 4.5142 17.125 9C17.125 13.4858 13.4858 17.125 9 17.125C4.5142 17.125 0.875 13.4858 0.875 9ZM9 2.125C5.20455 2.125 2.125 5.20455 2.125 9C2.125 12.7954 5.20455 15.875 9 15.875C12.7954 15.875 15.875 12.7954 15.875 9C15.875 5.20455 12.7954 2.125 9 2.125Z"
      fill="currentColor"
    />
    <path
      d="M7.45001 12.064L11.9207 9.36319C11.983 9.32523 12.0345 9.27187 12.0703 9.20825C12.106 9.14463 12.1248 9.07288 12.1248 8.99991C12.1248 8.92694 12.106 8.8552 12.0703 8.79157C12.0345 8.72795 11.983 8.6746 11.9207 8.63663L7.45001 5.93584C7.38593 5.89745 7.31278 5.8768 7.23809 5.876C7.16339 5.8752 7.08982 5.89429 7.02494 5.93131C6.96005 5.96832 6.90618 6.02193 6.86885 6.08664C6.83152 6.15134 6.81207 6.22481 6.81251 6.29952V11.7003C6.81207 11.775 6.83152 11.8485 6.86885 11.9132C6.90618 11.9779 6.96005 12.0315 7.02494 12.0685C7.08982 12.1055 7.16339 12.1246 7.23809 12.1238C7.31278 12.123 7.38593 12.1024 7.45001 12.064Z"
      fill="currentColor"
    />
  </svg>
);

export default DraftjsVideoIcon;
