import { IconComponent } from "Core/types/Icon";

const StrikeThroughIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 0.359985H16.4V7.79999H5.90001V0.359985H3.80001V7.79999H0.200012V10.2H3.80001C3.84601 14.898 6.55001 17.64 11.15 17.64C15.75 17.64 18.454 14.898 18.5 10.2H21.8V7.79999H18.5V0.359985ZM16.4 10.2V7.79999H18.5V10.2H16.4ZM16.4 10.2C16.36 13.699 14.59 15.48 11.15 15.48C7.71001 15.48 5.94001 13.698 5.90001 10.2H16.4ZM5.90001 10.2V7.79999H3.80001V10.2H5.90001V10.2Z"
      fill="currentColor"
    />
  </svg>
);

export default StrikeThroughIcon;
