import styles from "./styles.module.scss";
import { useState, KeyboardEvent } from "react";
import { Button, Input } from "Core/components";
import CloseIcon from "Core/components/Icon/close";

type ForumThreadCreateTagsProps = {
  label?: string;
  value: string[];
  placeholder?: string;
  onChange: (e: string[]) => void;
};

const ForumThreadCreateTags: React.FC<ForumThreadCreateTagsProps> = ({ value, label, placeholder, onChange }) => {
  const [text, setText] = useState("");
  const exists = value.includes(text);

  const onAdd = () => {
    if (!exists) {
      onChange([...value, text]);
      setText("");
    }
  };

  const onEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !exists) {
      e.preventDefault();
      onAdd();
    }
  };

  const onRemove = (i: number) => () => {
    value.splice(i, 1);
    onChange([...value]);
  };

  const after = text ? (
    <Button className={styles.forumThreadCreateTagsAdd} onClick={onAdd} disabled={exists}>
      Add
    </Button>
  ) : undefined;

  return (
    <div className={styles.forumThreadCreateTags}>
      <Input
        value={text}
        after={after}
        maxLength={16}
        showLength={false}
        onChange={setText}
        onKeyDown={onEnter}
        label={`${label}*`}
        placeholder={placeholder}
        className={styles.forumThreadCreateTagsInput}
      />
      {!!value.length && (
        <div className={styles.forumThreadCreateTagsList}>
          {value.map((x, i) => (
            <Button
              key={x}
              variant="outline"
              onClick={onRemove(i)}
              after={<CloseIcon />}
              className={styles.forumThreadCreateTagsListItem}
            >
              {x}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ForumThreadCreateTags;
