const getMonthName = (isoDateString: string): string => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date(isoDateString);
  const monthIndex = date.getUTCMonth();
  return months[monthIndex];
};

export default getMonthName;
