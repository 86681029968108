import modalOpen from ".";
import CloseCircleIcon from "Core/components/Icon/closeCircle";

type Payload = {
  ok?: string;
  width?: number;
  title?: string;
  onOk?: () => void;
  subtitle?: string;
  closeOnOk?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  closeOnCancel?: boolean;
  cancel?: string | boolean;
};

type Props = (payload: Payload) => Promise<void>;

const modalOpenError: Props = ({ ...rest }) => {
  return modalOpen({ ...rest, icon: <CloseCircleIcon style={{ color: "#FB0000" }} /> });
};

export default modalOpenError;
