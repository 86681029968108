import { IconComponent } from "Core/types/Icon";

const InfoIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.12"
      d="M36 66C52.5685 66 66 52.5685 66 36C66 19.4315 52.5685 6 36 6C19.4315 6 6 19.4315 6 36C6 52.5685 19.4315 66 36 66Z"
      fill="currentColor"
    />
    <path
      d="M36 46.8V36M36 25.2H36.027M63 36C63 50.9117 50.9117 63 36 63C21.0883 63 9 50.9117 9 36C9 21.0883 21.0883 9 36 9C50.9117 9 63 21.0883 63 36Z"
      stroke="currentColor"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default InfoIcon;
