import { IconComponent } from "Core/types/Icon";

const TextImageIcon: IconComponent = (props) => (
    <svg width="240" height="16" viewBox="0 0 240 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
        <circle cx="120" cy="8" r="8" fill="white" fillOpacity="0.9" />
        <path d="M134 5L240 8L134 11V5Z" fill="white" fillOpacity="0.5" />
        <path d="M106 5L0 8L106 11V5Z" fill="white" fillOpacity="0.5" />
    </svg>

);

export default TextImageIcon;
