import cx from "Core/utils/cx";
import { Img } from "Core/components";
import { CSSProperties } from "react";
import Modal from "Core/components/Modal";
import styles from "../styles.module.scss";
import Avatar from "Core/components/Avatar";
import Button from "Core/components/Button";
import imageSrc from "Core/utils/url/imageSrc";
import ExternalLink from "Core/components/ExternalLink";
import { Media } from "Content/types/ContentEditor/Media";
import { ContentItem } from "Content/types/ContentEditor/Item";
import ExternalLinkIcon from "Core/components/Icon/externalLink";

type Props = {
  visible: boolean;
  onClose: () => void;
  value: ContentItem<Media>;
  containerStyle: CSSProperties;
};

const MediaViewModal: React.FC<Props> = ({ value, containerStyle, visible, onClose }) => {
  const { style, text, url, user } = value.data;

  const onExternalClick = () => {
    window.open(user?.image_url, "_blank");
  };

  return (
    <Modal visible={visible} onClose={onClose} width={1200} contentClassName={styles.modal}>
      {user && (
        <div className={styles.user}>
          <Avatar value={user.profile_image} className={styles.avatar} size={36} />
          <div className={styles.username}>{user.name || user.username}</div>
          <Button variant="text" onClick={onExternalClick} className={styles.external}>
            <ExternalLinkIcon />
          </Button>
        </div>
      )}
      <div
        className={cx(styles.container, styles.modalContainer)}
        style={{ ...containerStyle, aspectRatio: `${style.width} / ${style.height}` }}
      >
        <ExternalLink href={value.data.link} target="_blank" params>
          <Img
            w={style.width}
            h={style.height}
            src={imageSrc(url)}
            style={{ aspectRatio: `${style.width} / ${style.height}` }}
          />
          {text.type !== "none" && (
            <div className={cx(styles.text, styles[text.type])}>
              <p>
                <span>{text.value}</span>
              </p>
            </div>
          )}
        </ExternalLink>
      </div>
      <div className={styles.closeButton}>
        <Button variant="secondary" onClick={onClose} block>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default MediaViewModal;
