import { cx } from "Core/utils";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import ArrowUpIcon from "Core/components/Icon/arrowUp";
import ArrowDownIcon from "Core/components/Icon/arrowDown";

type FaqListCardProps = {
  type: string;
  answer: string;
  active: boolean;
  question: string;
  className?: string;
  onClick: () => void;
};

const FaqListCard: React.FC<FaqListCardProps> = ({ className, question, answer, type, active, onClick }) => {
  return (
    <div className={cx(styles.faqListCard, className)} onClick={onClick}>
      <div className={styles.faqListCardHeader}>
        <div className={styles.faqListCardHeaderTitle}>{question}</div>
        <div className={styles.faqListCardHeaderRight}>
          <div className={styles.faqListCardHeaderTag}>{type}</div>
          <Button>{active ? <ArrowUpIcon /> : <ArrowDownIcon />}</Button>
        </div>
      </div>
      {active && <div className={styles.faqListCardContent}>{answer}</div>}
    </div>
  );
};

export default FaqListCard;
