const colors = [
  "rgb(255,255,255)",
  "rgb(0,0,0)",
  "rgb(255,224,103)",
  "rgb(255,124,83)",
  "rgb(245,70,70)",
  "rgb(93,249,143)",
  "rgb(93,233,255)",
  "rgb(255,199,94)",
  "rgb(96,255,204)",
  "rgb(210,210,210)",
  "rgb(82,82,82)",
  "rgb(116,160,255)",
  "rgb(131,113,244)",
];

export default colors;
