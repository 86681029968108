import { useState } from "react";
import DraftjsImage from "./Image";
import { DraftjsImageState } from "Core/types/Draftjs";
import createImagePlugin from "@draft-js-plugins/image";

type Props = (enabled?: boolean) => DraftjsImageState | undefined;

const useImage: Props = (enabled) => {
  const [imageState] = useState<DraftjsImageState | undefined>(
    enabled
      ? () => {
          const imagePlugin = createImagePlugin();

          return {
            plugin: imagePlugin,
            Component: (props) => <DraftjsImage {...props} />,
          };
        }
      : undefined
  );

  return imageState;
};

export default useImage;
