import { dateUtils } from "Core/utils";
import { Logo } from "Core/components";
import styles from "./styles.module.scss";
import { NavigationMenu } from "Root/components";
import { footerMenuItems } from "Root/constants";

type RootLayoutFooterProps = {};

const RootLayoutFooter: React.FC<RootLayoutFooterProps> = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <Logo light />
        <NavigationMenu type="footer" className={styles.footerNavigation} />
        <div className={styles.footerDivider} />
        <div className={styles.footerSub}>
          <div className={styles.footerCopyright}>© {dateUtils.getYear()} RamblyHub. All rights reserved.</div>
          <div className={styles.footerMenu}>
            {footerMenuItems.map((x) => (
              <a key={x.value} href={x.value} rel="noreferrer" className={styles.footerMenuItem}>
                {x.label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default RootLayoutFooter;
