import useAuth from "Auth/context";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import PlusIcon from "Core/components/Icon/plus";

type AcademyCreateButtonProps = {};

const AcademyCreateButton: React.FC<AcademyCreateButtonProps> = () => {
  const auth = useAuth();

  return auth.user ? (
    <a href={`${process.env.REACT_APP_URL}blog/admin/create`} className={styles.academyCreateLink}>
      <Button before={<PlusIcon />} className={styles.academyCreateButton}>
        Create a Blog
      </Button>
    </a>
  ) : (
    <></>
  );
};

export default AcademyCreateButton;
