import api from "Core/api";
import { sleep } from "Core/utils";

type Response = void;

type ApiResponse = void;

type Props = (id: string) => Promise<Response>;

const subscribeAcademy: Props = async (id) => {
  await sleep(2000);
  return;

  await api.get<ApiResponse>(`academy/${id}/subscribe`);
  return;
};

export default subscribeAcademy;
