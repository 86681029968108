import forumApi from "Forum/api";
import { useState } from "react";
import ForumThreadMenu from "./Menu";
import { dateUtils } from "Core/utils";
import styles from "./styles.module.scss";
import ForumThreadComments from "./Comments";
import ForumThreadNoComment from "./NoComment";
import { useGet, useUpdated } from "Core/hooks";
import ForumThreadLikeButton from "./LikeButton";
import ForumThreadAddComment from "./AddComment";
import { ThreadCreateButton } from "Forum/components";
import ForumThreadCommentButton from "./CommentButton";
import ArrowLeftIcon from "Core/components/Icon/arrowLeft";
import DraftjsView from "Core/components/DraftjsEditor/View";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Avatar, Button, EmptyContainer, PageHeader } from "Core/components";

const ForumThreadPage: React.FC = () => {
  const navigate = useNavigate();
  const { topicId, threadId } = useParams();
  const [params, setParams] = useSearchParams();
  const [page, setPage] = useState(Number(params.get("p") || "1"));
  const thread = useGet(() =>
    forumApi.getThread(String(topicId), String(threadId), { limit: 10, offset: (page - 1) * 10 })
  );

  const updateParams = (newPage: number) => {
    setParams(
      (p) => {
        p.set("p", String(newPage));
        return p;
      },
      { replace: true }
    );
  };

  const onBack = () => {
    navigate(`/forum/${topicId}`);
  };

  useUpdated(() => {
    thread.request();
    updateParams(page);
  }, page);

  return (
    <div className={styles.forumThreadPage}>
      <EmptyContainer empty={!thread.data} loading={thread.loading}>
        {thread.data && (
          <>
            <PageHeader
              back
              title={thread.data.thread.title}
              subtitle="Share what other creators are curious about and what they have discovered."
              extra={<ThreadCreateButton topicId={String(topicId)} />}
            />
            <div className={styles.forumThreadPageContainer}>
              <div className={styles.forumThreadPageContent}>
                <div className={styles.forumThreadPageCard}>
                  <div className={styles.forumThreadPageCardContent}>
                    <div className={styles.forumThreadPageCardHeader}>
                      <Avatar value={thread.data.thread.avatar} className={styles.forumThreadPageAvatar} size={60} />
                      <div className={styles.forumThreadPageCardUser}>
                        <div className={styles.forumThreadPageName}>
                          {thread.data.thread.name || thread.data.thread.username}
                        </div>
                        <div className={styles.forumThreadPageDate}>
                          Posted {dateUtils.getDateString(thread.data.thread.modifiedAt)}
                        </div>
                      </div>
                      <ForumThreadMenu thread={thread.data.thread} />
                    </div>
                    <div className={styles.forumThreadPageMessage}>
                      <DraftjsView value={thread.data.thread.message} />
                    </div>
                    <div className={styles.forumThreadPageButtons}>
                      <ForumThreadLikeButton thread={thread.data.thread} />
                      {!thread.data.thread.noComment && <ForumThreadCommentButton thread={thread.data.thread} />}
                    </div>
                    {thread.data.thread.noComment ? (
                      <ForumThreadNoComment />
                    ) : (
                      <>
                        <ForumThreadComments
                          page={page}
                          setPage={setPage}
                          refresh={thread.request}
                          data={thread.data.items}
                          count={thread.data.count}
                        />
                        <ForumThreadAddComment thread={thread.data.thread} refresh={thread.request} />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.forumThreadPageFooter}>
                  <Button before={<ArrowLeftIcon />} onClick={onBack}>
                    Go to topic listing
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </EmptyContainer>
    </div>
  );
};

export default ForumThreadPage;
