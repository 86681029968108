import { Permission } from "Auth/types/Permission";

type Props = Record<Permission, number>;

const permissions: Props = {
  admin: 1,
  creator: 2,
  endUser: 3,
  temporary: 4,
};

export default permissions;
