type Props = (color?: string) => string;

const pad = (e: string) => Number(e).toString(16).substring(0, 2).padStart(2, "0");

const rgba2hex: Props = (color) => {
  if (!color) return "";
  if (color.indexOf("rgba") < 0) return color.replace("#", "");

  const [r, g, b, a] = color.replace("rgba(", "").replace(/ /g, "").replace(")", "").split(",");
  const alpha = Number(a || "1") * 255;

  return `${[pad(r), pad(g), pad(b), alpha < 255 ? pad(String(alpha)) : ""].join("").toUpperCase()}`;
};

export default rgba2hex;
