import api from "Core/api";
import { ForumThreadForm } from "Forum/types/Form";

type Response = string;

type ApiResponse = string;

type Props = (topicId: string, form: ForumThreadForm) => Promise<Response>;

const createThread: Props = async (id, form) => {
  const response = await api.post<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}topics/${id}/thread/create`, form);
  return response.data;
};

export default createThread;
