import { IconComponent } from "Core/types/Icon";

const ChevronRightIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.479987 21.014C0.202416 20.7313 0.0340295 20.3594 0.00473637 19.9643C-0.0245568 19.5692 0.0871403 19.1765 0.319987 18.856L0.479987 18.67L8.04799 11L0.477987 3.32997C0.200717 3.04704 0.0326983 2.67499 0.00377173 2.27991C-0.0251548 1.88482 0.0868829 1.49227 0.319987 1.17197L0.477987 0.985972C0.753335 0.705695 1.12111 0.534964 1.51291 0.505533C1.9047 0.476102 2.29386 0.589975 2.60799 0.825972L2.79199 0.985972L11.52 9.82797C12.1 10.416 12.153 11.338 11.678 11.986L11.52 12.172L2.79199 21.014C2.64109 21.1678 2.46104 21.2899 2.26239 21.3733C2.06373 21.4567 1.85044 21.4996 1.63499 21.4996C1.41954 21.4996 1.20625 21.4567 1.00759 21.3733C0.808929 21.2899 0.628887 21.1678 0.477987 21.014H0.479987Z"
      fill="currentColor"
    />
  </svg>
);
export default ChevronRightIcon;
