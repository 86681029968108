import { cx } from "Core/utils";
import styles from "./styles.module.scss";
import { useOuterClick } from "Core/hooks";
import Button from "Core/components/Button";
import CloseIcon from "Core/components/Icon/close";
import { forwardRef, ReactNode, useImperativeHandle, useRef, useState } from "react";

type Ref = { close: () => void };

type Props = {
  title?: string;
  disabled?: boolean;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
  children: ReactNode[];
  bottomSheet?: boolean;
  placement?: "top" | "bottom" | "topRight";
};

const Dropdown = forwardRef<Ref, Props>(
  ({ onOpen, onClose, children, className, title, disabled, placement = "bottom", bottomSheet = true }, ref) => {
    const [trigger, ...items] = children;
    const divRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    const onClick = () => {
      setVisible(false);
      onClose?.();
    };

    const onClosed = () => {
      setVisible(false);
      onClose?.();
    };

    const onToggle = () => {
      if (!disabled) {
        visible ? onClose?.() : onOpen?.();
        setVisible(!visible);
      }
    };

    useOuterClick(
      () => {
        setVisible(false);
        onClose?.();
      },
      divRef,
      ["dropdownBackground"]
    );

    useImperativeHandle(ref, () => ({ close: onClosed }));

    return (
      <div className={cx(styles.dropdown, [styles.dropdownVisible, visible], className)}>
        {bottomSheet && (
          <div
            onClick={onClosed}
            className={cx(styles.dropdownBackground, [styles.visible, visible], "dropdownBackground")}
          />
        )}
        <span className={styles.trigger} onClick={onToggle}>
          {trigger}
        </span>
        <div
          ref={divRef}
          role="dialog"
          className={cx(
            styles[placement],
            styles.dropdownOverlay,
            [styles.visible, visible],
            [styles.bottomSheet, bottomSheet]
          )}
        >
          {visible && (
            <div>
              {bottomSheet && (
                <div className={styles.dropdownOverlayHeader}>
                  {title && <div className={styles.dropdownOverlayTitle}>{title}</div>}
                  <Button onClick={onClosed} className={styles.dropdownOverlayClose}>
                    <CloseIcon />
                  </Button>
                </div>
              )}
              <div onClick={onClick} className={styles.list} role="group">
                {items}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export type { Ref as DropdownRef };

export default Dropdown;
