import forumApi from "Forum/api";
import useAuth from "Auth/context";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { ForumThread } from "Forum/types/Thread";
import MoreIcon from "Core/components/Icon/more";
import EditIcon from "Core/components/Icon/edit";
import DeleteIcon from "Core/components/Icon/delete";
import EyeOutlineIcon from "Core/components/Icon/eyeOutline";
import { Button, Dropdown, DropdownItem, Modal } from "Core/components";

type ForumThreadMenuProps = {
  thread: ForumThread;
};

const ForumThreadMenu: React.FC<ForumThreadMenuProps> = ({ thread }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const deleteThread = useRequest(forumApi.deleteThread);

  const onEdit = () => {
    navigate(`/forum/${thread.topicID}/thread/${thread._id}/edit`);
  };

  const onProfile = () => {
    window.open(`${process.env.REACT_APP_URL}${thread.username}`, "_blank");
  };

  const onDelete = () => {
    Modal.confirm({
      title: "Delete Comment",
      subtitle: "Are you sure you want to permanently remove your comment? You cannot undo this action.",
    }).then(() => {
      deleteThread.request(thread.topicID, thread._id).then(() => {
        navigate(-1);
      });
    });
  };

  return (
    <Dropdown className={styles.forumThreadMenu} bottomSheet={false}>
      <Button className={styles.forumThreadMenuButton} variant="light">
        <MoreIcon />
      </Button>
      {auth.user?.username === thread.username ? (
        <>
          <DropdownItem onClick={onEdit}>
            <EditIcon />
            Edit
          </DropdownItem>
          <DropdownItem onClick={onDelete} className={styles.forumThreadMenuButtonDanger}>
            <DeleteIcon />
            Delete
          </DropdownItem>
        </>
      ) : (
        <>
          <DropdownItem onClick={onProfile}>
            <EyeOutlineIcon />
            Show Profile
          </DropdownItem>
        </>
      )}
    </Dropdown>
  );
};

export default ForumThreadMenu;
