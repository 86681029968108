import { EditorState, AtomicBlockUtils } from "draft-js";

type Props = (state: EditorState, src: string) => EditorState;

const createImage: Props = (editorState, src) => {
  const contentState = editorState.getCurrentContent();
  const newContentState = contentState.createEntity("IMAGE", "IMMUTABLE", { src });

  var entityKey = newContentState.getLastCreatedEntityKey();
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

  return newEditorState;
};

export default createImage;
