import { useGet } from "Core/hooks";
import academyApi from "Academy/api";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Loading } from "Core/components";
import { AcademyCard } from "Academy/components";

type AcademyLatestProps = {
  slug: string;
};

const AcademyLatest: React.FC<AcademyLatestProps> = ({ slug }) => {
  const relatedAcademies = useGet(() => academyApi.getRelatedAcademies(slug));

  return (
    <div className={styles.academyLatest}>
      {relatedAcademies.loading ? (
        <Loading loading />
      ) : !!relatedAcademies.data?.length ? (
        <>
          <div className={styles.academyLatestTitle}>Related Academy</div>
          {relatedAcademies.data?.map((x) => (
            <Link key={x._id} to={`/academy/${x.slug}`}>
              <AcademyCard data={x} />
            </Link>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AcademyLatest;
