import Root from "Root";
import "Core/styles/main.scss";
import { createRoot } from "react-dom/client";
import AuthProvider from "Auth/context/provider";
import { BrowserRouter } from "react-router-dom";
import NotificationProvider from "Notification/context/provider";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <AuthProvider>
      <NotificationProvider>
        <Root />
        <div id="modalContainer" />
      </NotificationProvider>
    </AuthProvider>
  </BrowserRouter>
);
