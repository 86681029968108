import cx from "Core/utils/cx";
import { ReactNode } from "react";
import { Portal } from "Core/components";
import styles from "./styles.module.scss";
import LoadingIcon from "Core/components/Icon/loading";

type Props = {
  info?: string;
  loading: boolean;
  className?: string;
  children?: ReactNode;
};

const FullScreenLoading: React.FC<Props> = ({ loading, className, info, children }) => {
  return loading ? (
    <Portal container={document.getElementById("modalContainer")}>
      <div className={cx(styles.container, className)}>
        {children ? (
          <div className={styles.content}>{children}</div>
        ) : (
          <div className={styles.loaderContainer}>
            <LoadingIcon className={styles.loader} />
            {info && <div className={styles.info}>{info}</div>}
          </div>
        )}
      </div>
    </Portal>
  ) : (
    <></>
  );
};

export default FullScreenLoading;
