import useAuth from "Auth/context";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import EditIcon from "Core/components/Icon/edit";
import { permissions } from "Auth/constants";

type HowToEditButtonProps = {
  blogId: string;
};

const HowToEditButton: React.FC<HowToEditButtonProps> = ({ blogId }) => {
  const auth = useAuth();

  return auth.user && auth.user.role === permissions.admin ? (
    <div className={styles.academyEditLink}>
      <a href={`${process.env.REACT_APP_URL}blog/admin/${blogId}`}>
        <Button before={<EditIcon />} className={styles.academyEditButton}>
          Edit
        </Button>
      </a>
    </div>
  ) : (
    <></>
  );
};

export default HowToEditButton;
