import { IconComponent } from "Core/types/Icon";

const NotificationFilledIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17096 16.9433C6.47551 16.5983 7.00213 16.5655 7.34719 16.87C7.7884 17.2594 8.366 17.4948 9.00059 17.4948C9.63519 17.4948 10.2128 17.2594 10.654 16.87C10.9991 16.5655 11.5257 16.5983 11.8302 16.9433C12.1348 17.2884 12.102 17.815 11.7569 18.1196C11.0229 18.7674 10.0567 19.1615 9.00059 19.1615C7.94445 19.1615 6.97831 18.7674 6.2443 18.1196C5.89924 17.815 5.8664 17.2884 6.17096 16.9433Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.87581 2.53667C5.96977 1.44271 7.4535 0.828125 9.0006 0.828125C10.5477 0.828125 12.0314 1.44271 13.1254 2.53667C14.2193 3.63063 14.8339 5.11436 14.8339 6.66146C14.8339 9.08869 15.4439 10.698 16.0834 11.7257L16.0933 11.7416C16.391 12.2201 16.6264 12.5984 16.7851 12.8737C16.8646 13.0116 16.9371 13.1445 16.9907 13.2627C17.0175 13.3216 17.0474 13.3939 17.0708 13.4725C17.0903 13.5377 17.1273 13.6771 17.1134 13.8467C17.1041 13.9607 17.0808 14.1555 16.969 14.3567C16.8572 14.5579 16.7041 14.6806 16.6123 14.7487C16.4037 14.9035 16.1642 14.9388 16.0842 14.9506L16.0805 14.9512C15.9577 14.9694 15.8183 14.9784 15.6777 14.9839C15.3983 14.9948 15.0115 14.9948 14.5305 14.9948H3.47072C2.9897 14.9948 2.60291 14.9948 2.32353 14.9839C2.18291 14.9784 2.04351 14.9694 1.92067 14.9512L1.91698 14.9506C1.83704 14.9388 1.59751 14.9035 1.38893 14.7487C1.29707 14.6806 1.14396 14.5579 1.03217 14.3567C0.920375 14.1555 0.89713 13.9607 0.887806 13.8467C0.873933 13.6771 0.910934 13.5377 0.930356 13.4725C0.953765 13.3939 0.983717 13.3216 1.01049 13.2627C1.06413 13.1445 1.13657 13.0116 1.21609 12.8737C1.37478 12.5983 1.61018 12.22 1.90795 11.7415L1.91778 11.7257C2.55726 10.698 3.16726 9.08869 3.16726 6.66146C3.16726 5.11436 3.78184 3.63063 4.87581 2.53667Z"
      fill="currentColor"
    />
  </svg>
);

export default NotificationFilledIcon;
