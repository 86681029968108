import days from "./days";
import { CalendarDay } from "Core/types/DateTime";

type Props = (month: number, year: number, length: number) => CalendarDay[];

const nextDays: Props = (month, year, length) => {
  const nextMonth = month < 12 ? month + 1 : 1;
  const nextYear = month < 12 ? year : year + 1;

  const result = days(nextMonth, nextYear, true);

  return result.slice(0, length);
};

export default nextDays;
