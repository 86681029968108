import { IconComponent } from "Core/types/Icon";

const ArrowUpRightIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 14L14 2M14 2H6M14 2V10"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUpRightIcon;
