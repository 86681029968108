import { IconComponent } from "Core/types/Icon";

const AudioForwardIcon: IconComponent = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.9061 0.830464C17.9045 0.485289 17.6235 0.206718 17.2783 0.208258C16.9331 0.209798 16.6545 0.490866 16.6561 0.836041L16.6635 2.4908C16.6665 3.16532 16.6675 3.61839 16.6301 3.96117L16.6287 3.9739C16.2483 3.55572 15.8289 3.1737 15.3762 2.83357C13.8788 1.70852 12.0164 1.04159 9.99999 1.04159C5.05244 1.04159 1.04166 5.05237 1.04166 9.99992C1.04166 14.9475 5.05244 18.9583 9.99999 18.9583C14.9475 18.9583 18.9583 14.9475 18.9583 9.99992C18.9583 9.65474 18.6785 9.37492 18.3333 9.37492C17.9881 9.37492 17.7083 9.65474 17.7083 9.99992C17.7083 14.2571 14.2572 17.7083 9.99999 17.7083C5.74279 17.7083 2.29166 14.2571 2.29166 9.99992C2.29166 5.74272 5.74279 2.29159 9.99999 2.29159C11.7364 2.29159 13.337 2.86495 14.6254 3.83294C15.0412 4.14535 15.4244 4.49884 15.769 4.88737C15.7454 4.89053 15.7207 4.89352 15.695 4.89634C15.3522 4.93377 14.8991 4.9327 14.2246 4.92969L12.5698 4.9223C12.2246 4.92076 11.9436 5.19933 11.942 5.54451C11.9405 5.88968 12.2191 6.17075 12.5642 6.17229L14.2551 6.17984C14.8842 6.18266 15.4088 6.18501 15.8307 6.13895C16.2731 6.09063 16.6809 5.98264 17.0351 5.7085C17.0916 5.66478 17.1459 5.61848 17.198 5.56975C17.2863 5.48699 17.368 5.39725 17.4423 5.30133C17.7164 4.94709 17.8244 4.53932 17.8727 4.09687C17.9188 3.67506 17.9164 3.15042 17.9136 2.52136L17.9061 0.830464Z"
      fill="currentColor"
    />
    <path
      d="M8.12499 8.67843C8.12499 7.68671 6.92596 7.19006 6.22471 7.89131L5.39138 8.72464C5.1473 8.96872 5.1473 9.36445 5.39138 9.60853C5.63546 9.8526 6.03119 9.8526 6.27526 9.60853L6.87499 9.0088V13.3333C6.87499 13.6784 7.15481 13.9583 7.49999 13.9583C7.84517 13.9583 8.12499 13.6784 8.12499 13.3333V8.67843Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 7.70825C11.2343 7.70825 10.2083 8.73427 10.2083 9.99992V11.6666C10.2083 12.9322 11.2343 13.9583 12.5 13.9583C13.7656 13.9583 14.7917 12.9322 14.7917 11.6666V9.99992C14.7917 8.73427 13.7656 7.70825 12.5 7.70825ZM11.4583 9.99992C11.4583 9.42462 11.9247 8.95825 12.5 8.95825C13.0753 8.95825 13.5417 9.42462 13.5417 9.99992V11.6666C13.5417 12.2419 13.0753 12.7083 12.5 12.7083C11.9247 12.7083 11.4583 12.2419 11.4583 11.6666V9.99992Z"
      fill="currentColor"
    />
  </svg>
);

export default AudioForwardIcon;
