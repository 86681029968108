import { IconComponent } from "Core/types/Icon";

const UserCancelIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.7506 13.3333L17.9172 17.5M17.9172 13.3333L13.7506 17.5M10.0006 12.9167H6.25057C5.0876 12.9167 4.50611 12.9167 4.03295 13.0602C2.96762 13.3834 2.13394 14.217 1.81077 15.2824C1.66724 15.7555 1.66724 16.337 1.66724 17.5M12.0839 6.25C12.0839 8.32107 10.405 10 8.3339 10C6.26283 10 4.5839 8.32107 4.5839 6.25C4.5839 4.17893 6.26283 2.5 8.3339 2.5C10.405 2.5 12.0839 4.17893 12.0839 6.25Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserCancelIcon;
