import Button from "Core/components/Button";
import colors from "Core/constants/draftjs/colors";
import getInlineStyle from "Core/utils/draftjs/getInlineStyle";
import { DraftjsToolbarButtonProps } from "Core/types/Draftjs";
import stopPropagation from "Core/utils/element/stopPropagation";
import ColorPaletteIcon from "Core/components/Icon/colorPalette";
import toggleInlineStyle from "Core/utils/draftjs/toggleInlineStyle";
import ArrowLeftIcon from "Core/components/Icon/arrowLeft";

const Color: React.FC<DraftjsToolbarButtonProps> = ({ getEditorState, setEditorState, active, onToggle }) => {
  const editorState = getEditorState();
  const selected = getInlineStyle(editorState, "color");

  const isSelected = (e: string) => `color-${e}` === selected;

  const onSelect = (e?: string) => () => {
    const isClear = !e || isSelected(e);
    const [key, selectedKey] = [isClear ? selected : `color-${e}`, isClear ? undefined : selected];

    setEditorState(toggleInlineStyle(editorState, key, selectedKey));
    if (e) onToggle();
  };

  return active ? (
    <>
      <h4>
        <Button variant="text" before={<ArrowLeftIcon />} onClick={onToggle}>
          Text Color
        </Button>
      </h4>
      <section>
        {colors.map((x, i) => (
          <li key={x} onClick={onSelect(x)} role={isSelected(x) ? "active" : ""}>
            <span role={i === 0 ? "active" : ""} style={{ backgroundColor: x }}></span>
          </li>
        ))}
      </section>
    </>
  ) : (
    <li onClick={onToggle} role={selected ? "active" : ""}>
      <span>
        <ColorPaletteIcon />
      </span>
      <h5>Text Color</h5>
      {selected && (
        <Button variant="whiteOverlay" onClick={stopPropagation(onSelect())}>
          Clear
        </Button>
      )}
    </li>
  );
};

export default Color;
