import { IconComponent } from "Core/types/Icon";

const UserAddThinIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6667 1.66663C11.1689 1.66663 8.33333 4.50216 8.33333 7.99996C8.33333 11.4978 11.1689 14.3333 14.6667 14.3333C18.1645 14.3333 21 11.4978 21 7.99996C21 4.50216 18.1645 1.66663 14.6667 1.66663ZM10.3333 7.99996C10.3333 5.60673 12.2734 3.66663 14.6667 3.66663C17.0599 3.66663 19 5.60673 19 7.99996C19 10.3932 17.0599 12.3333 14.6667 12.3333C12.2734 12.3333 10.3333 10.3932 10.3333 7.99996Z"
      fill="currentColor"
    />
    <path
      d="M10.6667 16.3333C7.16886 16.3333 4.33333 19.1688 4.33333 22.6666C4.33333 26.1644 7.16886 29 10.6667 29H18.6667C19.2189 29 19.6667 28.5522 19.6667 28C19.6667 27.4477 19.2189 27 18.6667 27H10.6667C8.27343 27 6.33333 25.0599 6.33333 22.6666C6.33333 20.2734 8.27343 18.3333 10.6667 18.3333H18.6667C19.2189 18.3333 19.6667 17.8856 19.6667 17.3333C19.6667 16.781 19.2189 16.3333 18.6667 16.3333H10.6667Z"
      fill="currentColor"
    />
    <path
      d="M25 18.6666C25 18.1143 24.5523 17.6666 24 17.6666C23.4477 17.6666 23 18.1143 23 18.6666V21.6666H20C19.4477 21.6666 19 22.1143 19 22.6666C19 23.2189 19.4477 23.6666 20 23.6666H23V26.6666C23 27.2189 23.4477 27.6666 24 27.6666C24.5523 27.6666 25 27.2189 25 26.6666V23.6666H28C28.5523 23.6666 29 23.2189 29 22.6666C29 22.1143 28.5523 21.6666 28 21.6666H25V18.6666Z"
      fill="currentColor"
    />
  </svg>
);

export default UserAddThinIcon;
