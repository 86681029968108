type FooterMenuItem = {
  label: string;
  value: string;
};

const footerMenuItems: FooterMenuItem[] = [
  {
    label: "Terms & Conditions",
    value: `${process.env.REACT_APP_URL}terms`,
  },
  {
    label: "Privacy Policy",
    value: `${process.env.REACT_APP_URL}privacy`,
  },
  {
    label: "EULA",
    value: `${process.env.REACT_APP_URL}eula`,
  },
];

export default footerMenuItems;
