import { cx } from "Core/utils";
import HowToSidebarItem from "./Item";
import { Post } from "Content/types/Post";
import styles from "./styles.module.scss";
import { MenuItem } from "HowTo/types/MenuItem";

type HowToSidebarProps = {
  selected?: Post;
  data?: MenuItem[];
  setSelected: (e: Post) => void;
};

const HowToSidebar: React.FC<HowToSidebarProps> = ({ data, selected, setSelected }) => {
  return (
    <div className={cx(styles.howToSidebar, [styles.selected, !!selected])}>
      {data?.map((x) => (
        <HowToSidebarItem key={x._id} data={x} selected={selected} onClick={setSelected} />
      ))}
    </div>
  );
};

export default HowToSidebar;
