import modalOpen from ".";

type Payload = {
  ok?: string;
  width?: number;
  title?: string;
  onOk?: () => void;
  subtitle?: string;
  closeOnOk?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  closeOnCancel?: boolean;
  cancel?: string | boolean;
};

type Props = (payload: Payload) => Promise<void>;

const modalOpenConfirm: Props = ({ ok = "Yes", cancel = "No", ...rest }) => {
  return modalOpen({ ...rest, ok, cancel });
};

export default modalOpenConfirm;
