import styles from "./styles.module.scss";
import getRelativeDate from "Core/utils/date/getRelativeDate";
import UserAddThinIcon from "Core/components/Icon/userAddThin";
import { Notification } from "Notification/types/Notification";
import { NewSubscriptionNotificationData } from "Notification/types/NotificationData";

type Props = {
  username: string;
  data: Notification<NewSubscriptionNotificationData>;
};

const NotificationButtonItemSubscription: React.FC<Props> = ({ username, data }) => {
  const onClick = () => {
    window.open(`/${data.data.username}`, "_self");
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <UserAddThinIcon className={styles.avatar} />
      <div className={styles.content}>
        <div className={styles.title}>{username} subscribed to you! 🥳</div>
        <div className={styles.innerContent}></div>
        <div className={styles.footer}>{getRelativeDate(data.createdAt)}</div>
      </div>
    </div>
  );
};

export default NotificationButtonItemSubscription;
