import api from "Core/api";

type Response = void;

type ApiResponse = void;

type Props = (replyId: string, topicId: string, threadId: string) => Promise<Response>;

const deleteThreadReply: Props = async (replyId, topicId, threadId) => {
  await api.delete<ApiResponse>(
    `${process.env.REACT_APP_HUB_API_URL}topics/${topicId}/thread/${threadId}/reply/${replyId}`
  );
  return;
};

export default deleteThreadReply;
