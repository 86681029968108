import AudioPlayer from "Content/components/AudioPlayer";
import { Audio } from "Content/types/ContentEditor/Audio";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<Audio> & {
  active?: boolean;
  onChange?: (key: string) => (e: any) => void;
};

const AudioView: React.FC<Props> = ({ value }) => {
  return (
    <div>
      <AudioPlayer value={value.data.url} style={value.data.style} />
    </div>
  );
};

export default AudioView;
