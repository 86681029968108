import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import { ChangeEvent, KeyboardEvent, ReactNode, useState } from "react";

type InputVariant = "default" | "error" | "info" | "success";
type InputType = "text" | "password" | "number";

type Props = {
  id?: string;
  min?: number;
  max?: number;
  info?: string;
  error?: string;
  label?: string;
  value?: string;
  type?: InputType;
  after?: ReactNode;
  before?: ReactNode;
  disabled?: boolean;
  className?: string;
  maxLength?: number;
  precision?: number;
  onBlur?: () => void;
  autoFocus?: boolean;
  showLength?: boolean;
  placeholder?: string;
  variant?: InputVariant;
  autoCapitalize?: string;
  clearOnFirstClick?: boolean;
  onChange?: (e: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const Input: React.FC<Props> = ({
  info,
  type,
  label,
  error,
  after,
  before,
  onChange,
  maxLength,
  className,
  showLength = true,
  clearOnFirstClick,
  variant = "default",
  precision,
  ...rest
}) => {
  const [touched, setTouched] = useState(false);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!touched) setTouched(true);
    let val = e.target.value;
    if (type === "number") {
      val = val.replace(/[^0-9\.]/g, "");
    }
    if (!(type === "number" && rest.max !== undefined && Number(val) > rest.max)) {
      if (precision) {
        const parts = val.split(".");
        if (parts[1] && parts[1].length > precision) {
          val = parts[0] + "." + parts[1].slice(0, precision);
        }
      }
      onChange?.(val.slice(0, maxLength));
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (type === "number" && !/^\d|\.$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
      e.preventDefault();
    }
    if (type === "number" && e.key === "." && rest.value?.includes(".")) {
      e.preventDefault();
    }
  };

  const onClick = () => {
    if (clearOnFirstClick && !touched && rest.value) {
      onChange?.("");
    }
  };

  const inputType = type === "number" ? "text" : type;

  return (
    <div
      className={cx(styles.container, className, styles[variant], [styles.error, !!error], [styles.before, !!before])}
    >
      {label && <label>{label}</label>}
      <section>
        {before && <span className={styles.before}>{before}</span>}
        <input
          type={inputType}
          onClick={onClick}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          onChange={handleOnChange}
          {...rest}
        />
        {after && <div className={styles.after}>{after}</div>}
        {maxLength && showLength && <div className={styles.length}>{maxLength - (rest.value?.length || 0)}</div>}
      </section>
      {(info || error) && <p>{info || error}</p>}
    </div>
  );
};

export default Input;
