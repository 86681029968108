type Props = (url: string, email?: boolean) => boolean;

// const regex: RegExp = /^(https?:\/\/)?(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
const regex: RegExp = /^(https?:\/\/)?(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*[^\s]*)*\/?$/;

const isValidUrl: Props = (payload, email) => {
  let url;

  try {
    url = new URL(payload);
  } catch (_) {
    return regex.test(payload);
  }
  return url.protocol === "http:" || url.protocol === "https:" || !!(email && url.protocol === "mailto:");
};

export default isValidUrl;
