import Cookies from "js-cookie";
import getNextYear from "./date/getNextYear";

type Props = {
  deleteToken: () => void;
  delete: (key: string) => void;
  getToken: () => string | undefined;
  get: (key: string) => string | undefined;
  setToken: (token: string, refreshToken: string) => void;
  set: (key: string, value: string | string[] | object | undefined) => void;
};

const domain = process.env.NODE_ENV === "development" ? "localhost" : ".rambly.com";

const cookie: Props = {
  get: (key) => {
    return Cookies.get(key);
  },
  set: (key, value) => {
    Cookies.set(key, typeof value !== "string" ? JSON.stringify(value) : value, {
      domain,
      expires: getNextYear(),
    });
  },
  delete: (key) => {
    Cookies.remove(key);
    Cookies.remove(key, { domain });
  },
  getToken: () => {
    return Cookies.get("token");
  },
  setToken: (token, refreshToken) => {
    Cookies.set("token", token, { expires: getNextYear(), domain });
    Cookies.set("refreshToken", refreshToken, { expires: getNextYear(), domain });
  },
  deleteToken: () => {
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    Cookies.remove("token", { domain });
    Cookies.remove("refreshToken", { domain });
  },
};

export default cookie;
