type Props = (date?: string) => string;

const getDateString: Props = (value) => {
  if (!value) return "";

  const date = new Date(value);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
  });
};

export default getDateString;
