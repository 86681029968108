import axios from "axios";

type Response = string;

type ApiResponse = { url: string };

type Props = (url: string) => Promise<Response>;

const parseEmbedUrl: Props = async (url) => {
  const response = await axios.get<ApiResponse>("/api/parseEmbedUrl", { params: { url } });
  return response.data.url;
};

export default parseEmbedUrl;
