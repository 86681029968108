import { EditorState } from "draft-js";

type Props = (state: EditorState, style: string, defaultStyles?: string[]) => string;

const getInlineStyle: Props = (state, style, defaultStyles) => {
  const value = state.getCurrentInlineStyle().find((_, x) => x!.indexOf(`${style}-`) > -1) || "";
  const defaultValue = defaultStyles?.find((x) => x.startsWith(style));

  return value || defaultValue || "";
};

export default getInlineStyle;
