import api from "Core/api";
import { DraftjsState } from "Core/types/Draftjs";

type Response = void;

type ApiResponse = void;

type Props = (topicId: string, threadId: string, message: DraftjsState) => Promise<Response>;

const replyThread: Props = async (topicId, threadId, message) => {
  await api.post<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}topics/${topicId}/thread/${threadId}/reply`, {
    message,
  });
  return;
};

export default replyThread;
