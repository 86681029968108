import { Select } from "Core/components";

type AcademySortSelectProps = {
  value: string;
  onChange: (e: string) => void;
};

const AcademySortSelect: React.FC<AcademySortSelectProps> = ({ value, onChange }) => {
  return (
    <Select title="Filter" value={value} onChange={onChange}>
      <Select.Option value="newest">Most recent</Select.Option>
      <Select.Option value="asc">Title (A to Z)</Select.Option>
      <Select.Option value="desc">Title (Z to A)</Select.Option>
    </Select>
  );
};

export default AcademySortSelect;
