import { ReactNode } from "react";
import PlusIcon from "Core/components/Icon/plus";
import TikTokIcon from "Core/components/Icon/tiktok";
import TwitchIcon from "Core/components/Icon/twitch";
import YouTubeIcon from "Core/components/Icon/youtube";
import TwitterIcon from "Core/components/Icon/twitter";
import FacebookIcon from "Core/components/Icon/facebook";
import SnapchatIcon from "Core/components/Icon/snapchat";
import InstagramIcon from "Core/components/Icon/instagram";
import PinterestIcon from "Core/components/Icon/pinterest";

const socialPlatforms: Record<string, ReactNode> = {
  facebook: <FacebookIcon />,
  twitter: <TwitterIcon />,
  youtube: <YouTubeIcon />,
  instagram: <InstagramIcon />,
  snapchat: <SnapchatIcon />,
  tiktok: <TikTokIcon />,
  twitch: <TwitchIcon />,
  pinterest: <PinterestIcon />,
  other: <PlusIcon />,
};

export default socialPlatforms;
