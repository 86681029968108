import { cx } from "Core/utils";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import RamblyIcon from "Core/components/Icon/rambly";
import AccountMenuMobile from "Root/components/AccountMenu/Mobile";

type Props = {
  theme?: "dark" | "light";
};

const GuestMenu: React.FC<Props> = ({ theme = "light" }) => {
  return (
    <div className={styles.guestMenu}>
      <a className={styles.guestMenuRambly} href={`${process.env.REACT_APP_URL}`}>
        <Button variant={"whiteOutline"} before={<RamblyIcon />}>
          Back to Rambly
        </Button>
      </a>
      <a className={styles.guestMenuRegister} href={`${process.env.REACT_APP_URL}register`}>
        <Button variant="primary">Sign Up</Button>
      </a>
      <AccountMenuMobile className={cx(styles.guestMenuMobile, styles[theme])} />
    </div>
  );
};

export default GuestMenu;
