import cx from "Core/utils/cx";
import dividerTypes from "./Types";
import styles from "./styles.module.scss";
import { Divider } from "Content/types/ContentEditor/Divider";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<Divider>;

const DividerView: React.FC<Props> = ({ value }) => {
  const Component = dividerTypes[value.data.type];

  return (
    <div className={cx(styles.container)} style={value.data.style}>
      <div className={styles.content}>
        <Component />
      </div>
    </div>
  );
};

export default DividerView;
