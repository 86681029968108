import Button from "Core/components/Button";
import { DraftjsToolbarButtonProps } from "Core/types/Draftjs";
import StrikeThroughIcon from "Core/components/Icon/strikeThrough";
import toggleInlineStyle from "Core/utils/draftjs/toggleInlineStyle";

const Strike: React.FC<DraftjsToolbarButtonProps> = ({ getEditorState, setEditorState }) => {
  const editorState = getEditorState();
  const selected = editorState.getCurrentInlineStyle().has("STRIKETHROUGH");

  const onSelect = () => {
    setEditorState(toggleInlineStyle(editorState, "STRIKETHROUGH"));
  };

  return (
    <li onClick={onSelect} role={selected ? "active" : ""}>
      <span>
        <StrikeThroughIcon />
      </span>
      <h5>Strike</h5>
      {selected && <Button variant="dark">Clear</Button>}
    </li>
  );
};

export default Strike;
