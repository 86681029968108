import { IconComponent } from "Core/types/Icon";

const EyeOutlineIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5845 8.40595L15.1938 8.84334L14.5845 8.40595ZM14.5845 5.59405L13.9752 6.03143L14.5845 5.59405ZM1.41551 8.40595L2.02476 7.96857L1.41551 8.40595ZM1.41551 5.59405L0.806248 5.15666L1.41551 5.59405ZM0.752686 7H0.00268555H0.752686ZM0.806249 8.84334C1.46227 9.75714 2.44225 10.9635 3.64502 11.9483C4.83921 12.9261 6.33239 13.75 8 13.75V12.25C6.82757 12.25 5.66506 11.6636 4.59529 10.7877C3.5341 9.91881 2.64185 8.82814 2.02476 7.96857L0.806249 8.84334ZM8 13.75C9.66762 13.75 11.1608 12.9261 12.355 11.9483C13.5578 10.9635 14.5377 9.75714 15.1938 8.84334L13.9752 7.96856C13.3582 8.82813 12.4659 9.91881 11.4047 10.7877C10.3349 11.6636 9.17244 12.25 8 12.25V13.75ZM15.1938 5.15666C14.5377 4.24286 13.5578 3.03651 12.355 2.05171C11.1608 1.07394 9.66762 0.25 8 0.25V1.75C9.17243 1.75 10.3349 2.3364 11.4047 3.21231C12.4659 4.08119 13.3582 5.17186 13.9752 6.03143L15.1938 5.15666ZM8 0.25C6.33239 0.25 4.83921 1.07394 3.64502 2.05171C2.44225 3.03651 1.46227 4.24286 0.806248 5.15666L2.02476 6.03144C2.64185 5.17187 3.53409 4.08119 4.59529 3.21231C5.66506 2.3364 6.82757 1.75 8 1.75V0.25ZM15.1938 8.84334C15.6009 8.27628 15.9973 7.76771 15.9973 7H14.4973C14.4973 7.19763 14.4519 7.30459 13.9752 7.96856L15.1938 8.84334ZM13.9752 6.03143C14.4519 6.69541 14.4973 6.80237 14.4973 7H15.9973C15.9973 6.23228 15.6008 5.72372 15.1938 5.15666L13.9752 6.03143ZM2.02476 7.96857C1.54809 7.30459 1.50269 7.19763 1.50269 7H0.00268555C0.00268555 7.76772 0.399159 8.27628 0.806249 8.84334L2.02476 7.96857ZM0.806248 5.15666C0.399159 5.72372 0.00268555 6.23228 0.00268555 7H1.50269C1.50269 6.80237 1.54809 6.69541 2.02476 6.03144L0.806248 5.15666ZM5 7C5 8.65685 6.34315 10 8 10V8.5C7.17158 8.5 6.5 7.82843 6.5 7H5ZM8 10C9.65686 10 11 8.65685 11 7H9.5C9.5 7.82843 8.82843 8.5 8 8.5V10ZM11 7C11 5.34315 9.65686 4 8 4V5.5C8.82843 5.5 9.5 6.17157 9.5 7H11ZM8 4C6.34315 4 5 5.34315 5 7H6.5C6.5 6.17157 7.17158 5.5 8 5.5V4Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeOutlineIcon;
