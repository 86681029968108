import api from "Core/api";

type Response = void;

type ApiResponse = void;

type Props = (topicId: string, threadId: string) => Promise<Response>;

const deleteThread: Props = async (topicId, threadId) => {
  await api.delete<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}topics/${topicId}/thread/${threadId}`);
  return;
};

export default deleteThread;
