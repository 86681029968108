import { IconComponent } from "Core/types/Icon";

const DeleteIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.12"
      d="M15.2463 13.7661L15.8307 5H4.16406L4.74847 13.7661C4.83615 15.0813 4.87999 15.7389 5.16405 16.2375C5.41414 16.6765 5.79136 17.0294 6.24601 17.2497C6.76242 17.5 7.42148 17.5 8.73961 17.5H11.2552C12.5733 17.5 13.2324 17.5 13.7488 17.2497C14.2034 17.0294 14.5807 16.6765 14.8307 16.2375C15.1148 15.7389 15.1586 15.0813 15.2463 13.7661Z"
      fill="currentColor"
    />
    <path
      d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeleteIcon;
