import { ContentItem } from "Content/types/ContentEditor/Item";
import { BasicButton } from "Content/types/ContentEditor/BasicButton";

type Props = {
  edit: boolean;
  value: ContentItem<BasicButton>;
};

const Outlined: React.FC<Props> = ({ value }) => {
  const style = {
    backgroundColor: "white",
    color: value.data.style.color,
    width: value.data.style.width,
    borderWidth: value.data.style.borderWidth,
    borderStyle: value.data.style.borderStyle,
    borderColor: value.data.style.borderColor,
    borderRadius: value.data.style.borderRadius,
  };

  return (
    <button style={style} aria-label={value.data.text}>
      {value.data.text}
    </button>
  );
};

export default Outlined;
