import month from "./month";
import safe from "./month/safe";
import { TimeCalendar, TimeDay } from "Core/types/DateTime";

type Props = (payload: TimeDay) => TimeCalendar;

const calendar: Props = (payload) => {
  payload = safe(payload);

  return {
    year: payload.year,
    month: payload.month,
    days: month(payload.month, payload.year),
    next: () => ({ ...calendar({ ...payload, month: payload.month + 1 }) }),
    prev: () => ({ ...calendar({ ...payload, month: payload.month - 1 }) }),
  };
};

export default calendar;
