const formatTimeAgo = (isoDate: string) => {
  const now = new Date();
  const date = new Date(isoDate);

  // Calculate the time difference in milliseconds
  const timeDifference = now.getTime() - date.getTime();
  const secondsAgo = timeDifference / 1000;
  const minutesAgo = secondsAgo / 60;
  const hoursAgo = minutesAgo / 60;
  const daysAgo = hoursAgo / 24;

  // If it's less than a minute ago
  if (secondsAgo < 60) {
    return `Just Now`;
  }
  // If it's less than an hour ago
  else if (minutesAgo < 60) {
    return `${Math.floor(minutesAgo)} minute${Math.floor(minutesAgo) === 1 ? "" : "s"} ago`;
  }
  // If it's less than a day ago
  else if (hoursAgo < 24) {
    return `${Math.floor(hoursAgo)} hour${Math.floor(hoursAgo) === 1 ? "" : "s"} ago`;
  }
  // If it's yesterday
  else if (daysAgo < 2) {
    return "Yesterday";
  }
  // Otherwise, format as dd.mm.yyyy hh:mm
  else {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }
};

export default formatTimeAgo;
