import { cx } from "Core/utils";
import { useState } from "react";
import useAuth from "Auth/context";
import styles from "./styles.module.scss";
import { permissions } from "Auth/constants";
import { useNavigate } from "react-router-dom";
import CloseIcon from "Core/components/Icon/close";
import RamblyIcon from "Core/components/Icon/rambly";
import { Avatar, Button, Logo, Portal } from "Core/components";
import ChevronDownIcon from "Core/components/Icon/chevronDown";
import ArrowUpRightIcon from "Core/components/Icon/arrowUpRight";

type AccountMenuMobileProps = {
  className?: string;
};

const AccountMenuMobile: React.FC<AccountMenuMobileProps> = ({ className }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const onBack = () => {
    window.open(`${process.env.REACT_APP_URL}creator`, "_self");
    setVisible(false);
  };

  const onCockpit = () => {
    window.open(`${process.env.REACT_APP_COCKPIT_URL}`, "_self");
    setVisible(false);
  };

  const onClick = (e: string) => () => {
    window.scroll({ top: -1, left: 0 });
    navigate(e);
    setVisible(false);
  };

  const onToggle = (e: boolean) => () => {
    setVisible(e);
  };

  const onLogout = () => {
    auth.logout();
    setVisible(false);
  };

  return (
    <>
      <Button
        onClick={onToggle(true)}
        variant={auth.user ? "whiteOutline" : "whiteOverlay"}
        after={auth.user ? <ChevronDownIcon /> : undefined}
        children={auth.user ? undefined : <ChevronDownIcon />}
        className={cx(styles.accountMenuMobileButton, [styles.guest, !auth.user], className)}
        before={auth.user ? <Avatar className={styles.avatar} value={auth.user?.avatar} size={30} /> : undefined}
      />
      {visible && (
        <Portal container={document.getElementById("modalContainer")}>
          <div className={styles.accountMenuMobileOverlay}>
            <div className={styles.accountMenuMobileOverlayHeader}>
              <Logo className={styles.logo} light />
              <Button className={styles.accountMenuMobileClose} onClick={onToggle(false)}>
                <CloseIcon />
              </Button>
            </div>
            <div className={styles.accountMenuMobileOverlayContent}>
              <div className={styles.accountMenuMobileOverlayItem} onClick={onClick("/")}>
                Home
              </div>
              <div className={styles.accountMenuMobileOverlayItem} onClick={onClick("/howto")}>
                How-To
              </div>
              <div className={styles.accountMenuMobileOverlayItem} onClick={onClick("/academy")}>
                Academy
              </div>
              <div className={styles.accountMenuMobileOverlayItem} onClick={onClick("/forum")}>
                Forum
              </div>
              <div className={styles.accountMenuMobileOverlayItem} onClick={onClick("/faq")}>
                FAQ
              </div>
              <div className={styles.accountMenuMobileOverlayItem} onClick={onBack}>
                Back to Rambly
              </div>
              {auth.user?.role === permissions.admin && (
                <div className={styles.accountMenuMobileOverlayItem} onClick={onCockpit}>
                  Rambly Cockpit
                </div>
              )}
              <div className={styles.accountMenuMobileOverlayItem} onClick={onLogout}>
                Logout
              </div>
            </div>
            {auth.user ? (
              <div className={styles.accountMenuMobileFooter} onClick={onClick(`/${auth.user?.username}`)}>
                <div className={styles.accountMenuMobileUser}>
                  <Avatar value={auth.user?.avatar} size={36} className={styles.accountMenuMobileAvatar} />
                  <div className={styles.accountMenuMobileName}>{auth.user?.name || auth.user?.username}</div>
                </div>
                <Button variant="secondary">
                  <ArrowUpRightIcon />
                </Button>
              </div>
            ) : (
              <div className={styles.accountMenuMobileButtons}>
                <a href={`${process.env.REACT_APP_URL}`}>
                  <Button variant="whiteOutline" before={<RamblyIcon />}>
                    Back to Rambly
                  </Button>
                </a>
                <a href={`${process.env.REACT_APP_URL}register`}>
                  <Button variant="primary">Sign Up</Button>
                </a>
                {/* <a href={`${process.env.REACT_APP_URL}login`}>
                  <Button variant="secondary">Log In</Button>
                </a> */}
              </div>
            )}
          </div>
        </Portal>
      )}
    </>
  );
};

export default AccountMenuMobile;
