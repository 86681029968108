import { IconComponent } from "Core/types/Icon";

const LikeIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.57095 25.8526V12.8156M1.64502 15.186V23.4823C1.64502 24.7914 2.70627 25.8526 4.01539 25.8526H19.928C21.6829 25.8526 23.1753 24.5722 23.4422 22.8377L24.7185 14.5414C25.0499 12.3876 23.3835 10.4452 21.2043 10.4452H17.0524C16.3979 10.4452 15.8672 9.9146 15.8672 9.26004V5.0714C15.8672 3.45736 14.5588 2.14893 12.9448 2.14893C12.5598 2.14893 12.2109 2.37565 12.0546 2.72744L7.88376 12.1118C7.69354 12.5398 7.2691 12.8156 6.80073 12.8156H4.01539C2.70627 12.8156 1.64502 13.8768 1.64502 15.186Z"
      stroke="currentColor"
      strokeWidth="2.37037"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LikeIcon;
