import { ContentBlock } from "draft-js";
import customStyleMap from "Core/constants/draftjs/customStyleMap";

type Props = (block: ContentBlock) => string;

const blockStyleFn: Props = (block) => {
  const blockStyles: string[] = [];
  const styleMap = Object.keys(customStyleMap);

  switch (block.getType()) {
    case "unordered-list-item":
      // With draft JS we cannot output different styles for the same block type.
      // We can however customise the css classes:
      block.findStyleRanges(
        (item) => {
          const itemStyles = item.getStyle();
          return styleMap.some((styleKey) => itemStyles.includes(styleKey));
        },
        (startCharacter) => {
          if (startCharacter === 0) {
            // Apply the same styling to the block as the first character
            block
              .getInlineStyleAt(startCharacter)
              .toArray()
              .forEach((styleKey) => {
                blockStyles.push(`${styleKey.replace(/[,(]/g, "-").replace(/\)/g, "")}`);
              });
          }
        }
      );
      return blockStyles.join(" ");
    case "align-left":
      return "align-left";
    case "align-center":
      return "align-center";
    case "align-right":
      return "align-right";
    default:
      return blockStyles.join(" ");
  }
};

export default blockStyleFn;
