import modalOpen from ".";
import InfoIcon from "Core/components/Icon/info";

type Payload = {
  ok?: string;
  width?: number;
  title?: string;
  onOk?: () => void;
  subtitle?: string;
  closeOnOk?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  closeOnCancel?: boolean;
  cancel?: string | boolean;
};

type Props = (payload: Payload) => Promise<void>;

const modalOpenInfo: Props = ({ ...rest }) => {
  return modalOpen({ ...rest, icon: <InfoIcon style={{ color: "#FBDD00" }} /> });
};

export default modalOpenInfo;
