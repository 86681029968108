import api from "Core/api";

type Response = void;

type ApiResponse = void;

type Props = (topicId: string, threadId: string, status: boolean) => Promise<Response>;

const likeThread: Props = async (topicId, threadId, status) => {
  await api.get<ApiResponse>(
    `${process.env.REACT_APP_HUB_API_URL}topics/${topicId}/thread/${threadId}/${status ? "like" : "unlike"}`
  );
  return;
};

export default likeThread;
