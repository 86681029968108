import { Img } from "Core/components";
import styles from "./styles.module.scss";
import { ForumTopic } from "Forum/types/Topic";

type ForumListItemProps = {
  data: ForumTopic;
};

const ForumListItem: React.FC<ForumListItemProps> = ({ data }) => {
  return (
    <div className={styles.forumListItem}>
      <div className={styles.forumListItemIcon}>
        <Img src={data.icon} w={28} h={28} />
      </div>
      <div className={styles.forumListItemTitle}>{data.title}</div>
      <div className={styles.forumListItemDescription}>
        {data.description.map((x) => (
          <div key={x} className={styles.forumListItemDescriptionItem}>
            {x}
          </div>
        ))}
      </div>
      <div className={styles.forumListItemCount}>
        {data.threads} {data.threads > 1 ? "posts" : "post"}
      </div>
    </div>
  );
};

export default ForumListItem;
