import hex2rgba from "./hex2rgba";

type Props = (color?: string) => number;

const getAlpha: Props = (color) => {
  if (!color) return 0;

  const rgbaColor = color.indexOf("rgba") > -1 ? color : hex2rgba(color);
  const alpha = rgbaColor.replace("rgba(", "").replace(/ /g, "").replace(")", "").split(",")[3];

  return Math.ceil(Number(alpha) * 100);
};

export default getAlpha;
