import { IconComponent } from "Core/types/Icon";

const BackgroundColorIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.480988 2.95401C0.48362 2.29973 0.744697 1.67301 1.20734 1.21036C1.66999 0.747719 2.29671 0.486642 2.95099 0.484009H13.321C13.9753 0.486642 14.602 0.747719 15.0646 1.21036C15.5273 1.67301 15.7884 2.29973 15.791 2.95401V5.91601H18.753C19.4073 5.91864 20.034 6.17972 20.4966 6.64236C20.9593 7.10501 21.2204 7.73173 21.223 8.38601V18.756C21.2204 19.4103 20.9593 20.037 20.4966 20.4997C20.034 20.9623 19.4073 21.2234 18.753 21.226H8.38299C7.72871 21.2234 7.10199 20.9623 6.63934 20.4997C6.1767 20.037 5.91562 19.4103 5.91299 18.756V15.793H2.95199C2.29771 15.7904 1.67099 15.5293 1.20834 15.0667C0.745697 14.604 0.484621 13.9773 0.481988 13.323V2.95401H0.480988ZM13.321 1.96601H2.95099C2.82057 1.96357 2.691 1.98745 2.57002 2.03624C2.44905 2.08502 2.33916 2.15771 2.24692 2.24995C2.15469 2.34218 2.082 2.45207 2.03322 2.57305C1.98443 2.69402 1.96054 2.82359 1.96299 2.95401V13.324C1.96299 13.884 2.39099 14.312 2.95099 14.312H13.321C13.4514 14.3145 13.581 14.2906 13.702 14.2418C13.8229 14.193 13.9328 14.1203 14.0251 14.0281C14.1173 13.9358 14.19 13.8259 14.2388 13.705C14.2875 13.584 14.3114 13.4544 14.309 13.324V2.95401C14.3114 2.82359 14.2875 2.69402 14.2388 2.57305C14.19 2.45207 14.1173 2.34218 14.0251 2.24995C13.9328 2.15771 13.8229 2.08502 13.702 2.03624C13.581 1.98745 13.4514 1.96357 13.321 1.96601V1.96601ZM16.824 7.39801H18.691L15.79 10.299V8.43201L16.824 7.39801ZM19.741 10.315V8.44701L15.791 12.39V13.324C15.791 13.816 15.641 14.28 15.389 14.666L19.741 10.315V10.315ZM19.741 12.405V14.281L14.27 19.744H12.403L19.741 12.406V12.405ZM10.311 19.744L14.671 15.384C14.281 15.642 13.819 15.793 13.321 15.793H12.395L8.43699 19.743H10.312L10.311 19.744ZM8.42999 15.793H10.297L7.39599 18.686V16.826L8.42999 15.793V15.793ZM19.742 18.756V16.364L16.37 19.744H18.754C18.8844 19.7465 19.014 19.7226 19.1349 19.6738C19.2559 19.625 19.3658 19.5523 19.458 19.4601C19.5503 19.3678 19.623 19.2579 19.6718 19.137C19.7205 19.016 19.7444 18.8864 19.742 18.756V18.756Z"
      fill="currentColor"
    />
  </svg>
);

export default BackgroundColorIcon;
