import api from "Core/api";

type Response = void;

type ApiResponse = void;

type Props = (id: string) => Promise<Response>;

const readNotification: Props = async (id) => {
  await api.get<ApiResponse>(`notification/markread/${id}`);
  return;
};

export default readNotification;
