import Button from "Core/components/Button";
import ItalicIcon from "Core/components/Icon/italic";
import { DraftjsToolbarButtonProps } from "Core/types/Draftjs";
import toggleInlineStyle from "Core/utils/draftjs/toggleInlineStyle";

const Italic: React.FC<DraftjsToolbarButtonProps> = ({ getEditorState, setEditorState }) => {
  const editorState = getEditorState();
  const selected = editorState.getCurrentInlineStyle().has("ITALIC");

  const onSelect = () => {
    setEditorState(toggleInlineStyle(editorState, "ITALIC"));
  };

  return (
    <li onClick={onSelect} role={selected ? "active" : ""}>
      <span>
        <ItalicIcon />
      </span>
      <h5>Italic</h5>
      {selected && <Button variant="dark">Clear</Button>}
    </li>
  );
};

export default Italic;
