import forumApi from "Forum/api";
import { useState } from "react";
import useAuth from "Auth/context";
import { useRequest } from "Core/hooks";
import { draftjsUtils } from "Core/utils";
import styles from "./styles.module.scss";
import { ForumThread } from "Forum/types/Thread";
import { Avatar, Button, DraftjsEditor } from "Core/components";

type ForumThreadAddCommentProps = {
  thread: ForumThread;
  refresh: () => void;
};

const ForumThreadAddComment: React.FC<ForumThreadAddCommentProps> = ({ thread, refresh }) => {
  const auth = useAuth();
  const [visible, setVisible] = useState(true);
  const reply = useRequest(forumApi.replyThread);
  const [value, setValue] = useState(draftjsUtils.createDraftjsState());
  const isEmpty = draftjsUtils.getPlainText(value.value).trim() === "" && value.value.blocks.length < 2;

  const onSubmit = () => {
    reply.request(thread.topicID, thread._id, value).then(() => {
      refresh();
      setValue(draftjsUtils.createDraftjsState());
      setVisible(false);
      setTimeout(() => setVisible(true), 1);
    });
  };

  const buttons = (
    <Button onClick={onSubmit} disabled={isEmpty} className={styles.forumThreadAddCommentButton} variant="dark">
      Submit
    </Button>
  );

  return auth.user && visible ? (
    <div className={styles.forumThreadAddComment}>
      <Avatar value={auth.user?.avatar} className={styles.forumThreadAddCommentAvatar} size={60} />
      <div className={styles.forumThreadAddCommentMessage} id="AddComment">
        <DraftjsEditor
          image
          emoji
          value={value}
          maxLength={500}
          buttons={buttons}
          onChange={setValue}
          placeholder="Leave a comment"
          className={styles.forumThreadAddCommentEditor}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ForumThreadAddComment;
