import { useRequest } from "Core/hooks";
import notificationItems from "./items";
import { isGuestUser } from "Auth/utils";
import styles from "./styles.module.scss";
import notificationApi from "Notification/api";
import { Notification } from "Notification/types/Notification";

type Props = {
  data: Notification;
  refresh: () => void;
};

const NotificationButtonItem: React.FC<Props> = ({ data, refresh }) => {
  const Component = notificationItems[data.type];
  const readNotification = useRequest(notificationApi.readNotification);

  const username = data.data.name
    ? `${data.data.name || ""}`
    : isGuestUser(data.data.username)
    ? "Someone"
    : `@${data.data.username}`;

  const onClick = () => {
    readNotification.request(data._id).then(() => {
      refresh();
    });
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <Component data={data} username={username} />
    </div>
  );
};

export default NotificationButtonItem;
