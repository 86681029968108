import { IconComponent } from "Core/types/Icon";

const AudioBackwardIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.50002 13.9583C7.15484 13.9583 6.87502 13.6784 6.87502 13.3333V9.0088L6.27529 9.60853C6.03122 9.8526 5.63549 9.8526 5.39141 9.60853C5.14733 9.36445 5.14733 8.96872 5.39141 8.72464L6.22474 7.89131C6.92599 7.19006 8.12502 7.68671 8.12502 8.67843V13.3333C8.12502 13.6784 7.8452 13.9583 7.50002 13.9583Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 7.70825C11.2344 7.70825 10.2084 8.73427 10.2084 9.99992V11.6666C10.2084 12.9322 11.2344 13.9583 12.5 13.9583C13.7657 13.9583 14.7917 12.9322 14.7917 11.6666V9.99992C14.7917 8.73427 13.7657 7.70825 12.5 7.70825ZM11.4584 9.99992C11.4584 9.42462 11.9247 8.95825 12.5 8.95825C13.0753 8.95825 13.5417 9.42462 13.5417 9.99992V11.6666C13.5417 12.2419 13.0753 12.7083 12.5 12.7083C11.9247 12.7083 11.4584 12.2419 11.4584 11.6666V9.99992Z"
      fill="currentColor"
    />
    <path
      d="M2.09394 0.830464C2.09548 0.485289 2.37655 0.206718 2.72172 0.208258C3.0669 0.209798 3.34547 0.490866 3.34393 0.836041L3.33654 2.4908C3.33353 3.16532 3.33246 3.61839 3.36989 3.96117L3.37131 3.9739C3.75167 3.55572 4.17107 3.1737 4.62379 2.83357C6.12122 1.70852 7.98363 1.04159 10 1.04159C14.9476 1.04159 18.9584 5.05237 18.9584 9.99992C18.9584 14.9475 14.9476 18.9583 10 18.9583C5.05247 18.9583 1.04169 14.9475 1.04169 9.99992C1.04169 9.65474 1.32151 9.37492 1.66669 9.37492C2.01186 9.37492 2.29169 9.65474 2.29169 9.99992C2.29169 14.2571 5.74282 17.7083 10 17.7083C14.2572 17.7083 17.7084 14.2571 17.7084 9.99992C17.7084 5.74272 14.2572 2.29159 10 2.29159C8.26365 2.29159 6.66301 2.86495 5.37463 3.83294C4.95881 4.14535 4.57558 4.49884 4.23099 4.88737C4.25461 4.89053 4.27928 4.89352 4.30506 4.89634C4.64784 4.93377 5.10091 4.9327 5.77543 4.92969L7.43019 4.9223C7.77536 4.92076 8.05643 5.19933 8.05797 5.54451C8.05951 5.88968 7.78094 6.17075 7.43577 6.17229L5.7449 6.17984C5.11584 6.18266 4.59118 6.18501 4.16936 6.13895C3.72691 6.09063 3.31914 5.98264 2.9649 5.7085C2.90842 5.66478 2.85407 5.61848 2.80204 5.56975C2.71367 5.48699 2.63197 5.39725 2.55773 5.30133C2.28359 4.94709 2.1756 4.53932 2.12728 4.09687C2.08122 3.67506 2.08357 3.15042 2.08639 2.52136L2.09394 0.830464Z"
      fill="currentColor"
    />
  </svg>
);

export default AudioBackwardIcon;
