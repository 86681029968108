import cx from "Core/utils/cx";
import { useRef } from "react";
import styles from "./styles.module.scss";
import { DraftjsState } from "Core/types/Draftjs";

type Props = {
  className?: string;
  value?: DraftjsState;
};

const DraftjsView: React.FC<Props> = ({ className, value }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={cx(styles.container, className, "DraftEditor-root")}>
      <div className="DraftEditor-editorContainer">
        <div className="notranslate public-DraftEditor-content">
          {value && <div ref={ref} dangerouslySetInnerHTML={{ __html: value?.html }} />}
        </div>
      </div>
    </div>
  );
};

export default DraftjsView;
