import { IconComponent } from "Core/types/Icon";

const AudioPauseIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="6" y="4" width="3" height="16" rx="1.5" fill="currentColor" />
    <rect x="15" y="4" width="3" height="16" rx="1.5" fill="currentColor" />
  </svg>
);

export default AudioPauseIcon;
