import { IconComponent } from "Core/types/Icon";

const AudioPlayIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.3049 3.90491C10.0059 3.16422 8.99685 2.58887 8.17735 2.21563C7.35478 1.84099 6.64076 1.62942 5.94647 1.70149C4.84456 1.81587 3.84142 2.38682 3.1877 3.2754C2.77479 3.83668 2.60286 4.55706 2.52001 5.44891C2.43749 6.33719 2.4375 7.48849 2.4375 8.96913V9.03084C2.4375 10.5115 2.43749 11.6628 2.52001 12.5511C2.60286 13.4429 2.77479 14.1633 3.1877 14.7246C3.84142 15.6132 4.84456 16.1841 5.94647 16.2985C6.64076 16.3705 7.35478 16.159 8.17735 15.7843C8.99684 15.4111 10.0059 14.8358 11.3049 14.0951L11.3577 14.065C12.6566 13.3244 13.6657 12.749 14.4028 12.2347C15.1421 11.7189 15.687 11.2127 15.9727 10.5789C16.4258 9.57401 16.4258 8.42595 15.9727 7.42105C15.687 6.78727 15.1421 6.28108 14.4028 5.76528C13.6657 5.25094 12.6566 4.67559 11.3576 3.93495L11.3049 3.90491Z"
      fill="currentColor"
    />
  </svg>
);

export default AudioPlayIcon;
