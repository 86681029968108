import { createContext } from "react";
import { User } from "Auth/types/User";

export type AuthContextProps = {
  loading: boolean;
  isGuest: boolean;
  logout: () => void;
  user?: User | null;
  get: () => Promise<void>;
};

const AuthContext = createContext({} as AuthContextProps);

export default AuthContext;
