import countries from "Core/constants/countries";

type Props = (number: string) => string;

const getCountryCode: Props = (number) => {
  if (typeof number !== "string") return "+1";

  const matches: string[] = [];

  countries.forEach((x) => {
    if (number.startsWith(x.phoneCode)) {
      matches.push(x.phoneCode);
    }
  });

  return matches[0] || "+1";
};

export default getCountryCode;
