import api from "Core/api";
import { SystemSettings } from "Auth/types/SystemSettings";

type Response = SystemSettings;

type ApiResponse = SystemSettings;

type Props = () => Promise<Response>;

const getSystemSettings: Props = async () => {
  const response = await api.get<ApiResponse>("system/config");
  return response.data;
};

export default getSystemSettings;
