import prop from "./prop";
import onKey from "./onKey";
import preventAll from "./preventAll";
import closePopover from "./closePopover";
import preventDefault from "./preventDefault";
import clearSelection from "./clearSelection";
import createComponent from "./createComponent";
import stopPropagation from "./stopPropagation";
import getPopoverPosition from "./getPopoverPosition";

const elementUtils = {
  prop,
  onKey,
  preventAll,
  closePopover,
  preventDefault,
  clearSelection,
  createComponent,
  stopPropagation,
  getPopoverPosition,
};

export default elementUtils;
