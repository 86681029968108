import { IconComponent } from "Core/types/Icon";

const YouTubeIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 0C17.25 0 18.625 0.382 19.25 1.145C20 2.036 20 3.436 20 5.218V8.782C20 10.945 19.625 12.091 18.875 12.982C18.125 13.618 16.875 14 14.625 14H5.375C0.75 14 0 12.218 0 8.782V5.218C0 3.564 0 2.164 0.625 1.273C1.5 0.382 2.75 0 5.375 0H14.75ZM8.75 9.673L12.875 7.509C13 7.382 13.25 7.127 13.25 7C13.2527 6.86962 13.2191 6.74105 13.1529 6.62872C13.0866 6.51638 12.9904 6.42472 12.875 6.364L8.75 4.2C8.625 4.073 8.375 4.073 8.125 4.2C8.00959 4.26072 7.91337 4.35238 7.84713 4.46472C7.7809 4.57705 7.74726 4.70562 7.75 4.836V9.164C7.75 9.291 7.75 9.546 8 9.673C8.125 9.8 8.25 9.8 8.375 9.8C8.438 9.8 8.5 9.768 8.563 9.736C8.61973 9.70105 8.68369 9.6795 8.75 9.673Z"
      fill="currentColor"
    />
  </svg>
);

export default YouTubeIcon;
