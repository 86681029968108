import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import { ReactNode, MouseEvent } from "react";
import LoadingIcon from "Core/components/Icon/loading";

type ButtonVariant =
  | "dark"
  | "text"
  | "light"
  | "primary"
  | "outline"
  | "darkLight"
  | "whiteText"
  | "secondary"
  | "lightGray"
  | "whiteOverlay"
  | "whiteOutline";

type Props = {
  id?: string;
  auto?: boolean;
  block?: boolean;
  circle?: boolean;
  after?: ReactNode;
  loading?: boolean;
  before?: ReactNode;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  variant?: ButtonVariant;
  type?: "button" | "submit" | "reset";
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Button: React.FC<Props> = ({
  auto,
  after,
  block,
  circle,
  before,
  loading,
  children,
  disabled,
  className,
  type = "button",
  variant = "primary",
  ...rest
}) => {
  return (
    <button
      type={type}
      disabled={loading || disabled}
      className={cx(
        className,
        styles[variant],
        styles.container,
        [styles.auto, auto],
        [styles.block, block],
        [styles.circle, circle],
        [styles.loading, loading]
      )}
      {...rest}
    >
      {loading && (
        <div className={styles.loading}>
          <LoadingIcon />
        </div>
      )}
      <div className={styles.content}>
        {before && <span className={styles.before}>{before}</span>}
        {children}
        {after && <span className={styles.after}>{after}</span>}
      </div>
    </button>
  );
};

export type { Props as ButtonProps, ButtonVariant };

export default Button;
