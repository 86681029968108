import { IconComponent } from "Core/types/Icon";

const CloseIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.495 10L19.478 3.01704C19.8095 2.68618 19.996 2.23718 19.9965 1.7688C19.997 1.30043 19.8113 0.851056 19.4805 0.519536C19.1496 0.188015 18.7006 0.00150528 18.2323 0.00103644C17.7639 0.0005676 17.3145 0.186178 16.983 0.517036L9.99999 7.50204L3.01699 0.518035C2.6856 0.186515 2.23609 0.000215874 1.76734 0.000122106C1.29859 2.83375e-05 0.849007 0.186148 0.517486 0.517536C0.185966 0.848924 -0.000333442 1.29843 -0.000427211 1.76718C-0.000520979 2.23593 0.185598 2.68552 0.516986 3.01704L7.50199 10L0.518986 16.983C0.354834 17.1471 0.224608 17.3419 0.135744 17.5564C0.0468806 17.7708 0.00111911 18.0006 0.00107268 18.2327C0.00102625 18.4648 0.0466958 18.6946 0.135474 18.9091C0.224252 19.1235 0.3544 19.3184 0.518486 19.4825C0.682573 19.6467 0.877385 19.7769 1.0918 19.8658C1.30621 19.9546 1.53603 20.0004 1.76813 20.0005C2.00023 20.0005 2.23007 19.9548 2.44452 19.866C2.65897 19.7773 2.85383 19.6471 3.01799 19.483L9.99999 12.498L16.983 19.481C17.3144 19.8126 17.7639 19.9989 18.2326 19.9989C18.7014 19.999 19.151 19.8129 19.4825 19.4815C19.814 19.1501 20.0003 18.7006 20.0004 18.2319C20.0005 17.7631 19.8144 17.3136 19.483 16.982L12.494 10H12.495Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
