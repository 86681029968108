import axios from "axios";

type Response = Blob;

type ApiResponse = ArrayBuffer;

type Props = (url: string) => Promise<Response>;

const parseAudio: Props = async (url) => {
  const response = await axios.get<ApiResponse>(`${process.env.REACT_APP_URL}api/parseAudio`, {
    params: { url },
    responseType: "arraybuffer",
    headers: { "Content-Type": "audio/mpeg" },
  });
  return new Blob([response.data], { type: "audio/mpeg" });
};

export default parseAudio;
