import { Avatar } from "Core/components";
import styles from "./styles.module.scss";
import PinIcon from "Core/components/Icon/pin";
import LockIcon from "Core/components/Icon/lock";
import { ForumThread } from "Forum/types/Thread";
import { dateUtils, draftjsUtils } from "Core/utils";

type ThreadCardProps = {
  data: ForumThread;
};

const ThreadCard: React.FC<ThreadCardProps> = ({ data }) => {
  return (
    <div className={styles.threadCard}>
      <div className={styles.threadCardContent}>
        <div className={styles.threadCardTitle}>
          {data.pinned ? (
            <div className={styles.threadCardTitlePin}>
              <PinIcon />
            </div>
          ) : (
            <div className={styles.threadCardDot} />
          )}{" "}
          {data.title}
        </div>
        <div className={styles.threadCardSubtitle}>{draftjsUtils.getPlainText(data.message?.value)}</div>
        <div className={styles.threadCardFooter}>
          <Avatar className={styles.threadCardAvatar} value={data.avatar} size={24} />
          {data.name || data.username} • {dateUtils.getDateString(data.modifiedAt)}
        </div>
      </div>
      {data.pinned && (
        <div className={styles.threadCardPin}>
          <LockIcon />
        </div>
      )}
    </div>
  );
};

export default ThreadCard;
