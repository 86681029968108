import Button from "Core/components/Button";
import alignments from "Core/constants/draftjs/alignments";
import getBlockType from "Core/utils/draftjs/getBlockType";
import { DraftjsToolbarButtonProps } from "Core/types/Draftjs";
import toggleBlockType from "Core/utils/draftjs/toggleBlockType";

const Align: React.FC<DraftjsToolbarButtonProps> = ({ getEditorState, setEditorState }) => {
  const editorState = getEditorState();
  const type = getBlockType(editorState);

  const selected = (e: string) => type === e;

  const onSelect = (e: string) => () => {
    setEditorState(toggleBlockType(editorState, e));
  };

  return (
    <>
      {alignments.map((x) => (
        <li key={x.value} onClick={onSelect(x.value)} role={selected(x.value) ? "active" : ""}>
          <span>{x.icon}</span>
          <h5>{x.label}</h5>
          {selected(x.value) && <Button variant="whiteOverlay">Clear</Button>}
        </li>
      ))}
    </>
  );
};

export default Align;
