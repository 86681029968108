import { Button } from "Core/components";
import styles from "./styles.module.scss";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "Core/components/Icon/search";

type SearchButtonProps = {};

const SearchButton: React.FC<SearchButtonProps> = () => {
  const { topicId } = useParams();

  return (
    <Link to={topicId ? `/forum/${topicId}/search` : "/forum/search"} className={styles.searchButton}>
      <Button block before={<SearchIcon />} variant="outline">
        <div className={styles.searchButtonText}>Search the Forum</div>
      </Button>
    </Link>
  );
};

export default SearchButton;
