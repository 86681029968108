type Props = (payload?: string) => string;

const getShortString: Props = (payload) => {
  if (!payload) return "";

  const date = new Date(payload);
  return date.toISOString().split("T")[0].split("-").reverse().join(".");
};

export default getShortString;
