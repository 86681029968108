import { ForumThreadForm } from "Forum/types/Form";
import { UseFormPayload } from "Core/hooks/useForm";
import createDraftjsState from "Core/utils/draftjs/createDraftjsState";
import { validateArray, validateDraftjsRequired, validateRequired } from "Core/utils/validators";

type Props = () => UseFormPayload<ForumThreadForm>;

const createThreadForm: Props = () => {
  return {
    title: {
      value: "",
      label: "Title",
      placeholder: "Enter Title",
      validate: validateRequired("Title"),
    },
    tags: {
      value: [],
      label: "Tags",
      placeholder: "Enter Tags",
      validate: validateArray("Tags"),
    },
    message: {
      value: createDraftjsState(),
      label: "Content",
      placeholder: "Share what you'd like to discuss with the community.",
      validate: validateDraftjsRequired("Content"),
    },
    pinned: {
      value: false,
      placeholder: "Select",
      label: "Pin to top of forum",
    },
    noComment: {
      value: false,
      label: "Comments",
      placeholder: "Select",
    },
  };
};

export default createThreadForm;
