import asArray from "Core/utils/object/asArray";

type Props = (value: string, tz: string) => string;

const dividers = {
  year: 31536000000,
  month: 2628000000,
  week: 604800000,
  day: 86400000,
  hour: 3600000,
  minute: 60000,
};

const until: Props = (value, tz) => {
  const date = new Date(`${new Date(value)}`);
  date.setHours(date.getHours() - Number(tz));

  const ms = date.getTime() - new Date().getTime();
  const [year, month, week, day, hour, minute] = asArray(dividers).map((x) => ms / x.value);
  if (year > 1) {
    return `Last ${Math.floor(year)}y`;
  } else if (month > 1) {
    return `Last ${Math.floor(month)}mo`;
  } else if (week > 1) {
    return `Last ${Math.floor(week)}w`;
  } else if (day > 1) {
    return `Last ${Math.floor(day)}d`;
  } else if (hour > 1) {
    return `Last ${Math.floor(hour)}h`;
  } else if (minute > 1) {
    return `Last ${Math.floor(minute)}min`;
  } else if (minute > 0) {
    return `Last 1 min`;
  } else {
    return `Expired`;
  }
};

export default until;
