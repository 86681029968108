import { IconComponent } from "Core/types/Icon";

const RamblyIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.6738 6.107L15.8067 4.45173C15.8942 4.18331 15.9416 3.89647 15.9416 3.5991C15.9416 2.07607 14.7067 0.841206 13.1837 0.841206C12.3699 0.841206 11.639 1.19384 11.1344 1.75436L9.59029 0.862917C9.13174 0.597785 8.6403 0.503706 8.17187 0.547127C7.95674 0.513575 7.72582 0.549101 7.51266 0.672127L7.21069 0.846469C7.1903 0.857654 7.1699 0.869496 7.14951 0.881338L0.506743 4.71686C-0.168914 5.10699 -0.168914 6.08199 0.506743 6.47147L5.97451 9.62805V13.4399C5.97451 15.2958 7.98306 16.4557 9.59029 15.5274L18.6745 10.2827C20.2817 9.35502 20.2817 7.03528 18.6745 6.10765L18.6738 6.107Z"
      fill="#FC6264"
    />
    <path
      d="M13.2141 5.88498C14.4153 5.88498 15.3891 4.90796 15.3891 3.70274C15.3891 2.49753 14.4153 1.52051 13.2141 1.52051C12.0128 1.52051 11.0391 2.49753 11.0391 3.70274C11.0391 4.90796 12.0128 5.88498 13.2141 5.88498Z"
      fill="white"
    />
    <path
      d="M13.8629 4.59598C14.4635 4.59598 14.9504 4.10732 14.9504 3.50453C14.9504 2.90174 14.4635 2.41309 13.8629 2.41309C13.2623 2.41309 12.7754 2.90174 12.7754 3.50453C12.7754 4.10732 13.2623 4.59598 13.8629 4.59598Z"
      fill="#1A191F"
    />
    <path
      d="M13.2153 3.27975C13.5154 3.27975 13.7587 3.03557 13.7587 2.73436C13.7587 2.43315 13.5154 2.18896 13.2153 2.18896C12.9152 2.18896 12.6719 2.43315 12.6719 2.73436C12.6719 3.03557 12.9152 3.27975 13.2153 3.27975Z"
      fill="white"
    />
  </svg>
);

export default RamblyIcon;
