import { EditorState, Modifier, SelectionState } from "draft-js";

type Props = (state: EditorState, type: string, data?: any, selection?: SelectionState) => EditorState;

const toggleEntity: Props = (state, type, data, selection) => {
  const newState = selection ? EditorState.acceptSelection(state, selection) : state;
  const contentState = state.getCurrentContent();
  const currentSelection = selection || newState.getSelection();

  if (!data) {
    return EditorState.set(newState, {
      currentContent: Modifier.applyEntity(contentState, currentSelection, null),
    });
  } else {
    const newContentState = contentState.createEntity(type, "MUTABLE", data);
    const entityKey = newContentState.getLastCreatedEntityKey();
    return EditorState.set(newState, {
      currentContent: Modifier.applyEntity(newContentState, currentSelection, entityKey),
    });
  }
};

export default toggleEntity;
