import api from "Core/api";
import { ForumThread } from "Forum/types/Thread";
import { Pagination } from "Core/types/Pagination";

type Response = { items: ForumThread[]; count: number };

type ApiResponse = { items: ForumThread[]; count: number };

type Props = (id: string, pagination: Pagination, sort?: string) => Promise<Response>;

const getTopicThreads: Props = async (id, pagination, sort = "newest") => {
  const response = await api.post<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}public/topics/${id}`, {
    ...pagination,
    sort,
  });

  return response.data;
};

export default getTopicThreads;
