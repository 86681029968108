import api from "Core/api";

type Response = void;

type ApiResponse = void;

type Props = (academyId: string) => Promise<Response>;

const deleteAcademy: Props = async (academyId) => {
  await api.delete<ApiResponse>(`${process.env.REACT_APP_HUB_API_URL}blog/${academyId}`);
  return;
};

export default deleteAcademy;
