import styles from "./styles.module.scss";
import { Text } from "Content/types/ContentEditor/Text";
import DraftjsEditor from "Content/components/DraftjsEditor";
import { ContentItemViewProps } from "Content/types/ContentEditor/Item";

type Props = ContentItemViewProps<Text> & {
  active?: boolean;
  onChange?: (key: string) => (e: any) => void;
};

const TextView: React.FC<Props> = ({ value }) => {
  return (
    <div className={styles.container} style={value.data.style}>
      <DraftjsEditor value={value.data.text} />
    </div>
  );
};

export default TextView;
