import { useRef } from "react";
import { objectUtils } from "Core/utils";
import useUnmounted from "./useUnmounted";

type EventBusEvent =
  | "chat"
  | "openMenu"
  | "notification"
  | "closeCollapse"
  | "userCardToggle"
  | "refreshComments"
  | "refreshUserStatus"
  | "openChangeCardView";

type EventBusCallback = (payload?: any) => void;

type EventObject = Partial<Record<EventBusEvent, EventBusCallback[]>>;

const eventObject: EventObject = {};

const useEventBus = () => {
  const ref = useRef<EventObject>({});

  const emit = (key: EventBusEvent, payload?: any) => {
    eventObject[key]?.forEach((cb) => {
      cb(payload);
    });
  };

  const on = (key: EventBusEvent, callback: EventBusCallback) => {
    eventObject[key] ? eventObject[key]!.push(callback) : (eventObject[key] = [callback]);
    ref.current[key] ? ref.current[key]!.push(callback) : (ref.current[key] = [callback]);
  };

  const off = (key: EventBusEvent, callback: EventBusCallback) => {
    if (eventObject[key]) {
      const index = eventObject[key]!.indexOf(callback);
      eventObject[key]!.splice(index, 1);
    }
    if (ref.current[key]) {
      const index = ref.current[key]!.indexOf(callback);
      ref.current[key]!.splice(index, 1);
    }
  };

  useUnmounted(() => {
    objectUtils.asArray(ref.current).map((event) => {
      event!.value?.map((callback) => {
        off(event!.key, callback);
      });
    });
  });

  return { emit, on, off };
};

export default useEventBus;
