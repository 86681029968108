import { useState } from "react";
import { Modal } from "Core/components";

type Action<T extends Array<any>, U> = (...args: T) => Promise<U>;

type Options = {
  showError?: boolean;
};

const useRequest = <T extends Array<any>, U>(action: Action<T, U>, options?: Options) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const updateState = (x: boolean, y: string) => {
    setLoading(x);
    setError(y);
  };

  const request: Action<T, U> = async (...args: T) => {
    try {
      updateState(true, "");
      const response = await action(...args);
      updateState(false, "");
      return response;
    } catch (error: any) {
      updateState(false, String(error.message));
      if (options?.showError !== false) {
        Modal.error({ title: error.title, subtitle: error.message });
      }
      throw error;
    }
  };

  return {
    error,
    loading,
    request,
  };
};

export default useRequest;
