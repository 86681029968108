import { EditorState } from "draft-js";
// import Button from "Core/components/Button";
// import ImagePicker from "Core/components/ImagePicker";
// import fileToString from "Core/utils/file/fileToString";
// import createImage from "Core/utils/draftjs/createImage";
// import DraftjsImageIcon from "Core/components/Icon/draftjsImage";

type Props = {
  value: EditorState;
  onChange: (e: EditorState) => void;
};

const Image: React.FC<Props> = () => {
  // const handleOnChange = async (e?: File) => {
  //   const file = await fileToString(e);
  //   const newState = createImage(value, file);
  //   onChange(newState);
  // };

  return <div></div>;

  // return (
  //   <ImagePicker onChange={handleOnChange} w={758} h={758} options={["upload", "library", "instagram", "link"]} grid>
  //     <Button variant="text">
  //       <DraftjsImageIcon />
  //     </Button>
  //   </ImagePicker>
  // );
};

export type { Props as ImageProps };

export default Image;
