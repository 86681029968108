import Toolbar from "./Toolbar";
import { useState } from "react";
import { DraftjsToolbarState } from "Core/types/Draftjs";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";

type Props = (enabled?: boolean) => DraftjsToolbarState | undefined;

const useToolbar: Props = (enabled) => {
  const [toolbarState] = useState<DraftjsToolbarState | undefined>(
    enabled
      ? () => {
          const inlineToolbarPlugin = createInlineToolbarPlugin();

          return {
            plugin: inlineToolbarPlugin,
            Component: (props) => <Toolbar Component={inlineToolbarPlugin.InlineToolbar} {...props} />,
          };
        }
      : undefined
  );

  return toolbarState;
};

export default useToolbar;
