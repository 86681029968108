import styles from "./styles.module.scss";
import AlertIcon from "Core/components/Icon/alert";

const ForumThreadNoComment: React.FC = () => {
  return (
    <div className={styles.noComment}>
      <AlertIcon />
      Comments cannot be made on this forum post.
    </div>
  );
};

export default ForumThreadNoComment;
