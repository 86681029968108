type Props = (value: number) => string;

const formatSeconds: Props = (value) => {
  const minutes: number = Math.floor(value / 60);
  const seconds: number = Math.round(value % 60);

  const formattedMinutes: string = minutes < 10 ? `0${minutes}` : String(minutes);
  const formattedSeconds: string = seconds < 10 ? `0${seconds}` : String(seconds);

  return `${formattedMinutes}:${formattedSeconds}`;
};

export default formatSeconds;
