import day from "./day";
import getTimezone from "Core/utils/date/getTimezone";

type Props = (value: string, tz?: string) => boolean;

const past: Props = (value, tz = getTimezone()) => {
  const date = new Date(`${new Date(value)}`);
  const now = new Date(day().date).getTime();
  date.setHours(date.getHours() - Number(tz));

  const ms = date.getTime() - now;

  return ms < 0;
};

export default past;
