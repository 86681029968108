import getNotifications from "./getNotifications";
import readNotification from "./readNotification";
import getNotificationCount from "./getNotificationCount";
import readAllNotifications from "./readAllNotifications";

const notificationApi = {
  getNotifications,
  readNotification,
  getNotificationCount,
  readAllNotifications,
};

export default notificationApi;
