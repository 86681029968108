const dividerTypes: Record<string, any> = {
  type1: require("Content/components/PostContent/Item/Divider/Types/Type1").default,
  type2: require("Content/components/PostContent/Item/Divider/Types/Type2").default,
  type3: require("Content/components/PostContent/Item/Divider/Types/Type3").default,
  type4: require("Content/components/PostContent/Item/Divider/Types/Type4").default,
  type5: require("Content/components/PostContent/Item/Divider/Types/Type5").default,
  type6: require("Content/components/PostContent/Item/Divider/Types/Type6").default,
};

export default dividerTypes;
