import { CSSProperties, ReactNode } from "react";
import getSafeUrl from "Core/utils/url/getSafeUrl";
import getUrlWithParams from "Core/utils/draftjs/getUrlWithParams";

type Props = {
  href?: string;
  email?: boolean;
  params?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  style?: CSSProperties;
  elk?: Record<string, any>;
  target?: "_blank" | "_self";
};

const ExternalLink: React.FC<Props> = ({
  elk,
  href,
  email,
  params,
  onClick,
  disabled,
  children,
  target = "_blank",
  ...rest
}) => {
  const aHref =
    disabled || !href ? undefined : email ? `mailto:${href}` : params ? getUrlWithParams(href) : getSafeUrl(href);

  const onClicked = async () => {
    onClick?.();
  };

  return disabled ? (
    <>{children ? children : href}</>
  ) : (
    <a
      href={aHref}
      target={target}
      rel="noreferrer"
      aria-label={href}
      onClick={disabled ? undefined : onClicked}
      {...rest}
    >
      {children ? children : href}
    </a>
  );
};

export default ExternalLink;
