import { useRef } from "react";
import useUnmounted from "./useUnmounted";

type Props = (ms: number) => (callback: () => void) => void;

const useDebounce: Props = (ms) => {
  const ref = useRef<NodeJS.Timeout>();

  const debounce = (callback: () => void) => {
    clearTimeout(ref.current!);
    ref.current = setTimeout(callback, ms);
  };

  useUnmounted(() => {
    clearTimeout(ref.current);
  });

  return debounce;
};

export default useDebounce;
