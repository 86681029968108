import { useGet } from "Core/hooks";
import academyApi from "Academy/api";
import { Link } from "react-router-dom";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import { AcademyCard } from "Academy/components";
import SectionHeader from "Landing/components/SectionHeader";
import ArrowUpRightIcon from "Core/components/Icon/arrowUpRight";

const LandingAcademy: React.FC = () => {
  const latestAcademies = useGet(() => academyApi.getAcademies({ limit: 3, offset: 0 }));

  return (
    <section className={styles.landingAcademy}>
      <div className={styles.landingAcademyContent}>
        <SectionHeader
          tag="Academy"
          title="Read the latest posts"
          subtitle="Level up your content for success on Rambly and beyond."
        />
        <div className={styles.landingAcademyContentList}>
          {latestAcademies.data?.items.map((x) => (
            <Link key={x._id} to={`/academy/${x.slug}`}>
              <AcademyCard data={x} />
            </Link>
          ))}
        </div>
        <div className={styles.landingAcademyButton}>
          <Link to="/academy">
            <Button after={<ArrowUpRightIcon />}>See All Academy Posts</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LandingAcademy;
