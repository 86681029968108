import Button from "Core/components/Button";
import BoldIcon from "Core/components/Icon/bold";
import { DraftjsToolbarButtonProps } from "Core/types/Draftjs";
import toggleInlineStyle from "Core/utils/draftjs/toggleInlineStyle";

const Bold: React.FC<DraftjsToolbarButtonProps> = ({ getEditorState, setEditorState }) => {
  const editorState = getEditorState();
  const selected = editorState.getCurrentInlineStyle().has("BOLD");

  const onSelect = () => {
    setEditorState(toggleInlineStyle(editorState, "BOLD"));
  };

  return (
    <li onClick={onSelect} role={selected ? "active" : ""}>
      <span>
        <BoldIcon />
      </span>
      <h5>Bold</h5>
      {selected && <Button variant="whiteOverlay">Clear</Button>}
    </li>
  );
};

export default Bold;
