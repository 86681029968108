import ForumListPage from "./pages/ForumListPage";
import ForumTopicPage from "./pages/ForumTopicPage";
import ForumSearchPage from "./pages/ForumSearchPage";
import ForumThreadPage from "./pages/ForumThreadPage";
import { Navigate, Route, Routes } from "react-router-dom";
import ForumThreadEditPage from "./pages/ForumThreadEditPage";
import ForumThreadCreatePage from "./pages/ForumThreadCreatePage";

const Forum: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ForumListPage />} />
      <Route path="search" element={<ForumSearchPage />} />
      <Route path=":topicId" element={<ForumTopicPage />} />
      <Route path=":topicId/search" element={<ForumSearchPage />} />
      <Route path=":topicId/create" element={<ForumThreadCreatePage />} />
      <Route path=":topicId/thread/:threadId" element={<ForumThreadPage />} />
      <Route path=":topicId/thread/:threadId/edit" element={<ForumThreadEditPage />} />
      <Route path="*" element={<Navigate to="/announcement" />} />
    </Routes>
  );
};
export default Forum;
