import { useState } from "react";
import { Avatar } from "Core/components";
import styles from "./styles.module.scss";
import ForumThreadCommentsItemMenu from "./Menu";
import ForumThreadCommentsItemEdit from "./Edit";
import { dateUtils, objectUtils } from "Core/utils";
import { ForumTopicReply } from "Forum/types/Reply";
import DraftjsView from "Core/components/DraftjsEditor/View";

type ForumThreadCommentsItemProps = {
  refresh: () => void;
  data: ForumTopicReply;
};

const ForumThreadCommentsItem: React.FC<ForumThreadCommentsItemProps> = ({ data, refresh }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(objectUtils.clone(data.message));

  const onEditToggle = (e: boolean) => () => {
    setEdit(e);
  };

  const onCancel = () => {
    setEdit(false);
    setValue(objectUtils.clone(data.message));
  };

  return (
    <div className={styles.forumThreadCommentsItem}>
      <div className={styles.forumThreadCommentsItemHeader}>
        <Avatar value={data.avatar} className={styles.forumThreadCommentsItemAvatar} size={60} />
        <div className={styles.forumThreadCommentsItemUser}>
          <div className={styles.forumThreadCommentsItemName}>{data.name || data.username}</div>
          <div className={styles.forumThreadCommentsItemDate}>{dateUtils.getDateString(data.createdAt)}</div>
        </div>
        <ForumThreadCommentsItemMenu edit={edit} reply={data} refresh={refresh} onEdit={onEditToggle(true)} />
      </div>
      <div className={styles.forumThreadCommentsItemMessage}>
        {edit ? (
          <ForumThreadCommentsItemEdit
            value={value}
            id={data._id}
            onChange={setValue}
            onCancel={onCancel}
            topic={data.topicID}
            thread={data.threadID}
            onFinish={onEditToggle(false)}
          />
        ) : (
          <DraftjsView value={value} />
        )}
      </div>
      <div></div>
    </div>
  );
};

export default ForumThreadCommentsItem;
