import forumApi from "Forum/api";
import { useGet } from "Core/hooks";
import { Link } from "react-router-dom";
import { Button } from "Core/components";
import styles from "./styles.module.scss";
import ForumListItem from "Forum/pages/ForumListPage/Item";
import SectionHeader from "Landing/components/SectionHeader";
import ArrowUpRightIcon from "Core/components/Icon/arrowUpRight";

const LandingForum: React.FC = () => {
  const topics = useGet(() => forumApi.getTopics({ limit: 6, offset: 0 }));

  return (
    <section className={styles.landingForum}>
      <div className={styles.landingForumContent}>
        <SectionHeader
          tag="Forum"
          title="Join the conversation"
          subtitle="Connect with creators to share ideas and engage in vibrant discussions."
        />
        <div className={styles.landingForumContentList}>
          {topics.data?.items.map((x) => (
            <Link key={x._id} to={`/forum/${x._id}`}>
              <ForumListItem data={x} />
            </Link>
          ))}
        </div>
        <div className={styles.landingForumButton}>
          <Link to="/forum">
            <Button after={<ArrowUpRightIcon />}>See All Forums</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LandingForum;
