import styles from "./styles.module.scss";
import Select from "Core/components/Select";
import { ButtonVariant } from "Core/components/Button";

type Props = {
  value?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
  onChange?: (e: boolean) => void;
};

const PinSelect: React.FC<Props> = ({ value, disabled, variant, onChange }) => {
  return (
    <div className={styles.pinSelect}>
      <Select
        value={value}
        variant={variant}
        disabled={disabled}
        onChange={onChange}
        placeholder="Select"
        label="Pin to top of forum*"
        className={styles.pinSelectSelection}
      >
        <Select.Option value={true}>Yes</Select.Option>
        <Select.Option value={false}>No</Select.Option>
      </Select>
    </div>
  );
};

export default PinSelect;
