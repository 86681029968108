type Platform = "connect.facebook.net" | "instagram.com" | "twitter.com" | "trellocdn.com";
type Embed = {
  platform: Platform;
  isLoaded: () => boolean;
  reload: (e: HTMLDivElement) => void;
};

const embeds: Embed[] = [
  {
    platform: "connect.facebook.net",
    isLoaded() {
      if (!document.querySelector("body > #fb-root")) {
        const list = document.querySelectorAll("#fb-root");
        [...Array.from(list)].forEach((n) => n.parentNode?.removeChild(n));
        const fbDiv = document.createElement("div");
        fbDiv.id = "fb-root";
        document.body.appendChild(fbDiv);
      }
      return window.FB !== undefined;
    },
    reload: (e: HTMLDivElement) => window.FB?.XFBML.parse(e),
  },
  {
    platform: "instagram.com",
    isLoaded: () => window.instgrm !== undefined,
    reload: () => window.instgrm?.Embeds.process(),
  },
  {
    platform: "twitter.com",
    isLoaded: () => window.twttr !== undefined && window.twttr.widgets !== undefined,
    reload: () => window.twttr?.widgets?.load(),
  },
  {
    platform: "trellocdn.com",
    isLoaded: () => window.TrelloCards !== undefined && window.TrelloCards.load !== undefined,
    reload: () =>
      window.TrelloCards?.load?.(document, {
        compact: false,
        allAnchors: false,
      }),
  },
];

export const getEmbedConfiguration = (src: string): Embed | undefined => {
  const embed = embeds.find((x) => src.indexOf(x.platform) > -1);
  return embed;
};

export default embeds;
