import { IconComponent } from "Core/types/Icon";

const LockIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.12"
      d="M6.83333 16.6667C10.055 16.6667 12.6667 14.055 12.6667 10.8333C12.6667 7.61167 10.055 5 6.83333 5C3.61167 5 1 7.61167 1 10.8333C1 14.055 3.61167 16.6667 6.83333 16.6667Z"
      fill="currentColor"
    />
    <path
      d="M2.75085 6.83333H2.66667V5.16667C2.66667 2.86548 4.53215 1 6.83333 1C9.13452 1 11 2.86548 11 5.16667V6.83333H10.9158M6.83333 10.1667V11.8333M12.6667 11C12.6667 14.2217 10.055 16.8333 6.83333 16.8333C3.61167 16.8333 1 14.2217 1 11C1 7.77834 3.61167 5.16667 6.83333 5.16667C10.055 5.16667 12.6667 7.77834 12.6667 11Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockIcon;
