import api from "Core/api";

type Response = void;

type ApiResponse = void;

type Props = (id: string[]) => Promise<Response>;

const readAllNotifications: Props = async (ids) => {
  await Promise.all(ids.map((x) => api.get<ApiResponse>(`notification/markread/${x}`)));
  return;
};

export default readAllNotifications;
