import useAuth from "Auth/context";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Logo, Button } from "Core/components";
import MessageIcon from "Core/components/Icon/message";
import { NotificationButton } from "Notification/components";
import { NavigationMenu, AccountMenu, GuestMenu } from "Root/components";

const RootLayoutHeader: React.FC = () => {
  const auth = useAuth();

  const onClick = () => {
    window.scroll({ top: -1, left: 0 });
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <Link to="/">
          <Logo className={styles.logo} onClick={onClick} light />
        </Link>
        <NavigationMenu className={styles.headerNavigationMenu} />
        <div className={styles.headerRight}>
          {auth.user ? (
            <>
              <a href={`${process.env.REACT_APP_URL}chat`}>
                <Button variant="whiteOutline" className={styles.circle}>
                  <MessageIcon />
                </Button>
              </a>
              <NotificationButton />
              <AccountMenu />
            </>
          ) : (
            <GuestMenu />
          )}
        </div>
      </div>
    </header>
  );
};

export default RootLayoutHeader;
