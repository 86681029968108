import api from "Core/api";
import { ForumThreadForm } from "Forum/types/Form";

type Response = string;

type ApiResponse = string;

type Props = (topicId: string, threadId: string, form: ForumThreadForm) => Promise<Response>;

const updateThread: Props = async (topicId, threadId, form) => {
  const response = await api.post<ApiResponse>(
    `${process.env.REACT_APP_HUB_API_URL}topics/${topicId}/thread/${threadId}/update`,
    form
  );
  return response.data;
};

export default updateThread;
