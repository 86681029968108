import forumApi from "Forum/api";
import { useState } from "react";
import styles from "./styles.module.scss";
import { useGet, useUpdated } from "Core/hooks";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { EmptyContainer, PageHeader, Pagination } from "Core/components";
import { SearchButton, ThreadCard, ThreadCreateButton, ThreadSortSelect } from "Forum/components";

const ForumTopicPage: React.FC = () => {
  const { topicId } = useParams();
  const [params, setParams] = useSearchParams();
  const topic = useGet(() => forumApi.getTopic(String(topicId)));
  const [sort, setSort] = useState(params.get("s") || "newest");
  const [page, setPage] = useState(Number(params.get("p") || "1"));
  const threads = useGet(() => forumApi.getTopicThreads(String(topicId), { limit: 10, offset: (page - 1) * 10 }, sort));

  const updateParams = (newPage: number, newSort: string) => {
    setParams(
      (p) => {
        p.set("s", newSort);
        p.set("p", String(newPage));
        return p;
      },
      { replace: true }
    );
  };

  useUpdated(() => {
    threads.request();
    updateParams(page, sort);
  }, page);

  useUpdated(() => {
    page === 1 ? threads.request() : setPage(1);
    updateParams(page, sort);
  }, sort);

  return (
    <div className={styles.forumSearchPage}>
      <EmptyContainer empty={!topic.data} loading={topic.loading}>
        {topic.data && (
          <PageHeader
            back
            title={topic.data?.title}
            subtitle={topic.data?.description.join(", ")}
            extra={<ThreadCreateButton topicId={String(topicId)} />}
          />
        )}
      </EmptyContainer>
      <div className={styles.forumSearchPageContainer}>
        <div className={styles.forumSearchPageContent}>
          <div className={styles.forumSearchPageFilter}>
            <SearchButton />
            <div className={styles.forumSearchPageSelect}>
              <ThreadSortSelect value={sort} onChange={setSort} variant="outline" />
            </div>
          </div>
          <EmptyContainer
            loading={threads.loading}
            emptyText={`There are no threads in this topic.`}
            empty={threads.data && !threads.data?.items.length}
          >
            {threads.data && (
              <div className={styles.forumSearchPageContentList}>
                {threads.data?.items.map((x) => (
                  <Link key={x._id} to={`/forum/${x.topicID}/thread/${x._id}`}>
                    <ThreadCard data={x} />
                  </Link>
                ))}
              </div>
            )}
            <Pagination
              pageSize={10}
              currentPage={page}
              onPageChange={setPage}
              showOnSinglePage={false}
              totalRecords={threads.data?.count}
            />
          </EmptyContainer>
        </div>
      </div>
    </div>
  );
};

export default ForumTopicPage;
