import { IconComponent } from "Core/types/Icon";

const UserIcon: IconComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C6.74022 0 5.53204 0.500445 4.64124 1.39124C3.75045 2.28204 3.25 3.49022 3.25 4.75C3.25 6.00978 3.75045 7.21796 4.64124 8.10876C5.53204 8.99955 6.74022 9.5 8 9.5C9.25978 9.5 10.468 8.99955 11.3588 8.10876C12.2496 7.21796 12.75 6.00978 12.75 4.75C12.75 3.49022 12.2496 2.28204 11.3588 1.39124C10.468 0.500445 9.25978 0 8 0V0ZM5 11C3.74022 11 2.53204 11.5004 1.64124 12.3912C0.750445 13.282 0.25 14.4902 0.25 15.75C0.25 17.0098 0.750445 18.218 1.64124 19.1088C2.53204 19.9996 3.74022 20.5 5 20.5H11C12.2598 20.5 13.468 19.9996 14.3588 19.1088C15.2496 18.218 15.75 17.0098 15.75 15.75C15.75 14.4902 15.2496 13.282 14.3588 12.3912C13.468 11.5004 12.2598 11 11 11H5Z"
      fill="currentColor"
    />
  </svg>
);

export default UserIcon;
