import useMounted from "./useMounted";
import useUpdated from "./useUpdated";
import useUnmounted from "./useUnmounted";
import { useCallback, useRef, useState } from "react";

type Props = (callback: (width: number, prev: number) => void, render?: boolean) => void;

const useResize: Props = (callback, render) => {
  const ref = useRef<number>(0);
  const [value, setValue] = useState(0);
  const cb = useCallback(callback, []);

  const onResize = () => {
    if (render) {
      setValue(window.innerWidth || document.documentElement.clientWidth);
    } else {
      cb(window.innerWidth || document.documentElement.clientWidth, ref.current);
      ref.current = window.innerWidth || document.documentElement.clientWidth;
    }
  };

  useMounted(() => {
    onResize();
    window.addEventListener("resize", onResize);
  });

  useUnmounted(() => {
    window.removeEventListener("resize", onResize);
  });

  useUpdated(() => callback(value, ref.current), value);
};

export default useResize;
