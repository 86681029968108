import api from "Core/api";
import { Notification } from "Notification/types/Notification";

type Response = Notification[];

type ApiResponse = Notification[];

type Props = () => Promise<Response>;

const getNotifications: Props = async () => {
  const response = await api.post<ApiResponse>("notification/list", { offset: 0, limit: 0 });
  response.data = response.data.filter((x) => !x.read);

  return response.data;
};

export default getNotifications;
