import getHTML from "./getHTML";
import { DraftjsState } from "Core/types/Draftjs";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

type Props = (value?: string) => DraftjsState;

const createDraftjsState: Props = (value = "") => {
  const state = EditorState.createWithContent(
    convertFromRaw({
      entityMap: {},
      blocks: [
        {
          text: value,
          key: "foo",
          type: "unstyled",
          entityRanges: [],
          depth: 1,
          inlineStyleRanges: [],
        },
      ],
    })
  );

  return {
    html: getHTML(state),
    value: convertToRaw(state.getCurrentContent()),
  };
};

export default createDraftjsState;
