import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import Portal from "Core/components/Portal";
import useMounted from "Core/hooks/useMounted";
import useUpdated from "Core/hooks/useUpdated";
import { Placement } from "Core/types/Placement";
import useUnmounted from "Core/hooks/useUnmounted";
import useOuterClick from "Core/hooks/useOuterClick";
import getPopoverPosition from "Core/utils/element/getPopoverPosition";
import { forwardRef, ReactNode, useImperativeHandle, useRef, useState, MouseEvent, useEffect } from "react";

type PopoverVariant = "dark" | "light";
type PopoverTrigger = "click" | "context";

type Ref = { close: () => void; open: () => void };

type Props = {
  ignore?: string[];
  title?: ReactNode;
  className?: string;
  placement?: Placement;
  forceRender?: boolean;
  mobileCenter?: boolean;
  closeOnClick?: boolean;
  closeOnScroll?: boolean;
  trigger?: PopoverTrigger;
  variant?: PopoverVariant;
  popoverClassName?: string;
  onChange?: (e: boolean) => void;
  children: [trigger: ReactNode, content: ReactNode];
};

const Popover = forwardRef<Ref, Props>(
  (
    {
      title,
      ignore,
      onChange,
      children,
      className,
      forceRender,
      mobileCenter,
      closeOnClick,
      closeOnScroll,
      popoverClassName,
      variant = "dark",
      trigger = "click",
      placement = "bottom",
    },
    ref
  ) => {
    const [triggerChild, contentChild] = children;
    const [visible, setVisible] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLSpanElement>(null);
    const [position, setPosition] = useState({ top: -1, left: -1 });
    const isVisible = position.top !== -1 && position.left !== -1;

    const onOpen = () => {
      setVisible(true);
    };

    const onContext = (e: MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
      onOpen();
    };

    const onClose = () => {
      setVisible(false);
      setPosition({ top: -1, left: -1 });
    };

    const onContentClick = () => {
      if (closeOnClick) {
        onClose();
      }
    };

    useUpdated(() => {
      onChange?.(visible);
      if (visible) {
        setTimeout(() => {
          setPosition(getPopoverPosition(triggerRef.current!, popoverRef.current!, placement));
        }, 30);
      }
    }, visible);

    useOuterClick(() => onClose(), popoverRef, ignore);

    useMounted(() => {
      if (closeOnScroll) {
        window.addEventListener("scroll", onClose);
      }
    });

    useUnmounted(() => {
      window.removeEventListener("scroll", onClose);
    });

    useEffect(() => {
      if (!popoverRef.current) return;
      const resizeObserver = new ResizeObserver(() => {
        setPosition((prev) => {
          return prev.top === -1 && prev.left === -1
            ? { top: -1, left: -1 }
            : getPopoverPosition(triggerRef.current!, popoverRef.current!, placement);
        });
      });
      resizeObserver.observe(popoverRef.current);
      return () => resizeObserver.disconnect();
    }, []);

    useImperativeHandle(ref, () => ({ close: onClose, open: onOpen }));
    return (
      <>
        {(visible || forceRender) && (
          <Portal container={document.getElementById("root")!}>
            <div
              style={{ ...position, zIndex: !isVisible ? -1 : undefined }}
              className={cx(styles[variant], styles.container, popoverClassName, styles[placement], [
                styles.center,
                mobileCenter,
              ])}
            >
              <div ref={popoverRef}>
                <div
                  onClick={onContentClick}
                  style={{ opacity: isVisible ? 1 : 0 }}
                  className={cx(styles.card, styles[variant], className)}
                >
                  {title && <div className={styles.title}>{title}</div>}
                  {contentChild}
                </div>
              </div>
            </div>
          </Portal>
        )}
        <span
          ref={triggerRef}
          onClick={trigger === "click" ? onOpen : undefined}
          onContextMenu={trigger === "context" ? onContext : undefined}
        >
          {triggerChild}
        </span>
      </>
    );
  }
);

export type { Ref as PopoverRef };

export default Popover;
